// asdff
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

/**
 * workaround react-hook-form issue
 */
export function useCustomField<T>(fieldId: string, defaultValue: T) {
	const { setValue, clearErrors, watch } = useFormContext();
	const currentValue = watch(fieldId);
	const [isChecked, setChecked] = useState(!!currentValue);

	const [lastState, setLastState] = useState<T | null>(defaultValue);

	const [nextAction, setNextAction] = useState<
		"idle" | "setNull" | "clear" | "validate"
	>("idle");

	useEffect(() => {
		if (nextAction === "setNull") {
			// set the value to null AFTER the field has been unmounted from the DOM (react-hook-form issue)
			// TODO: re-evaluate after update to v7
			setValue(fieldId, null, {
				shouldValidate: true,
				shouldDirty: true,
			});
			setChecked(false);
			setNextAction("clear");
		}
	}, [nextAction, setValue]);

	useEffect(() => {
		if (nextAction === "clear") {
			// I think this doesn't even do anything
			clearErrors(fieldId);
			setNextAction("idle");
		}
	}, [nextAction, clearErrors]);

	const clearField = () => {
		setLastState(currentValue);
		setChecked(false);
		setNextAction("setNull");
	};

	const checkField = () => {
		// restore state
		setValue(fieldId, lastState, {
			shouldValidate: true,
			shouldDirty: true,
		});
		setChecked(true);
	};

	const setFieldChecked = (value: boolean) => {
		if (value) {
			checkField();
		} else clearField();
	};

	return { clearField, checkField, isChecked, setFieldChecked };
}
