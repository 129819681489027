import React from "react";
import { HospitalityExpense } from "@models";
import BeneficiaryDetails from "./BeneficiaryDetails";
import { Currency } from "@util";
import { LargeText } from "@components";

type Props = {
	expense: HospitalityExpense;
};

export default function HospitalityExpenseDetails(props: Props) {
	const { expense } = props;
	return (
		<>
			{expense.beneficiaries.length > 0 && (
				<BeneficiaryDetails beneficiaries={expense.beneficiaries} />
			)}
			<LargeText style={{ margin: "16px 16px 4px" }}>Trinkgeld</LargeText>
			<div style={{ margin: "0 16px 16px" }}>
				<span>
					Angegebener Betrag:{" "}
					{Currency.format(expense.tip, { hideZeroDecimals: true })}
				</span>
				<br />
				<span>
					Erstattungsfähiger Betrag:{" "}
					{Currency.format(expense.reimbursableTip, { hideZeroDecimals: true })}
				</span>
				<br />
				<span className="text-muted small">
					Es dürfen steuerlich max. 10% des Bewirtungsbetrags als Trinkgeld
					erstattet werden.
				</span>
			</div>
		</>
	);
}
