import { Category, Product, RootProductId } from "@models";
import { useCallback, useContext, useMemo } from "react";
import StaticDataContext, { StaticData } from "../context/StaticDataContext";

export default function useStaticData() {
	const context = useContext(StaticDataContext);
	if (context === undefined) {
		throw new Error("StaticDataContext is not initialized");
	}

	return {
		...context,
		getCategory: useCallback(
			(category: string) => getCategory(context, category)?.category,
			[context]
		),
		getProductFor: useCallback(
			(category: string) => {
				const product = getCategory(context, category)?.product;
				if (!product)
					throw new Error(`product for category ${category} not found`);
				return product;
			},
			[context]
		),
		getProductOfId: useMemo(
			() => getProductOfId.bind(null, context),
			[context]
		),
	};
}

function getCategory(
	context: StaticData,
	categoryId: string
): { category: Category; product: Product } | undefined {
	for (const product of context.products) {
		for (const variant of product.variants) {
			const foundCategory = variant.findCategory(categoryId);
			if (foundCategory) {
				return { category: foundCategory, product };
			}
		}

		if (product.id === categoryId) {
			// issue a new Category if it matches with the productId
			// TODO: refactor and solve differently
			const category = new Category({
				id: categoryId,
				name: product.name,
				longName: product.name,
				isDeprecated: false,
			});
			category.product = product;
			return {
				category: category,
				product,
			};
		}
	}

	return undefined;
}

function getProductOfId(
	context: StaticData,
	productId: RootProductId
): Product {
	const product = context.products.find((p) => p.id === productId);
	if (!product) throw new Error(`product with id ${productId} not found`);
	return product;
}
