import React, { useCallback, useState } from "react";

import {
	useAsyncCommand,
	useFetchedData,
	useServices,
	useStaticData,
} from "@hooks";

import { Division } from "@models";
import { ReportSettingItem } from "@models/ReportTypeSetting";
import { defer } from "@util/helpers";
import { getUpdatedReportTypeSettings } from "../../../../pages/reports/util";
import PayrollAccountForm from "./PayrollAccountForm";

type Props = {
	division: Division;
	onClickCancel: () => void;
	refreshPage: () => void;
};

export default function PayrollSettings(props: Props) {
	const { division, refreshPage } = props;
	const { reportTypes } = useStaticData();
	const payrollReportTypes = reportTypes.filter((r) => r.isPayrollReport);
	const [reportTypeId, setReportTypeId] = useState(division.reportType ?? "");
	const { reportService } = useServices();

	const getReportPayrollSettings = useCallback(
		() =>
			reportService.getReportPayrollSettings(division.divisionId, reportTypeId),
		[division.divisionId, reportService, reportTypeId]
	);

	const [reportSettings] = useFetchedData(getReportPayrollSettings);

	const [updateReportSettings, reportSettingsQuery] = useAsyncCommand(
		(updated: ReportSettingItem[], reportTypeId: string | null) =>
			reportService.updateReportPayrollSettings({
				divisionId: division.divisionId,
				reportTypeId,
				settings: updated,
			})
	);

	const handleOnSubmit = async (data: { [x: string]: string }) => {
		const reportTypeSettings = { ...data };
		const reportTypeId =
			reportTypeSettings.reportType === ""
				? null
				: reportTypeSettings.reportType;

		const updated = getUpdatedReportTypeSettings(reportTypeSettings);
		const resultTrigger = await updateReportSettings(updated, reportTypeId);

		if (resultTrigger.state === "success") {
			refreshPage();
			await defer(props.onClickCancel);
		}
	};

	if (reportSettings.data) {
		return (
			<PayrollAccountForm
				payrollReportTypes={payrollReportTypes}
				reportTypeSettings={reportSettings.data.payrollAccountingItems}
				setReportTypeId={setReportTypeId}
				reportTypeId={reportTypeId}
				reportSettingsQuery={reportSettingsQuery}
				onClickCancel={props.onClickCancel}
				onSubmit={handleOnSubmit}
			/>
		);
	} else {
		return <></>;
	}
}
