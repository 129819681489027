import Keycloak from "keycloak-js";

import { envConfig } from "@service/config";

async function initKeycloak() {
	const keycloak = new Keycloak({
		url: envConfig.auth.url,
		realm: envConfig.auth.realm,
		clientId: envConfig.auth.clientId,
	});

	if (process.env.NODE_ENV !== "test") {
		await keycloak.init({
			checkLoginIframe: false, // Fix auth on Firefox
		});
	}

	return keycloak;
}

const _keycloakPromise = initKeycloak();

export async function keycloak(): Promise<Keycloak> {
	return await _keycloakPromise;
}
