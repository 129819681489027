import { colors } from "@theme/theming";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

const HintText = styled(Alert).attrs({ variant: "info" })`
	color: ${colors.text};
	background-color: ${colors.gray.g100};
	border-color: ${colors.gray.g100};
`;

export default HintText;
