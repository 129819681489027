import React from "react";
import { Input } from "./Input";

type Props = {
	min?: number;
	value: number | null;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, "as" | "value" | "type">;

const NumberInput = React.forwardRef((props: Props, ref) => {
	const { min, value, onChange, ...rest } = props;
	const inputValue = value ?? "";

	const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const targetValue: any = e.target.value !== "" ? e.target.value : null;
		onChange?.({ ...e, target: { ...e.target, value: targetValue } });
	};

	return (
		<Input
			type="number"
			min={min}
			onChange={customOnChange}
			value={inputValue}
			ref={ref}
			{...rest}
		/>
	);
});

export default NumberInput;
