import React from "react";
import { Header } from "./TableTypes";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AiOutlineLine } from "react-icons/ai";
import { Tooltip } from "@components";
import styled from "styled-components";
import { SortState } from "hooks/useSortQueryParams";

type Props = {
	header: Header;
	sort: SortState;
	children?: React.ReactNode;
};

export function SortControl(props: Props) {
	const { header, sort, children } = props;
	const { by, order, setSortState } = sort;

	if (!header.isSortable) return null;

	const tooltip = `${
		sort.order === "asc" ? "Absteigend" : "Aufsteigend"
	} nach ${header.title} sortieren`;

	return (
		<Tooltip id="tooltip-sort-table" tooltip={tooltip}>
			<Span
				data-testid="sort-icon-container"
				onClick={() => {
					setSortState(header.accessor, order === "asc" ? "desc" : "asc");
				}}
			>
				{children}
				{by === header.accessor ? (
					order && order?.toLowerCase() === "desc" ? (
						<SortIconSVGContainer>
							<FaChevronDown data-testid="sort-icon-down" />
						</SortIconSVGContainer>
					) : (
						<SortIconSVGContainer>
							<FaChevronUp data-testid="sort-icon-up" />
						</SortIconSVGContainer>
					)
				) : (
					<SortIconSVGContainer>
						<AiOutlineLine data-testid="sort-icon-neutral" />
					</SortIconSVGContainer>
				)}
			</Span>
		</Tooltip>
	);
}

export const Span = styled.span`
	margin-left: 4px;
	display: flex;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`;

const SortIconSVGContainer = styled.span`
	width: 1em;
	height: 1em;
	display: inline-grid;
	place-items: center;
	border: 1px solid black;
	padding: 0 1px;
	margin-left: 0.25rem;

	svg {
		width: 0.75em;
		height: 0.75em;
	}
`;
