import React from "react";
import { Icon } from "@components";
import { TransportationType } from "@models/travel/RouteLeg";

type Props = {
	mode: TransportationType;
};

export default function TransportModeIcon(props: Props) {
	const { mode } = props;
	switch (mode) {
		case TransportationType.PrivateVehicle:
		case TransportationType.BusinessVehicle:
			return <Icon.TransportationType.Vehicle />;
		case TransportationType.Other:
			return <Icon.TransportationType.PublicTransport />;
		case TransportationType.NoCosts:
			return <Icon.TransportationType.Walk />;
		default:
			return null;
	}
}
