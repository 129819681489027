import React from "react";
import { Voucher, VoucherStatus } from "@models";
import Icon from "./icons";
import BusinessTrip, { TripStatus } from "@models/travel/BusinessTrip";

type Props = { voucher: Voucher } | { trip: BusinessTrip };

export default function StatusIcon(props: Props) {
	if ("voucher" in props) {
		switch (props.voucher.reviewStatus) {
			case VoucherStatus.Approved:
				return <Icon.Status.Approved />;
			case VoucherStatus.Rejected:
				return <Icon.Status.Rejected />;
			case VoucherStatus.InReview:
				return <Icon.Status.InReview />;
			default:
				return null;
		}
	} else {
		switch (props.trip.status) {
			case TripStatus.Approved:
				return <Icon.Status.Approved />;
			case TripStatus.Rejected:
				return <Icon.Status.Rejected />;
			case TripStatus.InReview:
				return <Icon.Status.InReview />;
			case TripStatus.InReviewByEmployer:
				return <Icon.Status.InReviewByEmployer />;
			case TripStatus.ApprovedByEmployer:
				return <Icon.Status.ApprovedByEmployer />;
			case TripStatus.RejectedByEmployer:
				return <Icon.Status.RejectedByEmployer />;
			default:
				return null;
		}
	}
}
