import React, { useRef, useState } from "react";
import { NewContract, Contract, BudgetMaster } from "@models";
import { CancelDialog, ExpandCard } from "@components";
import { ContractCardHeader } from "./ContractCardHeader";
import ContractConfiguration from "./ContractConfiguration";
import { isNewContract } from "../util";
import { ExpandCardRef } from "components/ExpandCard";
import { getDateFlags } from "@util";
import checkCorrespondingBudgetMaster from "../util/checkCorrespondingBudgetMaster";
import styles from "@theme/styles";
import { useStaticData } from "@hooks";

type Props = {
	contract: NewContract | Contract;
	budgetMasters: BudgetMaster[];
	className?: string;
	isInitiallyExpanded?: boolean;
	discardContract?: () => void;
};

export default function ContractCard(props: Props) {
	const { contract, isInitiallyExpanded, budgetMasters } = props;
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [mustShowCloseWarning, setMustShowCloseWarning] = useState(false);
	const expandCardRef = useRef<ExpandCardRef>(null);

	const isNew = isNewContract(contract);

	const collapseCard = () => {
		expandCardRef.current?.collapse();
		if (isNew && props.discardContract) {
			props.discardContract();
		}
	};

	const onCloseEditMode = (needsConfirmation: boolean) => {
		if (needsConfirmation && (mustShowCloseWarning || isNew)) {
			setShowCancelDialog(true);
			return false;
		} else {
			collapseCard();
			return true;
		}
	};

	const uniqueId = "contractId" in contract ? contract.contractId : "new";
	const { isActive } = getDateFlags(contract.startDate, contract.endDate);

	const { getProductFor } = useStaticData();
	const product = getProductFor(contract.category);

	const hasCorrespondingBudget = checkCorrespondingBudgetMaster(
		contract,
		budgetMasters,
		product
	);

	const header = (
		<ContractCardHeader
			contract={contract}
			hasCorrespondingBudget={hasCorrespondingBudget}
		/>
	);

	return (
		<>
			<ExpandCard
				className={props.className}
				testId={"contractCard-" + uniqueId}
				css={`
					margin-bottom: 10px;
					${isNew ? styles.NewCard : ""}
				`}
				header={header}
				ref={expandCardRef}
				isInitiallyExpanded={isInitiallyExpanded}
				isActive={isActive}
				onCollapse={() => onCloseEditMode(true)}
			>
				<ContractConfiguration
					key={"ContractConfig-" + uniqueId}
					contract={contract}
					onCloseCard={onCloseEditMode}
					onFormDirty={setMustShowCloseWarning}
					hasCorrespondingBudget={hasCorrespondingBudget}
				/>
			</ExpandCard>

			<CancelDialog
				showAlertDialog={showCancelDialog}
				cancelEditMode={collapseCard}
				hideDialog={() => setShowCancelDialog(false)}
			>
				Sie haben ungespeicherte Änderungen in der Konfiguration des
				Dauerbelegs. Änderungen wirklich verwerfen?
			</CancelDialog>
		</>
	);
}
