import { DeepMutable, Properties } from "@util/types";
import _ from "lodash";
import Product from "./Product";

export default class Category {
	constructor(
		props: Omit<
			DeepMutable<Properties<Category>>,
			"root" | "parent" | "parents" | "product"
		>
	) {
		this.id = props.id;
		this.name = props.name;
		this.longName = props.longName ?? props.name;
		this.isDeprecated = props.isDeprecated;
		this.maxAmountMonth = props.maxAmountMonth;
		this.subCategories = props.subCategories?.map((c) => new Category(c));
		this.extArticleId = props.extArticleId;

		// link parent chain
		if (props.subCategories) {
			this.subCategories?.forEach((sc) => sc.setParent(this));
		}
	}

	get root(): Category {
		// TODO: precompute this
		return !this.parent ? this : this.parent.root;
	}

	get parents(): Category[] {
		if (this.parent) return [this.parent, ...this.parent.parents];
		return [];
	}

	findCategory(categoryId: string): Category | undefined {
		if (this.id === categoryId) {
			return this;
		}

		for (const category of this.subCategories ?? []) {
			if (category.id === categoryId) {
				return category;
			}

			const found = category.findCategory(categoryId);
			if (found) return found;
		}
		return undefined;
	}

	static fromJson(data: any) {
		return new Category({
			...data,
			subCategories: data.subCategories
				? _.sortBy(
						data.subCategories.map(Category.fromJson) as any,
						(category) => category.name.toLowerCase()
				  )
				: [],
		});
	}

	readonly id: string;
	readonly name: string;
	readonly longName: string;
	readonly isDeprecated: boolean;
	readonly maxAmountMonth?: number;
	readonly subCategories?: Category[];
	readonly extArticleId?: string;
	private _product: Product | undefined;
	private _parent: Category | undefined;

	get parent(): Category | undefined {
		return this._parent;
	}

	private setParent(category: Category) {
		this._parent = category;
	}

	get product(): Product {
		if (!this._product) {
			throw new Error(
				`Product for category ${this.id} has not been initialized`
			);
		}
		return this._product;
	}

	/**
	 * @private do not call directly, should only be called by the Product
	 */
	set product(product: Product) {
		if (this._product) {
			throw new Error(
				`Product for category ${this.id} has already been initialized`
			);
		}
		this._product = product;
		this.subCategories?.forEach((c) => (c.product = product));
	}
}

export function flattenCategories(category: Category): Category[] {
	if (category.subCategories) {
		const subs = _.flatMap(category.subCategories, flattenCategories);
		return [category, ...subs];
	}
	return [category];
}
