import React from "react";
import styled, { CSSProperties } from "styled-components";
import {
	ProductIcon,
	StatusIcon,
	CategoryCell,
	IconContainer,
	FlexCol,
	IconWrapper,
	AnimatedCollapsed,
	Icon,
	Tooltip,
} from "@components";
import {
	FaUpload as UploadedIcon,
	FaCalendar as DateIcon,
	FaCalendarCheck as ReviewIcon,
} from "react-icons/fa";
import BusinessTrip from "@models/travel/BusinessTrip";
import { formatDate } from "@components/forms/datePickerUtils";
import { Currency } from "@util";
import { StyledFlexCol } from "@components/vouchers/VoucherCardHeader";
import { useProduct } from "@hooks";
import { colors } from "@theme/theming";

type Props = {
	trip: BusinessTrip;
	expanded?: boolean;
	style?: CSSProperties;
};

export default function TripCardHeader(props: Props) {
	const { trip, expanded } = props;
	const product = useProduct(trip.productId);

	return (
		<CardHeaderContainer>
			<StyledFlexCol>
				<div title="Erstellt am" style={{ width: "10rem" }}>
					<IconWrapper>
						<DateIcon />
					</IconWrapper>
					{expanded
						? formatDate(trip.created, "dd.MM.yyyy HH:mm")
						: formatDate(trip.created)}
				</div>
				<AnimatedCollapsed expanded={expanded ?? false}>
					<div title="Bearbeitet am">
						<IconWrapper>
							<UploadedIcon />
						</IconWrapper>
						{formatDate(trip.updated, "dd.MM.yyyy HH:mm")}
					</div>
					{trip.reviewedAt && (
						<div title="Geprüft am">
							<IconWrapper>
								<ReviewIcon />
							</IconWrapper>
							{formatDate(trip.reviewedAt, "dd.MM.yyyy HH:mm")}
						</div>
					)}
				</AnimatedCollapsed>
			</StyledFlexCol>
			{"userName" in trip ? (
				<div>
					<div>{trip.userName}</div>
					<div className="text-muted small">{trip.customerName}</div>
					<div className="text-muted small">{trip.divisionName}</div>
				</div>
			) : (
				<div></div>
			)}
			<CategoryCell>
				<IconContainer>
					<ProductIcon productId="travel" />
				</IconContainer>
				<FlexCol>
					<div>Dienstreise</div>
					<div>{product.name}</div>
				</FlexCol>
			</CategoryCell>
			<Amount>{Currency.format(trip.amounts.total)}</Amount>
			<CategoryCell>
				<FlexCol>
					<div>
						<div>{formatDate(trip.startTime, "dd.MM.yyyy HH:mm")} -</div>
						<div>{formatDate(trip.endTime, "dd.MM.yyyy HH:mm")}</div>
					</div>
				</FlexCol>
				<FlexCol>
					<div>
						<div>{trip.name}</div>
						<div>{trip.occasion}</div>
					</div>
				</FlexCol>
			</CategoryCell>
			<StatusContainer>
				{trip.isReopenedTrip && (
					<Tooltip id="reset-icon-tooltip" tooltip="Bearbeitet">
						<Icon.Reset style={{ fontSize: "1rem", marginRight: "2ch" }} />
					</Tooltip>
				)}
				<StatusIcon trip={trip} />
			</StatusContainer>
		</CardHeaderContainer>
	);
}

const Amount = styled(FlexCol)`
	align-self: center;
	font-size: 1.2em;
`;

const CardHeaderContainer = styled.div`
	display: grid;
	width: 100%;
	gap: 24px;
	grid-template-columns: 0.8fr 1fr 1fr 0.5fr 5fr auto;
`;

const StatusContainer = styled.div`
	height: max-content;
	display: flex;
	align-items: center;
	align-self: center;
	column-gap: 0.6rem;

	svg {
		font-size: 2rem;
		color: ${colors.caution.c300};
	}
`;
