import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import { Tooltip, Icon } from "@components";
import { ReviewStatus } from "@service/review/ReviewFilters";
import { colors } from "@theme/theming";

type Props = {
	selection: ReviewStatus[];
	onSelectionChanged: (value: ReviewStatus[]) => void;
};

export default function SelectReviewStatusControl(props: Props) {
	return (
		<Group
			type="checkbox"
			variant="outline"
			value={props.selection}
			onChange={props.onSelectionChanged}
		>
			<Button value={ReviewStatus.Approved}>
				<Tooltip id="select-status-approved" tooltip={"Angenommen"}>
					<div>
						<Icon.Status.Approved />
					</div>
				</Tooltip>
			</Button>
			<Button value={ReviewStatus.Rejected}>
				<Tooltip id="select-status-rejected" tooltip={"Abgelehnt"}>
					<div>
						<Icon.Status.Rejected />
					</div>
				</Tooltip>
			</Button>
			<Button value={ReviewStatus.InReview}>
				<Tooltip id="select-status-in-review" tooltip={"In Prüfung"}>
					<div>
						<Icon.Status.InReview />
					</div>
				</Tooltip>
			</Button>
		</Group>
	);
}

export const Group = styled(ToggleButtonGroup)`
	.btn-primary {
		background: ${colors.white} !important;
		border-color: ${colors.primary.p100} !important;
		color: ${colors.gray.g400} !important;
		&:hover {
			color: ${colors.primary.p300} !important;
		}
		&.focus {
			box-shadow: none;
		}
		&.active {
			background-color: ${colors.primary.p200} !important;
			border-color: ${colors.primary.p100} !important;
			color: ${colors.white} !important;

			&:hover {
				background: ${colors.primary.p300} !important;
			}
		}
	}
`;

export const Button = styled(ToggleButton)`
	&.active {
		opacity: 1;
	}
`;
