import React from "react";
import { Product } from "@models";
import LegalForm from "../models/LegalForm";
import { ReportType } from "../models/ReportType";

export type StaticData = {
	products: Product[];
	legalForms: LegalForm[];
	reportTypes: ReportType[];
};

const StaticDataContext = React.createContext<StaticData | undefined>(
	undefined
);

export default StaticDataContext;
