import { css } from "styled-components";
import { colors } from "./theming";

const styles = {
	NewCard: css`
		outline: 2px solid ${colors.primary.p100};
		box-shadow: 2px 2px 5px 2px ${colors.gray.g400};
		margin: 32px 0px;
	`,
};

export default styles;
