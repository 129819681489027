import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Scrolls the page to top when the component is being mounted due to a PUSH or REPLACE action
 *
 * Based on https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
export default function ScrollToTop() {
	const history = useHistory();
	useEffect(() => {
		if (history.action !== "POP") {
			// scroll to top on initial mount, but not when coming here from "Back" navigation in browser

			// prevent stupid "not implemented" messages from JSDom in tests
			if (process.env.NODE_ENV !== "test") {
				window?.scrollTo(0, 0);
			}
		}
	}, [history]);

	return null;
}
