import { useContext } from "react";
import DivisionPageContext from "../DivisionPageContext";

export function useDivisionFromPageContext() {
	const contextValue = useContext(DivisionPageContext);
	if (!contextValue) {
		throw new Error("DivisionPageContext not mounted");
	}

	return contextValue;
}
