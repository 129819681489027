import React, { CSSProperties, useContext, useEffect } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import {
	ActionButton,
	Button,
	ButtonLoadingIndicator,
	DropDownToggleButton,
	Icon,
	QueryError,
	Tooltip,
} from "@components";
import { FaThumbsUp as ApproveIcon } from "react-icons/fa";
import { useCategory, useTimer } from "@hooks";
import { Voucher } from "@models";
import RejectButton from "./RejectButton";
import { buttonStyle, ButtonText } from "./styles";
import { isCommuteVehicleVoucher } from "../../utils";
import { useFormContext } from "react-hook-form";
import { useServices } from "@hooks";
import useAsyncCommand from "../../../../../hooks/useAsyncCommand";
import ReviewContext from "../../context/ReviewContext";
import Routes from "@service/navigation/routes";
import { useHistory } from "react-router-dom";
import DropDownMenu from "@components/DropDownMenu";
import { EditableVoucher } from "@models/EditableVoucher";

type Props = {
	style?: CSSProperties;
	voucher: Voucher;
	onClose?: () => void;
};

export default function VoucherReviewControls(props: Props) {
	const { voucher, onClose } = props;

	const { customerId, divisionId, userId } = voucher;
	const { reviewService } = useServices();
	const reviewContext = useContext(ReviewContext);
	const history = useHistory();

	const form = useFormContext<EditableVoucher>();

	const { startTimer, getElapsedMs } = useTimer();

	useEffect(() => startTimer(), [startTimer]);

	const [approveVoucher, approveQuery] = useAsyncCommand(async () => {
		const reviewDuration = getElapsedMs();

		const updatedVoucher = form.getValues();

		const returned = await reviewService.voucher.approve(
			voucher.voucherId,
			updatedVoucher,
			reviewDuration,
			voucher.integrityToken
		);

		reviewContext?.onApproved?.(returned);
		onClose?.();

		return returned;
	});

	const [rejectVoucher, rejectQuery] = useAsyncCommand(
		async (rejectReason: string | null) => {
			const reviewDuration = getElapsedMs();

			const updatedVoucher = form.getValues();

			const returned = await reviewService.voucher.reject(
				voucher.voucherId,
				updatedVoucher,
				rejectReason,
				reviewDuration,
				voucher.integrityToken
			);

			reviewContext?.onRejected?.(returned);
			onClose?.();

			return returned;
		}
	);

	const [saveVoucher, saveQuery] = useAsyncCommand(async () => {
		const reviewDuration = getElapsedMs();

		const updatedVoucher = form.getValues();

		const returned = await reviewService.voucher.save(
			voucher.voucherId,
			updatedVoucher,
			reviewDuration,
			voucher.integrityToken
		);
		reviewContext?.onSaved?.(returned);

		return returned;
	});

	const createContract = () => {
		const reviewDuration = getElapsedMs();

		const contractPath =
			Routes.UserContracts.makePath({ customerId, divisionId, userId }) +
			"?voucher=" +
			voucher.voucherId;

		const attachedVoucher = EditableVoucher.getUpdatedVoucher(
			voucher,
			form.getValues()
		);
		history.push(
			contractPath,
			Routes.UserContracts.makeState({ attachedVoucher, reviewDuration })
		);
	};

	const hasContract = !!voucher.contractId;
	const isLoading =
		approveQuery.state === "fetching" || rejectQuery.state === "fetching";

	const isApproveRejectButtonDisabled =
		!!form.formState.errors.vatAmounts || isLoading;

	const handleSaveAndCreateContract = async () => {
		await saveVoucher();
		createContract();
	};

	const approveButton = (
		<ApproveButton
			onClick={approveVoucher}
			disabled={isApproveRejectButtonDisabled}
		>
			<ButtonLoadingIndicator query={approveQuery} />
			<ApproveIcon />
			<ButtonText>Akzeptieren</ButtonText>
		</ApproveButton>
	);

	const category = useCategory(voucher.category);
	const isContractOnlyCategory = isCommuteVehicleVoucher(voucher, category);
	const saveAndCreateContractButton = (
		<ApproveButton
			onClick={handleSaveAndCreateContract}
			disabled={isApproveRejectButtonDisabled}
		>
			<ButtonLoadingIndicator query={saveQuery} />
			<ApproveIcon />
			<ButtonText>Speichern & Dauerbeleg erzeugen</ButtonText>
		</ApproveButton>
	);

	const lunchRejectReason = [
		"Datum oder Uhrzeit nicht erkennbar. Bitte erneut hochladen.",
		"Belege müssen innerhalb Deutschlands ausgestellt worden sein.",
		"Belege müssen zwischen 5-20 Uhr an Arbeitstagen ausgewiesen sein.",
		"Der Beleg ist bereits im System vorhanden.",
		"Sammelbelege müssen entsprechend gekennzeichnet sein. Doppelte Belege werden abgelehnt.",
		"Kein Betrag erkennbar.",
		"Belege bitte vollständig einreichen. Weitere Seiten ggf. über Plus-Button hinzufügen.",
		"Der Beleg ist nicht lesbar. Bitte erneut hochladen.",
		"Der Beleg ist in der ausgewählten Kategorie nicht gültig.",
		"Kein gültiger Beleg.",
	];

	const rejectButton = (
		<RejectButton
			onReject={rejectVoucher}
			rejectQuery={rejectQuery}
			isDisabled={isApproveRejectButtonDisabled}
			customRejectReasons={
				voucher.category === "lunch" ? lunchRejectReason : undefined
			}
		/>
	);

	const optionsButton = (
		<Dropdown as={ButtonGroup}>
			<DropDownToggleButton>
				<Tooltip id="optionsTooltip" tooltip="Einstellungen">
					<Icon.Settings />
				</Tooltip>
			</DropDownToggleButton>
			<DropDownMenu className="super-colors">
				<Dropdown.Item onSelect={createContract}>
					Dauerbeleg erstellen
				</Dropdown.Item>
			</DropDownMenu>
		</Dropdown>
	);

	const saveButton = (
		<Tooltip id="saveVoucher" tooltip={"Änderungen speichern"}>
			<ActionButton
				onClick={saveVoucher}
				query={saveQuery}
				disableAfterSuccess={false}
				disabled={!!form.formState.errors.vatAmounts}
			>
				<Icon.Save />
			</ActionButton>
		</Tooltip>
	);

	return (
		<Container style={props.style}>
			{isContractOnlyCategory ? saveAndCreateContractButton : approveButton}
			{rejectButton}
			{saveButton}
			{!hasContract && optionsButton}
			<QueryError queryState={[approveQuery, rejectQuery, saveQuery]} />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

const ApproveButton = styled(Button).attrs({ variant: "success" })`
	${buttonStyle}
`;
