import React, { useCallback } from "react";
import { Input } from "./Input";

type Props = {
	id?: string;
	name?: string;
	min?: number;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, "as" | "value" | "type"> &
	(
		| {
				allowNull: true;
				value: number | null;
		  }
		| {
				allowNull?: false;
				value: number; // if `allowNull` is not set to `true`, this cannot be `null`
		  }
	);

const CurrencyInput = React.forwardRef((props: Props, ref) => {
	const { onChange, value, allowNull, min, ...rest } = props;
	const euroValue = value !== null && value !== undefined ? value / 100 : "";

	const customOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let targetValue: any = null;
			if (e.target.value !== null && e.target.value !== "") {
				const value = Number.parseFloat(e.target.value);
				const centsValue = Math.round(value * 100);
				targetValue = centsValue;
			} else {
				if (!allowNull) {
					targetValue = 0;
				}
			}
			if (onChange) {
				onChange({
					...e,
					target: { ...e.target, value: targetValue },
				});
			}
		},
		[onChange, allowNull]
	);

	return (
		<Input
			data-testid="currency-input"
			type="number"
			step={0.01}
			min={min ?? 0.0}
			onChange={customOnChange}
			value={euroValue}
			ref={ref}
			{...rest}
		/>
	);
});

export default CurrencyInput;
