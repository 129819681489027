import React, { useState } from "react";
import { useAccountSummary } from "@hooks";
import { QueryStateIndicators, Select, SmallText } from "@components";
import { User } from "@models";
import { getCurrentYear, getYearsOptions } from "./util";
import MonthSummarySection from "./MonthSummarySection";
import styled from "styled-components";
import { isBefore } from "date-fns";

type Props = {
	user: User;
};

export default function UserStatisticsPage(props: Props) {
	const yearsOptions: number[] = getYearsOptions();
	const [selectedYear, setSelectedYear] = useState(getCurrentYear());

	const onSelectYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedYear(Number.parseInt(e.target.value, 10));
	};

	const { user } = props;
	const summaryQuery = useAccountSummary(user.userId, selectedYear);

	return (
		<>
			<SelectContainer>
				<SmallText>Jahr</SmallText>
				<Select
					css={`
						width: 10rem;
						margin-left: 1rem;
					`}
					name={"selectYear"}
					value={selectedYear}
					onChange={onSelectYear}
				>
					{yearsOptions.map((year, idx) => (
						<option key={idx} value={year}>
							{year}
						</option>
					))}
				</Select>
			</SelectContainer>

			{summaryQuery.state === "success" &&
				summaryQuery.data.map(
					(monthData, index) =>
						isBefore(monthData.date, new Date(Date.now())) && (
							<MonthSummarySection key={index} monthSummary={monthData} />
						)
				)}

			<QueryStateIndicators queryState={summaryQuery} />
		</>
	);
}

const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.5em;
	margin: 1rem 0;
	:first-child {
		margin: 1em;
	}
`;
