import React from "react";
import styled from "styled-components";
import { ChecklistProgress, ExpandCard } from "@components";
import { VoucherReviewCategory } from "@models/Voucher";
import VoucherReviewGuideline from "./VoucherReviewGuideline";
import { colors } from "@theme/theming";

type Props = {
	category: VoucherReviewCategory;
	isExpanded: boolean;
	onGuidelineClick: (guideline: string) => void;
	onExpanded: () => void;
};

export default function VoucherReviewGuidelineCategory(props: Props) {
	const {
		category: { guidelines, header, type },
		isExpanded,
		onGuidelineClick,
		onExpanded,
	} = props;

	const guidelinesCount = type === "OR" ? 1 : guidelines.length;
	const doneCount = Math.min(
		guidelinesCount,
		guidelines.filter((guideline) => guideline.done !== "none").length
	);

	const expandCardHeader = (
		<VoucherReviewCategoryExpandCardHeader>
			<CategoryHeader>{header}</CategoryHeader>
			<ChecklistProgress checked={doneCount} listLength={guidelinesCount} />
		</VoucherReviewCategoryExpandCardHeader>
	);

	return (
		<VoucherReviewCategoryContainer>
			<VoucherReviewCategoryExpandCard
				header={expandCardHeader}
				isInitiallyExpanded={isExpanded}
				onExpanded={onExpanded}
			>
				<VoucherReviewGuidelinesContainer>
					{guidelines.map((guideline, guidelineIdx) => (
						<VoucherReviewGuideline
							key={guidelineIdx}
							{...guideline}
							onToggle={(value) => onGuidelineClick(value)}
						/>
					))}
				</VoucherReviewGuidelinesContainer>
			</VoucherReviewCategoryExpandCard>
		</VoucherReviewCategoryContainer>
	);
}

const VoucherReviewCategoryContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const VoucherReviewGuidelinesContainer = styled.div`
	padding: 0 1rem 1rem 1rem;
`;

const VoucherReviewCategoryExpandCardHeader = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: calc(100% - 6ch) 6ch;

	width: 100%;
`;

const CategoryHeader = styled.span`
	font-weight: bold;
`;

const VoucherReviewCategoryExpandCard = styled(ExpandCard)`
	box-shadow: 0px 0px 3px 1px ${colors.gray.g300};
	border-radius: 5px;
	margin-bottom: 0.75rem;

	// reuse ExpandCard with a new design
	// TODO: Upgrade ExpandCard to handle various designs
	> :first-child {
		padding: 1.65rem 1rem;
		flex-direction: row-reverse;

		> :first-child {
			margin: 0;
			color: ${colors.gray.g300};
			width: 1rem;

			> :first-child {
				width: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					transform: rotate(90deg);
					transform-origin: center;
				}
			}
		}
	}

	.expanded {
		> :first-child > :first-child svg {
			transform: rotate(180deg);
		}
	}
`;
