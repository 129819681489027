import { de } from "date-fns/locale";
import { format } from "date-fns";
import _ from "lodash";
import { MONTHS } from "../components/forms/datePickerUtils";
import { User } from "@models";
import { PaginationResult } from "./types";
import axios, { AxiosError } from "axios";
import { DeserializableFromResponse, ErrorBase } from "@util/errors";
import { knownErrors } from "./common/knownErrors";

export const formatDate = (
	dateString: string | Date,
	dateFormat = "d. MMMM yyyy"
): string => {
	const date = new Date(dateString);
	return format(date, dateFormat, { locale: de });
};

export function assertDefined<T>(data: T | undefined | null): T {
	if (data === undefined) {
		throw new Error("data is undefined, but expected defined");
	}
	data = assertNotNull(data);

	return data;
}

export function assertNotNull<T>(data: T | null): T {
	if (data === null) {
		throw new Error("data is null, but expected defined");
	}
	return data;
}

export function formatName(user: User): string {
	return `${_.capitalize(user.firstname)} ${_.capitalize(user.lastname)}`;
}

export function mapPaginationResponse<T>(
	responseData: any,
	mapper: (data: any) => T
): PaginationResult<T> {
	const data = responseData.data.map(mapper);
	return { ...responseData, data };
}

export const formatDateMonth = (date: Date): string => {
	return format(date, "MMMM", { locale: de });
};

export const getMonthNumber = (month: string): number => {
	return MONTHS.indexOf(month) + 1;
};

export function capitalize(inputString: string): string {
	return _.capitalize(inputString);
}

export function isKnownError<
	TPayload extends Record<string, unknown> = Record<string, unknown>
>(
	e: unknown,
	type: string
): e is AxiosError & { response: { data: TPayload } } {
	return axios.isAxiosError(e) && e.response?.data.type === type;
}

export function tryConvertKnownErrorTo<T extends ErrorBase>(
	e: unknown,
	errClass: DeserializableFromResponse<T>
): T | undefined {
	if (isKnownError(e, errClass.errorId)) {
		if ("fromErrorResponse" in errClass) {
			return errClass.fromErrorResponse(e.response.data);
		} else {
			return new errClass();
		}
	}
}

export function checkKnownError<T extends ErrorBase>(
	e: unknown,
	errClass: DeserializableFromResponse<T>
) {
	const converted = tryConvertKnownErrorTo(e, errClass);
	if (converted) throw converted;
}

export function tryConvertKnownError(e: unknown): ErrorBase | undefined {
	for (const errClass of knownErrors) {
		const converted = tryConvertKnownErrorTo(e, errClass);
		if (converted) return converted;
	}
	return undefined;
}
