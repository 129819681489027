import React from "react";
import styled from "styled-components";
import { BusinessTrip } from "@models";
import RouteDetails from "./RouteDetails";
import { TripStatus } from "@models/travel/BusinessTrip";
import { FormProvider, useFormContext } from "react-hook-form";
import { capitalize } from "@service/util";
import { formatDate } from "@components/forms/datePickerUtils";
import UserComment from "@components/vouchers/review/components/UserComment";
import { EditableTrip } from "@models/travel/EditableTrip";
import { ControlledNoAutoFillInput } from "./ControlledNoAutoFillInput";

type Props = {
	trip: BusinessTrip;
};

export default function TripBasicDataPage(props: Props) {
	const { trip } = props;

	const form = useFormContext<EditableTrip>();

	return (
		<FormProvider {...form}>
			<div style={{ marginLeft: "18px" }}>
				<Status trip={trip} />
				<div>Prüferkommentar:</div>
				<ControlledNoAutoFillInput
					control={form.control}
					defaultValue=""
					name="backofficeComment"
					renderReadonly={(value) => <div>{value ?? ""}</div>}
				/>
				<hr />
				<GridContainer>
					<div>Bezeichnung: </div>
					<ControlledNoAutoFillInput
						control={form.control}
						defaultValue=""
						name="name"
						renderReadonly={capitalize(trip.name)}
					/>
					<div>Kundennummer: </div>
					{"customerNo" in trip && <div>{trip.customerNo}</div>}
					<div>Anlass: </div>
					<ControlledNoAutoFillInput
						control={form.control}
						defaultValue=""
						name="occasion"
						renderReadonly={capitalize(trip.occasion)}
					/>
					<div>Auftragsnummer: </div>
					<ControlledNoAutoFillInput
						control={form.control}
						defaultValue=""
						name="jobNumber"
						renderReadonly={trip.accountingDetails.jobNumber ?? "-"}
					/>
					<div>Startzeitpunkt: </div>
					<div>{formatDate(trip.startTime, "dd.MM.yyyy HH:mm")}</div>
					<div>Kostenstelle: </div>
					<ControlledNoAutoFillInput
						control={form.control}
						defaultValue=""
						name="costCenter"
						renderReadonly={trip.accountingDetails.costCenter ?? "-"}
					/>
					<div>Endzeitpunkt: </div>
					<div>{formatDate(trip.endTime, "dd.MM.yyyy HH:mm")}</div>
				</GridContainer>
				{!!trip.comment && <UserComment comment={trip.comment} />}
				<hr />
				<RouteDetails trip={trip} />
			</div>
		</FormProvider>
	);
}

function Status(props: { trip: BusinessTrip }) {
	const { status, rejectReason } = props.trip;

	const rejectReasonStr = rejectReason ?? "Kein Grund angegeben";

	let label: React.ReactNode = status;

	if (status === TripStatus.InReview) {
		label = "In Prüfung";
	} else if (status === TripStatus.Approved) {
		label = "Angenommen";
	} else if (status === TripStatus.Rejected) {
		label = "Abgelehnt: " + rejectReasonStr;
	} else if (status === TripStatus.InReviewByEmployer) {
		label = "Angenommen; wird noch durch den Arbeitgeber geprüft";
	} else if (status === TripStatus.ApprovedByEmployer) {
		label = "Angenommen; durch den Arbeitgeber auch angenommen";
	} else if (status === TripStatus.RejectedByEmployer) {
		label = "Angenommen; durch den Arbeitgeber abgelehnt: " + rejectReasonStr;
	}

	return (
		<>
			<b>Status:</b> {label}
		</>
	);
}

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto 1fr;
	align-items: baseline;
	width: fit-content;
	column-gap: 16px;
	row-gap: 2px;
	margin-bottom: 16px;
`;
