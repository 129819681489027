import styled, { css } from "styled-components";

export const buttonStyle = css`
	display: flex;
	align-items: center;
`;

export const ButtonText = styled.span`
	margin-left: 4px;
`;
