import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Routes, { UserRouteParams } from "@service/navigation/routes";
import { Button, Card, FlexCol, Icon, LargeText } from "@components";
import { Contract } from "@models";
import { Currency } from "@util";
import { formatDate } from "@service/util";

type Props = {
	contract: Contract;
};
export default function MonthlyContractCardHeader({ contract }: Props) {
	const { params } = useRouteMatch<UserRouteParams>();
	const contractPath =
		Routes.UserContracts.makePath(params) + "?contract=" + contract.contractId;
	return (
		<CardHeader data-testid={`contractCard-${contract.contractId}`}>
			<FlexColContent>
				<FlexCol
					css={`
						margin-left: 1em;
						width: 30em;
						white-space: nowrap;
					`}
				>
					<LargeText title="Belegdatum">
						{formatDate(contract.startDate)}
					</LargeText>
				</FlexCol>
				<FlexCol
					css={`
						width: 20em;
					`}
				>
					<LargeText title="Gesamtbudget">
						{Currency.format(contract.amount, {
							hideZeroDecimals: true,
						})}
					</LargeText>
				</FlexCol>
				<FlexCol
					css={`
						width: 30em;
					`}
				>
					<LargeText>{contract.name}</LargeText>
				</FlexCol>
				<FlexCol
					css={`
						justify-self: flex-end;
					`}
				>
					<Link to={contractPath}>
						<Button variant="outline-secondary">
							<Icon.Contracts /> zum Dauerbeleg
						</Button>
					</Link>
				</FlexCol>
			</FlexColContent>
		</CardHeader>
	);
}
const FlexColContent = styled(FlexCol)`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-right: 16px;
	width: 100%;
`;
const CardHeader = styled(Card)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	margin-inline: 30px;
`;
