import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
	filtersFromQueryParams,
	ReviewFilters,
} from "../components/FilterControls";

export function useReviewFilters(): ReviewFilters {
	const location = useLocation();
	const paramsWithoutPagination = new URLSearchParams(location.search);
	paramsWithoutPagination.delete("page");
	paramsWithoutPagination.delete("limit");
	const searchWithoutPagination = paramsWithoutPagination.toString();

	return useMemo(() => {
		const search = new URLSearchParams(searchWithoutPagination);

		return filtersFromQueryParams(search);
	}, [searchWithoutPagination]);
}
