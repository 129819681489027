import React from "react";

type AuthState = {
	isLoggedIn: boolean;
	isAuthorized: boolean;
};

export type GlobalState = {
	auth: AuthState;
};

export const defaultGlobalState: GlobalState = {
	auth: {
		isLoggedIn: false,
		isAuthorized: false,
	},
};

export const GlobalStateContext = React.createContext<{
	globalState: GlobalState;
	updateGlobalState: (newState: Partial<GlobalState>) => void;
}>({ globalState: defaultGlobalState, updateGlobalState: () => {} });
