import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import {
	Button,
	ActionButton,
	InputContainer,
	ButtonsContainer,
	QueryStateIndicators,
	FormGroup,
} from "@components";
import { Division, User } from "@models";
import SearchBar from "./SearchBar";
import MoveUsersSelection from "./MoveUsersSelection";
import { useAsyncCommand, useServices } from "@hooks";
import { MoveUsersPayload } from "@service/user/user.service";
import { formatName } from "@service/util";
import UsersListContext from "../../../division/UsersListContext";
import { defer } from "@util/helpers";
import { serializeDate } from "@util";

interface Props {
	onClickCloseModal: () => void;
	users: User[];
}

export type MoveUsersConfig = {
	moveVouchers: boolean;
	targetStartingDate: Date | null;
	copyBudgetMasterSettings: boolean;
	mode: "updateReference" | "createCopy" | null;
};

export default function MoveUsersForm({ onClickCloseModal, users }: Props) {
	const [selectedDivision, setSelectedDivision] = useState<Division | null>(
		null
	);
	const showMoveUsersSelection = !!selectedDivision;
	const formMethods = useForm<MoveUsersConfig>({
		mode: "onChange",
		defaultValues: {
			moveVouchers: false,
			targetStartingDate: null,
			copyBudgetMasterSettings: false,
			mode: null,
		},
	});
	const {
		formState: { isDirty },
		handleSubmit,
		watch,
	} = formMethods;

	const { userService } = useServices();

	const [moveUsers, moveUsersQuery] = useAsyncCommand(
		userService.moveUsersToAnotherDivision,
		{
			rethrowError: false,
		}
	);

	const usersListContext = useContext(UsersListContext);

	const handleSubmitForm = handleSubmit(async (formValues: MoveUsersConfig) => {
		if (!formValues.mode) {
			throw new Error("must have selected exactly one mode");
		}

		let moveUsersPayload: MoveUsersPayload = {
			userIds: users.map((u) => u.userId),
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			targetDivisionId: selectedDivision!.divisionId,
			mode: formValues.mode,
		};

		if (formValues.mode === "createCopy") {
			moveUsersPayload = {
				...moveUsersPayload,
				options: {
					targetStartingDate: serializeDate.nullable.date(
						formValues.targetStartingDate
					),
					copyBudgetMasters: formValues.copyBudgetMasterSettings,
					moveVouchers: formValues.moveVouchers,
				},
			};
		}

		const state = await moveUsers(moveUsersPayload);
		if (state.state === "success") {
			onClickCloseModal();
			await defer(() => {
				usersListContext.triggerRefresh();
			});
		}
	});

	const handleClickSearchResult = (division: Division) => {
		setSelectedDivision(division);
	};

	const first5Users = users
		.slice(0, 5)
		.map((u) => formatName(u))
		.join(", ");

	const moveVouchers = watch("moveVouchers");
	const copyBudgetMasterSettings = watch("copyBudgetMasterSettings");
	const shouldSetStartingDate = moveVouchers || copyBudgetMasterSettings;

	const startingDate = watch("targetStartingDate");
	const isSendButtonDisabled =
		!isDirty ||
		(shouldSetStartingDate && !startingDate) ||
		(!shouldSetStartingDate && startingDate);

	return (
		<FormProvider {...formMethods}>
			<FormContainer className="form" onSubmit={handleSubmitForm}>
				<InputContainer className="form-group">
					<FormGroup as={Row}>
						{users.length} Mitarbeiter zu anderem Arbeitgeber verschieben
					</FormGroup>
					<FormGroup as={Row}>
						{first5Users}
						{users.length > 5 ? `, (...${users.length - 5} weitere)` : ""}
					</FormGroup>
					<SearchBar onClickSearchResult={handleClickSearchResult} />
					{showMoveUsersSelection && <MoveUsersSelection />}
				</InputContainer>

				<ButtonsContainer>
					<Button
						type="button"
						variant="outline-secondary"
						onClick={onClickCloseModal}
					>
						Abbrechen
					</Button>
					<ActionButton
						type="submit"
						disabled={isSendButtonDisabled}
						query={moveUsersQuery}
						successContent={"Gespeichert"}
					>
						Speichern
					</ActionButton>
				</ButtonsContainer>
				<QueryStateIndicators queryState={moveUsersQuery} />
			</FormContainer>
		</FormProvider>
	);
}

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px;
	min-width: 100%;
`;
