import React, { useContext } from "react";
import styled from "styled-components";

import {
	RootProductId,
	BudgetMaster,
	NewBudgetMaster,
	getRootProductDescription,
	CongratsSettings,
} from "@models";
import { formatDate } from "../../../components/forms/datePickerUtils";
import BudgetMasterAmountsSummary from "./BudgetMasterAmountsSummary";
import {
	Badges,
	FlexCol,
	Icon,
	IconButton,
	LargeText,
	SmallText,
	Tooltip,
} from "@components";
import { isNewBudgetMaster } from "./util";
import { getDateFlags } from "@util";
import CongratsHeader from "./congrats/congratsHeader";
import BudgetMasterControlsDropdown from "./BudgetMasterControlsDropdown";
import UserDetailsPageContext from "../../user/UserDetailsPageContext";
import { CongratsOccasions } from "@models";
import { addDays } from "date-fns";
import { useProduct } from "@hooks";

type BudgetMasterCardHeaderProps = {
	budgetMaster: BudgetMaster | NewBudgetMaster;
	rootProductId: RootProductId;
	onCopy: () => void;
};

export function BudgetMasterCardHeader(props: BudgetMasterCardHeaderProps) {
	const { budgetMaster, rootProductId, onCopy } = props;

	const product = useProduct(rootProductId);
	const showAmounts = product.hasBudgets();
	const { isExpired, isInFuture } = getDateFlags(
		budgetMaster.availableFrom,
		budgetMaster.availableUntil
	);

	const handleCopy = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onCopy();
	};

	const isNew = isNewBudgetMaster(budgetMaster);

	const { user } = useContext(UserDetailsPageContext);
	const balanceAccount = user.balanceAccounts.find(
		(ba) => ba.balanceAccountId === budgetMaster.balanceAccountId
	);

	return (
		<>
			<FlexCol
				css={`
					min-width: 4em;
				`}
			>
				{showAmounts && (
					<BudgetMasterAmountsSummary budgetMaster={budgetMaster} />
				)}
			</FlexCol>
			<FlexCol
				css={`
					flex: 0 1 20em;
				`}
			>
				{budgetMaster.category === "congrats" ? (
					<CongratsHeader budgetMaster={budgetMaster} />
				) : (
					<LargeText>{getRootProductDescription(rootProductId).name}</LargeText>
				)}
			</FlexCol>
			<FlexCol>
				<AvailabilityDates budgetMaster={budgetMaster} />
			</FlexCol>
			{isExpired && <Badges.Expired />}
			{isInFuture && <Badges.InFuture />}
			<Spacer />
			{!!balanceAccount && (
				<Tooltip id="tooltip-icon-button" tooltip={"Guthabenkonto aktiv"}>
					<Icon.BalanceAccount />
				</Tooltip>
			)}
			{!isNew && (
				<CopyButton onClick={handleCopy} tooltip="Budget kopieren">
					<Icon.Copy />
				</CopyButton>
			)}
			{isNew && <Badges.New />}
			{"budgetMasterId" in budgetMaster && (
				<BudgetMasterControlsDropdown
					budgetMaster={budgetMaster as BudgetMaster}
				/>
			)}
		</>
	);
}

function AvailabilityDates(props: {
	budgetMaster: BudgetMaster | NewBudgetMaster;
}) {
	const { budgetMaster } = props;
	let endDate = budgetMaster.availableUntil;
	const startDate = budgetMaster.availableFrom;
	const congratsSettings =
		budgetMaster.category === "congrats"
			? (budgetMaster.settings as CongratsSettings)
			: null;

	if (
		!endDate &&
		congratsSettings &&
		congratsSettings.occasion !== CongratsOccasions.Birthday
	) {
		endDate = addDays(startDate, congratsSettings.validTimeInDays - 1);
	}

	return (
		<>
			<SmallText>
				<span title="Verfügbar ab">{formatDate(startDate)}</span>
				{"-"}
			</SmallText>
			<SmallText title="Verfügbar bis">
				{endDate ? formatDate(endDate) : "unbefristet"}
			</SmallText>
		</>
	);
}

const CopyButton = styled(IconButton)`
	background-color: transparent;
	padding: 5px;
	width: fit-content;
	justify-self: end;

	& > * {
		margin: 0;
	}
`;

const Spacer = styled.div`
	flex: 1 0 auto;
`;
