import { getRootProductDescription } from "@models";
import { BudgetMaster, rootProductIds, User } from "@models";
import { Currency, getDateFlags } from "@util";
import { endOfDay } from "date-fns";
import isBefore from "date-fns/isBefore";
import _ from "lodash";

export function groupBudgetMastersByProduct(budgetMasters: BudgetMaster[]) {
	const current = _(budgetMasters)
		.filter((b) => getDateFlags(b.availableFrom, b.availableUntil).isActive)
		.groupBy((b) => b.category);

	return rootProductIds.map((id) => ({
		id,
		productName: getRootProductDescription(id).name,
		budgetMasters: current.get(id),
	}));
}

export function summarizeBMs(budgetMasters: BudgetMaster[]): string {
	if (!budgetMasters) return "Kein Budget";

	const totalSum = _.sumBy(budgetMasters, (b) =>
		_.sumBy(b.amounts, (a) => a.maxAmountMonth)
	);
	return Currency.format(totalSum);
}

export function getStatusFlags(user: User) {
	return {
		nonTestUser: !user.isTestUser,
		uploadAllowed: user.ownVouchersEnabled,
		salutated: user.salutated,
		contractStarted: isBefore(user.contractStart, new Date()),
		nonExpired:
			user.contractEnd === null ||
			isBefore(new Date(), endOfDay(user.contractEnd)),
		active: user.isActive,
		hasLoggedIn: user.lastLoginMobile || user.lastLoginWeb,
	};
}
