import React from "react";
import { ScrollToTop } from "@components";
import DefaultFilterableVoucherList from "./DefaultFilterableVoucherList";

export default function VouchersPage() {
	return (
		<>
			<ScrollToTop />
			<DefaultFilterableVoucherList />
		</>
	);
}
