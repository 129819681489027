import styled from "styled-components";

export const FlexRow = styled.div<{ gap?: number }>`
	display: flex;
	flex-direction: row;
	${(props) => props.gap && `gap: ${props.gap}rem`}
`;

export const FlexCol = styled.div<{ gap?: number }>`
	display: flex;
	flex-direction: column;
	${(props) => props.gap && `gap: ${props.gap}rem`}
`;
