import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Label, RadioButton } from "@components";
import CongratsCouponsConfiguration from "./CongratsCouponsConfiguration";
import { BudgetMaster, NewBudgetMaster } from "@models";
import { CongratsOccasions } from "../../../../models/CongratsOccasion";
import { ConfigBudgetMaster } from "../configuration/types";

type Props = {
	budgetMaster: BudgetMaster | NewBudgetMaster;
};

export default function CouponConfigurationSelection(props: Props) {
	const { watch, setValue } = useFormContext<ConfigBudgetMaster>();

	const isBirthday = watch("settings.occasion") === CongratsOccasions.Birthday;

	return (
		<>
			<FormGroup as={Row}>
				<Label column sm={4}>
					Art
				</Label>
				<Col sm={8}>
					<RadioButton
						id="singleUseCoupon"
						value={!isBirthday}
						label="Einmaliger Gutschein"
						onChange={() => {
							setValue("settings.occasion" as any, CongratsOccasions.Custom);
						}}
					/>
				</Col>
			</FormGroup>

			<FormGroup as={Row}>
				<Col sm={4}></Col>
				<Col sm={8}>
					<RadioButton
						id="birthdayCoupon"
						value={isBirthday}
						label="Geburtstag"
						onChange={() => {
							setValue("settings.occasion" as any, CongratsOccasions.Birthday);
							// TODO: this is ugly. Remove this and find a better way to make formState valid
							(setValue as any)("availableUntil", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}}
					/>
				</Col>
			</FormGroup>

			<CongratsCouponsConfiguration budgetMaster={props.budgetMaster} />
		</>
	);
}
