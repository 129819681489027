import { ErrorBase } from "@util/errors";

export class MonthClosedForChangeError extends ErrorBase {
	static fromErrorResponse(): MonthClosedForChangeError {
		// TODO: add error details
		return new MonthClosedForChangeError();
	}

	static errorId = "accounting-month-closed";

	override get displayText(): string {
		return "Änderung konnte nicht durchgeführt werden: Ein Monat ist bereits abgeschlossen.";
	}
}

export class ThirdPartyVouchersNotEnabledError extends ErrorBase {
	static fromErrorResponse(): ThirdPartyVouchersNotEnabledError {
		return new ThirdPartyVouchersNotEnabledError();
	}

	static errorId = "third-party-vouchers-not-enabled";

	constructor() {
		super("Belege für Drittpersonen sind nicht erlaubt in diesem Standort");
	}
}
export class ThirdPartyCategoryNotAllowedError extends ErrorBase {
	static fromErrorResponse(): ThirdPartyCategoryNotAllowedError {
		return new ThirdPartyCategoryNotAllowedError();
	}

	static errorId = "third-party-category-not-allowed";

	constructor() {
		super(
			"Die Kategorie dieses Belegs wird nicht für Belege von Drittpersonen unterstützt"
		);
	}
}
