import { FormGroup, Label, RequiredMarker, Select } from "@components";
import { Contract, NewContract, VehicleType } from "@models";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { getVehicleType } from "../../util";

type Props = {
	contract: Contract | NewContract;
};

export default function JobVehicleTypes(props: Props) {
	const { register } = useFormContext();
	const { contract } = props;
	const vehicleType = getVehicleType(contract.category);

	return (
		<FormGroup as={Row}>
			<Label htmlFor="vehicleType" column sm={4}>
				Fahrzeugart <RequiredMarker />
			</Label>
			<Col sm={8}>
				<Select
					id="vehicleType"
					{...register("vehicleType", { required: true })}
					defaultValue={contract.vehicleType}
				>
					{vehicleType === VehicleType.Bike && (
						<>
							<option value="bike">Fahrrad/E-Fahrrad unter 25 km/h</option>
							<option value="s-pedelec">S-pedelec</option>
						</>
					)}
					{vehicleType === VehicleType.KickScooter && (
						<>
							<option value="e-kick-scooter">E-Scooter</option>
						</>
					)}
					{vehicleType === VehicleType.MotorScooter && (
						<>
							<option value="motor-scooter-under50kmh">
								Motorroller unter 50km/h
							</option>
							<option value="motor-scooter">Motorroller (Verbrenner)</option>
							<option value="e-motor-scooter-under50kmh">
								E-Motorroller unter 50km/h
							</option>
							<option value="e-motor-scooter">E-Motorroller</option>
						</>
					)}
					{vehicleType === VehicleType.Car && (
						<>
							<option value="car">PKW (Verbrenner)</option>
							<option value="e-car">E-PKW</option>
							<option value="hybrid-car">Hybrid-PKW</option>
						</>
					)}
				</Select>
			</Col>
		</FormGroup>
	);
}
