import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
	FlexCol,
	FlexRow,
	CommonModal,
	Button,
	Input,
	Icon,
	IconContainer,
} from "@components";
import { Voucher } from "@models";
import RejectButton from "./VoucherReviewControls/RejectButton";
import ReviewContext from "../context/ReviewContext";
import { useAsyncCommand, useServices, useTimer } from "@hooks";
import { EditableVoucher } from "@models/EditableVoucher";
import { useFormContext } from "react-hook-form";
import DuplicateVoucherColumn from "./DuplicateVoucherColumn";
import _ from "lodash";
import { colors } from "@theme/theming";

type Props = {
	voucher: Voucher;
	duplicateVouchers: Voucher[];
	onClose: () => void;
	onReject?: () => void;
};

export default function DuplicateReviewModal(props: Props) {
	const { voucher, duplicateVouchers, onReject, onClose } = props;

	const [comparisonVoucherIdxBuffer, setComparisonVoucherIdxBuffer] =
		useState("1");
	const [comparisonVoucherIdx, setComparisonVoucherIdx] = useState(0);

	const form = useFormContext<EditableVoucher>();
	const { reviewService } = useServices();
	const reviewContext = useContext(ReviewContext);
	const { getElapsedMs } = useTimer();

	const viewDuplicateWithIndex = (index: number) => {
		const acceptdIndex = Math.floor(index);
		const finalValue = _.clamp(acceptdIndex, 0, duplicateVouchers.length - 1);
		setComparisonVoucherIdxBuffer(`${finalValue + 1}`);
		setComparisonVoucherIdx(finalValue);
	};

	const handleClose = () => {
		onClose();
		viewDuplicateWithIndex(0);
	};

	const [rejectVoucher, rejectQuery] = useAsyncCommand(
		async (rejectReason: string | null) => {
			const reviewDuration = getElapsedMs();

			const updatedVoucher = form.getValues();

			const returned = await reviewService.voucher.reject(
				voucher.voucherId,
				updatedVoucher,
				rejectReason,
				reviewDuration,
				voucher.integrityToken
			);

			reviewContext?.onRejected?.(returned);
			onReject?.();
			handleClose();

			return returned;
		}
	);

	const isLoading = rejectQuery.state === "fetching";
	const isRejectButtonDisabled = !!form.formState.isValid || isLoading;
	return (
		<CommonModal handleClose={handleClose} header="Potentielle Duplikate">
			<FlexRow>
				<DuplicateVoucherColumn
					voucher={voucher}
					actionArea={
						<ActionsContainer>
							<RejectButton
								style={{ marginLeft: "auto" }}
								onReject={rejectVoucher}
								rejectQuery={rejectQuery}
								isDisabled={isRejectButtonDisabled}
								customRejectReasons={[
									"Der Beleg ist bereits im System vorhanden.",
								]}
							/>
						</ActionsContainer>
					}
				/>

				<Separator />

				<DuplicateVoucherColumn
					voucher={duplicateVouchers[comparisonVoucherIdx]}
					actionArea={
						<ActionsContainer>
							<StyledButton
								disabled={comparisonVoucherIdx === 0}
								onClick={() => viewDuplicateWithIndex(comparisonVoucherIdx - 1)}
							>
								<StyledIconContainer>
									<Icon.ArrowLeft />
								</StyledIconContainer>
							</StyledButton>
							<FlexRow style={{ alignItems: "center" }}>
								<FlexCol>
									<DuplicateVoucherInput
										type="number"
										value={comparisonVoucherIdxBuffer}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											const parsedValue = +e.target.value;
											if (!isNaN(parsedValue) && e.target.value !== "") {
												viewDuplicateWithIndex(parsedValue - 1);
											} else {
												setComparisonVoucherIdxBuffer(e.target.value);
											}
										}}
									/>
								</FlexCol>
								<FlexCol style={{ marginLeft: "0.5ch", height: 26 }}>
									/ {duplicateVouchers.length}
								</FlexCol>
							</FlexRow>
							<StyledButton
								disabled={comparisonVoucherIdx === duplicateVouchers.length - 1}
								onClick={() => viewDuplicateWithIndex(comparisonVoucherIdx + 1)}
							>
								<StyledIconContainer>
									<Icon.ArrowRight />
								</StyledIconContainer>
							</StyledButton>
						</ActionsContainer>
					}
				/>
			</FlexRow>
		</CommonModal>
	);
}

const Separator = styled(FlexCol)`
	height: 85vh;
	width: 2px;
	background: ${colors.gray.g300};
	margin: 0 15px;
`;

const ActionsContainer = styled(FlexRow)`
	height: 39px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const StyledIconContainer = styled(IconContainer)`
	font-size: 1.313rem;
	display: grid;
	place-items: center;
	margin-right: unset;
	color: unset;
`;

const StyledButton = styled(Button)`
	width: 80px;
`;

const DuplicateVoucherInput = styled(Input)`
	text-align: center;
	width: 42.5px;
	height: 26px;
	display: inline-block;
	padding: 0;
	padding-bottom: 2px;

	/* Hide the up and down arrows */
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
`;
