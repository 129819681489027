import { Category, Voucher } from "@models";
import {
	VatAmount,
	VoucherReviewCategory,
	VoucherReviewGuideline,
} from "@models/Voucher";

export function isCommuteVehicleVoucher(voucher: Voucher, category: Category) {
	return (
		category.parents.some((c) => c.id === "mobility.commute.vehicle") &&
		voucher.sourceValueUnit === "m" &&
		voucher.sourceValue !== null
	);
}

export function checkValidVatAmounts(vatAmounts: VatAmount[] | null) {
	if (vatAmounts && vatAmounts.length > 1) {
		return !vatAmounts.some((v) => v.vatAmount === 0);
	}
	return true;
}

export function getVoucherReviewGuidlines(
	voucher: Partial<Voucher>,
	reviewedGuidelines: string[]
): VoucherReviewCategory[] {
	if (!voucher.category) return [];

	const createGuideline = (
		value: string,
		condition: boolean
	): VoucherReviewGuideline => {
		return {
			value,
			/*
			 * done can give priority to the TCA or the form/condition
			 * based on how its value is formulated
			 */

			/*
			 * EXPECTED: when two guidelines are exactly the same but
			 * under different categories they would toggle together
			 * FIX: add category header to the equation here and in
			 * VoucherReviewGuidelineCategory -> onGuidelineClick
			 */
			done: reviewedGuidelines.includes(value)
				? "tca"
				: condition
				? "form"
				: "none",
		};
	};

	// --- special categories
	if (voucher.category.includes("mobility.commute.vehicle")) {
		return [
			{
				header: "Arbeitsweg → Eigenes Fahrzeug",
				type: "AND",
				guidelines: [
					createGuideline("Ist die angegebene Entfernung plausibel?", false),
				],
			},
		];
	}
	// ----

	const commonGuidelines: VoucherReviewCategory = {
		header: "Rechungspflichtangaben",
		type: "AND",
		guidelines: [
			createGuideline(
				"Vollständiger Name des/der Leistungserbringer:in",
				!!voucher.supplierName?.trim()
			),
			createGuideline(
				"Ausstellungsdatum der Rechnung",
				!!voucher.dateOnVoucher
			),
			createGuideline("Menge/Umfang und Art der Dienstleistungen", false),
			createGuideline(
				"Brutto-Gesamtsumme (Entgelt inkl. Umsatzsteuer) oder in Summen nach Steuersätzen aufgeteilt",
				!!voucher.amountAfterReview
			),
		],
	};

	if (voucher.category === "lunch") {
		const lunchGuidelines: VoucherReviewCategory = {
			header: "Lunch",
			type: "AND",
			guidelines: [
				createGuideline(
					"Der Beleg wurde innerhalb der Bundesrepublik Deutschland ausgestellt",
					false
				),
				createGuideline(
					"Ausstellungsuhrzeit ist erkennbar und liegt zwischen 5:00 Uhr und 20:00 Uhr",
					false
				),
				createGuideline(
					"Non-Food-Artikel wie z. B. Haushaltsbedarf, Kosmetika o. Ä. werden nicht in der erstattbaren Summe berücksichtigt",
					false
				),
				createGuideline(
					"Ein Flaschenpfand wird nicht in der erstattbaren Summe berücksichtigt",
					false
				),
				createGuideline(
					"Alkoholische Getränke, Tabakwaren oder ähnliche Produkte werden nicht in der erstattbaren Summe berücksichtigt",
					false
				),
			],
		};

		return [commonGuidelines, lunchGuidelines];
	}

	if (
		voucher.category.includes("mobility.commute.publicTransport.jobTicket") ||
		voucher.category.includes("mobility.commute.publicTransport.annualTicket")
	) {
		const publicTransportTicketsGuidelines: VoucherReviewCategory = {
			header: "Jahresabo und Jobticket",
			type: "OR",
			guidelines: [
				createGuideline(
					"Gibt es eine Rechnung, in der der/die Fahrkartennutzer:in aufgeführt wird?",
					false
				),
				createGuideline(
					"Liegt ein Kontoauszug vor, aus dem der/die Kontoinhaber:in und die dazugehörige Abbuchung hervorgehen?",
					false
				),
			],
		};

		return [commonGuidelines, publicTransportTicketsGuidelines];
	}

	if (voucher.category.includes("mobility")) {
		return [commonGuidelines];
	}

	return [];
}
