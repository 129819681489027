import { useState } from "react";
import { UserWithBudgetMasters } from "@models";

export function useSelection(users: UserWithBudgetMasters[] | null) {
	const [selection, setSelection] = useState<Record<string, boolean>>({});
	const [isSelectedAll, setSelectedAll] = useState(false);

	const handleSelectAll = (selected: boolean) => {
		setSelectedAll(selected);
		if (users) {
			setSelection(
				users.reduce(
					(prev, curr) => ({
						...prev,
						[curr.userId]: selected,
					}),
					{}
				)
			);
		}
	};

	const selectUser = (user: UserWithBudgetMasters, isSelected: boolean) =>
		setSelection({ ...selection, [user.userId]: isSelected });

	const isSelected = (user: UserWithBudgetMasters) =>
		selection[user.userId] ?? false;
	const selectedUsers = users?.filter(isSelected) ?? [];

	return {
		isSelected,
		isSelectedAll,
		handleSelectAll,
		selectUser,
		selectedUsers,
	};
}
