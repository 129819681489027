import styled from "styled-components";
import { Input } from "./Input";
import { colors } from "@theme/theming";

export default styled(Input).attrs({ as: "select" })`
	&:focus,
	&:active {
		border-color: ${colors.primary.p100};
		color: ${colors.text};
	}
`;
