import { useState, useEffect, useCallback } from "react";
import { Voucher } from "@models";
import { getVoucherReviewGuidlines } from "../utils";
import { VoucherReviewCategory } from "@models/Voucher";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { EditableVoucher } from "@models/EditableVoucher";

export function useReviewCategories(voucher: Voucher) {
	const { watch } = useFormContext<EditableVoucher>();
	const watchFields: (keyof EditableVoucher)[] = [
		"supplierName",
		"dateOnVoucher",
		"amountAfterReview",
	];

	const [reviewedGuidelines, setReviewedGuidelines] = useState<string[]>([]);

	const [guidelinesVoucher, setGuidelinesVoucher] = useState<Partial<Voucher>>({
		..._.pick(voucher, watchFields),
		category: voucher.category,
	});

	const [reviewCategories, setReviewCategories] = useState<
		VoucherReviewCategory[]
	>(getVoucherReviewGuidlines(guidelinesVoucher, []));

	const guidelinesCount = reviewCategories.reduce((acc, category) => {
		return acc + (category.type === "OR" ? 1 : category.guidelines.length);
	}, 0);

	const calculatePassedGuideline = useCallback(
		() =>
			reviewCategories.reduce((acc, category) => {
				if (category.type === "OR") {
					const hasDoneGuideline = category.guidelines.some(
						(guideline) => guideline.done !== "none"
					);
					return acc + (hasDoneGuideline ? 1 : 0);
				} else {
					const doneGuidelinesCount = category.guidelines.filter(
						(guideline) => guideline.done !== "none"
					).length;
					return acc + doneGuidelinesCount;
				}
			}, 0),
		[reviewCategories]
	);

	const [passedGuidelinesCount, setPassedGuidelinesCount] = useState(
		calculatePassedGuideline
	);

	const handleGuidelineClick = (guideline: string) => {
		setReviewedGuidelines((prevReviewCategories: string[]) => {
			if (!prevReviewCategories.includes(guideline)) {
				return [...prevReviewCategories, guideline];
			} else {
				return prevReviewCategories.filter((item) => item !== guideline);
			}
		});
	};

	// --- react to form changes
	const formData = watch();

	useEffect(
		() => {
			setGuidelinesVoucher((prevGuidelines) => {
				return {
					...prevGuidelines,
					..._.pick(formData, watchFields),
				};
			});
		},
		watchFields.map((field) => formData[field])
	);
	// ---

	// --- update guidlines states
	useEffect(() => {
		setReviewCategories(
			getVoucherReviewGuidlines(guidelinesVoucher, reviewedGuidelines)
		);
	}, [guidelinesVoucher, reviewedGuidelines]);

	useEffect(() => {
		setPassedGuidelinesCount(calculatePassedGuideline);
	}, [reviewCategories]);
	// ---

	return {
		reviewCategories,
		guidelinesCount,
		passedGuidelinesCount,
		handleGuidelineClick,
	};
}

export default useReviewCategories;
