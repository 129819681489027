import React from "react";
import { Voucher } from "@models";
import VoucherCard from "./VoucherCard";
import { CardsListContainer } from "@components";

type Props = {
	vouchers: Voucher[];
	expandedVoucherId?: string;
};

export default function VoucherList(props: Props) {
	const noVouchers = <div>Keine Belege gefunden</div>;
	const voucherCards = props.vouchers.map((v) => (
		<VoucherCard
			voucher={v}
			key={v.voucherId}
			isInitiallyExpanded={v.voucherId === props.expandedVoucherId}
		/>
	));

	return (
		<CardsListContainer>
			{props.vouchers.length === 0 ? noVouchers : voucherCards}
		</CardsListContainer>
	);
}
