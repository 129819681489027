import { colors } from "@theme/theming";
import { ComponentProps } from "@util/types";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type Props = ComponentProps<typeof Form.Check> & {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: boolean;
	id?: string;
	label: string;
	className?: string;
};

const RadioButton = React.forwardRef((props: Props, ref) => {
	const { onChange, value, id, label, className, ...rest } = props;
	return (
		<Form.Check type="radio" className={className}>
			<Form.Check.Label style={{ color: colors.gray.g400 }}>
				<StyledRadioButton
					type={
						"radio" as any /* Intended cast to any, there seems to be a bug in the type declarations */
					}
					{...rest}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onChange(e);
					}}
					checked={value}
					id={id}
					ref={ref}
				/>
				<span />
				<div style={{ marginLeft: "10px" }}>{label}</div>
			</Form.Check.Label>
		</Form.Check>
	);
});

const StyledRadioButton = styled(Form.Check.Input)`
	display: none;

	& ~ span {
		border: 2px solid ${colors.gray.g300};
		border-radius: 10px;
		height: 20px;
		width: 20px;
		display: block;
		position: absolute;
		left: 0;
		top: 1px;
		margin-right: 10px;
	}
	&:checked ~ span {
		border-color: ${colors.primary.p200};
		background-color: ${colors.white};
		&::after {
			content: "";
			position: absolute;
			left: 2px;
			bottom: 2px;
			width: 12px;
			height: 12px;
			border: 2px solid ${colors.primary.p200};
			background-color: ${colors.primary.p200};
			border-radius: 6px;
		}
	}
`;

export default RadioButton;
