import React, { useState } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import {
	Button,
	ButtonLoadingIndicator,
	DropDownToggleButton,
	Input,
} from "@components";

import { FaThumbsDown as RejectIcon } from "react-icons/fa";
import { QueryState } from "@hooks";
import { buttonStyle, ButtonText } from "./styles";
import DropDownMenu from "@components/DropDownMenu";

const rejectReasons = [
	"Datum nicht erkennbar. Bitte erneut hochladen.",
	"Rechnungsempfänger ist nicht ersichtlich.",
	"Kein Betrag erkennbar.",
	"Der Beleg ist nicht aus dem aktuellen Monat.",
	"Belege dürfen nur in Euro akzeptiert werden.",
	"Der Beleg ist bereits im System vorhanden.",
	"Belege bitte vollständig einreichen. Weitere Seiten ggf. über Plus-Button hinzufügen.",
	"Der Beleg ist nicht lesbar. Bitte erneut hochladen.",
	"Der Beleg ist in der ausgewählten Kategorie nicht gültig.",
	"Kein gültiger Beleg.",
	"Belege von Drittpersonen dürfen nicht eingereicht werden.",
	"Belege von Drittpersonen müssen entsprechend gekennzeichnet werden.",
	"Bitte reichen Sie diesen Beleg zusammen mit einem aktuellen Zahlungsnachweis ein.",
];

type Props = {
	onReject: (reason: string | null) => void;
	rejectQuery?: QueryState<any>;
	isDisabled?: boolean;
	customRejectReasons?: string[];
	style?: React.CSSProperties;
};

export default function RejectButton(props: Props) {
	const [showRejectMenu, setShowRejectMenu] = useState(false);
	const [customRejectReason, setCustomRejectReason] = useState("");
	const usedRejectReasons = props.customRejectReasons ?? rejectReasons;
	const reasonCharacterLimit = 256;

	const onSelectRejectReason = (key: string | null) => {
		if (key !== "custom") {
			let rejectReason: string | null = null;
			if (key !== null) {
				const rejectIdx = Number.parseInt(key, 10);
				if (!Number.isNaN(rejectIdx)) {
					rejectReason = usedRejectReasons[rejectIdx];
				}
			}
			setShowRejectMenu(false);
			props.onReject(rejectReason);
		}
	};

	const onToggleRejectDropdown = (isOpen: boolean, isSelect: boolean) => {
		if (!isSelect) {
			setShowRejectMenu(isOpen);
		}
	};

	const handleSubmitCustomReject = (e: React.FormEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setShowRejectMenu(false);
		props.onReject(customRejectReason.trim());
	};

	return (
		<Dropdown
			style={props.style}
			as={ButtonGroup}
			show={showRejectMenu}
			onSelect={onSelectRejectReason}
			onToggle={(isOpen, _e, meta) =>
				onToggleRejectDropdown(isOpen, meta.source === "select")
			}
		>
			<StyledButton disabled={props.isDisabled}>
				<ButtonLoadingIndicator query={props.rejectQuery} />
				<RejectIcon />
				<ButtonText>Ablehnen</ButtonText>
			</StyledButton>
			<DropDownMenu className="super-colors">
				{usedRejectReasons.map((rejectReason, idx) => (
					<Dropdown.Item key={idx} eventKey={"" + idx}>
						{rejectReason}
					</Dropdown.Item>
				))}
				<Dropdown.Divider />
				<Dropdown.Item eventKey="custom" as="div">
					<CustomRejectReasonContainer>
						<form onSubmit={handleSubmitCustomReject} style={{ flex: 1 }}>
							<div style={{ flex: 1, display: "flex" }}>
								<Input
									style={{ flex: 1 }}
									type="text"
									placeholder="Anderer Ablehngrund"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCustomRejectReason(e.target.value)
									}
									maxLength={reasonCharacterLimit}
								/>
								<Button
									variant="outline-secondary"
									style={{ padding: 4, margin: 0, marginLeft: 4 }}
									type="submit"
									disabled={!customRejectReason.trim()}
								>
									OK
								</Button>
							</div>
							<div className="small text-muted">
								{customRejectReason.length}/{reasonCharacterLimit}
							</div>
						</form>
					</CustomRejectReasonContainer>
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item>Keinen Grund angeben</Dropdown.Item>
			</DropDownMenu>
		</Dropdown>
	);
}

const StyledButton = styled(DropDownToggleButton).attrs({ variant: "danger" })`
	${buttonStyle}
`;

const CustomRejectReasonContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
