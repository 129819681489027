import React from "react";
import { Badge } from "react-bootstrap";
import { Voucher, VoucherType } from "@models";
import { Tooltip } from "@components";

export default function ContractVoucherMarker(props: { voucher: Voucher }) {
	const { voucher } = props;

	return (
		<Tooltip
			id="tooltip"
			tooltip="Dieser Beleg ist an einen Dauerbeleg angehängt"
		>
			<Badge variant="info">{getVoucherTypeName(voucher)}</Badge>
		</Tooltip>
	);
}

const nameLookup: { [k in VoucherType]: string } = {
	[VoucherType.Receipt]: "Zahlungsbeleg",
	[VoucherType.LicensePlateInfo]: "Fahrzeug-Kennzeichen",
	[VoucherType.ContractMetadata]: "Metadaten",
	[VoucherType.ContractMisc]: "Sonstige",
	[VoucherType.ServiceIntervalInfo]: "Wartungsnachweis",
	[VoucherType.WebInitialConfirmation]: "Internetbestätigung",
	[VoucherType.PeriodicConfirmation]: "Dauerbeleg-Bestätigung",
	[VoucherType.RailCardAmortizationAndPurchase]:
		"Bahncard-Bestellung / Amortisationsrechnung",
};

function getVoucherTypeName(voucher: Voucher): string {
	if (!voucher.type) return "Dauerbeleg-Dokument";
	if (voucher.type in nameLookup)
		return "Dauerbeleg-Dokument: " + nameLookup[voucher.type as VoucherType];
	return "Dauerbeleg-Dokument: Unbekannt";
}
