import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FilterContainer, Tab } from "@components";
import FilterControls, {
	filtersToQueryParams,
	ReviewFilters,
} from "./components/FilterControls";
import { SortHeader } from "./components/SortHeader";
import { useReviewFilters } from "./hooks/useReviewFilters";
import { useModeLocation } from "@hooks";
import TripListWithPagination from "./TripListWithPagination";
import VoucherListWithPagination from "./VoucherListWithPagination";

type Props = {
	requiredFilters?: ReviewFilters;
};

export type DisplayReviewMode = "vouchers" | "trips";

export default function FilterableReviewList(props: Props) {
	const filtersFromUrl = useReviewFilters();
	const history = useHistory();
	const location = useLocation();

	const urlMode = new URLSearchParams(location.search).get("mode");
	const mode: DisplayReviewMode = urlMode === "trips" ? "trips" : "vouchers";

	const activeFilters = useMemo(
		() => ({ ...filtersFromUrl, ...props.requiredFilters }),
		[filtersFromUrl, props.requiredFilters]
	);

	const vouchersLocation = useModeLocation("vouchers", mode);
	const tripsLocation = useModeLocation("trips", mode);

	const handleSearch = (filters: ReviewFilters) => {
		const search = new URLSearchParams(location.search);
		filtersToQueryParams(mode, filters, search);

		history.replace({
			...location,
			search: "?" + search.toString().replace(/%2C/g, ","),
		});

		const oldSearch = new URLSearchParams(location.search);
		filtersToQueryParams(mode, activeFilters, oldSearch);
	};

	// TODO: read expanded voucher from url

	return (
		<FilterContainer>
			<FilterControls activeFilters={activeFilters} onSearch={handleSearch} />
			<SortHeader />
			<Tab.Container
				css={`
					margin-bottom: 1rem;
				`}
			>
				<Tab.Item active={mode === "vouchers"} to={vouchersLocation}>
					Belege
				</Tab.Item>
				<Tab.Item active={mode === "trips"} to={tripsLocation}>
					Auslagen
				</Tab.Item>
			</Tab.Container>
			{mode === "trips" && (
				<TripListWithPagination activeFilters={activeFilters} />
			)}
			{mode === "vouchers" && (
				<VoucherListWithPagination activeFilters={activeFilters} />
			)}
		</FilterContainer>
	);
}
