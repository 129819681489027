import React from "react";
import LoadingIndicator from "./LoadingIndicator";
import { QueryState } from "../hooks/useFetchedData";

export default function QueryLoadingIndicator(props: {
	queryState: QueryState<any>;
}) {
	if (
		props.queryState.state === "fetching" ||
		props.queryState.state === "initialFetching"
	) {
		return <LoadingIndicator />;
	}
	return null;
}
