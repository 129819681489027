import { Voucher } from "@models";
import Contract, { NewContract } from "../../models/Contract";
import * as apiService from "../api.service";

export async function getContractsForUser(userId: string) {
	const contracts = await apiService.GET(`contract?userId=${userId}`);
	return contracts.data.map(Contract.fromJson);
}

export async function getContractById(contractId: string) {
	const contract = await apiService.GET(`contract/${contractId}`);
	return Contract.fromJson(contract.data);
}

export async function createContract(
	contract: NewContract,
	attachedVoucher?: Voucher,
	reviewDuration?: number
) {
	const payload = {
		contract: Contract.convertForJson(contract),
		attachedVoucherId: attachedVoucher?.voucherId,
		reviewDuration,
	};

	await apiService.POST("contract", payload);
}

export async function updateContract(contract: Contract) {
	return await apiService.PUT(
		`contract/${contract.contractId}`,
		Contract.convertForJson(contract)
	);
}

export async function deleteContract(contract: Contract) {
	return await apiService.DELETE(`contract/${contract.contractId}`);
}
