import { QueryState } from "@hooks";
import { User } from "@models";
import { UserRole } from "@models/UserRole";
import { useCallback } from "react";
import useFetchedData from "./useFetchedData";
import useServices from "./useServices";

export default function useRoles(user: User): QueryState<UserRole[]> {
	const { userService } = useServices();
	const userId = user.userId;
	const fetchData = useCallback(
		() => userService.getRoles(userId),
		[userId, userService]
	);

	const [queryState] = useFetchedData(fetchData);
	return queryState;
}
