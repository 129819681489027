import { CheckboxToggle, QueryStateIndicator } from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { Division, User } from "@models";
import { Roles } from "@models/UserRole";
import { UserAssignedAsSuperiorError } from "@service/user/errors";
import { RequiredConfirmation } from "@service/user/type";
import React, { useState } from "react";
import styled from "styled-components";

type Props = {
	role: Roles;
	initialHasRole: boolean;
	label: string;
	description: string;

	user: User;
	division: Division;
	disabled?: boolean;
};

type ConfirmationReason = RequiredConfirmation["requiredConfirmation"];

export default function RoleEntry(props: Props) {
	const { user, division, role, disabled = false } = props;

	const [hasRole, setHasRole] = useState(props.initialHasRole);
	const { userService } = useServices();
	const confirmDialog = useDialog<ConfirmationReason>();

	const [changeQuery, queryState] = useAsyncCommand(
		async (granted: boolean, confirmation?: ConfirmationReason) => {
			if (granted) {
				return userService.grantRole(user.userId, division.divisionId, role);
			} else {
				return userService.revokeRole(
					user.userId,
					division.divisionId,
					role,
					confirmation
				);
			}
		},
		{ rethrowError: false }
	);
	const [togglePortalAdminRoleQuery, togglePortalAdminRoleState] =
		useAsyncCommand(
			async (granted: boolean) => {
				return userService.togglePortalAdminRole(
					user.userId,
					division.customerId,
					granted ? "grant" : "revoke"
				);
			},
			{ rethrowError: false }
		);

	const handleChange = async (
		value: boolean,
		confirmation?: ConfirmationReason
	) => {
		const result = await changeQuery(value, confirmation);
		if (result.state === "success") {
			setHasRole(value);
		}
		if (
			result.state === "error" &&
			result.error instanceof UserAssignedAsSuperiorError
		) {
			confirmDialog.showConfirmDialog({
				payload: "assigned-as-superior",
				text: `Dieser Mitarbeiter ist noch als Reisekostenprüfer für andere Mitarbeiter eingetragen.
					Wenn die Berechtigung entfernt wird, können keine Dienstreisen mehr geprüft werden.

					Die Berechtigung wirklich entfernen?`,
				onConfirm: (e) => {
					void handleChange(value, e);
				},
			});
		}
	};

	const handlePortalAdminRoleToggle = async (value: boolean) => {
		const result = await togglePortalAdminRoleQuery(value);
		if (result.state === "success") {
			setHasRole(value);
		}
	};

	const status = (
		<Indicator
			query={
				role === Roles.PortalAdmin ? togglePortalAdminRoleState : queryState
			}
		/>
	);

	return (
		<Container>
			<CheckboxToggle
				disabled={disabled}
				label={props.label}
				checked={hasRole}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					if (role === Roles.PortalAdmin) {
						void handlePortalAdminRoleToggle(e.target.checked);
					} else {
						void handleChange(e.target.checked);
					}
				}}
				status={status}
			/>
			<Description>{props.description}</Description>
		</Container>
	);
}

const Container = styled.div`
	margin-bottom: 8px;
`;
const Description = styled.i`
	margin-left: 32px;
`;
const Indicator = styled(QueryStateIndicator)`
	margin-left: 4px;
`;
