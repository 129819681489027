import {
	VoucherImage,
	Voucher,
	Contract,
	BusinessTrip,
	VoucherImageFile,
} from "@models";
import { ExcessBudget } from "@models/ExcessBudget";
import {
	filtersToQueryParams,
	ReviewFilters,
} from "@service/review/ReviewFilters";
import axios from "axios";
import * as apiService from "../api.service";
import { PaginationResult, QueryOptions } from "../types";
import { mapPaginationResponse } from "../util";
import { BudgetCorrection } from "@models/BudgetCorrection";
import { DuplicateVoucher } from "@models/Voucher";
import { serializeDate } from "@util";

export async function queryVouchers(
	options: QueryOptions,
	filters: ReviewFilters
): Promise<PaginationResult<Voucher>> {
	const queryParams: any = {
		limit: options.limit,
		page: options.page,
		sort: options.sort,
		order: options.order,
	};

	const filterParams = new URLSearchParams();
	filtersToQueryParams("vouchers", filters, filterParams);
	filterParams.forEach((value, key) => (queryParams[key] = value));

	const responseData = await apiService.GET("voucher", {
		params: queryParams,
	});

	return mapPaginationResponse(responseData.data, Voucher.fromJson);
}

export type MonthUsageSummary = {
	vouchers: Voucher[];
	contracts: Contract[];
	trips: BusinessTrip[];
	excessBudget: ExcessBudget | null;
	budgetCorrection: BudgetCorrection[] | null;
};

export async function getMonthSummaryByBudgetAndInterval(
	budgetOrModuleSettingId: string,
	date: string
): Promise<MonthUsageSummary> {
	const { data } = await apiService.GET(
		`budget/${budgetOrModuleSettingId}/month/${date}/summary`
	);

	return {
		vouchers: data.vouchers.map(Voucher.fromJson),
		contracts: data.contracts.map(Contract.fromJson),
		trips: data.trips.map(BusinessTrip.fromJson),
		excessBudget: data.excessBudget
			? ExcessBudget.fromJson(data.excessBudget)
			: null,
		budgetCorrection:
			data.budgetCorrection?.map(BudgetCorrection.fromJson) || null,
	};
}

export async function getVouchersForContract(
	contractId: string
): Promise<Voucher[]> {
	const responseData = await apiService.GET(`contract/${contractId}/vouchers`, {
		params: { contractId },
	});

	return (responseData.data as any[]).map(Voucher.fromJson);
}

export async function getVoucherImages(
	voucherId: string
): Promise<VoucherImage[]> {
	const responseData = await apiService.GET(`voucher/${voucherId}/image`);

	return (responseData.data as any[]).map(VoucherImage.fromJson);
}

export async function getVoucherImageFile(
	voucherId: string,
	voucherImageId: string
): Promise<VoucherImageFile> {
	try {
		const responseData = await apiService.GET(
			`voucher/${voucherId}/image/${voucherImageId}/file`
		);

		const mimeType = responseData.headers["content-type"] ?? "image/jpg;base64";

		return VoucherImageFile.fromSuccessfulResponse(
			voucherImageId,
			responseData.data,
			mimeType
		);
	} catch (e) {
		if (axios.isAxiosError(e) && e.response?.data.message === "file-infected") {
			return VoucherImageFile.fromError({ isInfected: true });
		}
		throw e;
	}
}

export async function getVoucherById(voucherId: string): Promise<Voucher> {
	const voucher = await apiService.GET("voucher/" + voucherId);
	return Voucher.fromJson(voucher.data);
}

export async function queryVouchersWithoutPagination(
	filters: ReviewFilters
): Promise<Voucher[]> {
	const queryParams: any = {
		limit: 15,
		page: 1,
	};

	const filterParams = new URLSearchParams();
	filtersToQueryParams("vouchers", filters, filterParams);
	filterParams.forEach((value, key) => (queryParams[key] = value));

	const responseData = await apiService.GET("voucher", { params: queryParams });

	return (responseData.data.data as any[]).map(Voucher.fromJson);
}

export async function getDuplicateVouchers(voucher: Voucher) {
	const {
		voucherId,
		amountAfterReview,
		dateOnVoucher,
		supplierName,
		customerId,
	} = voucher;

	// Construct the query parameters
	const params = new URLSearchParams({
		amountOnVoucher: amountAfterReview.toString(),
		dateOnVoucher: serializeDate.date(dateOnVoucher),
		supplierName: supplierName ?? "",
		customerId,
		page: "1",
		limit: "15",
	});

	const res = await apiService.GET(
		`voucher/${voucherId}/duplicates?${params.toString()}`
	);

	const duplicates = res.data as DuplicateVoucher[];
	return duplicates;
}
