import { colors } from "@theme/theming";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const DropDownWithIcon = styled(Dropdown.Item)`
	align-items: center;
	display: flex;

	svg {
		margin-right: 4px;
	}

	&.danger {
		color: ${colors.highlight.h300};
	}

	a {
		width: 100%;
		padding: 0.4rem 1.5rem;
		color: ${colors.primary.p200};
		&:hover {
			color: ${colors.primary.p300};
		}
	}
`;
