/** asd
 *
 * helps with converting nullable values from and to the expected string-based values of HTML Input elements
 *
 * Inspired by https://react-hook-form.com/advanced-usage#TransformandParse
 */
export function numericSelectProps(
	props: {
		value: number | null;
		onChange: (value: number | null) => void;
	},
	nullValue: string
) {
	return {
		onChange: (e: React.ChangeEvent<HTMLSelectElement>) =>
			props.onChange(
				e.target.value === nullValue
					? null
					: Number.parseInt(e.target.value, 10)
			),
		value: props.value?.toString() ?? nullValue,
	};
}
