import { Report } from "@models";
import { Header } from "@components/TableGeneric/TableTypes";
import React from "react";
import { TableGeneric, TableStylesPrimary } from "@components";
import { ReportRow } from "./ReportRow";
import { SortState } from "@hooks";

type Props = {
	reports: Report[];
	sort?: SortState;
};

export default function ReportsTable(props: Props) {
	const headers: Header[] = [
		{
			title: "Datum",
			isSortable: true,
			accessor: "created",
		},
		{
			title: "Monat",
			isSortable: true,
			accessor: "sourceMonth",
		},
		{
			title: "Erstellt von",
			isSortable: true,
			accessor: "createdBy",
		},
		{
			title: "Art",
			isSortable: true,
			accessor: "reportType",
		},
		{
			title: "", // Badges go here
		},
		{
			title: "Download Report",
		},
	];

	const noReports = <div>Keine Reports gefunden</div>;
	const reportsTable = (
		<TableStylesPrimary>
			<TableGeneric
				data={props.reports}
				headers={headers}
				sortOptions={props.sort}
			>
				{(report: Report) => <ReportRow report={report} />}
			</TableGeneric>
		</TableStylesPrimary>
	);

	return props.reports.length === 0 ? noReports : reportsTable;
}
