import Product, { DefaultProduct } from "@models/Product";
import ProductVariant from "@models/ProductVariant";
import { Properties } from "@util/types";

export abstract class ProductDeserializer<
	S extends Record<string, unknown> | null,
	T extends Product<S>
> {
	abstract deserialize(data: any): T;

	protected deserializeProps(data: any): Properties<T> {
		return {
			...data,
			variants: data.variants.map(ProductVariant.fromJson),
		};
	}
}

export class ProductDefaultDeserializer extends ProductDeserializer<
	null,
	DefaultProduct
> {
	deserialize(data: any): DefaultProduct {
		return new DefaultProduct(this.deserializeProps(data));
	}
}
