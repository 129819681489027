import { useCallback } from "react";
import { ContextServices } from "../context/ServiceContext";
import { QueryState, useFetchedData } from ".";
import { StaticData } from "../context/StaticDataContext";
import { Optional } from "@util/types";

export function useInitialStaticData(
	services: ContextServices,
	isLoggedIn: boolean
): QueryState<Optional<StaticData>> {
	const initialFetch = useCallback(async () => {
		if (isLoggedIn) {
			const service = services.staticDataService;
			const [legalForms, products, reportTypes] = await Promise.all([
				service.getLegalForms(),
				service.getProducts(),
				service.getAllReportTypes(),
			]);
			return Optional.some({ legalForms, products, reportTypes });
		} else {
			return Optional.none<StaticData>();
		}
	}, [isLoggedIn, services.staticDataService]);

	return useFetchedData(initialFetch)[0];
}
