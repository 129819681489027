/**
 * Delays the execution of a function for a set time.
 *
 * This should only be used for visual effects. In tests, `fn` is executed immediately.
 */
export function defer<T>(fn: () => T, timeout = 800): Promise<T> {
	if (process.env.NODE_ENV === "test") {
		// avoid animations and resolve immediately in tests
		return Promise.resolve(fn());
	}
	return new Promise((resolve) => setTimeout(() => resolve(fn()), timeout));
}

/**
 * Creates a new function that executes async function `fn`, then calls `defer` to wait a bit until eventually calling the `callbackFn`.
 *
 * This can be used as syntactic sugar to display some success UI feedback for an async operation before refreshing the state from the backend after some time.
 *
 * @example
 * ```ts
 *   const onClickButton = awaitWithDeferedFeedback(apiService.callSomeEndpoint, refreshData);
 * ```
 */
export function awaitWithDeferedCallback<T>(
	fn: () => Promise<T>,
	callbackFn: () => void
): () => Promise<T> {
	const deferedFn = async () => {
		const result = await fn();
		await defer(callbackFn);
		return result;
	};
	return deferedFn;
}
