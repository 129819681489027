import React from "react";
import {
	FormGroup,
	InputValidation,
	Label,
	NoAutoFillInput,
	RequiredMarker,
} from "@components";
import { Row, Col } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import {
	ExpenseAccountSystem,
	FinancialAccountingSettings,
	PostingKeyKey,
} from "@models/FinancialAccountingSettings";
import FinancialOptionsDropdown from "./FinancialOptionsDropdown";
import { CSSProp } from "styled-components";
import _ from "lodash";
import { FormValues } from "./types";

type Props = {
	handleSetDefaultsExpenseAccounts: (system: ExpenseAccountSystem) => void;
	expenseAccountNames: Record<string, string>;
};

const rowStyle: CSSProp = `
	justify-content: space-between;
	margin-bottom: 15px;
`;

export default function ExpenseAccountSettings({
	handleSetDefaultsExpenseAccounts,
	expenseAccountNames,
}: Props) {
	const { control, watch, formState } = useFormContext<FormValues>();
	const isSelectedSystem = !!watch("financialSoftwareId");

	const escapedLabelKeys = _.mapKeys(expenseAccountNames, (value, k) =>
		k.replaceAll(".", "-")
	);

	const escapedPostingKeyKeys = _.mapKeys(
		FinancialAccountingSettings.labelsPostingKeys,
		(value, k) => k.replaceAll(".", "-")
	);

	return (
		<>
			{isSelectedSystem && (
				<>
					<InputValidation error={formState.errors.clientNumber}>
						<FormGroup as={Row} css={rowStyle}>
							<Label column sm={6} htmlFor="clientNumber">
								Mandantennummer <RequiredMarker />
							</Label>
							<Col sm={6}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput id={"clientNumber"} {...field} />
									)}
									control={control}
									rules={{ required: true, minLength: 1 }}
									name="clientNumber"
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.consultantNumber}>
						<FormGroup as={Row} css={rowStyle}>
							<Label column sm={6} htmlFor="consultantNumber">
								Beraternummer <RequiredMarker />
							</Label>
							<Col sm={6}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput id={"consultantNumber"} {...field} />
									)}
									control={control}
									rules={{ required: true, minLength: 1 }}
									name="consultantNumber"
								/>
							</Col>
						</FormGroup>
					</InputValidation>

					<h3>Aufwandskonten</h3>
					<FormGroup as={Row} css={rowStyle}>
						<Label column sm={6} htmlFor="financialOptionsDropdown">
							Vorgabe Laden
						</Label>
						<FinancialOptionsDropdown
							handleSetDefault={handleSetDefaultsExpenseAccounts}
						/>
					</FormGroup>

					{Object.entries(escapedLabelKeys).map(([escapedKey, label]) => (
						<InputValidation
							key={escapedKey}
							error={formState.errors.expenseAccounts?.[escapedKey]}
						>
							<FormGroup as={Row}>
								<Label htmlFor={escapedKey} column sm={6}>
									{label}
								</Label>
								<Col sm={6}>
									<Controller
										control={control}
										render={({ field }) => (
											<NoAutoFillInput id={escapedKey} {...field} />
										)}
										name={`expenseAccounts.[${escapedKey}]`}
									/>
								</Col>
							</FormGroup>
						</InputValidation>
					))}

					<h3>Buchungsschlüssel</h3>
					{Object.entries(escapedPostingKeyKeys).map(([key, label]) => (
						<InputValidation
							key={key}
							error={formState.errors.postingKeys?.[key as PostingKeyKey]}
						>
							<FormGroup as={Row}>
								<Label htmlFor={key} column sm={6}>
									{label}
									<RequiredMarker />
								</Label>
								<Col sm={6}>
									<Controller
										render={({ field }) => (
											<NoAutoFillInput id={key} {...field} />
										)}
										control={control}
										rules={{ required: true, minLength: 1 }}
										name={`postingKeys.${key as PostingKeyKey}`}
									/>
								</Col>
							</FormGroup>
						</InputValidation>
					))}

					<h3>Verbindlichkeitskonto</h3>

					<InputValidation error={formState.errors.liabilityAccount}>
						<FormGroup as={Row} css={rowStyle}>
							<Label column sm={6} htmlFor="liabilityAccount">
								Verbindlichkeitskonto <RequiredMarker />
							</Label>
							<Col sm={6}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput id="liabilityAccount" {...field} />
									)}
									control={control}
									rules={{ required: true, minLength: 1 }}
									name="liabilityAccount"
								/>
							</Col>
						</FormGroup>
					</InputValidation>

					<InputValidation
						error={formState.errors.liabilityAccountPaidByEmployer}
					>
						<FormGroup as={Row} css={rowStyle}>
							<Label column sm={6} htmlFor="liabilityAccountPaidByEmployer">
								Verbindlichkeitskonto: Bezahlt vom Arbeitgeber{" "}
								<RequiredMarker />
							</Label>
							<Col sm={6}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput
											id="liabilityAccountPaidByEmployer"
											{...field}
										/>
									)}
									control={control}
									rules={{ required: true, minLength: 1 }}
									name="liabilityAccountPaidByEmployer"
								/>
							</Col>
						</FormGroup>
					</InputValidation>
				</>
			)}
		</>
	);
}
