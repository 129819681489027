import { colors } from "@theme/theming";
import styled from "styled-components";

export const LineSpacer = styled.hr`
	border-color: ${colors.gray.g200};
	width: 100%;
`;

export const IconWrapper = styled.div`
	margin-right: 2px;
`;
