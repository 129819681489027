import { BudgetMaster, Contract, NewContract, Product } from "@models";
import _ from "lodash";

export default function checkCorrespondingBudgetMaster(
	contract: Contract | NewContract,
	budgetMasters: BudgetMaster[],
	product: Product
) {
	const now = new Date(Date.now());

	const overlappingBudgetMasters = budgetMasters.filter((bm) => {
		if (bm.availableUntil) {
			return (
				contract.startDate <= bm.availableUntil &&
				(!contract.endDate || contract.endDate >= bm.availableFrom)
			);
		} else {
			return !contract.endDate || contract.endDate >= bm.availableFrom;
		}
	});

	if (overlappingBudgetMasters.length === 0) return false;

	const activeBudgetMasters = overlappingBudgetMasters.filter(
		(bm) =>
			bm.availableFrom <= now &&
			((bm.availableUntil && bm.availableUntil >= now) || !bm.availableUntil)
	);

	const budgetCategoryCandidates = _.flatMap(
		product.variants,
		(v) => v.budgetCategories
	)
		.filter((bc) => bc.findCategory(contract.category) !== undefined)
		.map((bc) => bc.id);

	const hasCorrespondingBudgetMasters = activeBudgetMasters.some((bm) => {
		const hasCorrespondingAmount = bm.amounts.some((amount) =>
			budgetCategoryCandidates.includes(amount.category)
		);
		const correspondingCategories = bm.categories.some(
			(category) => category === contract.category
		);

		if (contract.category === "web") {
			// here we have no sub-categories to enable
			return hasCorrespondingAmount;
		}
		return hasCorrespondingAmount && correspondingCategories;
	});

	return hasCorrespondingBudgetMasters;
}
