import { Category } from "@models";
import useStaticData from "./useStaticData";

export function useCategory(categoryId: string): Category {
	const { getCategory } = useStaticData();

	const category = getCategory(categoryId);

	if (!category) throw new Error(`Category ${categoryId} not found`);

	return category;
}
