import { deserializeDate } from "@util";
import { Mutable, Properties } from "@util/types";

export default class Customer {
	constructor(props: Properties<Customer>) {
		this.customerId = props.customerId;
		this.customerNo = props.customerNo;
		this.name = props.name;
		this.legalForm = props.legalForm;
		this.created = props.created;
		this.updated = props.updated;
		this.isArchived = props.isArchived;
	}

	readonly customerId: string;
	readonly customerNo: number | null;
	readonly name: string;
	readonly legalForm: string;
	readonly created: Date | null;
	readonly updated: Date | null;
	readonly isArchived: boolean;

	public static fromJson(data: any): Customer {
		return new Customer({
			...data,
			created: deserializeDate.nullable.dateTime(data["created"]),
			updated: deserializeDate.nullable.dateTime(data["updated"]),
		});
	}
}

export type NewCustomer = Mutable<
	Pick<Customer, "name" | "legalForm" | "isArchived">
>;
export type UpdatedCustomer = NewCustomer;
