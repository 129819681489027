import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import styled, { CSSProperties } from "styled-components";
import { IoClose } from "react-icons/io5";
import { colors } from "@theme/theming";

type Props = {
	children?: React.ReactNode;
	sticky?: boolean;
};

export default function ErrorAlert(props: Props) {
	const [isVisible, setIsVisible] = useState(true);

	const hideError = () => {
		setIsVisible(false);
	};

	if (isVisible)
		return (
			<StyledAlert
				style={props.sticky ?? true ? alertStyle : alertFlex}
				variant="danger"
			>
				{props.children ?? "Es ist ein Fehler aufgetreten."}
				<CloseBtn onClick={hideError} />
			</StyledAlert>
		);

	return null;
}

// NOTE: Alert cannot be used with styled(Alert)
export const alertStyle: CSSProperties = {
	left: "20%",
	top: "10px",
	width: "60%",
	position: "fixed",
	zIndex: 9999, // above any modal dialogs
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

const alertFlex: CSSProperties = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

const CloseBtn = styled(IoClose)`
	&:hover {
		cursor: pointer;
	}
`;

const StyledAlert = styled(Alert)`
	background-color: ${colors.highlight.h100};
	color: ${colors.danger.d400};
`;
