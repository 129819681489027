import {
	ActionButton,
	ButtonContainer,
	Form,
	FormContainer,
	QueryError,
} from "@components";
import { useAsyncCommand, useServices, useStaticData } from "@hooks";
import { Division, Product } from "@models";
import PricingRecord, { PricingSettings } from "@models/PricingRecord";
import { endOfDay, startOfDay } from "date-fns";
import _ from "lodash";
import {
	escapeCategoryId,
	unescapeCategoryId,
} from "../../products/components/util";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { useDivisionFromPageContext } from "../hooks/useDivisionFromPageContext";
import BasicDataSettings from "./settings/BasicDataSettings";
import ExcessBudgetSettings from "./settings/ExcessBudgetSettings";
import TimerSettings from "./settings/TimerSettings";
import PricingSettingsList from "./SettingsPage/PricingSettingsList";
import ReviewSettings from "./settings/ReviewSettings";
import OtherSettings from "./settings/OtherSettings";

export default function DivisionSettingsPage() {
	const context = useDivisionFromPageContext();
	const { division } = context;
	const products = useStaticData().products;

	const productsByKeyObj = products.reduce(
		(prev: PricingRecord["feePerModuleAndEmployee"], cur: Product) => ({
			...prev,
			[cur["id"]]: { type: null, amount: null },
		}),
		{}
	);

	const initialPricingSettings = (pricingSettings: PricingSettings) =>
		pricingSettings.map((pricingRecord: PricingRecord) => {
			if (pricingRecord.feePerModuleAndEmployee)
				return {
					...pricingRecord,
					feePerModuleAndEmployee: _.mapKeys(
						{
							...productsByKeyObj,
							...pricingRecord.feePerModuleAndEmployee,
						},
						(_, key) => escapeCategoryId(key)
					),
				};
			return pricingRecord;
		});

	const checkPricingSettings = (pricingSettings: PricingSettings) =>
		pricingSettings.map((pricingRecord: PricingRecord) => {
			const clearedFeePerModuleAndEmployee: PricingRecord["feePerModuleAndEmployee"] =
				{};
			if (pricingRecord.feePerModuleAndEmployee !== null) {
				for (const key in pricingRecord.feePerModuleAndEmployee) {
					const item = pricingRecord.feePerModuleAndEmployee[key];
					if (item.type !== null && item.type !== undefined) {
						const unescapedKey = unescapeCategoryId(key);
						clearedFeePerModuleAndEmployee[unescapedKey] = item;
					}
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { id, ...pricingRecordWithoutId } = pricingRecord;
			return {
				...pricingRecordWithoutId,
				availableFrom:
					pricingRecordWithoutId.availableFrom &&
					startOfDay(pricingRecordWithoutId.availableFrom),
				availableUntil:
					pricingRecordWithoutId.availableUntil &&
					endOfDay(pricingRecordWithoutId.availableUntil),
				feePerModuleAndEmployee: clearedFeePerModuleAndEmployee,
			};
		});

	const defaultValues = {
		...division,
		pricingSettings: division.pricingSettings
			? initialPricingSettings(division.pricingSettings)
			: [],
	};

	const form = useForm<Division>({
		defaultValues,
		mode: "onChange",
		shouldUnregister: false,
	});

	const { handleSubmit, formState, setValue, watch } = form;
	const { divisionService } = useServices();
	const [updateCmd, updateQueryState] = useAsyncCommand(
		divisionService.updateDivision
	);

	const handleSubmitUserForm = handleSubmit(async (values) => {
		const updated: Division = {
			...division,
			...values,
			pricingSettings: values.pricingSettings
				? checkPricingSettings(values.pricingSettings)
				: null,
			reviewSettings: values.reviewSettings?.shouldReviewTrips
				? values.reviewSettings
				: null,
		};
		const updatedValue = await updateCmd(
			division.divisionId,
			updated,
			defaultValues
		);
		if (updatedValue.state === "success") {
			form.reset(updatedValue.data);
			context.updateDivision(updatedValue.data);
		}
	});

	const jobradSettings = watch("providerSettings.jobrad");

	useEffect(() => {
		if (
			defaultValues.providerSettings?.jobrad !== jobradSettings &&
			jobradSettings === undefined
		) {
			setValue("providerSettings.jobrad", undefined, { shouldDirty: true });
		}
	}, [jobradSettings]);

	return (
		<FormContainer className="form">
			<FormProvider {...form}>
				<StyledForm onSubmit={handleSubmitUserForm}>
					<QueryError queryState={updateQueryState} />
					<BasicDataSettings />
					<TimerSettings />
					<ExcessBudgetSettings />
					<ReviewSettings
						initiallyEnabled={!!division.reviewSettings?.shouldReviewTrips}
					/>
					<OtherSettings />
					<PricingSettingsList />
					<ButtonContainer>
						<ActionButton
							type="submit"
							query={updateQueryState}
							disabled={!formState.isValid || !formState.isDirty}
							successContent="Gespeichert"
							disableAfterSuccess={false}
						>
							Speichern
						</ActionButton>
					</ButtonContainer>
				</StyledForm>
			</FormProvider>
		</FormContainer>
	);
}

const StyledForm = styled(Form)`
	display: grid;
	row-gap: 3rem;
	padding-left: 2rem;
`;
