import { Form, FormGroup, InputValidation, Label } from "@components";
import { Contract, NewContract } from "@models";
import { isBefore } from "date-fns";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "@components/forms/DatePicker";

export default function JobVehicleLeaseDates() {
	const { control, watch, formState, setValue } = useFormContext<
		Contract | NewContract
	>();
	return (
		<>
			<InputValidation error={formState.errors.leaseStartDate}>
				<FormGroup as={Row}>
					<Label htmlFor="leaseStartDate" column sm={4}>
						Leasing-Vertrag Start
					</Label>
					<Col sm={4}>
						<Controller
							name="leaseStartDate"
							control={control}
							render={({ field }) => (
								<DatePicker
									{...field}
									id="leaseStartDate"
									onClearDate={() =>
										setValue("leaseStartDate", null, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
									canClearDate
								/>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
			<InputValidation error={formState.errors.leaseEndDate}>
				<FormGroup as={Row}>
					<Label htmlFor="leaseEndDate" column sm={4}>
						Leasing-Vertrag Ende
					</Label>
					<Col sm={4}>
						<Controller
							name="leaseEndDate"
							control={control}
							rules={{
								validate: (leaseEndDate: Date | null) => {
									const leaseStartDate = watch("leaseStartDate");
									if (
										leaseStartDate &&
										leaseEndDate &&
										!isBefore(leaseStartDate, leaseEndDate)
									) {
										return "Leasing Enddatum darf nicht vor Leasing Startdatum liegen";
									}
								},
							}}
							render={({ field }) => (
								<DatePicker
									{...field}
									id="leaseEndDate"
									onClearDate={() =>
										setValue("leaseEndDate", null, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
									canClearDate
								/>
							)}
						/>
						<Form.Text className="text-muted">
							Das Leasing Start/End-Datum dient dem Mitarbeiter nur zur
							Information. Dieses wird in der App als Start/Enddatum des
							Fahrzeugs angezeigt.
						</Form.Text>
					</Col>
				</FormGroup>
			</InputValidation>
		</>
	);
}
