import React, { useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { QueryState, useFetchedData, useServices } from "@hooks";
import { NewContract, ConfigContract, Contract, Voucher } from "@models";
import styled from "styled-components";
import {
	Form,
	ActionButton,
	Button,
	QueryError,
	FormSection,
	FormSectionContent,
	FormGrid,
	ButtonContainer,
	ExpandSection,
	QueryStateIndicators,
	Icon,
	Tooltip,
} from "@components";
import { useAsyncCommand } from "@hooks";
import { defer } from "@util/helpers";
import ContractsPageContext from "../ContractsPageContext";
import ContractSettings from "./ContractSettings";
import JobVehicleFinancing from "./jobVehicles/JobVehicleFinancing";
import {
	getUpdatedContract,
	isJobVehicleContract,
	isNewContract,
	NO_CORRESPONDING_BUDGET_MESSAGE,
} from "../util";
import ContractSegments from "./ContractSegments";
import { Alert } from "react-bootstrap";
import VoucherList from "@components/vouchers/VoucherList";
import EmployerFinancedRailcard from "./longDistance/EmployerFinancedRailcard";
import { isEmployerFinancedRailcardContract } from "@models/Contract";
import { colors } from "@theme/theming";
import { useLocation } from "react-router-dom";
import { ContractsPageState } from "@service/navigation/routes";

type Props = {
	contract: Contract | NewContract;
	hasCorrespondingBudget: boolean;
	onCloseCard: (needsConfirmation: boolean) => void;
	onFormDirty: (dirty: boolean) => void;
};

export default function ContractConfiguration(props: Props) {
	const { contract, onFormDirty, hasCorrespondingBudget } = props;
	const { contractService, voucherService } = useServices();
	const pageContext = useContext(ContractsPageContext);
	const isNew = isNewContract(contract);
	const hasVoucher = isNewContract(contract) && !!contract.attachedVoucher;
	const reviewDuration = useReviewDuration();

	const [voucherQuery] = useFetchedData(
		useCallback(async () => {
			if ("contractId" in contract) {
				const response = await voucherService.getVouchersForContract(
					contract.contractId
				);
				return response;
			}
			return [] as Voucher[];
		}, [contract, voucherService])
	);

	const formMethods = useForm<ConfigContract>({
		mode: "onChange",
		defaultValues: contract,
	});

	const { formState, handleSubmit, getValues, reset } = formMethods;

	const [triggerSave, saveQuery] = useAsyncCommand(
		async (contract: Contract | NewContract) => {
			if (isNewContract(contract)) {
				await contractService.createContract(
					contract,
					contract.attachedVoucher,
					reviewDuration
				);
			} else {
				await contractService.updateContract(contract);
			}
		}
	);

	useEffect(() => {
		onFormDirty(formState.isDirty);
	}, [onFormDirty, formState.isDirty]);

	// update the form values when user changes the category of a draft contract (before submitting)
	// by selecting a new category from the dropdown
	useEffect(() => {
		if (contract.category !== getValues().category && isNew) {
			reset(contract);
		}
	}, [contract]);

	const handleSubmitForm = handleSubmit(async (formValues) => {
		const updatedContract = getUpdatedContract(formValues, contract);
		const state = await triggerSave(updatedContract);
		if (state.state === "success") {
			pageContext.triggerRefresh();

			await defer(() => {
				props.onCloseCard(false);
			});
		}
	});

	return (
		<Container data-testid="contract-config-container">
			<QueryError queryState={saveQuery} />
			<FormProvider {...formMethods}>
				<Form onSubmit={handleSubmitForm}>
					{!hasCorrespondingBudget && (
						<AlertMessageContainer>
							<Alert variant={"warning"}>
								{NO_CORRESPONDING_BUDGET_MESSAGE}
							</Alert>
						</AlertMessageContainer>
					)}

					<FormGrid>
						<FormSection>Einstellungen</FormSection>

						<StyledFormSectionContent>
							<ContractSettings contract={contract} />
						</StyledFormSectionContent>
					</FormGrid>

					{isJobVehicleContract(contract) && (
						<>
							<FormGrid>
								<FormSection>Finanzierung</FormSection>
								<StyledFormSectionContent>
									<JobVehicleFinancing />
								</StyledFormSectionContent>
							</FormGrid>
						</>
					)}
					{isEmployerFinancedRailcardContract(contract) && (
						<>
							<FormGrid>
								<FormSection>Finanzierung</FormSection>
								<StyledFormSectionContent>
									<EmployerFinancedRailcard />
								</StyledFormSectionContent>
							</FormGrid>
						</>
					)}

					<FormGrid>
						<FormSection>Weitere Einstellungen</FormSection>
						<StyledFormSectionContent>
							<ContractSegments contract={contract} />
						</StyledFormSectionContent>
					</FormGrid>

					<VouchersSection query={voucherQuery} />

					<ButtonContainer>
						{hasVoucher && (
							<AttachedVoucherContainer>
								<Tooltip
									id="tooltip"
									tooltip="Dieser Dauerbeleg wird aus einem Einzelbeleg erstellt. Der Einzelbeleg wird an den Dauerbeleg angehängt."
								>
									<div>
										<Icon.Vouchers /> Angehängter Einzelbeleg
									</div>
								</Tooltip>
							</AttachedVoucherContainer>
						)}
						<Button
							variant="outline-secondary"
							onClick={() => props.onCloseCard(true)}
						>
							Abbrechen
						</Button>
						<ActionButton
							type="submit"
							disabled={!formState.isValid || (!formState.isDirty && !isNew)}
							query={saveQuery}
							variant={isNew ? undefined : "warning"}
							successContent={"Gespeichert"}
						>
							Speichern
						</ActionButton>
					</ButtonContainer>
				</Form>
			</FormProvider>
		</Container>
	);
}

const Container = styled.div`
	padding: 16px;
`;

const StyledFormSectionContent = styled(FormSectionContent)`
	grid-template-columns: auto;
	row-gap: 0;
`;

const AlertMessageContainer = styled(FormSectionContent)`
	grid-column: span 2;
	margin-top: 8px;
`;

function VouchersSection(props: { query: QueryState<Voucher[]> }) {
	const { query } = props;

	return (
		<ExpandSection
			title={
				<ExpandSectionTitle
					title="Belege"
					subtitle={
						query.state === "success"
							? query.data.length === 1
								? "1 Beleg"
								: query.data.length + " Belege"
							: "Belege"
					}
				/>
			}
		>
			<QueryStateIndicators queryState={query} />
			{query.state === "success" && (
				<StyledFormSectionContent>
					<VoucherList vouchers={query.data} />
				</StyledFormSectionContent>
			)}
		</ExpandSection>
	);
}

function ExpandSectionTitle(props: { title: string; subtitle: string }) {
	return (
		<div>
			<FormSection
				css={`
					margin-left: 8px;
					margin-right: 16px;
					display: inline;
				`}
			>
				{props.title}
			</FormSection>
			<span
				css={`
					font-size: small;
					color: ${colors.gray.g400};
				`}
			>
				{props.subtitle}
			</span>
		</div>
	);
}
const AttachedVoucherContainer = styled.div`
	width: 100%;
	align-self: center;
	font-size: larger;
	display: flex;
`;

function useReviewDuration() {
	const routerState = useLocation<ContractsPageState>();
	return routerState.state?.reviewDuration;
}
