import {
	filtersToQueryParams,
	ReviewFilters,
	ReviewStatus,
} from "@service/review/ReviewFilters";
import { endOfMonth, startOfMonth } from "date-fns";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import FilterableReviewList from "./FilterableReviewList";

type Props = {
	requiredFilters?: ReviewFilters;
};

export default function DefaultFilterableVoucherList(props: Props) {
	const location = useLocation();

	if (!location.search) {
		// no query params: redirect to url with default params
		// not implemented in actual FilterableVoucherList to prevent multiple requests
		const params = new URLSearchParams();
		filtersToQueryParams(
			"vouchers",
			{
				date: {
					start: startOfMonth(new Date()),
					end: endOfMonth(new Date()),
				},
				reviewStatus: [ReviewStatus.InReview],
			},
			params
		);

		params.set("page", "1");
		params.set("limit", "15");
		params.set("mode", "vouchers");

		const newLocation = { ...location, search: "?" + params.toString() };
		return <Redirect to={newLocation} />;
	} else {
		const urlMode = new URLSearchParams(location.search).get("mode");
		if (!urlMode) {
			const newLocation = {
				...location,
				search: location.search + `&mode=vouchers`,
			};
			return <Redirect to={newLocation} />;
		}
	}

	return <FilterableReviewList {...props} />;
}
