import { deserializeDate, serializeDate } from "@util";
import { Properties } from "@util/types";

export class OvernightStay {
	constructor(props: Properties<OvernightStay>) {
		this.checkinDate = props.checkinDate;
		this.checkoutDate = props.checkoutDate;
		this.name = props.name;
		this.voucherId = props.voucherId;
	}

	readonly checkinDate: Date;
	readonly checkoutDate: Date;
	readonly name: string;
	readonly voucherId: string | null;

	static convertForJson(overnightStay: OvernightStay): any {
		return {
			...overnightStay,
			checkinDate: serializeDate.date(overnightStay.checkinDate),
			checkoutDate: serializeDate.date(overnightStay.checkoutDate),
		};
	}

	static fromJson(data: any) {
		return new OvernightStay({
			...data,
			checkinDate: deserializeDate.date(data.checkinDate),
			checkoutDate: deserializeDate.date(data.checkoutDate),
		});
	}
}
