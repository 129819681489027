import { UserWithBalanceAccounts } from "@models";
import React from "react";

type UserDetailsPageContextValue = {
	user: UserWithBalanceAccounts;
	triggerRefresh: () => void;
};

export default React.createContext<UserDetailsPageContextValue>({
	triggerRefresh: () => {},
} as UserDetailsPageContextValue);
