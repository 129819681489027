import styled from "styled-components";
import { colors } from "@theme/theming";

const Table = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	border-radius: 5px;

	table {
		border: none;
		border-collapse: separate;
		border-spacing: 0 16px;
		padding: 5px;
	}
	th {
		border: none;
	}

	table td {
		border: none;
		vertical-align: middle;
	}

	tr {
		background: ${colors.gray.g100}30;
	}
`;
export const TableStylesPrimary = styled(Table)`
	tr {
		:hover {
			background-color: ${colors.gray.g100};
		}
		td {
			&:first-child {
				border-left: 1px solid ${colors.gray.g200};
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			&:last-child {
				border-right: 1px solid ${colors.gray.g200};
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			border: 1px solid ${colors.gray.g200};
			border-left: none;
			border-right: none;
		}
	}

	th {
		position: sticky;
		top: 0;
		z-index: 99;
		background: ${colors.white};
	}
`;

export const TableStylesSecondary = styled(Table)`
	table tr td {
		padding: 12px;
		padding: 0.2rem 0.5rem;
	}
`;
