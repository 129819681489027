import { GiftExpense } from "@models";
import React from "react";
import BeneficiaryDetails from "./BeneficiaryDetails";

type Props = {
	expense: GiftExpense;
};

export default function HospitalityExpenseDetails(props: Props) {
	return <BeneficiaryDetails beneficiaries={props.expense.beneficiaries} />;
}
