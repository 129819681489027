import { DeepMutable, Properties } from "@util/types";
import Category from "./Category";

export default class BudgetCategory extends Category {
	constructor(props: DeepMutable<Properties<BudgetCategory>>) {
		super(props);
		this.maxAmountMonth = props.maxAmountMonth;
	}

	get root(): Category {
		return this;
	}

	get parents(): Category[] {
		return [];
	}

	static fromJson(data: any) {
		return new BudgetCategory({
			...data,
			...Category.fromJson(data),
		});
	}

	readonly maxAmountMonth: number;
}
