import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { colors } from "@theme/theming";

type Props = {
	show?: boolean;
	header?: string;
	handleClose: () => void;
	children: React.ReactNode;
};

export default function CommonModal(props: Props) {
	return (
		<StyledModal
			show={props.show ?? true}
			onHide={props.handleClose}
			size="lg"
			centered
		>
			{props.header ? (
				<StyledHeader closeLabel="Schließen" closeButton>
					<Modal.Title>{props.header}</Modal.Title>
				</StyledHeader>
			) : (
				<StyledHeader closeLabel="Schließen" closeButton></StyledHeader>
			)}
			<StyledBody>{props.children}</StyledBody>
		</StyledModal>
	);
}

const StyledHeader = styled(Modal.Header)`
	background: ${colors.white};
	border: 0px;
`;

const StyledModal = styled(Modal)`
	padding-left: unset !important;
	padding-right: unset !important;
	border-radius: 35px;
	opacity: 1;

	.modal-dialog {
		width: clamp(570px, 85%, 1400px);
		max-width: unset !important;
		margin: auto !important;
		height: 100% !important;
	}

	.close {
		&:focus {
			outline: 0;
			color: ${colors.primary.p200};
		}
	}
`;

const StyledBody = styled(Modal.Body)`
	background: ${colors.white};
	border-radius: 0 0 calc(0.3rem - 1px) calc(0.3rem - 1px);
	overflow-y: auto;
	max-height: 90vh;
`;
