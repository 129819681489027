import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Checkbox } from "@components";
import { Category } from "@models";
import { useFormContext } from "react-hook-form";
import { escapeCategoryId } from "../util";
import { ConfigBudgetMaster } from "./types";
import { Alert } from "react-bootstrap";

type Props = {
	value: string[];
	onChange: (value: string[]) => void;
	onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	name: string;
	budgetCategory: Category;
};

export default function NestedCategorySelection(props: Props) {
	const { budgetCategory, onChange } = props;
	const categories = useMemo(
		() => flattenCategories(budgetCategory),
		[budgetCategory]
	);
	const {
		watch,
		setError,
		clearErrors,

		formState: { errors },
	} = useFormContext<ConfigBudgetMaster>();
	const selectedCategories = watch().categories;
	const fieldName = escapeCategoryId(budgetCategory.id);
	const namePrefix = `amounts.${fieldName}` as const;
	const isCategoryActive: boolean = watch(`${namePrefix}.enabled`);

	const isChecked = useCallback(
		(category: Category) => selectedCategories.includes(category.id),
		[selectedCategories]
	);

	// set form to invalid and show alert if amounts of category is enabled but has no categories selected.
	useEffect(() => {
		const subCategories = flattenCategories(budgetCategory).map(
			(item) => item.category.id
		);

		if (
			isCategoryActive &&
			subCategories.length > 0 &&
			!_.intersection(selectedCategories, subCategories).length
		) {
			return setError(`${namePrefix}.enabled` as any, {
				type: "manual",
			});
		}
		return clearErrors(`${namePrefix}.enabled` as any);
	}, [
		selectedCategories,
		isCategoryActive,
		clearErrors,
		namePrefix,
		setError,
		budgetCategory,
	]);

	const handleCheck = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const categoryId = e.target.getAttribute("data-value");
			if (!categoryId) return;

			if (e.target.checked && !selectedCategories.includes(categoryId)) {
				onChange(selectCategory(categoryId, selectedCategories, categories));
			}
			if (!e.target.checked && selectedCategories.includes(categoryId)) {
				onChange(deselectCategory(categoryId, selectedCategories, categories));
			}
		},
		[selectedCategories, onChange, categories]
	);

	return (
		<div>
			{errors.amounts && errors.amounts[fieldName] && (
				<Alert variant="warning">
					Es muss mindestens eine Kategorie ausgewählt werden.
				</Alert>
			)}
			{categories.map(({ category, level }) => (
				<Checkbox
					key={category.id}
					label={category.name}
					value={isChecked(category)}
					onChange={handleCheck}
					css={`
						margin-left: ${16 * level}px;
					`}
					data-value={category.id}
					data-testid={`category-${category.id}`}
				/>
			))}
		</div>
	);
}

type CategoryNode = {
	category: Category;
	level: number;
	isLeaf: boolean;
};

function selectCategory(
	categoryId: string,
	selectedCategories: string[],
	budgetCategory: CategoryNode[]
) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const selected = budgetCategory.find((c) => c.category.id === categoryId)!;
	const parentIds = selected.category.parents.map((c) => c.id);

	if (selected.isLeaf) {
		return _.uniq([...selectedCategories, categoryId, ...parentIds]);
	} else {
		const subCategories = flattenCategories(selected.category);
		return _.uniq([
			...selectedCategories,
			categoryId,
			...subCategories.map((c) => c.category.id),
			...parentIds,
		]);
	}
}

function deselectCategory(
	categoryId: string,
	selectedCategories: string[],
	budgetCategory: CategoryNode[]
) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const selected = budgetCategory.find((c) => c.category.id === categoryId)!;

	if (selected.isLeaf) {
		return _.without(selectedCategories, categoryId);
	} else {
		const subCategories = flattenCategories(selected.category);
		return _.without(
			selectedCategories,
			categoryId,
			...subCategories.map((c) => c.category.id)
		);
	}
}

function flattenCategories(category: Category): CategoryNode[] {
	function flattenRec(category: Category, level: number): CategoryNode[] {
		const node = {
			category,
			level,
			isLeaf: !category.subCategories,
		};
		if (category.subCategories) {
			const subs = _.flatMap(category.subCategories, (c) =>
				flattenRec(c, level + 1)
			);
			return [node, ...subs];
		}
		return [node];
	}

	return _.flatMap(category.subCategories, (c) => flattenRec(c, 0));
}
