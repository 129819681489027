import React from "react";
import { ProgressBar } from "react-bootstrap";
import { FlexCol, LargeText, ProductIcon, SmallText } from "@components";
import { useCategory } from "@hooks";
import { Budget, BudgetMaster } from "@models";
import { Currency, formatCategoryName } from "@util";
import { Amount } from "./VoucherCard/Amount";
import { colors } from "@theme/theming";

type Props = {
	product: Budget | BudgetMaster;
};
export default function MonthSummaryHeader(props: Props) {
	const { product } = props;
	const category = useCategory(product.category);
	let usedAmount = 0,
		maxAmount = 0;
	let showAsBudget = false;

	if (product instanceof Budget) {
		usedAmount = product.usedAmount;
		maxAmount = product.maxAmount;
		showAsBudget = product.category !== "shortTime";
	}

	const percentUsage = Math.round((usedAmount / maxAmount) * 100);

	const usedPercentage =
		isNaN(percentUsage) || !Number.isFinite(percentUsage) ? 0 : percentUsage;

	const styleProgressBar =
		usedPercentage < 30
			? "danger"
			: usedPercentage < 70
			? "warning"
			: "success";

	return (
		<>
			<FlexCol
				css={`
					font-size: 2em;
				`}
			>
				<ProductIcon productId={category.product.id} />
			</FlexCol>
			<FlexCol
				css={`
					width: 40em;
				`}
			>
				<LargeText>{formatCategoryName(category)}</LargeText>
			</FlexCol>
			<FlexCol
				css={`
					width: 20em;
					white-space: nowrap;
				`}
			>
				<Amount>
					<LargeText
						title="Gebraucht"
						css={`
							margin: 0 0.5em;
						`}
					>
						{Currency.format(usedAmount, {
							hideZeroDecimals: true,
						})}
					</LargeText>

					{showAsBudget && (
						<SmallText
							title="Max. Betrag/Monat"
							css={`
								margin: 0 0.5em;
								color: ${colors.gray.g400};
							`}
						>
							{`/ ${Currency.format(maxAmount, {
								hideZeroDecimals: true,
							})}`}
						</SmallText>
					)}
				</Amount>
			</FlexCol>
			{showAsBudget && (
				<FlexCol
					css={`
						align-items: center;
						width: 30em;
					`}
				>
					<SmallText title="Prozentsatz">{usedPercentage}%</SmallText>
					<ProgressBar
						variant={styleProgressBar}
						min={0}
						max={100}
						now={usedPercentage}
						css={`
							width: 15em;
						`}
					/>
				</FlexCol>
			)}
		</>
	);
}
