import { Customer } from "@models";
import axios from "axios";
import { NewCustomer, UpdatedCustomer } from "../../models/Customer";
import * as apiService from "../api.service";
import { PaginationResult, QueryOptions } from "../types";
import { mapPaginationResponse } from "../util";
import { DeleteCustomerWithVouchersError } from "./errors";

export async function getCustomerById(customerId: string): Promise<Customer> {
	const customer = await apiService.GET("customer/" + customerId);
	return Customer.fromJson(customer.data);
}

export async function getCustomers(
	options: QueryOptions
): Promise<PaginationResult<Customer>> {
	const { limit, page, sort, order, filter } = options;
	const params: Record<string, unknown> = {
		// TODO: extract utility
		limit,
		page,
		sort,
		order,
	};

	if (filter && filter["customer"]) {
		params["customer"] = filter["customer"];
	}

	const response = await apiService.GET(`customer`, {
		params,
	});
	return mapPaginationResponse(response.data, Customer.fromJson);
}

export async function addCustomer(customer: NewCustomer): Promise<Customer> {
	const newCustomer = await apiService.POST("customer", customer);
	return Customer.fromJson(newCustomer.data);
}

export async function updateCustomer(
	customerId: string,
	customer: UpdatedCustomer
): Promise<Customer> {
	const updatedCustomer = await apiService.PUT(
		"customer/" + customerId,
		customer
	);
	return Customer.fromJson(updatedCustomer.data);
}

export async function deleteCustomer(customerId: string): Promise<number> {
	try {
		return (await apiService.DELETE("customer/" + customerId)).data;
	} catch (error) {
		console.log("deleteCustomerError", error);
		if (
			axios.isAxiosError(error) &&
			error.response?.data.type === "customer-with-vouchers"
		) {
			throw new DeleteCustomerWithVouchersError();
		}
		throw error;
	}
}
