import React, { ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Button from "./Button";

interface Props {
	show?: boolean;
	handleClose: () => void;
	handleOk: () => void;
	children: ReactNode;
	headerContent?: ReactNode;
	/** Content of "OK" button, defaults to "OK" */
	okText?: ReactNode;
	/** Content of Cancel button, defaults to "Abbrechen" */
	cancelText?: ReactNode;
}

/**
 * Prefer not to use this directly, use `useDialog` hook instead
 */
export default function ConfirmDialog(props: Props) {
	return (
		<Modal show={props.show ?? true} onHide={props.handleClose} size="lg">
			<StyledHeader closeButton>
				{props.headerContent && <h5>{props.headerContent}</h5>}
			</StyledHeader>
			<Modal.Body>{props.children}</Modal.Body>
			<StyledFooter>
				<FormBtn variant="outline-primary" onClick={props.handleClose}>
					{props.cancelText ?? "Abbrechen"}
				</FormBtn>
				<FormBtn variant="primary" onClick={props.handleOk}>
					{props.okText ?? "OK"}
				</FormBtn>
			</StyledFooter>
		</Modal>
	);
}

const FormBtn = styled(Button).attrs({ size: "sm" })`
	text-transform: none;
`;

const StyledHeader = styled(Modal.Header)`
	border-bottom: 0px;
`;

const StyledFooter = styled(Modal.Footer)`
	border-top: 0px;
`;
