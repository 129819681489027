import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./VoucherReviewImage.css";
import { Button, QueryStateIndicators } from "@components";
import { saveAs } from "file-saver";
import VoucherDocumentViewer from "./VoucherDocumentViewer";
import VoucherPdfViewer from "./VoucherPdfViewer";
import { base64ImageToBlob } from "@util";
import { useVoucherImages } from "../hooks/useVoucherImages";
import Voucher from "@models/Voucher";
import { colors } from "@theme/theming";

type Props = {
	voucher: Voucher;
	style?: CSSProperties;
	showControls?: boolean;
};

export default function VoucherReviewImage({
	voucher,
	style,
	showControls = true,
}: Props) {
	const voucherImageQuery = useVoucherImages(voucher);

	const [selectedImage, setSelectedImage] = useState(0);

	const containerRef = useRef<HTMLDivElement>(null);
	const [containerSize, setContainerSize] = useState<DOMRect>();

	useLayoutEffect(() => {
		if (containerRef.current) {
			const size = containerRef.current.getBoundingClientRect();
			setContainerSize(size);
		}
	}, [containerRef]);

	const images = voucherImageQuery.data ?? [];

	const img = images[selectedImage];

	let documentComponent: React.ReactNode;

	if (!img) {
		documentComponent = <div>Kein Bild vorhanden</div>;
	} else if (containerSize) {
		if (img.loadedSuccessfully) {
			if (img.mimeType.startsWith("application/pdf")) {
				documentComponent = (
					<VoucherPdfViewer
						containerSize={containerSize}
						image={img}
						style={{
							flex: 1,
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					/>
				);
			} else {
				documentComponent = (
					<VoucherDocumentViewer
						containerSize={containerSize}
						style={{ flex: 1, width: "100%" }}
					>
						<img src={`data:${img.mimeType},${img.imageData}`} alt="voucher" />
					</VoucherDocumentViewer>
				);
			}
		} else {
			if (img.isInfected) {
				documentComponent = (
					<div
						css={`
							color: ${colors.danger.d300};
						`}
					>
						Die Datei wurde vom Virenscanner als mit Malware infiziert erkannt
					</div>
				);
			} else {
				documentComponent = <div>Bild konnte nicht dargestellt werden</div>;
			}
		}
	} else {
		documentComponent = <div>Bild konnte nicht dargestellt werden</div>;
	}

	const handleSaveImageFile = async () => {
		if (img.fileExtension === "pdf") {
			saveAs(img.imageData, `Beleg-${img.voucherImageId}.pdf`);
		}
		const imageFile = await base64ImageToBlob(img.imageData, img.mimeType);
		saveAs(imageFile, `Beleg-${img.voucherImageId}.${img.fileExtension}`);
	};

	return (
		<Container ref={containerRef} style={{ ...style }}>
			<QueryStateIndicators
				queryState={voucherImageQuery}
				absolutePosition={false}
			/>
			{voucherImageQuery.state === "success" && (
				<>
					{images.length > 1 && showControls && (
						<ImageSelectionContainer>
							<span>Bilder:</span>
							{images.map((_, imageIdx) => (
								<SmallButton
									key={imageIdx}
									onClick={() => setSelectedImage(imageIdx)}
								>
									{imageIdx + 1}
								</SmallButton>
							))}
						</ImageSelectionContainer>
					)}
					{documentComponent}
					{img && img.loadedSuccessfully && showControls && (
						<DownloadButton size="sm" onClick={handleSaveImageFile}>
							Download Bild (.{img.fileExtension.toUpperCase()})
						</DownloadButton>
					)}
				</>
			)}
		</Container>
	);
}

const Container = styled.div`
	background: ${colors.gray.g100};
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
`;

const SmallButton = styled(Button)`
	padding: 8px;
`;

const DownloadButton = styled(SmallButton)`
	position: absolute;
	bottom: 0.5rem;
	left: 0.5rem;
`;

const ImageSelectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: fit-content;
	align-items: baseline;
`;
