import validator from "validator";

export function isValidEmail(email: string): boolean {
	if (
		!validator.isEmail(email, {
			allow_utf8_local_part: false,
		})
	) {
		return false;
	}

	const domainName = email.split("@")[1];
	const hasDomainNameSpecialCharacters = /[\x7f-\xff]/.test(domainName);

	return !hasDomainNameSpecialCharacters;
}
