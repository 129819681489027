import AccountingMonth from "@models/AccountingMonth";
import { PaginationResult, QueryOptions } from "@service/types";
import { mapPaginationResponse } from "@service/util";
import axios from "axios";
import { format } from "date-fns";
import * as apiService from "../api.service";
import { UnreviewedVouchersError } from "./error";

export async function getAccountingMonthsByDivision(
	divisionId: string,
	options: QueryOptions
): Promise<PaginationResult<AccountingMonth>> {
	const queryParams: any = {
		limit: options.limit,
		page: options.page,
		sort: options.sort,
		order: options.order,
	};
	const responseData = await apiService.GET(
		`division/${divisionId}/accountingMonth`,
		{
			params: queryParams,
		}
	);
	return mapPaginationResponse(responseData.data, (item) =>
		AccountingMonth.fromJson(item, divisionId)
	);
}

export async function getAccountingMonthsByDivisionAndMonth(
	divisionId: string,
	month: Date
): Promise<AccountingMonth | undefined> {
	const dateStr = format(month, "yyyy-MM");
	try {
		const responseData = await apiService.GET(
			`division/${divisionId}/accountingMonth/${dateStr}`,
			{
				validateStatus: (s) => s < 300 || s === 404,
			}
		);
		// HOTFIX: with updated axios client the promise resolves to `undefined` rather than fail if return status is 404
		if (responseData.status === 404) {
			return undefined;
		}
		return AccountingMonth.fromJson(responseData.data, divisionId);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 404) {
				// no AccountingMonth found for those input params
				return undefined;
			}
		}
		throw e;
	}
}
export async function updateStatus(
	divisionId: string,
	monthDate: Date,
	status: AccountingMonth["status"]
): Promise<void> {
	try {
		await apiService.PUT(
			`division/${divisionId}/accountingMonth/${format(monthDate, "yyyy-MM")}`,
			{
				status,
			}
		);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				if (e.response.data.type === "unreviewed-vouchers") {
					throw new UnreviewedVouchersError();
				}

				throw new Error(
					"Objekt wurde in der Zwischenzeit geändert, bitte neu laden und nochmal probieren"
				);
			}
		}
		throw e;
	}
}

export async function lockMonthForDivision(
	divisionId: string,
	monthDate: Date
): Promise<void> {
	await apiService.POST(`division/${divisionId}/accountingMonth`, {
		month: format(monthDate, "yyyy-MM"),
	});
}
