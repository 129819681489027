import { Badges, FlexCol, Icon, LargeText, Tooltip } from "@components";
import { BalanceAccount } from "@models";
import { Currency } from "@util";
import React from "react";
import styled from "styled-components";
import { NO_ASSOCIATED_BM_MESSAGE } from "./util";
import { colors } from "@theme/theming";

type Props = {
	balanceAccount: BalanceAccount;
	hasAssociatedBudgetMaster: boolean;
	expanded?: boolean;
};

export default function BalanceAccountCardHeader(props: Props) {
	const { balanceAccount, hasAssociatedBudgetMaster } = props;
	return (
		<>
			<FlexCol
				css={`
					min-width: 2em;
					font-size: 2em;
				`}
			>
				<Icon.BalanceAccount />
			</FlexCol>
			<FlexCol
				css={`
					min-width: 4em;
				`}
			>
				<Amount title="Guthabenkonto Balance">
					{Currency.format(balanceAccount.balance, { hideZeroDecimals: true })}
				</Amount>
			</FlexCol>

			<FlexCol
				css={`
					min-width: 10em;
				`}
			>
				<LargeText>Guthabenkonto</LargeText>
			</FlexCol>
			<Spacer />
			<FlexCol>
				{balanceAccount.isActive ? <Badges.Active /> : <Badges.Paused />}
			</FlexCol>
			{!hasAssociatedBudgetMaster && !props.expanded && (
				<FlexCol
					css={`
						min-width: 10em;
					`}
				>
					<Tooltip
						id="no-budgetMaster-warning"
						tooltip={NO_ASSOCIATED_BM_MESSAGE}
					>
						<div style={{ color: colors.caution.c300 }}>
							<span>
								<Icon.Warning /> nicht verwendet
							</span>
						</div>
					</Tooltip>
				</FlexCol>
			)}
		</>
	);
}

const Spacer = styled.div`
	flex: 1 0 auto;
`;

const Amount = styled.div`
	font-size: 1.5rem;
`;
