import React, { useEffect, useState } from "react";
import { Tab } from "@components";
import TripBasicDataPage from "./components/TripBasicDataPage";
import { BusinessTrip } from "@models";
import TripMeals from "./components/TripMeals";
import TripAccomodation from "./components/TripAccomodation";
import TripExpenses from "./components/TripExpenses";
import TripReviewControls from "./components/TripReviewControls";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { EditableTrip } from "@models/travel/EditableTrip";
import TripContext from "./context/TripContext";

type Props = {
	trip: BusinessTrip;
	isEditable: boolean;
};

export type DisplayTripMode =
	| "basicData"
	| "expenses"
	| "accomodation"
	| "meals";

export default function TripReviewPage(props: Props) {
	const { trip, isEditable } = props;
	const [activeTab, setActiveTab] = useState("basicData");

	const form = useForm({
		defaultValues: new EditableTrip(trip),
	});

	useEffect(() => {
		// reset the form every time the trip input changes
		form.reset(new EditableTrip(trip));
	}, [trip]);

	return (
		<TripContext.Provider value={{ currentTrip: trip, isEditable }}>
			<FormProvider {...form}>
				<Tab.Container
					css={`
						margin-bottom: 1rem;
					`}
				>
					<Tab.Item
						active={activeTab === "basicData"}
						onClick={() => setActiveTab("basicData")}
					>
						Reisedaten
					</Tab.Item>
					<Tab.Item
						active={activeTab === "expenses"}
						onClick={() => setActiveTab("expenses")}
					>
						Auslagen
					</Tab.Item>
					<Tab.Item
						active={activeTab === "accomodation"}
						onClick={() => setActiveTab("accomodation")}
					>
						Unterkunft
					</Tab.Item>
					<Tab.Item
						active={activeTab === "meals"}
						onClick={() => setActiveTab("meals")}
					>
						Mahlzeiten
					</Tab.Item>
				</Tab.Container>

				<HidableTab isVisible={activeTab === "basicData"}>
					<TripBasicDataPage trip={trip} />
				</HidableTab>
				<HidableTab isVisible={activeTab === "accomodation"}>
					<TripAccomodation trip={trip} />
				</HidableTab>
				<HidableTab isVisible={activeTab === "meals"}>
					<TripMeals trip={trip} />
				</HidableTab>
				<HidableTab isVisible={activeTab === "expenses"}>
					<TripExpenses trip={trip} />
				</HidableTab>
				<hr />
				<TripReviewControls trip={trip} />
			</FormProvider>
		</TripContext.Provider>
	);
}

const HidableTab = styled.div`
	display: ${({ isVisible }: { isVisible: boolean }) =>
		isVisible ? "block" : "none"};
`;
