import { deserializeDate, serializeDate } from "@util";
import { Properties } from "@util/types";
import { RootProductId, rootProductIds } from "./Product";
import { ProductMobility } from "./products/ProductMobility";
import { ProductTravel } from "./products/ProductTravel";
import * as yup from "yup";

export default class BudgetMaster {
	constructor(props: Properties<BudgetMaster>) {
		this.budgetMasterId = props.budgetMasterId;
		this.userId = props.userId;
		this.balanceAccountId = props.balanceAccountId;
		this.productVariantId = props.productVariantId;
		this.category = props.category;
		this.availableFrom = props.availableFrom;
		this.availableUntil = props.availableUntil;
		this.settings = props.settings;
		this.useBalanceAccount = props.useBalanceAccount;
		this.amounts = props.amounts;
		this.categories = props.categories;
		this.useExcessBudget = props.useExcessBudget;
	}

	readonly budgetMasterId: string;
	readonly userId: string;
	readonly balanceAccountId: string | null;
	readonly productVariantId: string;
	readonly category: RootProductId;
	readonly availableFrom: Date;
	readonly availableUntil: Date | null;
	readonly settings: Record<string, unknown> | null;
	readonly useBalanceAccount: boolean;
	readonly amounts: Readonly<BudgetMasterAmounts>[];
	readonly categories: string[];
	readonly useExcessBudget: boolean;

	public static convertForJson(
		budgetMaster: NewBudgetMaster | UpdatedBudgetMaster | BudgetMaster
	) {
		const converted = {
			...budgetMaster,
			availableFrom: serializeDate.date(budgetMaster.availableFrom),
			availableUntil: serializeDate.nullable.date(budgetMaster.availableUntil),
		};
		return converted;
	}

	static fromJson(data: any) {
		const settings = deserializeSettings(data.category, data.settings);
		const validated = budgetMasterSchema.validateSync(data);

		return new BudgetMaster({
			...validated,
			availableFrom: deserializeDate.date(validated.availableFrom),
			availableUntil: deserializeDate.nullable.date(validated.availableUntil),
			category: validated.category as RootProductId,
			settings,
		});
	}
}

function deserializeSettings(
	category: RootProductId,
	settings: Record<string, unknown> | null
) {
	switch (category) {
		case "mobility":
			return ProductMobility.deserializeSettings(settings);
		case "travel":
		case "travel.budget":
			return ProductTravel.deserializeSettings(settings);
		case "congrats":
			return congratsSettingsSchema.validateSync(settings);
		default:
			return null;
	}
}

export type NewBudgetMaster = Omit<Properties<BudgetMaster>, "budgetMasterId">;
export type UpdatedBudgetMaster = Properties<BudgetMaster>;

export type BudgetMasterAmounts = {
	category: string;
	maxAmountMonth: number;
	maxAmountDay: number | null;
};

export type CongratsSettings = {
	occasion: string;
	customOccasion: string | null;
	validTimeInDays: number;
	greetingText: string;
	greetingImageFileKey: string | null;
	greetingVideoFileKey: string | null;
	restrictPostalCodes: string[] | null;
	restrictStreet: string | null;
	restrictSupplier: string | null;
};

const congratsSettingsSchema = yup.object({
	occasion: yup.string().defined(),
	customOccasion: yup.string().defined().nullable(),
	validTimeInDays: yup.number().defined(),
	greetingText: yup.string().defined(),
	greetingImageFileKey: yup.string().defined().nullable(),
	greetingVideoFileKey: yup.string().defined().nullable(),
	restrictPostalCodes: yup.array(yup.string().defined()).defined().nullable(),
	restrictStreet: yup.string().defined().nullable(),
	restrictSupplier: yup.string().defined().nullable(),
});

const budgetMasterSchema = yup
	.object({
		budgetMasterId: yup.string().defined(),
		userId: yup.string().defined(),
		balanceAccountId: yup.string().nullable().defined(),
		productVariantId: yup.string().defined(),
		category: yup
			.string()
			.oneOf(rootProductIds as unknown as string[])
			.defined(),
		availableFrom: yup.string().defined(),
		availableUntil: yup.string().defined().nullable(),
		settings: yup.object().nullable(),
		useBalanceAccount: yup.boolean().defined(),
		useExcessBudget: yup.boolean().defined(),
		amounts: yup
			.array(
				yup
					.object({
						category: yup.string().defined(),
						maxAmountMonth: yup.number().defined(),
						maxAmountDay: yup.number().nullable().defined(),
					})
					.defined()
			)
			.defined(),
		categories: yup.array(yup.string().defined()).defined(),
		//congratsSettings: congratsSettingsSchema.optional(),
	})
	.defined();
