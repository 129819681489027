import { Properties } from "@util/types";

export class ReportType {
	constructor(props: Properties<ReportType>) {
		this.id = props.id;
		this.name = props.name;
		this.isPayrollReport = props.isPayrollReport;
		this.isPublic = props.isPublic;
	}

	readonly id: string;
	readonly name: string;
	readonly isPayrollReport: boolean;
	readonly isPublic: boolean;

	public static fromJson(data: any): ReportType {
		return new ReportType({ ...data });
	}
}
