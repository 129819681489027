import React from "react";
import styled from "styled-components";
import { FaUser as UserIcon } from "react-icons/fa";
import { Customer, Division, User } from "@models";
import { FlexRow, FlexCol } from "@components";
import UserSalutationDropdown from "../../../pages/division/components/UserRow/UserSalutationDropdown";

type Props = {
	user: User;
	customer: Customer;
	division: Division;
};

export default function UserDetailsHeader({ user, customer, division }: Props) {
	return (
		<>
			<StyledFlexCol>
				<StyledUserIcon />
			</StyledFlexCol>
			<StyledFlexCol>
				<StyledFlexRow>
					Benutzer {user.firstname} {user.lastname}
				</StyledFlexRow>
				<FlexRow>Kunde {customer.name}</FlexRow>
				<FlexRow>Standort {division.name}</FlexRow>
			</StyledFlexCol>
			<Spacer />
			{!user.salutated && (
				<StyledFlexCol>
					<UserSalutationDropdown user={user} />
				</StyledFlexCol>
			)}
		</>
	);
}

const StyledUserIcon = styled(UserIcon)`
	height: 80px;
	width: 80px;
`;

const StyledFlexRow = styled(FlexRow)`
	font-size: 25px;
`;

const StyledFlexCol = styled(FlexCol)`
	margin: 0px 8px;
`;

const Spacer = styled.div`
	flex: 1 0 auto;
`;
