import React from "react";
import styled from "styled-components";
import Label from "./forms/Label";
import Select from "./forms/Select";
import { colors } from "@theme/theming";

interface Props {
	allowShowAll: boolean;
	limit: number | null;
	setPaginationLimit: (limit: number | null) => void;
}

export const PaginationLimit = (props: Props) => {
	const { setPaginationLimit, limit } = props;
	const options = [15, 30, 100];

	return (
		<SelectContainer>
			<Label
				css={`
					margin: 0;
				`}
				htmlFor="limit"
			>
				Zeilen pro Seite:
			</Label>
			<SelectRowPerPage
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
					setPaginationLimit(
						e.target.value !== "all"
							? Number.parseInt(e.target.value, 10)
							: null
					)
				}
				data-testid="pagination-limit"
				id="limit"
				name="limit"
				defaultValue={limit ?? "all"}
			>
				{options.map((value: number) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
				{props.allowShowAll && (
					<option key="all" value="all">
						Alle
					</option>
				)}
			</SelectRowPerPage>
		</SelectContainer>
	);
};

const SelectRowPerPage = styled(Select)`
	margin: 0 15px 0 5px;
	color: ${colors.primary.p200};
`;
const SelectContainer = styled.div`
	display: flex;
	line-height: 1.25;
	align-items: center;
	white-space: nowrap;
`;
