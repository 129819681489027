import { BalanceAccount, BudgetMaster } from "@models";
import { isAfter, isBefore } from "date-fns";

export function checkAssociatedBudgetMaster(
	balanceAccount: BalanceAccount,
	budgetMasters: BudgetMaster[]
) {
	const associatedBudgetMasters = budgetMasters.filter(
		(bm) => bm.balanceAccountId === balanceAccount.balanceAccountId
	);

	if (associatedBudgetMasters.length === 0) return false;
	const now = new Date(Date.now());

	return associatedBudgetMasters.some((bm) => {
		if (isAfter(bm.availableFrom, now)) return false;

		if (bm.availableUntil && isBefore(bm.availableUntil, now)) return false;

		return true;
	});
}

export const NO_ASSOCIATED_BM_MESSAGE =
	"Das Guthabenkonto wird zurzeit nicht verwendet, weil es in den Budgeteinstellungen nicht aktiviert ist.";
