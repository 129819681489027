import { Properties } from "@util/types";

export default class LegalForm {
	constructor(props: Properties<LegalForm>) {
		this.legalFormId = props.legalFormId;
		this.description = props.description;
	}
	readonly legalFormId: number;
	readonly description: string;

	public static fromJson(data: any): LegalForm {
		return new LegalForm({
			legalFormId: data.legalFormId,
			description: data.description,
		});
	}
}
