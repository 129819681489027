import React from "react";

import { Division } from "@models";
import PayrollSettings from "./PayrollAccounts/PayrollSettings";
import FinancialAccountSettings from "./FinancialAccounts/FinancialAccountSettings";

type Props = {
	division: Division;
	onClickCancel: () => void;
	refreshPage: () => void;
	showReportSettingsModal: string;
};

export default function ReportSettingsForm(props: Props) {
	const { showReportSettingsModal, ...formProps } = props;

	if (showReportSettingsModal === "payrollAccountingSettings") {
		return <PayrollSettings {...formProps} />;
	} else {
		return <FinancialAccountSettings {...formProps} />;
	}
}
