import React from "react";
import styled from "styled-components";
import { ReportSettingsType } from "./ReportList";
import ReportMonthDropdown from "./ReportMonthDropdown";
import ReportSettingsOptionsDropdown from "./ReportSettingsOptionsDropdown";

type Props = {
	handleClickGenerateReport: (month: string) => void;
	handleClickReportSettings: (reportSettingsType: ReportSettingsType) => void;
};
export default function ReportControls(props: Props) {
	const { handleClickGenerateReport, handleClickReportSettings } = props;

	return (
		<InputContainer>
			<ReportSettingsOptionsDropdown
				onClickReportSettings={handleClickReportSettings}
			/>
			<ReportMonthDropdown
				onClickGenerateReport={(month) => handleClickGenerateReport(month)}
			/>
		</InputContainer>
	);
}

const InputContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 2px;
	margin-bottom: 10px;
`;
