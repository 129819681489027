import React, { useState } from "react";
import { ButtonWithIcon, Info } from "../ImportCSVPage";
import { CommonModal, Icon, IconWrapper } from "@components";
import { CsvImportType } from "@service/validation/validation.service";
import { Link } from "react-router-dom";
import { LineSpacer } from "./StyledComponents";

interface CsvHelpModalProps {
	type: CsvImportType;
}

export function retrieveDocsPathForCsvImport(type: CsvImportType): string {
	switch (type) {
		case "employeeImport":
			return "https://docs.lofino.de/csv-imports/user/";
		case "budgetImport":
			return "https://docs.lofino.de/csv-imports/budget/";
		case "contractImport":
			return "https://docs.lofino.de/csv-imports/contract/";
		case "sachbezugImport":
			return "";
	}
}

export const CsvHelpModal = ({ type }: CsvHelpModalProps) => {
	const [showHelpModal, setShowHelpModal] = useState(false);

	return (
		<>
			<IconWrapper
				onClick={() => setShowHelpModal(true)}
				css={{ "margin-left": "6px" }}
			>
				<Icon.Help />
			</IconWrapper>

			{showHelpModal && (
				<CommonModal handleClose={() => setShowHelpModal(false)}>
					<h5 css={"text-align: center"}>Informationen zum Import</h5>
					<Info>
						Um Daten zu importieren, muss eine CSV Datei genutzt werden. Es ist
						bekannt, dass das Öffnen von CSV Dateien in Excel zu Problemen
						führt. Es ist empfohlen, wenn die Datei geöffnet werden muss, dies
						in Notepad++ zu machen.
					</Info>
					<Info>Spezifische Informationen zum CSV Import</Info>
					<ul css={"margin-top: 6px"}>
						<li>Ein Semikolon &#040;;&#041; dient als Feldtrenner </li>

						<li>Die Kodiering ist UTF-8</li>
						<li>In der ersten Reihe müssen die Spaltennamen vorhanden sein</li>
						<li>Die benötigten und optionalen Werte sind angegeben</li>
						<li>
							Daten können in dem folgenden Format angegeben sein:
							<ul>
								<li>2020-01-03</li>
								<li>03.01.2020</li>
								<li>03.01.20</li>
							</ul>
						</li>
						<li>
							Boolesche Werte &#040;Ja/Nein&#041; können folgendermaßen
							angegeben werden:
							<ul>
								<li>true/false</li>
								<li>1/0</li>
								<li>Groß- und Kleinschreibung muss nicht beachtet werden</li>
							</ul>
						</li>
						<li>
							Werte, welche freibleiben sollen, müssen leer bleiben &#040;keinen
							Inhalt&#041;
						</li>
						<li>
							Währungswerte müssen entweder in vollen EUR Werten gegeben sein
							oder mit einem Komma oder Punkt getrennt werden für Cent Beträge
							<ul>
								<li>100 = 100 EUR</li>
								<li>100,10 = 100 EUR und 10 Cent</li>
								<li>100.10 = 100 EUR und 10 Cent</li>
							</ul>
						</li>
						<li>Werte in Anführungszeichen &#040;"&#041; werden akzeptiert</li>
					</ul>

					<LineSpacer />
					<Link
						to={{
							pathname: retrieveDocsPathForCsvImport(type),
						}}
						target="_blank"
					>
						<ButtonWithIcon>Link zu Lofino Docs</ButtonWithIcon>
					</Link>
				</CommonModal>
			)}
		</>
	);
};
