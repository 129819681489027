/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Currency } from "@util";
import {
	CurrencyInput,
	Icon,
	InputValidation,
	Button,
	Select,
	Tooltip,
} from "@components";
import { VatAmount } from "@models/Voucher";
import { ReadonlyText } from "./ReadonlyText";
import { checkValidVatAmounts } from "../utils";
import useTripReviewContext from "../../../../pages/trips/hooks/useTripReviewContext";
import ReviewContext from "../context/ReviewContext";
import { EditableTrip } from "@models/travel/EditableTrip";
import { EditableVoucher } from "@models/EditableVoucher";

export default function VatAmounts(props: { voucherId: string }) {
	const {
		register,
		control,
		watch,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		formState: { errors }, // this line is left as a render dependency on errors,
		getFieldState, // otherwise the error got via getFieldState is not rendered immediately
		setError,
		setValue,
		clearErrors,
	} = useFormContext<EditableVoucher | EditableTrip>();
	const vatPercentages = ["0", "5", "7", "16", "19"];

	const { isTripEditable, hasReviewContext: hasTripReviewContext } =
		useTripReviewContext();
	const hasVoucherReviewContext = !!useContext(ReviewContext);

	const hasReviewContext = hasTripReviewContext
		? isTripEditable
		: hasVoucherReviewContext;

	const name: "vatAmounts" | `vouchers.${string}.vatAmounts` =
		hasTripReviewContext
			? `vouchers.${props.voucherId}.vatAmounts`
			: "vatAmounts";

	const { fields, append, remove } = useFieldArray({ control, name });

	const allVatPercentagesUsed = () => {
		const vatAmounts: VatAmount[] = watch(name)!;
		return vatAmounts.length === vatPercentages.length;
	};

	const onClickAddVatAmount = () => {
		const vatAmounts: VatAmount[] = watch(name)!;
		const usedVatPercentages = vatAmounts.map((a) => a.vatPercentage);
		const unusedVatPercentages = vatPercentages.filter(
			(p) => !usedVatPercentages.includes(p)
		);

		const newVatAmount: VatAmount = {
			vatPercentage: unusedVatPercentages.includes("7")
				? "7"
				: unusedVatPercentages[0],
			vatAmount: 0,
		};

		append(newVatAmount);

		// wait before validating otherwise watch doesn't have the new data
		_.defer(() => {
			const vatAmounts = watch(name)!;

			validateVatAmounts(vatAmounts.length - 1);
		});
	};

	const validateVatAmounts = (index: number) => {
		const vatAmounts: VatAmount[] = watch(name)!;
		const hasValidVatAmounts = checkValidVatAmounts(vatAmounts);
		if (!hasValidVatAmounts) {
			setError(`${name}.${index}.vatAmount`, {
				type: "validation",
				message: "Der Umsatzsteuerbetrag kann nicht Null sein",
			});
		}

		clearErrorVatAmounts({
			vatAmounts: vatAmounts,
		});
	};

	const clearErrorVatAmounts = (args: { vatAmounts: VatAmount[] }) => {
		args.vatAmounts.forEach((v, index) => {
			if (v.vatAmount !== 0) {
				clearErrors(`${name}.${index}.vatAmount`);
			}
		});
	};

	const filteredVatPercentages = (currentIndex: number) => {
		const vatAmounts: VatAmount[] = watch(name)!;
		const usedVatPercentages = vatAmounts.map((a) => a.vatPercentage);
		const currentVatPercentage = vatAmounts[currentIndex].vatPercentage;
		return vatPercentages.filter(
			(vatPercentage) =>
				!usedVatPercentages.includes(vatPercentage) ||
				vatPercentage === currentVatPercentage
		);
	};

	return (
		<Container hasReviewContext={hasReviewContext}>
			{!!fields &&
				fields.map((data, index) => (
					<React.Fragment key={data.id}>
						<InputValidation
							error={getFieldState(`${name}.${index}.vatAmount`).error}
						>
							<Controller
								render={({ field }) =>
									hasReviewContext ? (
										<CurrencyInput
											{...field}
											id={`${name}.${index}.vatAmount`}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setValue(
													`${name}.${index}.vatAmount`,
													Number.parseInt(e.target.value)
												);
												validateVatAmounts(index);
											}}
										/>
									) : (
										<ReadonlyText value={Currency.format(field.value)} />
									)
								}
								control={control}
								name={`${name}.${index}.vatAmount`}
								rules={{
									required: true,
								}}
								defaultValue={data.vatAmount ?? 0}
							/>
						</InputValidation>

						{hasReviewContext ? (
							<Select
								id={`${name}.${index}.vatPercentage`}
								{...register(`${name}.${index}.vatPercentage`, {
									required: true,
								})}
								defaultValue={data.vatPercentage}
								data-testid="vatPercentageDropdown"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValue(`${name}.${index}.vatPercentage`, e.target.value);
								}}
							>
								{filteredVatPercentages(index).map((vatPercentage) => (
									<option value={vatPercentage} key={vatPercentage}>
										{`${vatPercentage}%`}
									</option>
								))}
							</Select>
						) : (
							<ReadonlyText value={`${data.vatPercentage}%`} />
						)}

						{hasReviewContext && (
							<>
								{index === 0 && (
									<Tooltip
										id="addVatAmount"
										tooltip="Umsatzsteuerbetrag hinzufügen"
									>
										<StyledButton
											onClick={onClickAddVatAmount}
											disabled={allVatPercentagesUsed()}
										>
											<Icon.Add />
										</StyledButton>
									</Tooltip>
								)}
								{index > 0 && (
									<Tooltip
										id="removeVatAmount"
										tooltip="Umsatzsteuerbetrag entfernen"
									>
										<StyledButton onClick={() => remove(index)}>
											<Icon.Remove />
										</StyledButton>
									</Tooltip>
								)}
							</>
						)}
					</React.Fragment>
				))}
		</Container>
	);
}

type ContainerProps = {
	hasReviewContext: boolean;
};

const Container = styled.div((props: ContainerProps) => ({
	display: "grid",
	columnGap: "8px",
	rowGap: "2px",
	gridTemplateColumns: props.hasReviewContext ? "1fr 1fr 1fr" : "1fr 1fr",
}));

const StyledButton = styled(Button)`
	max-height: 36px;

	&:disabled {
		background-color: gray;
	}
`;
