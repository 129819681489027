import { useCallback } from "react";
import { PaginatedQuery } from "@service/types";
import useFetchedData, {
	FetchQueryOptions,
	QueryState,
} from "./useFetchedData";
import usePagination, { PaginationState } from "./usePagination";

export type PaginatedFetchedQueryResult<T> = QueryState<T[]> & {
	pagination: PaginationState;
};

/**
 * Performs a backend query using pagination.
 *
 * @returns the fetched paginated data as well as the pagination state
 */
export default function usePaginatedFetchedData<T>(
	queryFn: PaginatedQuery<T>,
	options?: FetchQueryOptions
): [PaginatedFetchedQueryResult<T>, () => Promise<void>] {
	const [pagination, registerPaginationResult] = usePagination();
	const cb = useCallback(async () => {
		const queryResult = await queryFn({
			page: pagination.limit ? pagination.currentPage : undefined,
			limit: pagination.limit ?? undefined,
			sort: pagination.sort ?? undefined,
			order: pagination.order ?? undefined,
			filter: pagination.filter ?? undefined,
		});
		registerPaginationResult(queryResult);
		return queryResult.data;
	}, [
		queryFn,
		pagination.limit,
		pagination.currentPage,
		pagination.sort,
		pagination.order,
		JSON.stringify(pagination.filter),
		registerPaginationResult,
	]);

	const [queryState, triggerFn] = useFetchedData(cb, options);

	return [
		{
			...queryState,
			pagination,
		},
		triggerFn,
	];
}
