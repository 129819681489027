import { Icon, Tooltip } from "@components";
import { colors } from "@theme/theming";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ScrollUpButton() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window?.pageYOffset > 200) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window?.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		window?.addEventListener("scroll", toggleVisibility);
		return () => {
			window?.removeEventListener("scroll", toggleVisibility);
		};
	}, []);

	return (
		<>
			{isVisible && (
				<Tooltip id="tooltip-scroll-up" tooltip="Nach oben">
					<ScrollUp onClick={scrollToTop}>
						<Icon.ScrollUp />
					</ScrollUp>
				</Tooltip>
			)}
		</>
	);
}

export const ScrollUp = styled.button`
	background: ${colors.primary.p200};
	border: none;
	border-radius: 50%;
	color: ${colors.white};
	cursor: pointer;
	font-size: 16px;
	line-height: 48px;
	width: 70px;
	height: 70px;
	box-shadow: 0 3px ${colors.gray.g400};
	position: fixed;
	bottom: 50px;
	right: 50px;
	transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;

	z-index: 100;

	:hover {
		cursor: pointer;
		background-color: ${colors.primary.p400};
	}

	:active {
		background-color: ${colors.primary.p300};
		box-shadow: 0 5px ${colors.gray.g400};
		transform: translateY(4px);
	}

	outline: 0 !important;
`;
