import { BudgetMaster, Division, Customer } from "@models";
import { deserializeDate, serializeDate } from "@util";
import { Mutable, Properties } from "@util/types";
import { BalanceAccountWithTransactions } from "./BalanceAccount";

export default class User {
	constructor(props: Properties<User>) {
		this.userId = props.userId;
		this.externalId = props.externalId;
		this.customerId = props.customerId;
		this.divisionId = props.divisionId;
		this.salutated = props.salutated;
		this.title = props.title;
		this.firstname = props.firstname;
		this.lastname = props.lastname;
		this.email = props.email;
		this.phone = props.phone;
		this.created = props.created;
		this.updated = props.updated;
		this.personalNo = props.personalNo;
		this.contractStart = props.contractStart;
		this.contractEnd = props.contractEnd;
		this.isActive = props.isActive;
		this.ownVouchersEnabled = props.ownVouchersEnabled;
		this.isTestUser = props.isTestUser;
		this.dateOfBirth = props.dateOfBirth;
		this.costCenter = props.costCenter;
		this.commentInternal = props.commentInternal;
		this.comment = props.comment;
		this.paymentReduction = props.paymentReduction;
		this.workingDaysPerWeek = props.workingDaysPerWeek;
		this.firebaseToken = props.firebaseToken;
		this.notificationSettings = props.notificationSettings;
		this.lastAppVersion = props.lastAppVersion;
		this.firstLogin = props.firstLogin;
		this.lastLoginWeb = props.lastLoginWeb;
		this.lastLoginMobile = props.lastLoginMobile;
		this.superiorUserId = props.superiorUserId;
		this.isSSOUser = props.isSSOUser;
		this.isKeycloakUser = props.isKeycloakUser;
	}

	readonly userId: string;
	readonly externalId: string;
	readonly customerId: string;
	readonly divisionId: string;
	readonly salutated: boolean;
	readonly title: string;
	readonly firstname: string;
	readonly lastname: string;
	readonly email: string;
	readonly phone: string;
	readonly created: Date | null;
	readonly updated: Date | null;
	readonly personalNo: string;
	readonly contractStart: Date;
	readonly contractEnd: Date | null;
	readonly isActive: boolean;
	readonly ownVouchersEnabled: boolean;
	readonly isTestUser: boolean;
	readonly dateOfBirth: Date | null;
	readonly costCenter: string;
	readonly commentInternal: string;
	readonly comment: string | null;
	readonly paymentReduction: number;
	readonly workingDaysPerWeek: number | null;
	readonly firebaseToken: string | null;
	readonly notificationSettings: Readonly<NotificationSettings> | null;
	readonly lastAppVersion: string | null;
	readonly firstLogin: Date | null;
	readonly lastLoginWeb: Date | null;
	readonly lastLoginMobile: Date | null;
	readonly superiorUserId: string | null;
	readonly isSSOUser: boolean;
	readonly isKeycloakUser: boolean;

	public static convertForJson(data: NewUser | UpdatedUser) {
		const converted = {
			...data,
			externalId: data.externalId || undefined,
			contractStart: serializeDate.date(data.contractStart),
			contractEnd: serializeDate.nullable.date(data.contractEnd),
			dateOfBirth: serializeDate.nullable.date(data.dateOfBirth),
		};

		return converted;
	}

	public static fromJson(data: any): User {
		return new User({
			...data,
			created: deserializeDate.nullable.dateTime(data.created),
			updated: deserializeDate.nullable.dateTime(data.updated),
			contractStart: deserializeDate.date(data.contractStart),
			contractEnd: deserializeDate.nullable.date(data.contractEnd),
			dateOfBirth: deserializeDate.nullable.date(data.dateOfBirth),
			firstLogin: deserializeDate.nullable.date(data.firstLogin),
			lastLoginWeb: deserializeDate.nullable.dateTime(data.lastLoginWeb),
			lastLoginMobile: deserializeDate.nullable.dateTime(data.lastLoginMobile),
		});
	}
}

export type UserWithBudgetMasters = Properties<User> & {
	budgetMasters: BudgetMaster[];
};

export type UserWithBalanceAccounts = User & {
	balanceAccounts: BalanceAccountWithTransactions[];
};
export type UserWithCustomerAndDivision = User & {
	customer: Customer;
	division: Division;
};

interface NotificationSettings {
	[notificationTypeId: string]: boolean;
}

export type NewUser = Mutable<
	Pick<
		User,
		| "externalId"
		| "customerId"
		| "divisionId"
		| "title"
		| "firstname"
		| "lastname"
		| "email"
		| "phone"
		| "personalNo"
		| "contractStart"
		| "contractEnd"
		| "ownVouchersEnabled"
		| "isTestUser"
		| "dateOfBirth"
		| "costCenter"
		| "comment"
		| "commentInternal"
		| "paymentReduction"
		| "workingDaysPerWeek"
		| "superiorUserId"
	>
>;

export type UpdatedUser = NewUser;

export enum UserStatus {
	Unconfirmed = "UNCONFIRMED",
	Confirmed = "CONFIRMED",
	Archived = "ARCHIVED",
	Compromised = "COMPROMISED",
	Unknown = "UNKNOWN",
	ResetRequired = "RESET_REQUIRED",
	ForceChangePassword = "FORCE_CHANGE_PASSWORD",
}
