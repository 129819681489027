import React, { useEffect, useState } from "react";
import { useServices } from "@hooks";
import User from "@models/User";
import DropdownSelect from "react-select";

type Props = {
	id: string;
	currentUser: User | null;
	divisionId: string;
	defaultUserSuperiorId: string | null;
	onChange: (user: string | null) => void;
};

export default function UsersByDivisionSearchBar(props: Props) {
	const { currentUser, divisionId, defaultUserSuperiorId, onChange } = props;
	const { searchService, userService } = useServices();

	const [userSuperior, setUserSuperior] = useState<User | null>(null);
	const [userInput, setUserInput] = useState<string | null>(null);
	const [userList, setUserList] = useState<User[]>([]);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		if (defaultUserSuperiorId) {
			const getUserSuperior = async () => {
				const userSuperiorById = await userService.getUserById(
					defaultUserSuperiorId
				);

				setUserSuperior(userSuperiorById);
				setUserList([userSuperiorById]);
			};
			void getUserSuperior();
		}
	}, []);

	useEffect(() => {
		const getData = async () => {
			if (userInput) {
				const users = await searchService.getUserFromDivision(divisionId, {
					query: userInput,
				});
				const filteredUsers = users.filter(
					(u) => u.userId !== currentUser?.userId
				);
				setUserList(filteredUsers ?? []);
			} else {
				// When losing focus of input set userList, so when the input is reopened it shows the selected option
				setUserList(userSuperior ? [userSuperior] : []);
			}
		};

		void getData();
	}, [userInput]);

	return (
		<DropdownSelect
			inputId={props.id}
			isClearable
			isSearchable
			menuPlacement={"top"}
			placeholder="Suchen"
			value={userSuperior}
			onChange={(user) => {
				onChange(user ? user.userId : null);
				setUserSuperior(user);
			}}
			onInputChange={(input) => {
				setUserInput(input);
			}}
			onMenuOpen={() => setIsMenuOpen(true)}
			onMenuClose={() => setIsMenuOpen(false)}
			options={userList}
			noOptionsMessage={() => "Benutzer nicht gefunden"}
			getOptionValue={(u) => `${u.personalNo}`} // Shows the selected input
			getOptionLabel={(u) =>
				isMenuOpen
					? `${u.firstname} ${u.lastname} (${u.personalNo}) - ${u.email}${
							u.isActive ? "" : " - inaktiv"
					  }`
					: `${u.firstname} ${u.lastname} (${u.personalNo})${
							u.isActive ? "" : " - inaktiv"
					  }`
			}
			styles={{
				singleValue: (styles, { data: user }) =>
					!user.isActive ? { ...styles, color: "red" } : styles,
				option: (styles, { data: user }) =>
					!user.isActive ? { ...styles, color: "red" } : styles,
			}}
		/>
	);
}
