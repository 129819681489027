import { startOfDay } from "date-fns";
import User, { NewUser, UpdatedUser } from "../../../models/User";

export function createNewUser(): NewUser {
	return {
		externalId: "",
		customerId: "",
		divisionId: "",
		title: "",
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		personalNo: "",
		contractStart: startOfDay(new Date()),
		contractEnd: null,
		ownVouchersEnabled: true,
		isTestUser: false,
		dateOfBirth: null,
		costCenter: "",
		commentInternal: "",
		comment: "",
		paymentReduction: 0,
		workingDaysPerWeek: 0,
		superiorUserId: null,
	};
}

export function createUpdatedUser(user: User): UpdatedUser {
	return {
		externalId: user.externalId,
		customerId: user.customerId,
		divisionId: user.divisionId,
		title: user.title,
		firstname: user.firstname,
		lastname: user.lastname,
		email: user.email,
		phone: user.phone,
		personalNo: user.personalNo,
		contractStart: user.contractStart,
		contractEnd: user.contractEnd,
		ownVouchersEnabled: user.ownVouchersEnabled,
		isTestUser: user.isTestUser,
		dateOfBirth: user.dateOfBirth,
		costCenter: user.costCenter,
		commentInternal: user.commentInternal,
		comment: user.comment,
		paymentReduction: user.paymentReduction,
		workingDaysPerWeek: user.workingDaysPerWeek,
		superiorUserId: user.superiorUserId,
	};
}
