import { useContext } from "react";
import TripReviewContext from "../context/ReviewContext";
import TripContext from "../context/TripContext";

export default function useTripReviewContext() {
	const tripContext = useContext(TripContext);
	const reviewContext = useContext(TripReviewContext);

	const hasReviewContext = !!reviewContext;

	const isTripEditable =
		hasReviewContext &&
		!tripContext?.currentTrip.isReadOnly() &&
		(!tripContext || tripContext?.isEditable);

	return { reviewContext, hasReviewContext, isTripEditable };
}
