import { BudgetMaster } from "@models";
import React from "react";

type ProductsPageContextValue = {
	budgetMasters: BudgetMaster[];
	triggerRefresh: () => void;
};

export default React.createContext<ProductsPageContextValue>({
	budgetMasters: [],
	triggerRefresh: () => {},
});
