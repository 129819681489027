import {
	Checkbox,
	CurrencyInput,
	FormGroup,
	Input,
	Label,
	RequiredMarker,
} from "@components";
import { Division } from "@models";
import PricingRecord from "@models/PricingRecord";
import React, { BaseSyntheticEvent } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { PricingCardProps } from "./IPricingCardProps";
import { useCustomField } from "./useCustomField";

export default function FlatFeeSettings(props: PricingCardProps) {
	const { control } = useFormContext<Division>();
	const { index, pricingSettings } = props;

	const pricingRecord: PricingRecord = pricingSettings[index];

	const flatFee = pricingRecord?.flatFee;

	const { setFieldChecked, isChecked } = useCustomField<
		PricingRecord["flatFee"]
	>(`pricingSettings.${index}.flatFee`, {
		amount: 100,
		description: "",
	});

	const checkboxClicked = (e: BaseSyntheticEvent) =>
		setFieldChecked(!!e.target?.checked);

	return (
		<>
			<Controller
				name={`pricingSettings.${index}.flatFee`}
				control={control}
				defaultValue={flatFee}
				render={({ field }) => (
					<Checkbox
						value={!!field.value}
						label={"Festpreis"}
						onChange={checkboxClicked}
					/>
				)}
			/>
			<br />
			{isChecked && (
				<div>
					<FormGroup as={Row}>
						<Label style={{ marginLeft: 24 }} column sm="3">
							Betrag (€)
						</Label>
						<Col sm="5">
							<Controller
								control={control}
								name={`pricingSettings.${index}.flatFee.amount`}
								defaultValue={flatFee?.amount || 0}
								render={({ field }) => <CurrencyInput {...field} min={0.0} />}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label style={{ marginLeft: 24 }} column sm="3">
							Beschreibung <RequiredMarker />
						</Label>
						<Col sm="5">
							<Controller
								control={control}
								name={`pricingSettings.${index}.flatFee.description`}
								defaultValue={flatFee?.description || ""}
								rules={{ required: true }}
								render={({ field }) => (
									<Input placeholder="Bitte Beschreibung eingeben" {...field} />
								)}
							/>
						</Col>
					</FormGroup>
					<br />
				</div>
			)}
		</>
	);
}
