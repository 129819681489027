import { Product, LegalForm } from "@models";
import { deserializeProduct } from "@models/products/deserialize";
import { ReportType } from "../../models/ReportType";
import * as apiService from "../api.service";

export async function getLegalForms(): Promise<LegalForm[]> {
	const json = await apiService.GET("legalForm");
	return (json.data as any[]).map(LegalForm.fromJson);
}

export async function getProducts(): Promise<Product[]> {
	const json = await apiService.GET("products");
	return (json.data as any[]).map(deserializeProduct);
}

export async function getEnvironmentIdentifier(): Promise<string> {
	return (
		await apiService.GET("ops/environment", {
			auth: { isPublicRoute: true } as any,
		})
	).data;
}

export async function getAllReportTypes(): Promise<ReportType[]> {
	const response = await apiService.GET("reporttype");
	return (response.data as any[]).map(ReportType.fromJson);
}
