import React, { useState } from "react";
import styled from "styled-components";

import { useRouteMatch } from "react-router-dom";

import { Division, Report } from "@models";
import { DivisionRouteParams } from "@service/navigation/routes";
import {
	useServices,
	useAsyncCommand,
	useSortQueryParams,
	PaginationState,
} from "@hooks";
import { CommonModal, QueryStateIndicators } from "@components";

import { getYearMonth } from "../util";
import ReportSettingsForm from "./ReportSettingsForm";
import ReportControls from "./ReportControls";
import ToastInfo from "@components/ToastInfo";
import ReportsTable from "./ReportsTable";

type Props = {
	reports: Report[];
	pagination: PaginationState;
	refreshPage: () => void;
	division: Division;
};

export type ReportSettingsType =
	| "financialAccountSettings"
	| "payrollAccountingSettings";

export default function ReportList(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;
	const [showReportSettingsModal, setShowReportSettingsModal] =
		useState<ReportSettingsType | null>(null);

	const [showToast, setShowToast] = useState(false);

	const sortQueryParams = useSortQueryParams();

	const { reportService } = useServices();

	const [generateReports, generateReportsQuery] = useAsyncCommand(
		(yearMonth: string) => reportService.generateReports(divisionId, yearMonth)
	);

	const handleClickGenerateReport = async (month: string) => {
		const yearMonth = getYearMonth(month);
		setShowToast(true);
		const result = await generateReports(yearMonth);
		if (result.state === "success") {
			props.refreshPage();
		}
	};

	const handleClickReportSettings = (formName: ReportSettingsType) => {
		setShowReportSettingsModal(formName);
	};

	return (
		<Container>
			<ToastInfo
				isVisible={showToast}
				onClose={() => {
					if (generateReportsQuery.state === "success") {
						props.refreshPage();
						setShowToast(false);
					}
				}}
				message="Wir erstellen Reports. Dies kann einige Zeit dauern."
			/>
			<ReportControls
				handleClickGenerateReport={handleClickGenerateReport}
				handleClickReportSettings={handleClickReportSettings}
			/>
			<ReportsTable reports={props.reports} sort={sortQueryParams} />
			{showReportSettingsModal && (
				<CommonModal handleClose={() => setShowReportSettingsModal(null)}>
					<ReportSettingsForm
						division={props.division}
						onClickCancel={() => setShowReportSettingsModal(null)}
						refreshPage={props.refreshPage}
						showReportSettingsModal={showReportSettingsModal}
					/>
				</CommonModal>
			)}

			<QueryStateIndicators queryState={generateReportsQuery} />
		</Container>
	);
}

const Container = styled.div`
	padding: 8px;
`;
