import {
	BudgetMasterCollisionError,
	SachbezugErrors,
} from "@service/product/errors";
import { DeserializableFromResponse, ErrorBase } from "@util/errors";
import {
	MonthClosedForChangeError,
	ThirdPartyCategoryNotAllowedError,
	ThirdPartyVouchersNotEnabledError,
} from "./errors";
import { AccessBudgetDisabledError } from "@service/accountingMonth/error";

/** a list of known errors that are DeserializableFromResponse */
export const knownErrors: DeserializableFromResponse<ErrorBase>[] = [
	AccessBudgetDisabledError,
	BudgetMasterCollisionError,
	MonthClosedForChangeError,
	ThirdPartyVouchersNotEnabledError,
	ThirdPartyCategoryNotAllowedError,
	...SachbezugErrors,
];
