import { isValid } from "date-fns";
import dateFnsFormat from "date-fns/format";
import LocaleDE from "date-fns/locale/de";

export const MONTHS = [
	"Januar",
	"Februar",
	"März",
	"April",
	"Mai",
	"Juni",
	"Juli",
	"August",
	"September",
	"Oktober",
	"November",
	"Dezember",
];

export const FORMAT = "dd.MM.yyyy";
const parseRegex = /(\d{1,2})\.(\d{1,2}).(\d{4})/;

export function parseDate(
	input: string | Date | undefined | null
): Date | undefined {
	if (!input) return undefined;

	if (typeof input === "object") {
		// it's probably a Date
		return input;
	}

	const matches = input.match(parseRegex);
	if (!matches) return undefined;

	const parsed = new Date(matches[3] + "-" + matches[2] + "-" + matches[1]);

	if (isValid(parsed)) {
		return parsed;
	}
	return undefined;
}
export function formatDate(date: Date | null, format: string = FORMAT) {
	if (!date) return "-";
	return dateFnsFormat(date, format, { locale: LocaleDE });
}
