import {
	CheckboxToggle,
	CurrencyInput,
	InputContainer,
	Label,
	NumberInput,
} from "@components";
import { UpdatedDivision } from "@models/Division";
import { colors } from "@theme/theming";
import React from "react";
import { Alert } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Container } from "../StyledComponents";

type Props = {
	initiallyEnabled: boolean;
};

export default function ReviewSettings(props: Props) {
	const { control, register, watch } = useFormContext<UpdatedDivision>();

	const isTripReviewer = watch("reviewSettings.shouldReviewTrips");
	return (
		<Container data-testid="reviewSettings">
			<h4>Prüfprozesse</h4>
			<CheckboxToggle
				id="isTripReviewer"
				label="Kunde muss Dienstreisen selbst prüfen"
				{...register("reviewSettings.shouldReviewTrips")}
			/>
			{isTripReviewer ? (
				<>
					<InputContainer>
						<Label htmlFor="minAmountToReview">
							Mindestbetrag von Dienstreisen zur Vorlage beim Kunden (in €)
						</Label>

						<Controller
							name="reviewSettings.minAmountToReviewTrips"
							control={control}
							render={({ field }) => (
								<CurrencyInput
									{...field}
									id="minAmountToReview"
									min={0}
									allowNull
									placeholder="Deaktiviert - es werden alle Reisen zur Prüfung vorgelegt"
								/>
							)}
						/>
						<DescriptionText>
							Reisen unter diesem Betrag gelten automatisch als durch den Kunden
							akzeptiert. Reisen über diesem Betrag werden dem Kunden zur
							Prüfung vorgelegt.
						</DescriptionText>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="maxTripAgeInDaysForAutoApproval">
							Ab so vielen Tagen zwischen Endzeitpunkt der Reise und Einreichung
							der Reise ist die Prüfung durch den Arbeitgeber erforderlich:
						</Label>

						<Controller
							name="reviewSettings.maxTripAgeInDaysForAutoApproval"
							control={control}
							render={({ field }) => (
								<NumberInput
									{...field}
									id="maxTripAgeInDaysForAutoApproval"
									min={1}
									placeholder="Unbefristet"
								/>
							)}
						/>
						<DescriptionText>
							Reisen, die nach diesem Zeitraum eingreicht werden, werden nicht
							automatisch genehmigt, sondern müssen immer vom Arbeitgeber
							geprüft werden, unabhängig von der Höhe des Gesamtbetrags (d.h.
							auch wenn der Betrag unterhalb des Schwellenwerts liegt.)
						</DescriptionText>
					</InputContainer>
				</>
			) : (
				props.initiallyEnabled && (
					<Alert variant="warning">
						Alle Reisen, die bereits von Lofino angenommen wurden und aktuell
						beim Arbeitgeber zur Prüfung vorliegen, werden automatisch auf
						"angenommen" gesetzt.
					</Alert>
				)
			)}
		</Container>
	);
}

const DescriptionText = styled.div`
	font-style: italic;
	color: ${colors.gray.g400};
`;
