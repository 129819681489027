import styled from "styled-components";

export const InputContainer = styled.div`
	width: 100%;
`;

export const ButtonsContainer = styled(InputContainer)`
	display: flex;
	justify-content: center;
	align-items: center;
`;
