import React from "react";
import styled from "styled-components";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Routes, { DivisionRouteParams } from "@service/navigation/routes";
import {
	Button,
	LoadingIndicator,
	PageContainer,
	PageHeadContainer,
	PageHeading,
	Tab,
	ScrollToTop,
	ScrollUpButton,
	MainBreadcrumbs,
	QueryStateIndicators,
	ErrorImage,
} from "@components";

import UsersList from "./components/UsersList";
import {
	RelativeContainer,
	Subheading,
	MediumGrayTextContainer,
} from "@components/PageStyle";
import ReportsPage from "../reports/ReportsPage";
import DivisionSettingsPage from "./components/DivisionSettingsPage";
import VouchersPage from "../vouchers/DefaultFilterableVoucherList";
import AddUserPage from "../user/components/AddUserPage";
import ImportCSVPage from "./components/ImportCSVPage/ImportCSVPage";
import { useCustomerAndDivision } from "@hooks";
import AccountingMonthsPage from "../accountingMonths/AccountingMonthsPage";
import DivisionPageContext from "./DivisionPageContext";

export default function DivisionsPage() {
	const routeParams = useRouteMatch<DivisionRouteParams>().params;

	const divisionAndCustomerQuery = useCustomerAndDivision();
	const cachedLocation = useLocation();

	const currentRoute = {
		usersPage: !!useRouteMatch(Routes.Users.route),
		reportsPage: !!useRouteMatch(Routes.Reports.route),
		vouchersPage: !!useRouteMatch(Routes.DivisionVouchers.route),
		accountingMonthsPage: !!useRouteMatch(Routes.AccountingMonths.route),
		settingsPage: !!useRouteMatch(Routes.Settings.route),
		importPage: !!useRouteMatch(Routes.CsvImports.route),
	};

	return (
		<>
			<ScrollToTop />
			<ScrollUpButton />
			<PageContainer>
				{divisionAndCustomerQuery.state === "error" ? (
					<ErrorImage />
				) : (
					<>
						{divisionAndCustomerQuery.state !== "success" && (
							<LoadingIndicator />
						)}
						{divisionAndCustomerQuery.data && (
							<>
								<MainBreadcrumbs
									customer={divisionAndCustomerQuery.data.customer}
									division={divisionAndCustomerQuery.data.division}
								/>

								<RelativeContainer>
									<PageHeadContainer>
										<BasicInfoContainer>
											<PageHeading data-testid="userspage-division-name">
												{divisionAndCustomerQuery.data.division.name}
											</PageHeading>
											<Subheading data-testid="userspage-customer-name">
												{divisionAndCustomerQuery.data.customer.name}
											</Subheading>
											<MediumGrayTextContainer data-testid="userspage-comment">
												{divisionAndCustomerQuery.data.division.comment}
											</MediumGrayTextContainer>
										</BasicInfoContainer>
									</PageHeadContainer>
									<Tab.Container
										css={`
											margin-bottom: 1rem;
										`}
									>
										<Tab.Item
											active={currentRoute.usersPage}
											to={Routes.Users.makePath(routeParams)}
										>
											Benutzer
										</Tab.Item>
										<Tab.Item
											active={currentRoute.vouchersPage}
											to={Routes.DivisionVouchers.makePath(routeParams)}
										>
											Belege
										</Tab.Item>
										<Tab.Item
											active={currentRoute.reportsPage}
											to={Routes.Reports.makePath(routeParams)}
										>
											Reports
										</Tab.Item>
										<Tab.Item
											active={currentRoute.accountingMonthsPage}
											to={Routes.AccountingMonths.makePath(routeParams)}
										>
											Monatsabschlüsse
										</Tab.Item>
										<Tab.Item
											active={currentRoute.settingsPage}
											to={Routes.Settings.makePath(routeParams)}
										>
											Einstellungen
										</Tab.Item>
										<Tab.Item
											active={currentRoute.importPage}
											to={Routes.CsvImports.makePath(routeParams)}
										>
											Import
										</Tab.Item>
									</Tab.Container>

									<DivisionPageContext.Provider
										value={{
											...divisionAndCustomerQuery.data,
											updateDivision: divisionAndCustomerQuery.setDivision,
										}}
									>
										<Switch location={cachedLocation}>
											<Route exact path={Routes.AddUser.route}>
												<AddUserPage />
											</Route>
											<Route exact path={Routes.CsvImports.route}>
												<ImportCSVPage
													division={divisionAndCustomerQuery.data.division}
												/>
											</Route>
											<Route exact path={Routes.Users.route}>
												<UsersList />
											</Route>
											<Route path={Routes.Reports.route}>
												<ReportsPage />
											</Route>
											<Route path={Routes.DivisionVouchers.route}>
												<VouchersPage
													requiredFilters={{
														divisionId: [
															divisionAndCustomerQuery.data.division.divisionId,
														],
													}}
												/>
											</Route>
											<Route path={Routes.Settings.route}>
												<DivisionSettingsPage />
											</Route>
											<Route path={Routes.AccountingMonths.route}>
												<AccountingMonthsPage />
											</Route>
										</Switch>
									</DivisionPageContext.Provider>
								</RelativeContainer>
							</>
						)}
					</>
				)}
				<QueryStateIndicators queryState={divisionAndCustomerQuery} />
			</PageContainer>
		</>
	);
}

export const BasicInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
export const ButtonMiddle = styled(Button)`
	font-size: 0.7rem;
	min-width: 150px;
`;
