import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
	DropDownButton,
	Icon,
	QueryStateIndicators,
	Tooltip,
} from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { defer } from "@util/helpers";
import { UserRouteParams } from "@service/navigation/routes";
import {
	BalanceAccount,
	getRootProductDescription,
	NewBalanceAccount,
	RootProductId,
} from "@models";
import UserDetailsPageContext from "../../../user/UserDetailsPageContext";
import { canConfigureBalanceAccount } from "../util";

type Props = {
	productId: string;
	balanceAccount: BalanceAccount | undefined;
};

export default function BalanceAccountControlsDropdown(props: Props) {
	const productId = props.productId as RootProductId;
	const { params } = useRouteMatch<UserRouteParams>();
	const { userId } = params;

	const pageContext = useContext(UserDetailsPageContext);

	const { balanceAccountService } = useServices();

	const newBalanceAccount: NewBalanceAccount = {
		balance: 0,
		budgetCategory: productId,
		userId: userId,
		isActive: true,
	};

	const [createBalanceAccountFn, createQueryResult] = useAsyncCommand(() =>
		balanceAccountService.createBalanceAccountForUser(userId, newBalanceAccount)
	);

	const createBalanceAccountDialog = useDialog();

	const { name: productName } = getRootProductDescription(productId);

	const onSelectCreateBalanceAccount = () => {
		createBalanceAccountDialog.showConfirmDialog({
			title: "Guthabenkonto erzeugen",
			text: `Es wird ein leeres Guthabenkonto für ${productName} angelegt. Sind Sie sicher?`,
			confirmText: "Bestätigen",
			onConfirm: async () => {
				const result = await createBalanceAccountFn();
				if (result.state === "success") {
					await defer(() => pageContext.triggerRefresh());
				}
			},
		});
	};

	const canCreateBalanceAccount =
		!props.balanceAccount && canConfigureBalanceAccount(productId);

	return (
		<>
			<DropDownButton
				variant="outline-primary"
				id="balanceAccountControlsDropdown"
				title={
					<Tooltip
						id="balanceAccountControlsDropdownButton"
						tooltip={"Einstellungen"}
					>
						<Icon.Settings />
					</Tooltip>
				}
				size="sm"
			>
				<Dropdown.Item
					disabled={!canCreateBalanceAccount}
					onSelect={onSelectCreateBalanceAccount}
				>
					Guthabenkonto erzeugen
				</Dropdown.Item>
			</DropDownButton>
			<QueryStateIndicators queryState={createQueryResult} />
		</>
	);
}
