import React from "react";

import {
	FaTimes as Rejected,
	FaCheck as Approved,
	FaHourglassHalf as InReview,
} from "react-icons/fa";
import { colors } from "@theme/theming";

export function ApprovedIcon({ title = "Angenommen" }) {
	return <Approved style={{ color: colors.success.s200 }} title={title} />;
}

export function InReviewIcon({ title = "In Prüfung" }) {
	return <InReview style={{ color: colors.caution.c300 }} title={title} />;
}

export function RejectedIcon({ title = "Abgelehnt" }) {
	return <Rejected style={{ color: colors.highlight.h200 }} title={title} />;
}

export function InReviewByEmployerIcon() {
	return (
		<>
			<ApprovedIcon />
			<InReviewIcon title="Wird noch durch den Arbeitgeber geprüft" />
		</>
	);
}

export function ApprovedByEmployerIcon() {
	return (
		<>
			<ApprovedIcon />
			<ApprovedIcon title="Durch den Arbeitgeber angenommen" />
		</>
	);
}

export function RejectedByEmployerIcon() {
	return (
		<>
			<ApprovedIcon />
			<RejectedIcon title="Durch den Arbeitgeber abgelehnt" />
		</>
	);
}
