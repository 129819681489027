import { Voucher } from "@models";
import React from "react";

type ReviewContextValue = {
	onApproved: (voucher: Voucher) => void;
	onRejected: (voucher: Voucher) => void;
	onSaved: (voucher: Voucher) => void;
};

export default React.createContext<ReviewContextValue | null>(null);
