import { colors } from "@theme/theming";
import React from "react";
import styled from "styled-components";

type Props = {
	message?: string;
};

export default function RequiredMarker(props: Props) {
	return <Marker title="Benötigtes Feld">* {props.message}</Marker>;
}

const Marker = styled.span`
	color: ${colors.highlight.h200};
	font-size: 0.9rem;
`;
