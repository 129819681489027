import * as React from "react";
import styled from "styled-components";
import { ExpandCard, Tooltip } from "@components";
import { FaCopy, FaLink, FaQuestionCircle } from "react-icons/fa";
import { colors } from "@theme/theming";
import { Omit } from "lodash";
import { LineSpacer } from "./StyledComponents";

export interface CsvColumnInfoProps {
	attribute: string;
	description?: string | JSX.Element;
	link?: string;
	de?: string;
	type: "required" | "optional";
}

interface CsvHelpCardProps {
	header?: string;
	columns: CsvColumnInfoProps[];
}

export default function CsvHelpCard(props: CsvHelpCardProps) {
	const { header, columns } = props;
	const reqColumns = columns.filter((column) => column.type === "required");
	const optColumns = columns.filter((column) => column.type === "optional");
	const columnHeaders = [...reqColumns, ...optColumns]
		.map((info) => info.attribute)
		.join(";");

	const copyHeadersToClipboard = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		navigator.clipboard
			.writeText(columnHeaders)
			.catch(() => console.log("Error copying"));
	};

	const headerElem = (
		<CsvHelpCardHeader>
			<h4 style={{ marginBottom: 0 }}>{header ?? `Daten-Spalten`}</h4>

			<CsvHeadersCopyButton onClick={copyHeadersToClipboard}>
				<FaCopy />
				Kopieren Sie die Kopfzeilen für Notepad++
			</CsvHeadersCopyButton>
		</CsvHelpCardHeader>
	);

	return (
		<CsvHelpCardContainer header={headerElem}>
			<CsvHelpCardBody>
				{reqColumns.length > 0 && (
					<>
						<h5>Benötigte Spalten</h5>
						<CsvColumnInfosContainer>
							{reqColumns.map((column, columnIdx) => (
								<CsvColumnInfo key={columnIdx} {...column} />
							))}
						</CsvColumnInfosContainer>
					</>
				)}
				{optColumns.length > 0 && (
					<>
						<h5 style={{ marginTop: "1em" }}>Optionale Spalten</h5>
						<CsvColumnInfosContainer>
							{optColumns.map((column, columnIdx) => (
								<CsvColumnInfo key={columnIdx} {...column} />
							))}
						</CsvColumnInfosContainer>
					</>
				)}
			</CsvHelpCardBody>
		</CsvHelpCardContainer>
	);
}

function CsvColumnInfo(props: CsvColumnInfoProps) {
	const { attribute } = props;
	const column = CsvColumns.find((col) => col.attribute === attribute);
	const { link } = column ?? {};

	// allows overriding de / descriptions for flexibility
	const description = props.description ?? column?.description;
	const de = props.de ?? column?.de;

	const linkElem =
		link !== undefined ? (
			<a href={link} target="_blank">
				<FaLink />
			</a>
		) : (
			<></>
		);

	const tooltipElem = description ? (
		<Tooltip
			id="csv-attribute-description-tooltip"
			tooltip={<InnerTooltip>{description}</InnerTooltip>}
			css={{ padding: "16px" }}
		>
			<FaQuestionCircle />
		</Tooltip>
	) : (
		<></>
	);

	return (
		<CsvColumnInfoContainer>
			<span className="csv-help-card-header-attribute">
				{attribute}
				{linkElem}
				{tooltipElem}
			</span>
			<span className="csv-help-card-header-de">{`(DE: ${
				de?.length ? de : "N/A"
			})`}</span>
		</CsvColumnInfoContainer>
	);
}

const CsvColumns: Omit<CsvColumnInfoProps, "type">[] = [
	{ attribute: "personnelNumber", de: "Personalnummer" },
	{ attribute: "salutation", de: "Anrede" },
	{ attribute: "firstName", de: "Vorname" },
	{ attribute: "lastName", de: "Nachname" },
	{ attribute: "email", de: "E-Mail" },
	{ attribute: "birthday", de: "Geburtsdatum" },
	{ attribute: "phone", de: "Telefon" },
	{
		attribute: "startDate",
		de: "Verfügbar ab",
	},
	{
		attribute: "endDate",
		de: "Verfügbar bis",
	},
	{ attribute: "costCenter", de: "Kostenstelle" },
	{
		attribute: "budget",
		de: "Modul",
		description: '"mobility" / "lunch" / "web" / "sachbezug"',
	},
	{
		attribute: "budgetAmount",
		de: "Max. Betrag/Monat",
		description: (
			<>
				Für Lunch beträgt das maximal erstattungsfähige Budget genau 108,45 Euro
				pro Monat.
				<LineSpacer />
				Für Web beträgt das maximal erstattungsfähige Budget genau 50 Euro pro
				Monat.
				<LineSpacer />
				Für Sachbezug muss der Budgetbetrag genau 50 Euro betragen.
			</>
		),
	},
	{
		attribute: "budgetAmountCorrection",
		de: "Korrekturwert",
		description:
			"Nur für Mobility zulässig, macht Sinn nur mit Überschuss-Budget. Wird am Startdatum angewendet.",
	},
	{
		attribute: "budgetCategories",
		de: "Kategorien",
		link: "https://docs.lofino.de/csv-imports/budget/budget_categories/",
		description: "Nur für Mobility",
	},
	{
		attribute: "isOverflowBudget",
		de: "Überschuss-Budget verwenden",
		description: "Nur für Mobility zulässig",
	},
	{ attribute: "externalId", de: "Externe ID" },
	{
		attribute: "category",
		de: "Kategorie",
		link: "https://docs.lofino.de/csv-imports/contract/contract_categories/",
	},
	{ attribute: "name", de: "Name" },
	{
		attribute: "vehicleType",
		de: "Fahrzeugart",
		link: "https://docs.lofino.de/csv-imports/contract/vehicle_types/",
	},
	{ attribute: "leaseRate", de: "Finanzierungsrate" },
	{ attribute: "settingsExpenses", de: "Reisekosten / Auslagen" },
	{ attribute: "settingsCommuting", de: "Fahrt Wohnung-Arbeitsstätte" },
	{ attribute: "settingsFringeBenefits", de: "Sachbezug" },
	{ attribute: "amountPerMonth", de: "Monatliches Budget" },
	{ attribute: "netDeduction", de: "Nettobeteiligung" },
	{ attribute: "grossDeduction", de: "Bruttobeteiligung" },
	{
		attribute: "overflowBudgetAmountIn",
		de: "Überlauf aus Budget übertragen in",
		description: 'Entweder "netDeduction" oder "grossDeduction"',
	},
	{ attribute: "comment", de: "Kommentar" },
	{ attribute: "leaseStartDate", de: "Leasing Start Datum" },
	{ attribute: "leaseEndDate", de: "Leasing End Datum" },
	{
		attribute: "insuranceRate",
		de: "Versicherungsrate",
		description: "in Euro pro Monat",
	},
	{
		attribute: "serviceRate",
		de: "Wartungsrate",
		description: "in Euro pro Monat",
	},
	{
		attribute: "flatTaxRate",
		de: "Pauschalsteuer",
		description: "in Euro pro Monat",
	},
	{
		attribute: "expectedVendor",
		de: "Voraussichtlicher Verkäufer",
		description: 'Entweder Arbeitgeber ("employer") oder Provider ("provider")',
	},
	{
		attribute: "expectedPurchasePrice",
		de: "Voraussichtlicher Kaufpreis",
		description:
			"Kann nur eingegeben werden, wenn Voraussichtlicher Verkäufer (expectedVendor) gesetzt ist.",
	},
	{
		attribute: "serviceIntervalInMonths",
		de: "Service-Interval (Monate)",
		description:
			"Wie oft soll der Benutzer an die Wartung des Fahrzeugs erinnert werden, ausgehend vom Überlassungsdatum?",
	},
	{
		attribute: "expectServiceVerification",
		de: "",
		description: (
			<>
				true/false
				<br />
				<br />
				Mitarbeiter soll Bestätigung von Serviceinspektion hochladen. Wenn
				aktiviert, wird der Benutzer in der Erinnerungsmail auch aufgefordert,
				einen Nachweis für die Inspektion hochzuladen. Andernfalls dient die
				Erinnerungsmail nur als Erinnerung.
			</>
		),
	},
	{
		attribute: "commuteDistanceInKm",
		de: "Entfernung Wohnung-Arbeitsstätte",
		description: "in Kilometern",
	},
	{ attribute: "transferDate", de: "Überlassungsdatum" },
	{ attribute: "listPrice", de: "Bruttolistenpreis" },
	{
		attribute: "isFromThirdParty",
		de: "Beleg einer Drittperson",
		description:
			"Darf nur auf true/1 gesetzt werden, wenn die Kategorie unterstützt wird. Falls nicht angegeben, wird es als eigener Dauerbeleg gewertet.",
	},
	{ attribute: "Iftd. Nr.", de: "" },
	{ attribute: "Productktbezeichnung", de: "" },
	{ attribute: "Url", de: "" },
	{ attribute: "Productktnr", de: "" },
	{ attribute: "Gutscheinwert", de: "" },
	{ attribute: "Gutscheincode", de: "" },
	{ attribute: "PIN", de: "" },
	{ attribute: "Ablaufdatum", de: "" },
	{ attribute: "eCard-Link", de: "" },
];

const CsvHelpCardContainer = styled(ExpandCard)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;

	&:last-child {
		margin-bottom: unset;
	}
`;

const CsvHelpCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	padding-block: 18px;
`;

const CsvHelpCardBody = styled.div`
	padding: 32px;
`;

const CsvHeadersCopyButton = styled.span`
	padding: 5px 8px;
	cursor: pointer;
	color: ${colors.primary.p200};
	transition: all 0.25s ease;
	display: flex;
	align-items: center;
	align-self: end;
	max-width: max-content;

	svg {
		margin-right: 0.5ch;
		width: 1em;
		min-width: 1em;
	}

	&:hover {
		color: ${colors.primary.p300};
	}
`;

const CsvColumnInfosContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;
`;

// ----
const CsvColumnInfoContainer = styled.div`
	display: grid;
	grid-template-rows: calc(1.2em + 15px) auto;
	grid-template-areas: "attribute" "de";
	border: 1px solid black;
	border-radius: 5px;
	height: max-content;

	span {
		padding: 5px 10px;
		line-height: 1.2em;
		height: 100%;
		display: grid;
		align-items: center;
	}

	.csv-help-card-header-attribute {
		grid-area: attribute;
		display: flex;
		align-items: center;

		svg {
			margin-left: 0.5ch;
			width: 1em;
			min-width: 1em;
		}
	}

	.csv-help-card-header-de {
		grid-area: de;
		border-top: 1px solid black;
	}
`;

const InnerTooltip = styled.div`
	padding: 16px;
	text-align: start;
`;
