import React from "react";
import { CardsListContainer } from "@components";
import BusinessTrip from "@models/travel/BusinessTrip";

import TripCard from "./TripCard";

type Props = {
	trips: BusinessTrip[];
	expandedTripId?: string;
};

export default function TripList(props: Props) {
	const notrips = <div>Keine Dienstreisen gefunden</div>;
	const tripCards = props.trips.map((t: BusinessTrip) => (
		<TripCard
			trip={t}
			key={t.tripId}
			isInitiallyExpanded={t.tripId === props.expandedTripId}
		/>
	));

	return (
		<CardsListContainer>
			{props.trips.length === 0 ? notrips : tripCards}
		</CardsListContainer>
	);
}
