import React from "react";
import { ExpenseBeneficiary } from "@models";
import { LargeText } from "@components";
import styled from "styled-components";

type Props = {
	beneficiaries: ReadonlyArray<ExpenseBeneficiary>;
};

export default function BeneficiaryDetails(props: Props) {
	const { beneficiaries } = props;
	if (beneficiaries.length === 0) {
		return <>Keine Begünstigten gefunden</>;
	}

	return (
		<>
			<LargeText style={{ margin: "16px", marginBottom: "4px" }}>
				Angaben zu den Begünstigten
			</LargeText>
			{beneficiaries.map((beneficiary: ExpenseBeneficiary, index: number) => (
				<React.Fragment key={index}>
					{index > 0 && <hr />}
					<GridContainer>
						<div>Name: </div>
						<div>
							{beneficiary.firstName} {beneficiary.lastName}
						</div>
						|
						{"company" in beneficiary.role && (
							<>
								<div>Unternehmen: </div>
								<div>{beneficiary.role.company}</div>
							</>
						)}
						{"occupation" in beneficiary.role && (
							<>
								<div>Berufsbezeichnung: </div>
								<div>{beneficiary.role.occupation}</div>
							</>
						)}
					</GridContainer>
				</React.Fragment>
			))}
		</>
	);
}

const GridContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;
	margin-left: 20px;
	column-gap: 20px;
	width: fit-content;
`;
