import { LogoutError } from "./errors";
import { envConfig } from "@service/config";
import { keycloak } from "./keycloak";

export async function login() {
	const kc = await keycloak();
	await kc.login({
		redirectUri: window.location.href,
		idpHint: envConfig.auth.lofinoIdp,
	});
}

export async function logout() {
	try {
		const kc = await keycloak();
		await kc.logout();
	} catch (error) {
		console.log(`Error signing out: ${error}`);
		throw new LogoutError();
	}
}
