import { Customer, Division } from "@models";
import React from "react";

type DivisionPageContextValue = {
	division: Division;
	customer: Customer;
	updateDivision: (division: Division) => void;
};

const DivisionPageContext = React.createContext<
	DivisionPageContextValue | undefined
>(undefined);

export default DivisionPageContext;
