import React, { useRef } from "react";
import { ExpandCard } from "@components";
import { ExpandCardRef } from "@components/ExpandCard";
import { BalanceAccountWithTransactions, BudgetMaster } from "@models";
import BalanceAccountCardHeader from "./BalanceAccountCardHeader";
import BalanceAccountDetails from "./BalanceAccountDetails";
import { checkAssociatedBudgetMaster } from "./util";

type Props = {
	balanceAccount: BalanceAccountWithTransactions;
	budgetMasters: BudgetMaster[];
	className?: string;
	isInitiallyExpanded?: boolean;
};

export default function BalanceAccountCard(props: Props) {
	const { balanceAccount, isInitiallyExpanded, budgetMasters } = props;

	const expandCardRef = useRef<ExpandCardRef>(null);

	const hasAssociatedBudgetMaster = checkAssociatedBudgetMaster(
		balanceAccount,
		budgetMasters
	);

	const header = (
		<BalanceAccountCardHeader
			balanceAccount={balanceAccount}
			hasAssociatedBudgetMaster={hasAssociatedBudgetMaster}
		/>
	);

	const collapseCard = () => {
		expandCardRef.current?.collapse();
	};

	const onCloseEditMode = () => {
		collapseCard();
		return true;
	};

	return (
		<>
			<ExpandCard
				className={props.className}
				testId={"balanceAccountCard-" + balanceAccount.balanceAccountId}
				css={`
					margin-bottom: 10px;
				`}
				header={header}
				ref={expandCardRef}
				isInitiallyExpanded={isInitiallyExpanded}
				onCollapse={() => onCloseEditMode()}
			>
				<BalanceAccountDetails
					balanceAccount={balanceAccount}
					hasAssociatedBudgetMaster={hasAssociatedBudgetMaster}
				/>
			</ExpandCard>
		</>
	);
}
