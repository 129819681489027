import React, { useContext, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash";
import {
	CancelDialog,
	CheckboxToggle,
	Form,
	FormSection,
	Icon,
	SmallText,
	TransparentStatusIndicator,
} from "@components";
import {
	BalanceAccountWithTransactions,
	BalanceTransactionType,
} from "@models";
import { Currency } from "@util";
import { formatDate } from "@components/forms/datePickerUtils";
import { NO_ASSOCIATED_BM_MESSAGE } from "./util";
import BalanceAccountControls from "./BalanceAccountControls";
import { useAsyncCommand, useServices } from "@hooks";
import UserDetailsPageContext from "../../../user/UserDetailsPageContext";
import { defer } from "@util/helpers";

type Props = {
	balanceAccount: BalanceAccountWithTransactions;
	hasAssociatedBudgetMaster: boolean;
	onClose?: (needsConfirmation: boolean) => void;
};

export default function BalanceAccountDetails(props: Props) {
	const { balanceAccount, hasAssociatedBudgetMaster } = props;
	const transactions = _.sortBy(
		balanceAccount.transactions,
		(bt) => bt.date,
		"asc"
	);

	const { setValue, control, watch } = useForm<{
		isActive: boolean;
	}>();

	const [showBalanceAccountControls, setShowBalanceAccountControls] =
		useState(false);
	const [mustShowCloseWarning, setMustShowCloseWarning] = useState(false);
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [showStatusIndicator, setShowStatusIndicator] = useState(true);

	const onCloseEditMode = (needsConfirmation: boolean) => {
		if (needsConfirmation && mustShowCloseWarning) {
			setShowCancelDialog(true);
		} else {
			setShowBalanceAccountControls(false);
		}
	};

	const { balanceAccountService } = useServices();
	const [updateBalanceAccountStatus, updateQueryResult] = useAsyncCommand(
		(isBalanceAccountActive: boolean) =>
			balanceAccountService.updateBalanceAccountStatus(
				balanceAccount.userId,
				balanceAccount.balanceAccountId,
				isBalanceAccountActive
			)
	);

	const pageContext = useContext(UserDetailsPageContext);

	const handleUpdateBalanceAccountStatus = async () => {
		const isBalanceAccountActive = watch("isActive");
		setShowStatusIndicator(true);
		const result = await updateBalanceAccountStatus(isBalanceAccountActive);
		if (result.state === "success") {
			await defer(() => {
				setShowStatusIndicator(false);
				pageContext.triggerRefresh();
			});
		}
	};

	return (
		<Container>
			{!hasAssociatedBudgetMaster && (
				<AlertMessageContainer>
					<Alert variant={"warning"}>{NO_ASSOCIATED_BM_MESSAGE}</Alert>
				</AlertMessageContainer>
			)}
			<Form>
				<FormContainer>
					<Controller
						name="isActive"
						defaultValue={balanceAccount.isActive}
						control={control}
						render={({ field }) => (
							<CheckboxToggle
								{...field}
								value={field.value ? "checked" : ""}
								label="Monatlich auszahlen"
								checked={field.value}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValue("isActive", e.target.checked);
									void handleUpdateBalanceAccountStatus();
								}}
							/>
						)}
					/>

					{showStatusIndicator && (
						<div style={{ marginLeft: "8px" }}>
							<TransparentStatusIndicator query={updateQueryResult} />
						</div>
					)}
					<ButtonContainer>
						<Button
							variant="outline-primary"
							onClick={() => setShowBalanceAccountControls(true)}
						>
							<Icon.Edit /> Kontostand ändern
						</Button>
					</ButtonContainer>
				</FormContainer>
			</Form>
			<SmallText title="balance">
				Guthaben:{" "}
				{Currency.format(balanceAccount.balance, { hideZeroDecimals: true })}
			</SmallText>
			<FormSection>Transaktionen:</FormSection>
			{/* TODO: find a better way to select row in tests */}
			<GridContainer>
				<TransactionsContainer>
					{transactions.map((bt) => (
						<React.Fragment key={bt.balanceTransactionId}>
							<div data-testid="transactionDate">
								{formatDate(bt.date, "dd.MM.yyyy HH:mm:ss")}
							</div>
							<div
								className={
									bt.transactionType === BalanceTransactionType.Credit
										? "text-success"
										: "text-danger"
								}
								data-testid="transactionAmount"
							>
								{Currency.format(bt.amount, { hideZeroDecimals: true })}
							</div>

							<div data-testid="transactionType">
								{bt.transactionType === BalanceTransactionType.Credit
									? "aufgeladen"
									: "abgebucht auf Monatsbudget"}
							</div>
						</React.Fragment>
					))}
				</TransactionsContainer>

				<ControlPanelContainer>
					{showBalanceAccountControls && (
						<BalanceAccountControls
							balanceAccount={balanceAccount}
							hideBalanceAccountControls={() =>
								setShowBalanceAccountControls(false)
							}
							onClose={onCloseEditMode}
							onFormDirty={setMustShowCloseWarning}
						/>
					)}
				</ControlPanelContainer>
			</GridContainer>
			<CancelDialog
				showAlertDialog={showCancelDialog}
				cancelEditMode={() => setShowBalanceAccountControls(false)}
				hideDialog={() => {
					setShowCancelDialog(false);
					setShowBalanceAccountControls(false);
				}}
			>
				Sie haben ungespeicherte Änderungen in der Guthabenkontokonfiguration.
				Änderungen wirklich verwerfen?
			</CancelDialog>
		</Container>
	);
}

const Container = styled.div`
	margin-left: 20px;
	margin-top: 20px;
`;

const AlertMessageContainer = styled.div`
	margin-top: 8px;
`;

const FormContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	margin: 8px;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 4fr 2fr;
	row-gap: 0px;
`;

const TransactionsContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 2fr;
	width: 50%;
	margin: 2em;
	align-self: start;
`;

const ControlPanelContainer = styled.div`
	min-width: 50%;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-left: auto;
`;
