import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useServices } from "@hooks";

export default function EnvironmentBanner() {
	const { staticDataService } = useServices();
	const [environment, setEnv] = useState("");

	const isProd = window.location.host
		.toLowerCase()
		.includes("backoffice.lofino.de");

	useEffect(() => {
		if (!isProd) {
			(async () => {
				// NOTE: we cannot use the staticDataContext since we have to be logged in for the other static data fetches, but this route is public
				setEnv(await staticDataService.getEnvironmentIdentifier());
			})();
		}
	}, [staticDataService, isProd]);

	if (isProd) {
		return null;
	}
	if (!environment) return null;

	return (
		<Banner style={{ backgroundColor: getColor(environment) }}>
			{environment.toUpperCase()} ENVIRONMENT!
		</Banner>
	);
}

function getColor(environment: string) {
	switch (environment) {
		case "test":
			return "darkblue";
		case "new-prod":
			return "brown";
		case "local":
			return "darkolivegreen";
	}
	return "darkslategray";
}

const Banner = styled.div`
	color: white;
	text-align: center;
`;
