export function getPaginationStateFromSearchParams(
	queryParams: URLSearchParams
) {
	const sort = queryParams.get("sort") ?? undefined;
	let order = queryParams.get("sortDirection") ?? undefined;
	if (order && !["asc", "desc"].includes(order.toLowerCase())) {
		order = undefined;
	}
	const limit = queryParams.get("limit");
	const page = queryParams.get("page");

	let filter: Record<string, string> | null = null;

	queryParams.forEach((value, key) => {
		if (key !== "limit" && key !== "page" && !key.startsWith("sort")) {
			if (!filter) {
				filter = {};
			}
			// it's setting a `filters` state which consumers of `usePagination` can use; currently it's whitelisted on the one occasion where it's used
			filter[key] = value;
		}
	});

	return { sort, order, limit, page, filter };
}
