import { envConfig } from "../config";
import { keycloak } from "./keycloak";

export async function isLoggedIn(): Promise<boolean> {
	const kc = await keycloak();

	return !!kc.idToken && !kc.isTokenExpired();
}

export async function isAuthorized(): Promise<boolean> {
	const kc = await keycloak();

	const backofficeRoles =
		kc.idTokenParsed?.resource_access?.[envConfig.auth.clientId]?.roles ?? [];

	const isAdmin = backofficeRoles.includes("backoffice-admin");

	return isAdmin;
}

export async function getAccessToken(): Promise<string> {
	const kc = await keycloak();

	try {
		await kc.updateToken(60);
	} catch {
		console.warn("couldn't refresh token -- signing out...");
		// this should also trigger the onAuthLogout callback
		kc.clearToken();
	}

	const accessToken = kc.token;
	if (!accessToken) {
		throw new Error("Couldn't find accessToken");
	}

	return accessToken;
}

export async function getAuthorizationHeader(): Promise<string> {
	const token = await getAccessToken();

	return `Bearer ${token}`;
}
