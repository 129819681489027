import { CardsListContainer, ExpandCard, LargeText } from "@components";
import { ExpandCardRef } from "@components/ExpandCard";
import React, { useRef } from "react";
import MonthSummaryHeader from "./MonthSummaryHeader";
import MonthSummaryDetails from "./MonthSummaryDetails";
import { Alert } from "react-bootstrap";
import { MonthSummary } from "@service/user/type";
import { formatDateMonth } from "@service/util";
import { filterMonthSummary } from "./util";

type Props = {
	monthSummary: MonthSummary;
};

export default function MonthSummarySection(props: Props) {
	const { monthSummary } = props;
	const { date, products, moduleSettings } = filterMonthSummary(monthSummary);
	const month = formatDateMonth(date);
	const year = date.getFullYear();
	const hasData = products.length > 0 || moduleSettings.length > 0;

	const expandCardRef = useRef<ExpandCardRef>(null);

	return (
		<>
			<LargeText
				css={`
					margin: 20px 0;
				`}
			>
				{`${month} ${year}`}
			</LargeText>
			{hasData ? (
				<>
					<CardsListContainer data-testid={`test-product-${month}`}>
						{products.map((budget) => (
							<ExpandCard
								key={budget.budgetId}
								header={<MonthSummaryHeader product={budget} />}
								ref={expandCardRef}
							>
								<MonthSummaryDetails product={budget} date={date} />
							</ExpandCard>
						))}
					</CardsListContainer>
					<CardsListContainer data-testid={`test-moduleSettings-${month}`}>
						{moduleSettings.map((product) => (
							<ExpandCard
								key={product.budgetMasterId}
								header={<MonthSummaryHeader product={product} />}
								ref={expandCardRef}
							>
								<MonthSummaryDetails product={product} date={date} />
							</ExpandCard>
						))}
					</CardsListContainer>
				</>
			) : (
				<Alert variant="info">
					Für diesen Monat gibt es kein Monatsbudget.
				</Alert>
			)}
		</>
	);
}
