import { LargeText, SmallText } from "@components";
import { useStaticData } from "@hooks";
import { BudgetMaster, CongratsSettings, NewBudgetMaster } from "@models";
import { CongratsOccasions } from "../../../../models/CongratsOccasion";
import React from "react";
import { ProductCongrats } from "@models/products/ProductCongrats";

type Props = {
	budgetMaster: BudgetMaster | NewBudgetMaster;
};

export default function CongratsHeader(props: Props) {
	const { budgetMaster } = props;

	const { getCategory, getProductOfId } = useStaticData();
	const category = getCategory(budgetMaster.categories[0]);
	const product = getProductOfId("congrats") as ProductCongrats;
	const congratsSettings = budgetMaster.settings as CongratsSettings;

	const congratsOccasion =
		congratsSettings?.customOccasion ??
		product.congratsOccasions.find(
			(o) => o.id === budgetMaster.settings?.occasion
		)?.name;

	return (
		<>
			<LargeText>
				{budgetMaster.settings?.occasion === CongratsOccasions.Birthday
					? "Geburtstags-Gutschein"
					: "Gutschein"}
			</LargeText>

			{budgetMaster.settings?.occasion !== CongratsOccasions.Birthday &&
				congratsOccasion && <SmallText>{congratsOccasion}</SmallText>}

			<SmallText>{category?.name}</SmallText>
		</>
	);
}
