import React from "react";
import { Header } from "./TableTypes";
import { SortControl } from "./SortControl";
import { SortState } from "@hooks";
import Checkbox from "@components/forms/Checkbox";

interface Props {
	headers: Header[];
	sort?: SortState;

	allowSelect?: boolean;
	isSelectAll?: boolean;
	onSelectAll?: (selected: boolean) => void;
}

export default function TableHeader(props: Props) {
	const { headers, sort, allowSelect, isSelectAll, onSelectAll } = props;

	return (
		<>
			{allowSelect && (
				<th key="select-all">
					<Checkbox
						type="checkbox"
						title={isSelectAll ? "Alle abwählen" : "Alle auswählen"}
						value={isSelectAll}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							onSelectAll?.(e.target.checked)
						}
					/>
				</th>
			)}
			{headers.map((header, index) => (
				<th key={index} data-testid="column-header">
					{sort && header.isSortable ? (
						<SortControl header={header} sort={sort}>
							{header.title}
						</SortControl>
					) : (
						header.title
					)}
				</th>
			))}
		</>
	);
}
