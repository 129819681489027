import React from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "@theme/theming";

type Props = {
	checked: number;
	listLength: number;
	barHeight?: number;
	styles?: CSSProperties;
};

export default function ChecklistProgress(props: Props) {
	const { checked, listLength, styles, barHeight = 0.26 } = props;

	return (
		<ChecklistProgressContainer style={styles} barHeight={barHeight}>
			<ProgressText>
				{checked} von {listLength}
			</ProgressText>
			<ProgressBar
				checked={checked}
				listLength={listLength}
				barHeight={barHeight}
			/>
		</ChecklistProgressContainer>
	);
}

const ChecklistProgressContainer = styled.div<{ barHeight: number }>`
	display: grid;
	grid-template-rows: ${(props) =>
		`calc(100% - ${props.barHeight}rem) ${props.barHeight}rem`};
	align-items: center;
	justify-items: flex-start;

	width: 100%;
	height: min-content;
	min-width: 6ch;
`;

const ProgressText = styled.span`
	// TODO: add variables for common weights
	font-weight: 600;
	font-size: 0.72rem;

	margin-bottom: 0.1rem;

	color: ${colors.gray.g400};

	user-select: none;
`;

const ProgressBar = styled.div<{
	checked: number;
	listLength: number;
	barHeight: number;
}>`
	width: 100%;
	height: 100%;
	border-radius: ${(props) => props.barHeight / 2}rem;
	position: relative;
	background: ${colors.gray.g100};

	&::before {
		content: "";
		border-radius: ${(props) => props.barHeight / 2}rem;
		width: ${(props) =>
			Math.round((props.checked / props.listLength) * 1000) / 10}%;
		height: 100%;
		position: absolute;
		left: 0;
		background: ${colors.primary.p200};
		transition: all 0.25s ease;
	}
`;
