import { useStaticData } from "@hooks";
import { Category } from "@models";
import { isDefined } from "@util/isDefined";

export default function useContractCategories(): Category[] {
	const { getCategory } = useStaticData();

	const allowedCategories = [
		"mobility.commute.publicTransport.jobTicket",
		"mobility.commute.publicTransport.annualTicket",
		"mobility.longDistance.railcard",
		"mobility.longDistance.railcardEmployerFinanced",
		"mobility.sharing.scooter",
		"mobility.sharing.bike",
		"mobility.sharing.carRental",
		"mobility.vehicleCosts.bike.leasing",
		"mobility.vehicleCosts.car.parkingAtCompany",
		"mobility.vehicleCosts.car.leasing",
		"spend.fitness",
		"spend.vehicleRental",
		"spend.vehicleSharing",
		"web",
		"mobility.jobVehicle.bike",
		"mobility.jobVehicle.motorScooter",
		"mobility.jobVehicle.kickScooter",
		"mobility.jobVehicle.car",
	];

	return allowedCategories
		.map(getCategory)
		.filter(isDefined)
		.filter((c) => !c.isDeprecated);
}
