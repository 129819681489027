import React from "react";
import { NoAutoFillInput } from "@components";
import {
	FieldPath,
	FieldPathValue,
	FieldValues,
	useController,
	UseControllerProps,
} from "react-hook-form";
import useTripReviewContext from "../hooks/useTripReviewContext";

type InputProps<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
	id?: string;
	renderReadonly:
		| string
		| ((value: FieldPathValue<TFieldValues, TName>) => JSX.Element);
};

export function ControlledNoAutoFillInput<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(props: InputProps<TFieldValues, TName>) {
	const { isTripEditable } = useTripReviewContext();

	const { field } = useController(props);

	if (isTripEditable) {
		return <NoAutoFillInput id={props.id ?? field.name} {...field} />;
	} else {
		if (typeof props.renderReadonly === "string") {
			return <div>{props.renderReadonly}</div>;
		} else {
			return props.renderReadonly(field.value) as JSX.Element;
		}
	}
}
