import { MonthSummary } from "@service/user/type";
import { useCallback } from "react";
import useFetchedData, { QueryState } from "./useFetchedData";
import useServices from "./useServices";

export default function useAccountSummary(
	userId: string,
	year: number
): QueryState<MonthSummary[]> {
	const { userService } = useServices();
	const getUserYearSummary = useCallback(
		async () => await userService.getAccountSummaryByYear(userId, year),
		[userService, userId, year]
	);
	const [queryState] = useFetchedData(getUserYearSummary);
	return queryState;
}
