import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CommonModal, Icon, Tooltip, DropDownButton } from "@components";
import ChargeBalanceAccountsForm from "./ChargeBalanceAccountsForm";

export default function BalanceAccountSettingsDropdown() {
	const [showChargeBalanceAccountModal, setShowChargeBalanceAccountModal] =
		useState(false);

	return (
		<>
			<DropDownButton
				variant="outline-primary"
				id="balanceAccountSettingsDropdown"
				title={
					<Tooltip
						id="balanceAccountSettingsDropdownButton"
						tooltip={"Einstellungen"}
					>
						<Icon.Settings />
					</Tooltip>
				}
				size="sm"
			>
				<Dropdown.Item onSelect={() => setShowChargeBalanceAccountModal(true)}>
					Guthabenkonten aufladen
				</Dropdown.Item>
			</DropDownButton>
			{showChargeBalanceAccountModal && (
				<CommonModal
					handleClose={() => setShowChargeBalanceAccountModal(false)}
					header="Guthabenkonten aufladen"
				>
					<ChargeBalanceAccountsForm
						onClickCloseModal={() => setShowChargeBalanceAccountModal(false)}
					/>
				</CommonModal>
			)}
		</>
	);
}
