import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Pagination, QueryStateIndicators } from "@components";
import { usePaginatedFetchedData, useServices } from "@hooks";
import { DivisionRouteParams } from "@service/navigation/routes";
import { QueryOptions } from "@service/types";
import AccountingMonthList from "./AccountingMonthList";

export default function AccountingMonthsPage() {
	const { accountingMonthService } = useServices();
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;

	const fetchAccountingMonths = useCallback(
		(options: QueryOptions) =>
			accountingMonthService.getAccountingMonthsByDivision(divisionId, options),
		[divisionId, accountingMonthService]
	);

	const [accountingMonthsQuery, triggerAccountingMonthsQuery] =
		usePaginatedFetchedData(fetchAccountingMonths);

	return (
		<DataContainer>
			<QueryStateIndicators queryState={accountingMonthsQuery} />

			{accountingMonthsQuery.state === "success" && (
				<>
					<Container>
						<AccountingMonthList
							accountingMonths={accountingMonthsQuery.data}
							onRefreshNeeded={triggerAccountingMonthsQuery}
						/>
					</Container>
					<Pagination queryState={accountingMonthsQuery} />
				</>
			)}
		</DataContainer>
	);
}

const Container = styled.div`
	margin: 15px;
`;

const DataContainer = styled.div`
	position: relative;
`;
