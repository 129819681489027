import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
	Button,
	ExpandableTableRow,
	IconButton,
	IconsContainer,
	LoadingIndicator,
	RelativeContainer,
	SectionHeader,
	TableColumn,
	TableGeneric,
} from "@components";
import { Customer, Division } from "@models";
import { formatDate } from "@service/util";
import Routes from "@service/navigation/routes";
import { useServices, useAsyncCommand, useSortQueryParams } from "@hooks";
import { TableStylesSecondary, Icon } from "@components";
import { Header } from "@components/TableGeneric/TableTypes";
import DivisionRow from "./DivisionRow";
import { colors } from "@theme/theming";

type Props = {
	customer: Customer;
	headers: Header[];
};

export default function CustomerRow(props: Props) {
	const { customer } = props;
	const { divisionService } = useServices();

	const [fetchDivision, divisionQuery] = useAsyncCommand(async () => {
		return divisionService.getDivisions(customer.customerId);
	});

	const editCustomerUrl = Routes.EditCustomer.makePath({
		customerId: customer.customerId,
	});
	const addDivisionUrl = Routes.AddDivision.makePath({
		customerId: customer.customerId,
	});

	const sortQueryParams = useSortQueryParams();
	const divisionHead = [
		{
			title: "Name",
			accessor: "",
		},
		{
			title: "Adresse",
			accessor: "",
		},
		{
			title: "Telefon Telefax",
			accessor: "",
		},
		{
			title: "Steuernr",
			accessor: "",
		},
		{
			title: "Umsatzsteuer-Id",
			accessor: "",
		},
		{
			title: "Erstellt",
			accessor: "",
		},
		{
			title: "Geändert",
			accessor: "",
		},
		{
			title: "",
			accessor: "",
		},
	];

	const expandedContent = (
		<td colSpan={props.headers.length} style={{ border: "none" }}>
			{divisionQuery.state === "success" ? (
				<TableStylesSecondary>
					<SectionHeader>
						<h2>Standort</h2>
						<span>
							<Link to={addDivisionUrl}>
								<Button variant="outline-primary">
									{" "}
									<Icon.Add /> Standort hinzufügen
								</Button>
							</Link>
						</span>
					</SectionHeader>

					<TableGeneric
						headers={divisionHead}
						data={divisionQuery.data as Division[]}
						sortOptions={sortQueryParams}
					>
						{(division: Division) => (
							<DivisionRow key={division.divisionId} division={division} />
						)}
					</TableGeneric>
				</TableStylesSecondary>
			) : (
				<RelativeContainer style={{ minHeight: 50 }}>
					<LoadingIndicator />
				</RelativeContainer>
			)}
		</td>
	);

	const handleOnExpand = () => {
		void fetchDivision();
	};

	const row = (
		<>
			<CustomerAccordion
				expandedContent={expandedContent}
				onExpand={handleOnExpand}
			>
				<TableColumn>{customer.customerNo}</TableColumn>
				<TableColumn>
					{customer.name}
					{customer.isArchived ? <StatusSpan>archiviert</StatusSpan> : ""}
				</TableColumn>
				<TableColumn>
					{customer.created ? formatDate(customer.created) : "-"}
				</TableColumn>
				<TableColumn>
					{customer.updated ? formatDate(customer.updated) : "-"}
				</TableColumn>
				<TableColumn>
					<IconsContainer>
						<Link to={editCustomerUrl}>
							<IconButton tooltip="Kunden bearbeiten">
								<Icon.Edit test-dataid="edit-button" />
							</IconButton>
						</Link>
					</IconsContainer>
				</TableColumn>
			</CustomerAccordion>
		</>
	);

	return row;
}

const CustomerAccordion = styled(ExpandableTableRow)`
	background: ${colors.white} !important;
`;

const StatusSpan = styled.span`
	font-size: 0.8em;
	margin: 0 5px;
	padding: 5px;
	background: ${colors.white};
	border: 1px solid ${colors.gray.g300};
	border-radius: 5px;
	color: ${colors.highlight.h400};
`;
