import { Properties } from "@util/types";
import ExpenseBeneficiary from "./ExpenseBeneficiary";
import TravelExpense from "./TravelExpense";

export default class HospitalityExpense extends TravelExpense {
	constructor(props: Properties<HospitalityExpense>) {
		super(props);

		this.occasion = props.occasion;
		this.tip = props.tip;
		this.reimbursableTip = props.reimbursableTip;
		this.beneficiaries = props.beneficiaries;
	}

	readonly occasion: string;
	readonly tip: number;
	readonly reimbursableTip: number;
	readonly beneficiaries: ReadonlyArray<ExpenseBeneficiary>;

	static readonly type = "hospitality";

	override get displayName() {
		return "Bewirtung";
	}

	static fromJson(data: any) {
		return new HospitalityExpense({
			...data,
			...TravelExpense.fromJson(data),
			beneficiaries: data.beneficiaries.map(ExpenseBeneficiary.fromJson),
		});
	}
}
