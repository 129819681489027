import { QueryState } from "@hooks";
import React from "react";
import ActionButton from "./ActionButton";
import ErrorAlert from "./ErrorAlert";
import { MapErrorToElementFn } from "./QueryError";

type QueryConfig = {
	query: QueryState<any>;
	successText?: string;
	errorText?: string;
	activeText?: string;
	mapError?: MapErrorToElementFn;
};

type Props = {
	children?: React.ReactNode;
	queries: QueryConfig[];
	className?: string;
};

/**
 * Conditionally renders either the children, or an indicator for any of the passed queries if either of those is not idle
 */
export default function ActionIndicator(props: Props) {
	const activeQuery = props.queries.find((q) => q.query.state !== "idle");

	if (activeQuery) {
		return (
			<>
				<ActionButton
					query={activeQuery.query}
					successContent={activeQuery.successText}
					errorContent={activeQuery.errorText ?? "Fehler"}
					className={props.className}
				>
					{activeQuery.activeText}
				</ActionButton>
				{activeQuery.query.state === "error" && (
					<ErrorAlert>
						{activeQuery.mapError?.(activeQuery.query.error)}
					</ErrorAlert>
				)}
			</>
		);
	} else return <>{props.children}</>;
}
