import { ErrorBase } from "@util/errors";

export class ConflictError extends ErrorBase {
	constructor(what1: string, what2: string) {
		super(
			`${what1} sich in der Zwischenzeit geändert. Klappe ${what2} zu und wieder auf, um die aktualisierten Daten zu sehen.`
		);
	}
}

export class VoucherConflictError extends ConflictError {
	constructor() {
		super("Die Belegdaten haben", "den Beleg");
	}
}

export class TripConflictError extends ConflictError {
	constructor() {
		super("Die Reise hat", "die Reise");
	}
}
