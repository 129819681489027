import { ErrorBase } from "@util/errors";

export class BudgetMasterCollisionError extends ErrorBase {
	static errorId = "budget-master-collision-range";
	constructor() {
		super("Der Zeitraum überschneidet sich mit anderen Budgets.");
	}
}
export class ModuleBillingNotActive extends ErrorBase {
	static errorId = "module-billing-not-active";
	constructor(moduleName: string) {
		super(
			`Das Modul "${moduleName}" ist in den Honorareinstellungen nicht freigeschaltet. Füge das Modul hinzu und versuche es erneut.`
		);
	}
}
export class CutoffDateReachedMissingCurrentMonth extends ErrorBase {
	static errorId = "cutoff-date-reached-missing-current-month";
	constructor() {
		super(
			"Die Moduleinstellung kann nicht aktualisiert werden, da die Frist erreicht ist. Bitte geben Sie auch den aktuellen Monat an."
		);
	}
}
export class CutoffDateReachedCannotExtend extends ErrorBase {
	static errorId = "cutoff-date-reached-cannot-extend";
	constructor() {
		super(
			"Änderungen sind in dieser Moduleinstellung nicht möglich, da die Frist erreicht ist. Bitte erwägen Sie die Erstellung eines neuen."
		);
	}
}
export class EndDateCannotBePastDate extends ErrorBase {
	static errorId = "end-date-cannot-be-past-date";
	constructor() {
		super(
			"Die Moduleinstellung kann nicht aktualisiert werden, da das Enddatum nicht in der Vergangenheit liegen darf."
		);
	}
}
export class StartDateCannotBePastDate extends ErrorBase {
	static errorId = "start-date-cannot-be-past-date";
	constructor() {
		super(
			"Die Moduleinstellung kann nicht aktualisiert werden, da das Startdatum nicht in der Vergangenheit liegen darf."
		);
	}
}
export class CannotUpdatePastMonthSetting extends ErrorBase {
	static errorId = "cannot-update-past-months-setting";
	constructor() {
		super(
			"Änderungen in der Einstellung der vergangenen Monate sind nicht möglich."
		);
	}
}
export class InvalidDateRange extends ErrorBase {
	static errorId = "invalid-date-range-middle-of-month";
	constructor() {
		super(
			"Ungültiger Datumsbereich. Das Startdatum sollte der erste Tag des Monats und das Enddatum der letzte Tag des Monats sein."
		);
	}
}
export class EndDateBeforeStartDate extends ErrorBase {
	static errorId = "end-date-before-start-date";
	constructor() {
		super(
			"Ungültiger Datumsbereich. Das Startdatum darf nicht vor dem Enddatum liegen."
		);
	}
}
export class CannotCreateModuleSettingForPastMonths extends ErrorBase {
	static errorId = "cannot-create-module-setting-for-past-months";
	constructor() {
		super(
			"Moduleinstellungen für vergangene Monate können nicht erstellt werden."
		);
	}
}
export class CannotDeleteModuleSettingForPastMonths extends ErrorBase {
	static errorId = "cannot-delete-module-setting-of-past-months";
	constructor() {
		super(
			"Moduleinstellungen für vergangene Monate können nicht gelöscht werden."
		);
	}
}
export class CannotCreateCuttoffDateReached extends ErrorBase {
	static errorId = "cannot-create-cutoff-date-reached";
	constructor() {
		super(
			"Moduleinstellungen können nicht für Zeiträume erstellt werden, die den aktuellen Monat umfassen, da der Stichtag erreicht worden ist."
		);
	}
}
export class CannotDeleteCuttoffDateReached extends ErrorBase {
	static errorId = "cannot-delete-cutoff-date-reached";
	constructor() {
		super(
			"Kann nicht gelöscht werden, da diese Moduleinstellung den aktuellen Monat umfasst und der Stichtag erreicht worden ist."
		);
	}
}
export class CannotIncludeCurrentMonthCutoffDateReached extends ErrorBase {
	static errorId = "cannot-include-current-month-cutoff-date-reached";
	constructor() {
		super(
			"Der aktuelle Monat kann nicht einbezogen werden, da der Stichtag erreicht worden ist."
		);
	}
}
export class CannotUpdatePastStartDate extends ErrorBase {
	static errorId = "cannot-update-past-start-date";
	constructor() {
		super("Vergangenes Startdatum kann nicht geändert werden.");
	}
}
export class CannotUpdateAmountForPastMonths extends ErrorBase {
	static errorId = "cannot-update-amount-for-past-months";
	constructor() {
		super(
			"Kann nicht aktualisiert werden, da die Änderungen des Betrags die vergangene Monate betrifft."
		);
	}
}
export class CannotUpdateAmountCutoffDateReached extends ErrorBase {
	static errorId = "cannot-update-amount-cutoff-date-reached";
	constructor() {
		super(
			"Kann nicht aktualisiert werden, da es Änderungen des Betrags umfasst und der Stichtag für diesen Monat erreicht worden ist."
		);
	}
}

export const SachbezugErrors = [
	CutoffDateReachedMissingCurrentMonth,
	CutoffDateReachedCannotExtend,
	EndDateCannotBePastDate,
	StartDateCannotBePastDate,
	CannotUpdatePastMonthSetting,
	InvalidDateRange,
	EndDateBeforeStartDate,
	CannotCreateModuleSettingForPastMonths,
	CannotDeleteModuleSettingForPastMonths,
	CannotCreateCuttoffDateReached,
	CannotDeleteCuttoffDateReached,
	CannotIncludeCurrentMonthCutoffDateReached,
	CannotUpdatePastStartDate,
	CannotUpdateAmountForPastMonths,
	CannotUpdateAmountCutoffDateReached,
];
