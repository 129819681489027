import React from "react";
import { Icon, TableColumn, TableRow, Tooltip } from "@components";
import { FORMAT, formatDate } from "../../../components/forms/datePickerUtils";
import { useServices, useStaticData } from "@hooks";
import Report, { ReportStatus } from "../../../models/Report";
import { formatDateMonth } from "@service/util";
import { SmallButton } from "@components";
import styled from "styled-components";
import { colors } from "@theme/theming";

type Props = {
	report: Report;
};

export function ReportRow({ report }: Props) {
	const { created, createdBy, sourceMonth, reportId, monthClosedEventId } =
		report;
	const date = formatDate(created, FORMAT);
	const monthName = formatDateMonth(sourceMonth);
	const { reportTypes } = useStaticData();
	const reportType = reportTypes.find((r) => r.id === report.reportType);
	const isDraft = !monthClosedEventId;

	const { reportService } = useServices();

	const onClickDownloadReport = async () => {
		const reportLink = await reportService.downloadReport(reportId);
		const downloadLink = document.createElement("a");
		downloadLink.href = reportLink;
		downloadLink.click();
	};

	return (
		<TableRow>
			<TableColumn>{date}</TableColumn>
			<TableColumn>{monthName + " " + sourceMonth.getFullYear()}</TableColumn>
			<TableColumn>{createdBy}</TableColumn>
			<TableColumn>{reportType?.name ?? report.reportType}</TableColumn>
			<TableColumn>
				{isDraft && <DraftBadge />}
				{reportType?.isPublic && !isDraft && <PublicBadge />}
			</TableColumn>
			<TableColumn>
				<ReportStateButton report={report} onClick={onClickDownloadReport} />
				<SubmitStatus report={report} />
			</TableColumn>
		</TableRow>
	);
}

function SubmitStatus({ report }: { report: Report }) {
	switch (report.submitStatus) {
		case "submitted":
			return (
				<Tooltip
					id="tt"
					tooltip={`Der Datensatz wurde bereits erfolgreich an das DATEV Rechenzentrum übertragen und liegt dort zum Import an. 
						 Die Datei muss nicht nochmal an den Lohnabrechner geschickt werden.`}
				>
					<div>
						<Icon.Success color={colors.success.s300} />{" "}
						<SubmitStatusText>übertragen</SubmitStatusText>
					</div>
				</Tooltip>
			);
		case "submit_failed":
			return (
				<Tooltip
					id="tt"
					tooltip={`Bei der Übertragung des Datensatzes an das DATEV Rechenzentrum ist ein Fehler aufgetreten.`}
				>
					<div>
						<Icon.Error color={colors.highlight.h300} />{" "}
						<SubmitStatusText>Übertragung fehlgeschlagen</SubmitStatusText>
					</div>
				</Tooltip>
			);
	}
	return null;
}

const SubmitStatusText = styled.span`
	color: ${colors.gray.g400};
`;

type ReportStateButtonProps = {
	report: Report;
	onClick: () => void;
};

function ReportStateButton({ report, onClick }: ReportStateButtonProps) {
	const isDisabled = report.status === ReportStatus.Processing;
	const btnState =
		report.status === ReportStatus.Processing
			? { variant: "outline-secondary", text: " Verarbeitung..." }
			: report.status === ReportStatus.Error
			? { variant: "outline-danger", text: " Fehler beim Generieren..." }
			: { variant: "outline-primary", text: " Download" };

	return report.status === ReportStatus.Error ? (
		<div>
			<b style={{ color: colors.highlight.h300 }}>Fehler! </b>
			<span data-testid="errorMessage" style={{ color: colors.gray.g400 }}>
				{handleErrorMessage(report)}
			</span>
		</div>
	) : (
		<SmallButton
			data-testid="downloadButton"
			onClick={onClick}
			disabled={isDisabled}
			variant={btnState.variant}
		>
			{btnState.text}
		</SmallButton>
	);
}

function handleErrorMessage(report: Report): string {
	if (report.errorMessage?.type === "wage-key-not-found") {
		return `Lohnschlüssel ${report.errorMessage.payload.taxTypeName} nicht gesetzt`;
	}

	if (report.errorMessage?.type === "wage-type-not-found")
		return `Lohnart ${report.errorMessage.payload.taxTypeName} nicht gesetzt`;

	return "Ein Fehler ist aufgetreten";
}

const Badge = styled.span<{ color: string }>`
	font-size: 0.8em;
	margin: 0 5px;
	padding: 5px;
	background-color: ${({ color }) => color};
	border-radius: 5px;
	color: white;
`;

function PublicBadge() {
	return (
		<Tooltip
			id="tooltip-badge"
			tooltip={"Dieser Report ist auf dem Arbeitgeberportal verfügbar"}
		>
			<Badge color={colors.success.s300}>Öffentlich</Badge>
		</Tooltip>
	);
}

function DraftBadge() {
	return (
		<Tooltip
			id="tooltip-badge"
			tooltip={
				"Dieser Report wurde nicht im Rahmen des Monatsabschlusses erstellt. Somit können für den Kunden wichtige Inhalte fehlen. Dieser Report wird NICHT im Arbeitgeberportal angezeigt"
			}
		>
			<Badge color={colors.gray.g400}>Entwurf</Badge>
		</Tooltip>
	);
}
