import { Icon } from "@components";
import { QueryState } from "@hooks";
import React from "react";
import { MapErrorToElementFn } from "./QueryError";
import { Spinner } from "react-bootstrap";
import Tooltip from "./Tooltip";
import { colors } from "@theme/theming";

type Props = {
	query: QueryState<any>;
	mapError?: MapErrorToElementFn;
	className?: string;
};

/**
 * Conditionally renders a small indicator informing about the state of the query
 */
export default function QueryStateIndicator(props: Props) {
	const { query, className, mapError } = props;

	if (query.state === "idle") return <></>;

	const isLoading =
		query?.state === "fetching" || query?.state === "initialFetching";
	const isSuccess = query?.state === "success";

	let icon: JSX.Element = <></>;

	if (isSuccess) {
		icon = <Icon.Success color={colors.success.s200} />;
	} else if (isLoading) {
		icon = <Spinner animation="border" size="sm" />;
	} else if (query?.state === "error") {
		const msg =
			mapError?.(query.error) ??
			"Ein Fehler ist aufgetreten: " + query.error.message;
		icon = (
			<Tooltip id="tt" tooltip={msg}>
				<Icon.Error color={colors.danger.d200} />
			</Tooltip>
		);
	}

	return <span className={className}>{icon}</span>;
}
