import React, { useMemo } from "react";
import { PageContainer, ErrorImage, ErrorAlert } from "@components";
import { MaintenanceServerError, UnauthorizedError } from "@service/errors";

export default function MaintenancePage(props: { error: unknown }) {
	const { error } = props;

	const message = useMemo(() => {
		if (
			error instanceof MaintenanceServerError ||
			error instanceof UnauthorizedError
		) {
			return error.displayText;
		}

		return "Beim Laden ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.";
	}, [error]);

	return (
		<PageContainer>
			<ErrorAlert>{message}</ErrorAlert>
			<ErrorImage />
		</PageContainer>
	);
}
