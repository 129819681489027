import { UserWithBudgetMasters } from "@models";
import React from "react";

type UsersListContextValue = {
	users: UserWithBudgetMasters[];
	triggerRefresh: () => void;
};

export default React.createContext<UsersListContextValue>({
	users: [],
	triggerRefresh: () => {},
});
