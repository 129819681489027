/* sdfgff */

import { deserializeDate } from "@util";
import { Properties } from "@util/types";
import * as yup from "yup";

export class ExcessBudget {
	private constructor(props: Properties<ExcessBudget>) {
		this.month = props.month;
		this.amounts = props.amounts;
		this.savingPeriod = props.savingPeriod;
		this.prevSavingPeriod = props.prevSavingPeriod;
	}

	readonly month: Date;
	readonly amounts: {
		readonly amountCurrentSavingPeriod: number;
		readonly amountPreviousSavingPeriod: number;
		readonly expired: number;
	};
	readonly savingPeriod: {
		readonly start: Date;
		readonly end: Date;
	};
	readonly prevSavingPeriod: {
		readonly start: Date;
		readonly end: Date;
	};

	public static fromJson(data: any): ExcessBudget {
		return new ExcessBudget(schema.validateSync(data));
	}
}

const schema = yup.object().shape({
	month: yup.date().required(),
	amounts: yup
		.object({
			amountCurrentSavingPeriod: yup.number().min(0).required(),
			amountPreviousSavingPeriod: yup.number().min(0).required(),
			expired: yup.number().min(0).required(),
		})
		.required(),
	savingPeriod: yup
		.object({
			start: yup
				.date()
				.transform((_, stringVal) => deserializeDate.date(stringVal))
				.required(),
			end: yup
				.date()
				.transform((_, stringVal) => deserializeDate.date(stringVal))
				.required(),
		})
		.required(),
	prevSavingPeriod: yup
		.object({
			start: yup
				.date()
				.transform((_, stringVal) => deserializeDate.date(stringVal))
				.required(),
			end: yup
				.date()
				.transform((_, stringVal) => deserializeDate.date(stringVal))
				.required(),
		})
		.required(),
});
