import React, { HTMLProps } from "react";
import styled from "styled-components";
import { colors } from "@theme/theming";

type PropsCheckboxToggle = HTMLProps<HTMLInputElement> & {
	label: string;
	status?: React.ReactNode;
};

const CheckboxToggle = React.forwardRef<HTMLInputElement, PropsCheckboxToggle>(
	(props: PropsCheckboxToggle, ref) => {
		const { label, status, ...inputProps } = props;
		return (
			<ToggledStyle>
				<label>
					<SwitchWrapper>
						<input type="checkbox" {...inputProps} ref={ref} />
						<Switch>
							<SwitchHandle />
						</Switch>
					</SwitchWrapper>
					{status}
					<span className="labelText">{label}</span>
				</label>
			</ToggledStyle>
		);
	}
);

export default CheckboxToggle;

const SwitchWrapper = styled.span`
	border-radius: 20px;
	cursor: pointer;
	height: 26px;
	float: left;
	overflow: hidden;
	position: relative;
	width: 48px;
	> input[type="checkbox"] {
		opacity: 0;
		position: absolute;
	}
	input[type="checkbox"]:checked {
		+ span {
			color: ${colors.white};
			background-color: ${colors.primary.p200};
			transform: translateX(50%) translateX(-19px);
		}

		:disabled {
			+ span {
				background-color: ${colors.primary.p100};
			}
		}
	}
	input[type="checkbox"] {
		+ span {
			color: ${colors.white};
			background-color: ${colors.gray.g400};
		}

		:disabled {
			+ span {
				background-color: ${colors.gray.g300};
			}
		}
	}
	input[type="checkbox"] + span {
		transform: translateX(7px);
	}
`;

const SwitchHandle = styled.span`
	background: ${colors.white};
	border-radius: 50%;
	display: inline-block;
	height: 20px;
	width: 20px;
	left: 46%;
	position: relative;
	z-index: 3;
`;

const Switch = styled.span`
	display: flex;
	align-items: center;
	height: 100%;
	left: -100%;
	position: relative;
	transition: 100ms linear;
	width: 200%;
`;

const ToggledStyle = styled.div`
	.labelText {
		margin-left: 10px;
	}

	label {
		user-select: none;
	}
`;
