import { deserializeDate } from "@util";
import { Properties } from "@util/types";

export default class Voucher {
	constructor(props: Properties<Voucher>) {
		this.voucherId = props.voucherId;
		this.userId = props.userId;
		this.budgetId = props.budgetId;
		this.contractId = props.contractId;
		this.category = props.category;
		this.reviewedBy = props.reviewedBy;
		this.reviewStatus = props.reviewStatus;
		this.reviewDate = props.reviewDate;
		this.amount = props.amount;
		this.amountAfterReview = props.amountAfterReview;
		this.amountOnVoucher = props.amountOnVoucher;
		this.amountRefunded = props.amountRefunded;
		this.currency = props.currency;
		this.dateOnVoucher = props.dateOnVoucher;
		this.description = props.description;
		this.created = props.created;
		this.updated = props.updated;
		this.isRepeatMaster = props.isRepeatMaster;
		this.repeatMasterVoucherId = props.repeatMasterVoucherId;
		this.supplierName = props.supplierName;
		this.isDraft = props.isDraft;
		this.userComment = props.userComment;
		this.backofficeComment = props.backofficeComment;
		this.sourceValue = props.sourceValue;
		this.sourceValueUnit = props.sourceValueUnit;
		this.netDeduction = props.netDeduction;
		this.type = props.type;
		this.vatAmounts = props.vatAmounts;
		this.didConfirmWeekendWork = props.didConfirmWeekendWork;
		this.budget = props.budget;
		this.divisionName = props.divisionName;
		this.customerName = props.customerName;
		this.userName = props.userName;
		this.divisionId = props.divisionId;
		this.customerId = props.customerId;
		this.userWorkingDaysPerWeek = props.userWorkingDaysPerWeek;
		this.isFromThirdParty = props.isFromThirdParty;
		this.integrityToken = props.integrityToken;
		this.fraudProbability = props.fraudProbability;
	}

	readonly voucherId: string;
	readonly userId: string;
	readonly budgetId: string | null;
	readonly contractId: string | null;
	readonly category: string;
	readonly reviewedBy: string | null;
	readonly reviewStatus: VoucherStatus;
	readonly reviewDate: Date | null;
	readonly amount: number;
	readonly amountAfterReview: number;
	readonly amountOnVoucher: number;
	readonly amountRefunded: number | null;
	readonly currency: string;
	readonly dateOnVoucher: Date;
	readonly description: string | null;
	readonly created: Date;
	readonly updated: Date;
	readonly isRepeatMaster: boolean;
	readonly repeatMasterVoucherId: string | null;
	readonly supplierName: string | null;
	readonly isDraft: boolean;
	readonly userComment: string | null;
	readonly backofficeComment: string | null;
	readonly sourceValue: number | null;
	readonly sourceValueUnit: string | null;
	readonly netDeduction: number | null;
	readonly type: string;
	readonly vatAmounts: VatAmount[] | null;
	readonly didConfirmWeekendWork: boolean | null;
	readonly budget: BudgetSummary | null;
	readonly divisionName: string;
	readonly customerName: string;
	readonly userName: string;
	readonly divisionId: string;
	readonly customerId: string;
	readonly userWorkingDaysPerWeek: number | null;
	readonly isFromThirdParty: boolean;
	readonly integrityToken: string;
	readonly fraudProbability: number | null;

	public static fromJson(data: any): Voucher {
		const created = deserializeDate.dateTime(data.created);
		const vatAmounts: Record<string, number> | null = data.vatAmounts;
		let transformedVatAmounts: VatAmount[] | null = null;
		if (vatAmounts) {
			transformedVatAmounts = Object.entries(vatAmounts).map(([k, amount]) => {
				const obj: VatAmount = { vatPercentage: k, vatAmount: amount };
				return obj;
			});
		}

		return new Voucher({
			...data,
			created: created,
			updated: data.updated ? deserializeDate.dateTime(data.updated) : created,
			dateOnVoucher: deserializeDate.date(data.dateOnVoucher),
			reviewDate: deserializeDate.nullable.dateTime(data.reviewDate),
			vatAmounts: transformedVatAmounts,
		});
	}
}

export enum VoucherStatus {
	Approved = "approved",
	Rejected = "rejected",
	InReview = "in-review",
}

export enum VoucherType {
	Receipt = "receipt",
	ContractMetadata = "contract.metadata",
	ContractMisc = "contract.misc", // ???
	/* business vehicles */
	ServiceIntervalInfo = "contract.serviceIntervalInfo",
	LicensePlateInfo = "contract.licensePlateInfo",
	/* web confirmation */
	WebInitialConfirmation = "web.initialConfirmation",
	/* periodic confirmation of contracts */
	PeriodicConfirmation = "contract.periodicConfirmation",
	/* employer financed railcards: Purchase Order and Amortization Calculation */
	RailCardAmortizationAndPurchase = "contract.railCardAmortizationAndPurchase",
}

export type VatAmount = {
	vatPercentage: string;
	vatAmount: number;
};

export type BudgetSummary = {
	remainingAmount: number;
	usedAmount: number;
	totalAmount: number;
};

export type GuidelineDoneReason = "tca" | "form" | "none";

export type VoucherReviewGuideline = {
	value: string;
	done: GuidelineDoneReason;
};

export type VoucherReviewCategory = {
	header: string;
	type: "AND" | "OR";
	guidelines: VoucherReviewGuideline[];
};

export type DuplicateVoucher = Properties<Voucher> & {
	/**
	 * ```plaintext
	 * similarity index...
	 * =25: moderate
	 * =50: substantial
	 * =75: high
	 * =100: certain
	 * >100: definite
	 * ```
	 */
	duplicateProbability: number;
};
