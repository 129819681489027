import { deserializeDate } from "@util";
import { Properties } from "@util/types";

export default class BalanceTransaction {
	constructor(props: Properties<BalanceTransaction>) {
		this.balanceTransactionId = props.balanceTransactionId;
		this.balanceAccountId = props.balanceAccountId;
		this.budgetId = props.budgetId;
		this.amount = props.amount;
		this.transactionType = props.transactionType;
		this.date = props.date;
		this.targetMonth = props.targetMonth;
	}

	readonly balanceTransactionId: string;
	readonly balanceAccountId: string;
	readonly budgetId: string | null;
	readonly amount: number;
	readonly transactionType: BalanceTransactionType;
	readonly date: Date;
	readonly targetMonth: Date | null;

	public static fromJson(data: any): BalanceTransaction {
		return new BalanceTransaction({
			...data,
			date: deserializeDate.dateTime(data["date"]),
			targetMonth: deserializeDate.nullable.date(data["targetMonth"]),
		});
	}
}

export enum BalanceTransactionType {
	Credit = "credit",
	Debit = "debit",
}
