import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, FlexRow, NoAutoFillInput, Tooltip } from "@components";
import SelectReviewStatusControl from "./SelectReviewStatusControl";
import { ReviewFilters } from "@service/review/ReviewFilters";
import SelectProductControl from "./SelectProductControl";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import DatePicker from "../../../../components/forms/DatePicker";
import { colors } from "@theme/theming";
import { FaQuestionCircle } from "react-icons/fa";

type Props = {
	activeFilters: ReviewFilters;
	onSearch: (filters: ReviewFilters) => void;
};

export default function FilterControls(props: Props) {
	const { activeFilters, onSearch } = props;
	const [filters, setFilters] = useState<ReviewFilters>(activeFilters);

	const handleChange = (changedData: Partial<ReviewFilters>) => {
		setFilters({ ...filters, ...changedData });
	};

	const handleClickMonth = (monthOffset: number) => {
		const start = startOfMonth(addMonths(new Date(), monthOffset));
		const end = endOfMonth(start);
		handleChange({ date: { start, end } });
	};

	// source of truth for the filters is the url
	useEffect(() => setFilters(activeFilters), [activeFilters]);

	const showSearchBox = location.pathname === "/vouchers";

	// TODO: collapse/expand
	return (
		<Container>
			<FilterControl>
				<label>Belegdatum:</label>
				<DatePickerContainer>
					<DatePicker
						value={filters.date?.start}
						placeholder="Von"
						canClearDate
						onClearDate={() =>
							handleChange({
								date: { ...filters.date, start: undefined },
							})
						}
						onDayChange={(date: Date) =>
							handleChange({
								date: { ...filters.date, start: date },
							})
						}
					/>
					<DatePicker
						value={filters.date?.end}
						placeholder="Bis"
						canClearDate
						onClearDate={() =>
							handleChange({
								date: { ...filters.date, end: undefined },
							})
						}
						onDayChange={(date: Date) =>
							handleChange({
								date: { ...filters.date, end: date },
							})
						}
					/>
					<MonthPicker
						variant="outline-secondary"
						size="md"
						style={{ marginRight: 4 }}
						onClick={() => handleClickMonth(-1)}
					>
						voriger Monat
					</MonthPicker>
					<MonthPicker
						variant="outline-secondary"
						size="md"
						onClick={() => handleClickMonth(0)}
					>
						dieser Monat
					</MonthPicker>
				</DatePickerContainer>
			</FilterControl>
			<StatusProductFilter gap={1}>
				<FilterControl style={{ flex: 1.5 }}>
					<label>Status:</label>
					<SelectReviewStatusControl
						selection={filters.reviewStatus ?? []}
						onSelectionChanged={(selection) =>
							handleChange({
								reviewStatus: selection.length > 0 ? selection : undefined,
							})
						}
					/>
				</FilterControl>
				<FilterControl style={{ flex: 4 }}>
					<label>Produkt:</label>
					<SelectProductControl
						selection={filters.product ?? []}
						onSelectionChanged={(selection) =>
							handleChange({
								product: selection.length > 0 ? selection : undefined,
							})
						}
					/>
				</FilterControl>
				{showSearchBox && (
					<FilterControl style={{ flex: 1 }}>
						<label>Kunden/Standorte:</label>
						<SearchTarget>
							<NoAutoFillInput
								value={filters.search ?? ""}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleChange({
										search: e.target.value,
									});
								}}
							/>
							<Tooltip
								placement="bottom"
								id="voucher-search-targets-tooltip"
								tooltip={
									<InnerTooltip>
										Filtern nach Namen der Kunden / Standorte.
										<br />
										<br />
										<b style={{ marginRight: "0.7em" }}>Kundennamen</b> werden
										durch <MonoSpaceSpan>++</MonoSpaceSpan> getrennt.
										<br />
										<b style={{ marginRight: "0.2em" }}>Standortnamen</b> werden
										durch <MonoSpaceSpan>;;</MonoSpaceSpan> getrennt.
										<br />
										<br />
										Beispiele:
										<br />
										<br />
										Kunde-1 <MonoSpaceSpan>;;</MonoSpaceSpan> Standort-a{" "}
										<MonoSpaceSpan>;;</MonoSpaceSpan> Standort-b{" "}
										<MonoSpaceSpan>++</MonoSpaceSpan>
										<br />
										Kunde-2 <MonoSpaceSpan>;;</MonoSpaceSpan> Standort-a{" "}
										<MonoSpaceSpan>++</MonoSpaceSpan>
										<br />
										Kunde-3
									</InnerTooltip>
								}
							>
								<FaQuestionCircle size="1.5rem" color={colors.gray.g400} />
							</Tooltip>
						</SearchTarget>
					</FilterControl>
				)}
				<FilterControl style={{ flex: 1 }}>
					<SearchButton size="md" onClick={() => onSearch(filters)}>
						Suchen
					</SearchButton>
				</FilterControl>
			</StatusProductFilter>
		</Container>
	);
}

const Container = styled(Card)`
	padding: 16px;
	display: flex;
	gap: 1rem;
	@media (max-width: 1500px) {
		flex-direction: column;
	}
`;

const StatusProductFilter = styled(FlexRow)`
	flex: 1;
	@media (max-width: 1000px) {
		flex-wrap: wrap;
	}
`;

const SearchTarget = styled.div`
	height: 38px;
	display: grid;
	grid-template-columns: auto 1.5rem;
	place-items: center;
	gap: 5px;
	min-width: 100px;
`;

const FilterControl = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const DatePickerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 1rem;
	@media (max-width: 900px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 560px) {
		grid-template-columns: 1fr;
	}
	> * {
		flex: 1;
		min-width: 140px;
	}
`;

const MonthPicker = styled(Button)`
	border: solid 1px ${colors.gray.g200} !important;
	margin: 0 !important;
	min-width: 140px;
	padding-inline: 10px !important;
`;

const SearchButton = styled(Button)`
	margin: 0 !important;
`;

const InnerTooltip = styled.div`
	padding: 0.5rem;
	text-align: start;
	font-size: 0.8rem;
	width: max-content;
`;

const MonoSpaceSpan = styled.span`
	font-family: monospace;
`;
