import { usePaginatedFetchedData, useServices } from "@hooks";
import { QueryOptions } from "@service/types";
import { ReviewFilters } from "@service/review/ReviewFilters";
import { useCallback, useEffect, useState } from "react";
import { BusinessTrip } from "@models";

export function useTripList(activeFilters: ReviewFilters) {
	const { tripService } = useServices();
	const [trips, setTrips] = useState<BusinessTrip[] | null>(null);

	const fetchTrips = useCallback(
		(options: QueryOptions) => tripService.queryTrips(options, activeFilters),
		[tripService, JSON.stringify(activeFilters)]
	);
	const [tripQuery, triggerTripQuery] = usePaginatedFetchedData(fetchTrips);

	useEffect(() => {
		if (tripQuery.data && tripQuery.state === "success") {
			setTrips(tripQuery.data);
		}
	}, [tripQuery.data, tripQuery.state]);

	const overrideTrip = (trip: BusinessTrip) => {
		if (trips) {
			const newTrips = trips.map((t) => (t.tripId === trip.tripId ? trip : t));
			setTrips(newTrips);
		}
	};

	const refreshTrip = useCallback(
		async (trip: BusinessTrip) => {
			const queryTrip = await tripService.getTripById(trip.tripId);
			overrideTrip(queryTrip);
		},
		[overrideTrip, tripService]
	);

	return { trips, tripQuery, triggerTripQuery, overrideTrip, refreshTrip };
}
