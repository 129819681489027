import { ExpandCard } from "@components";
import PricingRecord from "@models/PricingRecord";
import { getDateFlags } from "@util";
import { areOpenIntervalsOverlapping } from "@util/areOpenIntervalsOverlapping";
import React from "react";
import { HeaderFunctionProps, PricingCardProps } from "./IPricingCardProps";
import PricingRecordContent from "./PricingRecordContent";
import PricingRecordHeader from "./PricingRecordHeader";

export default function PricingRecordCard(
	props: PricingCardProps &
		HeaderFunctionProps & {
			isExpanded: boolean;
			onCollapse: () => void;
		}
) {
	const { index, pricingSettings, isExpanded, onCollapse } = props;

	const pricingRecord: PricingRecord = pricingSettings[index];

	const { isActive } = pricingRecord.availableFrom
		? getDateFlags(pricingRecord.availableFrom, pricingRecord.availableUntil)
		: { isActive: true };

	const checkDateOverlapError = () => {
		for (let i = 0; i < pricingSettings.length; i++) {
			if (i !== index) {
				if (
					areOpenIntervalsOverlapping(
						{
							start: pricingRecord.availableFrom,
							end: pricingRecord.availableUntil,
						},
						{
							start: pricingSettings[i].availableFrom,
							end: pricingSettings[i].availableUntil,
						}
					)
				) {
					return { message: ERROR_MESSAGE.Overlapping };
				}
			}
		}
	};

	const overlapError = checkDateOverlapError();

	return (
		<ExpandCard
			testId={"pricingSettingsCard-" + index}
			header={<PricingRecordHeader {...props} />}
			isInitiallyExpanded={isExpanded || !!overlapError}
			isActive={isActive}
			hasError={!!overlapError}
			onCollapsed={onCollapse}
		>
			<PricingRecordContent {...props} dateOverlapError={overlapError} />
		</ExpandCard>
	);
}

const ERROR_MESSAGE: Record<string, string> = {
	Overlapping: "Überschneidung mit einem anderen Datensatz",
};
