import styled from "styled-components";
import { colors } from "@theme/theming";

export const PageContainer = styled.div`
	padding: 32px;
`;

export const RelativeContainer = styled.div`
	position: relative;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const PageHeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 10px 25px;
`;

export const PageHeading = styled.h3`
	color: ${colors.text};
	font-weight: bold;
`;

export const Subheading = styled.h5`
	color: ${colors.text};
	font-weight: bold;
`;

export const NoContentContainer = styled.div`
	font-style: italic;
`;

export const MediumGrayTextContainer = styled.div`
	color: ${colors.gray.g400};
	font-style: italic;
`;
