import React from "react";
import { Pagination, QueryStateIndicators } from "@components";
import { ReviewFilters } from "./components/FilterControls";
import ReviewContext from "@components/vouchers/review/context/ReviewContext";
import { useVoucherList } from "./hooks/useVoucherList";
import VoucherList from "@components/vouchers/VoucherList";

type Props = {
	activeFilters: ReviewFilters;
};

const VoucherListWithPagination = (props: Props) => {
	const { activeFilters } = props;
	const { vouchers, voucherQuery, overrideVoucher } =
		useVoucherList(activeFilters);
	return (
		<ReviewContext.Provider
			value={{
				onApproved: overrideVoucher,
				onRejected: overrideVoucher,
				onSaved: overrideVoucher,
			}}
		>
			<QueryStateIndicators queryState={voucherQuery} />
			{vouchers && (
				<>
					<VoucherList vouchers={vouchers} />
					<Pagination queryState={voucherQuery} allowShowAll={false} />
				</>
			)}
		</ReviewContext.Provider>
	);
};

export default VoucherListWithPagination;
