import React from "react";
import styled from "styled-components";
import { FlexRow, Tooltip } from "@components";
import { User } from "@models";
import UserSalutationDropdown from "./UserSalutationDropdown";
import UserStatusIcon from "@components/users/UserStatusIcon";
import { getStatusFlags } from "@components/users/util";
import { colors } from "@theme/theming";

type Props = {
	user: User;
};

export default function UserStatusSummary({ user }: Props) {
	const status = getStatusFlags(user);

	const allEnabled = Object.values(status).every((s) => !!s);

	return (
		<Container>
			{!status.nonTestUser && (
				<StatusIcon tooltip="Test-Benutzer">
					<UserStatusIcon.TestUser />
				</StatusIcon>
			)}

			{!status.uploadAllowed && (
				<StatusIcon tooltip="Hochladen nicht erlaubt">
					<UserStatusIcon.Vouchers />
				</StatusIcon>
			)}

			{!status.active && (
				<StatusIcon tooltip="Deaktiviert">
					<UserStatusIcon.Active />
				</StatusIcon>
			)}

			{!status.contractStarted && (
				<StatusIcon tooltip="Vetragsbeginn liegt in der Zukunft">
					<UserStatusIcon.NotStarted />
				</StatusIcon>
			)}
			{!status.nonExpired && (
				<StatusIcon tooltip="Befristung abgelaufen">
					<UserStatusIcon.Expired />
				</StatusIcon>
			)}
			{!status.hasLoggedIn && (
				<StatusIcon tooltip="Benutzer noch nie angemeldet">
					<UserStatusIcon.NeverLoggedIn />
				</StatusIcon>
			)}

			{!status.salutated && (
				<>
					<StatusIcon tooltip="Nicht eingeladen">
						<UserStatusIcon.Salutated />
					</StatusIcon>

					<UserSalutationDropdown
						css={`
							margin-left: 1rem;
						`}
						user={user}
					/>
				</>
			)}

			{allEnabled && (
				<>
					<span style={{ color: colors.success.s300 }}>
						<UserStatusIcon.Ok />
					</span>{" "}
					<span style={{ color: colors.gray.g400 }}>aktiv</span>
				</>
			)}
		</Container>
	);
}

function StatusIcon(props: { tooltip: string; children: React.ReactNode }) {
	return (
		<Tooltip id="status-tooltip" tooltip={props.tooltip}>
			<CrossedIcon>{props.children}</CrossedIcon>
		</Tooltip>
	);
}

const Container = styled(FlexRow)`
	align-items: center;
	@media (max-width: 1550px) {
		flex-wrap: wrap;
		> div {
			margin-left: 0;
		}
	}
`;

const CrossedIcon = styled.span`
	color: ${colors.highlight.h300};
`;
