import { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useFetchedData, useServices } from "@hooks";
import { DivisionRouteParams } from "@service/navigation/routes";

export default function useUserSummary(userId: string) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { customerId, divisionId } = params;

	const services = useServices();

	const fetchUserDataFn = useCallback(async () => {
		const [customer, division, budgetMasters] = await Promise.all([
			services.customerService.getCustomerById(customerId),
			services.divisionService.getDivisionById(divisionId),
			services.productService.getBudgetMastersForUser(userId),
		]);
		return { customer, division, budgetMasters };
	}, [services, customerId, divisionId, userId]);

	return useFetchedData(fetchUserDataFn)[0];
}
