import classNames from "classnames";
import React from "react";
import { Pagination as PaginationBS } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { PaginatedFetchedQueryResult } from "../hooks/usePaginatedFetchedData";
import { PaginationState } from "../hooks/usePagination";
import { PaginationLimit } from "./PaginationLimit";
import { colors } from "@theme/theming";

type Props = (
	| {
			pagination: PaginationState;
	  }
	| {
			queryState: PaginatedFetchedQueryResult<any>;
	  }
) & {
	allowShowAll?: boolean;
};

export default function Pagination(props: Props) {
	let pagination: PaginationState | undefined = undefined;

	if ("pagination" in props) {
		pagination = props.pagination;
	} else if (
		props.queryState.state === "success" ||
		props.queryState.state === "fetching"
	) {
		pagination = props.queryState.pagination;
	}

	const history = useHistory();
	const location = useLocation();

	if (!pagination) return null;

	const { totalPages, currentPage, previousPage, nextPage, limit } = pagination;
	const urlToPage = (pageNo: number) => {
		const queryParams = new URLSearchParams(location.search);
		queryParams.set("page", pageNo.toString());
		if (limit) {
			queryParams.set("limit", limit.toString());
		} else {
			queryParams.set("limit", "all");
		}
		return location.pathname + "?" + queryParams.toString();
	};

	const setLimit = (limit: number | null) => {
		const queryParams = new URLSearchParams(location.search);
		queryParams.set("page", "1");
		if (limit) {
			queryParams.set("limit", limit.toString());
		} else {
			queryParams.set("limit", "all");
		}
		return history.push(location.pathname + "?" + queryParams.toString());
	};

	const items = createPageItems(currentPage, totalPages, urlToPage);

	if (currentPage > totalPages && totalPages > 0) {
		history.push(urlToPage(totalPages));
	}

	return (
		<PaginationContainer>
			<PaginationLimit
				limit={limit}
				setPaginationLimit={setLimit}
				allowShowAll={props.allowShowAll ?? true}
			/>
			<PaginationBS data-testid="pagination-items">
				<MyPageItem href={urlToPage(1)}>«</MyPageItem>
				{previousPage !== null && (
					<MyPageItem href={urlToPage(previousPage)}>‹</MyPageItem>
				)}
				{items}
				{nextPage !== null && (
					<MyPageItem href={urlToPage(nextPage)}>›</MyPageItem>
				)}
				<MyPageItem href={urlToPage(totalPages)}>»</MyPageItem>
			</PaginationBS>
		</PaginationContainer>
	);
}

function createPageItems(
	currentPage: number,
	totalPages: number,
	urlToPage: (pageNo: number) => string
) {
	let items: React.ReactNode[] = [];

	const MAX_ITEMS = 20;
	const min = Math.max(currentPage - MAX_ITEMS / 2, 1);
	const max = Math.min(currentPage + MAX_ITEMS / 2, totalPages);
	items = Array.from({ length: MAX_ITEMS }, (_, idx) => {
		const pageNo = currentPage - MAX_ITEMS / 2 + idx;
		if (pageNo >= min && pageNo <= max) {
			return (
				<MyPageItem
					key={pageNo}
					active={pageNo === currentPage}
					href={urlToPage(pageNo)}
				>
					{pageNo}
				</MyPageItem>
			);
		} else {
			return null;
		}
	});
	if (currentPage > MAX_ITEMS / 2 + 1) {
		items.unshift(
			<div className="page-link" key="skipped_low" data-testid="skipped_low">
				...
			</div>
		);
	}
	if (totalPages > MAX_ITEMS) {
		items.push(
			<div className="page-link" key="skipped_high" data-testid="skipped_high">
				...
			</div>
		);
	}
	return items;
}

function MyPageItem(props: {
	active?: boolean;
	href: string;
	children: React.ReactNode;
}) {
	return (
		<StyledPageItemLink
			className="page-link"
			to={props.href}
			$active={props.active ?? false}
		>
			<li className={classNames("page-item")}>{props.children}</li>
		</StyledPageItemLink>
	);
}

const StyledPageItemLink = styled(Link)`
	color: ${colors.primary.p200};
	&:hover {
		color: ${colors.primary.p300};
	}
	${(props: { $active: boolean }) =>
		props.$active
			? css`
					background-color: ${colors.primary.p200};
					color: ${colors.white};
			  `
			: ""};
`;

const PaginationContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: start;
`;
