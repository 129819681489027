import React from "react";
import styled from "styled-components";
import { FlexCol, SmallText, Text } from "@components";
import { Currency } from "@util";
import { Budget } from "@models";
import { ExcessBudget } from "@models/ExcessBudget";
import { DateInterval } from "@util/types";
import { format } from "date-fns";
import { MonthUsageSummary } from "@service/voucher/voucher.service";
import { formatDate } from "@components/forms/datePickerUtils";
import { utcToZonedTime } from "date-fns-tz";
import { colors } from "@theme/theming";

type Props = {
	product: Budget;
	summary: MonthUsageSummary;
};

export default function MonthlyBudgetStatisticsSection({
	product,
	summary,
}: Props) {
	const { pendingAmount, maxAmount, usedAmount, remainingAmount } = product;
	const percentUsage = Math.round((usedAmount / maxAmount) * 100) || 0;
	return (
		<div data-testid="user-statistics-section">
			<Container>
				<FlexCol>
					<CurrencyWithLabel label="Gesamtbudget" value={maxAmount} />
				</FlexCol>
				<FlexCol>
					<CurrencyWithLabel label="Verbraucht" value={usedAmount}>
						({percentUsage}%)
					</CurrencyWithLabel>
					<CurrencyWithLabel label="In Prüfung" value={pendingAmount} />
					<CurrencyWithLabel label="Offen" value={remainingAmount} />
				</FlexCol>
				<FlexCol>
					{summary.budgetCorrection?.map((b) => (
						<CurrencyWithLabel
							label="Korrekturwert"
							value={b.correctionAmount}
							description={`Für den ${formatDate(
								utcToZonedTime(b.date, "Europe/Berlin")
							)} wurde ein ${
								b.correctionAmount > 0 ? "positiver" : "negativer"
							} Korrekturwert angelegt.`}
							key={`${b.correctionAmount}#${formatDate(b.date)}`}
						/>
					))}
				</FlexCol>
				<ExcessBudgetSummary excessBudget={summary.excessBudget} />
			</Container>
			<hr style={{ marginTop: 8 }} />
		</div>
	);
}

function ExcessBudgetSummary(props: { excessBudget: ExcessBudget | null }) {
	const { excessBudget } = props;
	if (!excessBudget) {
		return null;
	}

	function formatSavingPeriod(interval: DateInterval) {
		return `${format(interval.start, "d.MM.yy")} - ${format(
			interval.end,
			"d.MM.yy"
		)}`;
	}

	return (
		<FlexCol>
			<SmallText>Überschuss-Budget</SmallText>
			<Indent amount="2rem">
				<CurrencyWithLabel
					label="Gesamtbetrag"
					description="Summe der Beträge aus aktueller und voriger Ansparphase. Dies wurde dem aktuellen Budget aufgebucht."
					value={
						excessBudget.amounts.amountPreviousSavingPeriod +
						excessBudget.amounts.amountCurrentSavingPeriod
					}
				/>
				<Indent amount="1rem">
					<CurrencyWithLabel
						label="Aktuelle Ansparphase"
						description={`Überschussbudget aus aktueller Ansparphase (${formatSavingPeriod(
							excessBudget.savingPeriod
						)}) zu Beginn des Monats`}
						value={excessBudget.amounts.amountCurrentSavingPeriod}
					/>
					<CurrencyWithLabel
						label="Restbetrag"
						description={`Überschussbudget aus vorangegangener Ansparphase (${formatSavingPeriod(
							excessBudget.prevSavingPeriod
						)}) zu Beginn des Monats`}
						value={excessBudget.amounts.amountPreviousSavingPeriod}
					/>
				</Indent>

				<CurrencyWithLabel
					label="Verfallen"
					description="Betrag, der aus dem Überschussbudget mit Wechsel in diesen Monat verfallen ist"
					value={excessBudget.amounts.expired}
				/>
			</Indent>
		</FlexCol>
	);
}

const Indent = styled.div`
	margin-left: ${(props: { amount: string }) => props.amount};
`;

function CurrencyWithLabel(props: {
	children?: React.ReactNode;
	label: string;
	description?: string;
	value: number;
}) {
	return (
		<div
			css={`
				margin-bottom: 4px;
			`}
		>
			<SmallText>
				{props.label}: {Currency.format(props.value)}
				{!!props.children && <> {props.children}</>}
			</SmallText>
			{props.description && (
				<Text
					css={`
						font-size: 0.8rem;
						color: ${colors.gray.g400};
					`}
				>
					{props.description}
				</Text>
			)}
		</div>
	);
}

const Container = styled.div`
	display: flex;
	margin-left: 1em;
	margin-top: 1em;
	margin-bottom: 1em;

	> * {
		padding: 1rem;
	}
`;
