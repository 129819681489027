import React from "react";
import { useSortQueryParams } from "@hooks";
import { Header } from "@components/TableGeneric/TableTypes";
import { SortControl } from "@components/TableGeneric/SortControl";
import styled from "styled-components";

export function SortHeader() {
	const sortQueryParams = useSortQueryParams();
	const isVoucherMode =
		new URLSearchParams(location.search).get("mode") === "vouchers";

	const headers: Header[] = [
		{
			title: "Belegdatum",
			isSortable: true,
			accessor: "date",
		},
		{
			title: "Betrag",
			isSortable: true,
			accessor: "amount",
		},
		{
			title: "Benutzer",
			isSortable: true,
			accessor: "firstname",
		},
		{
			title: "Kategorie",
			isSortable: isVoucherMode,
			accessor: "category",
		},
		{
			title: "Hochladedatum",
			isSortable: isVoucherMode,
			accessor: "created",
		},
	];

	return (
		<SortControlContainer>
			<SortControlTitle>Sortieren nach:</SortControlTitle>
			{headers.map((h) => (
				<SortControlHeaderContainer key={h.isSortable ? h.accessor : h.title}>
					<SortControl header={h} sort={sortQueryParams}>
						{h.title}
					</SortControl>
				</SortControlHeaderContainer>
			))}
		</SortControlContainer>
	);
}

const SortControlContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin: 1em 0;
`;

const SortControlTitle = styled.span`
	margin: 0 1ch 0 2ch;
	font-weight: 500;
`;

const SortControlHeaderContainer = styled.span`
	margin-right: 8px;
`;
