import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { colors } from "@theme/theming";

export default styled(Dropdown.Menu)`
	> .dropdown-item {
		&:active,
		&:active .text-muted {
			background-color: ${colors.primary.p200} !important;
			color: ${colors.white} !important;
		}
	}
`;
