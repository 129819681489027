import {
	Checkbox,
	CurrencyInput,
	Label,
	PercentageInput,
	RadioButton,
	Text,
} from "@components";
import { useStaticData } from "@hooks";
import { Division, Product } from "@models";
import PricingRecord, {
	FeePerModuleAndEmployee,
	PerModuleFeeType,
} from "@models/PricingRecord";
import { escapeCategoryId } from "../../../products/components/util";
import React, { BaseSyntheticEvent, useMemo } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { PricingCardProps } from "./IPricingCardProps";
import { useCustomField } from "./useCustomField";
import { useDivisionFromPageContext } from "../../hooks/useDivisionFromPageContext";
import { colors } from "@theme/theming";

export default function FlatFeePerModuleAndEmployeeSettings(
	props: PricingCardProps
) {
	const { control } = useFormContext<Division>();
	const { index, pricingSettings } = props;

	const pricingRecord: PricingRecord = pricingSettings[index];

	const feePerModuleAndEmployee = pricingRecord?.feePerModuleAndEmployee;

	const products = useStaticData().products;
	const defaultValue = useMemo(
		() =>
			products.reduce(
				(prev: PricingRecord["feePerModuleAndEmployee"], cur: Product) => ({
					...prev,
					[escapeCategoryId(cur["id"])]: { type: null, amount: null },
				}),
				{}
			),
		[]
	);

	const { setFieldChecked, isChecked } = useCustomField<
		PricingRecord["feePerModuleAndEmployee"]
	>(`pricingSettings.${index}.feePerModuleAndEmployee`, defaultValue);

	const checkboxClicked = (e: BaseSyntheticEvent) =>
		setFieldChecked(!!e.target?.checked);

	return (
		<>
			<Controller
				name={`pricingSettings.${index}.feePerModuleAndEmployee`}
				control={control}
				defaultValue={feePerModuleAndEmployee ?? defaultValue}
				render={({ field }) => (
					<Checkbox
						value={!!field.value}
						label={"Preis pro Mitarbeiter/Modul"}
						onChange={checkboxClicked}
					/>
				)}
			/>
			<br />
			{isChecked &&
				products.map((product) => (
					<RadioGroup
						key={product.id}
						product={product}
						pricingRecord={pricingRecord}
						index={index}
					/>
				))}
		</>
	);
}

const RadioGroup = (props: {
	product: Product<any>;
	pricingRecord: PricingRecord;
	index: number;
}) => {
	const context = useDivisionFromPageContext();
	const { division } = context;
	const savedFeePerModuleAndEmployee =
		division.pricingSettings?.length &&
		division.pricingSettings[props.index]?.feePerModuleAndEmployee;
	const { product, pricingRecord, index } = props;
	const feePerModuleAndEmployee = pricingRecord?.feePerModuleAndEmployee;
	const { setValue, control } = useFormContext<Division>();
	const escapedProductId = escapeCategoryId(product.id);
	const productFeeSettings = feePerModuleAndEmployee?.[escapedProductId];
	const feeKey =
		`pricingSettings.${index}.feePerModuleAndEmployee.${escapedProductId}` as const;
	const feeTypeKey = `${feeKey}.type` as const;
	const setFeeType = (type: PerModuleFeeType | null) => {
		const flatFeeDefaults: Record<string, number> = {
			web: 250,
			spend: 250,
			lunch: 700,
			travel: 450,
			travel_budget: 450,
			mobility: 750,
			fitAndRelax: 200,
			shortTime: 200,
			homeAndWork: 450,
			congrats: 500,
		};
		const defaultValues = {
			[PerModuleFeeType.PayOnDemand]: 10, // default: 10% pay on demand
			[PerModuleFeeType.PayPerUse]: 16, // 16% pay per use
			[PerModuleFeeType.FlatFee]: flatFeeDefaults,
			[PerModuleFeeType.FlatPerUse]: flatFeeDefaults,
			[PerModuleFeeType.FlatPerLoggedIn]: flatFeeDefaults,
		};
		const savedAmount =
			savedFeePerModuleAndEmployee &&
			savedFeePerModuleAndEmployee[escapedProductId] &&
			savedFeePerModuleAndEmployee[escapedProductId].type === type
				? savedFeePerModuleAndEmployee[escapedProductId].amount
				: null;
		switch (type) {
			case null:
				setValue(
					feeKey,
					{ type: null, amount: null },
					{
						shouldDirty: true,
					}
				);
				return;
			case PerModuleFeeType.FlatFee:
			case PerModuleFeeType.FlatPerUse:
			case PerModuleFeeType.FlatPerLoggedIn:
				setValue(
					feeKey,
					{
						type,
						amount: savedAmount ?? defaultValues[type][escapedProductId] ?? 500,
					} as FeePerModuleAndEmployee,
					{
						shouldDirty: true,
					}
				);
				return;
			default:
				setValue(
					feeKey,
					{
						type,
						amount: savedAmount ?? defaultValues[type],
					} as FeePerModuleAndEmployee,
					{
						shouldDirty: true,
					}
				);
				return;
		}
	};

	// TODO: fix typing with escaped identifiers
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const type = productFeeSettings?.type as any;
	const amount: any = productFeeSettings?.amount || 0;

	const payingOptions = [
		{
			label: "Pay Per Use",
			description: "% des abgerufenen Budgets",
			type: PerModuleFeeType.PayPerUse,
		},
		{
			label: "Pay on Demand",
			description: "% des gebuchten Budgets",
			type: PerModuleFeeType.PayOnDemand,
		},
		{
			label: "Fixpreis pro Mitarbeiter pro Modul",
			description: "€ pro Mitarbeiter pro Modul",
			type: PerModuleFeeType.FlatFee,
		},
		{
			label: "Fixpreis pro Mitarbeiter pro aktiv genutztem Modul",
			description:
				"€ pro Mitarbeiter pro Modul, wenn das abgerufene Budget des Moduls > 0",
			type: PerModuleFeeType.FlatPerUse,
		},
		{
			label: "Fixpreis pro angemeldetem Mitarbeiter pro Modul",
			description:
				"€ pro Mitarbeiter pro Modul. Es werden nur Nutzer berechnet, die sich mindestens einmal angemeldet haben.",
			type: PerModuleFeeType.FlatPerLoggedIn,
		},
	];

	return (
		<FormGroup
			key={product.id}
			as={Row}
			style={{ marginLeft: 7, borderTop: "1px solid lightgrey" }}
		>
			<Label sm={3} column>
				{product.name}
			</Label>
			<Col sm={9}>
				<Controller
					name={feeTypeKey}
					control={control}
					defaultValue={type}
					render={({ field: feeTypeField }) => (
						<>
							{payingOptions.map((option, idx) => (
								<React.Fragment key={idx}>
									<RadioButton
										{...feeTypeField}
										label={option.label}
										value={productFeeSettings?.type === option.type}
										onChange={() => setFeeType(option.type)}
									/>
									{productFeeSettings?.type === option.type && (
										<SettingContainer>
											<Row>
												<Col sm={5}>
													<Controller
														name={`${feeKey}.amount`}
														control={control}
														defaultValue={amount}
														render={({ field }) => {
															if (
																option.type === PerModuleFeeType.FlatFee ||
																option.type === PerModuleFeeType.FlatPerUse ||
																option.type === PerModuleFeeType.FlatPerLoggedIn
															) {
																return (
																	<CurrencyInput
																		{...field}
																		value={field.value ?? 0}
																		min={0.0}
																	/>
																);
															}
															return (
																<PercentageInput
																	{...field}
																	value={field.value ?? 0}
																	min={0.0}
																/>
															);
														}}
													/>
												</Col>
											</Row>
											<DescriptionText>{option.description}</DescriptionText>
										</SettingContainer>
									)}
								</React.Fragment>
							))}
							<RadioButton
								{...feeTypeField}
								label="abgewählt"
								value={!productFeeSettings?.type}
								onChange={() => setFeeType(null)}
							/>
						</>
					)}
				/>
			</Col>
		</FormGroup>
	);
};

const DescriptionText = styled(Text)`
	font-size: 0.8em;
	color: ${colors.gray.g400};
`;

const SettingContainer = styled.div`
	margin-left: 1rem;
	margin-bottom: 0.8rem;
`;
