import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPaginationStateFromSearchParams } from "./utils";

export type OrderDirection = "asc" | "desc" | undefined;

export type SortChangeHandler = (
	sort: string,
	sortDirection: OrderDirection
) => void;

export type SortState = {
	by: string | undefined;
	order: OrderDirection;
	setSortState: SortChangeHandler;
};

export default function useSortQueryParams(): SortState {
	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { sort: sortFromUrl, order: orderFromUrl } =
		getPaginationStateFromSearchParams(queryParams);

	const setSortState = useCallback(
		(sort: string, sortDirection: OrderDirection) => {
			const queryParams = new URLSearchParams(location.search);
			queryParams.set("sort", sort);
			queryParams.set("sortDirection", sortDirection ?? "");
			return history.push(location.pathname + "?" + queryParams.toString());
		},
		[history, location.pathname, location.search]
	);

	return {
		by: sortFromUrl ?? undefined,
		order: orderFromUrl as OrderDirection,
		setSortState,
	};
}
