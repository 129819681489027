import _ from "lodash";
import React from "react";
import { CSVLink } from "react-csv";

export type CSVDataAndHeader = {
	accessor: string;
	title: string;
	data: string[];
};

interface Props {
	children: React.ReactNode;
	csvData: CSVDataAndHeader[];
	testId: string;
	fileName?: string;
}

export const DownloadableCSV = (props: Props) => {
	const headers = props.csvData.map((h) => h.title);
	const data = _.zip(...props.csvData.map((h) => h.data));
	return (
		<CSVLink
			data-testid={props.testId}
			enclosingCharacter={``}
			filename={(props.fileName || "beispiel-datei") + ".csv"}
			separator={";"}
			data={[headers, ...data]}
		>
			{props.children}
		</CSVLink>
	);
};
