import { Button } from "react-bootstrap";
import styled from "styled-components";
import { buttonStyleMixin } from "./Button";

export const SmallButton = styled(Button).attrs((props) => ({
	variant: props.variant || "outline-primary",
	size: "sm",
}))`
	${buttonStyleMixin}
`;
