import { addDays, addMonths, startOfMonth } from "date-fns";
import { CongratsSettings, NewBudgetMaster, Product, User } from "@models";

export default function createDefaultBudgetMaster(
	product: Product,
	user: User,
	hasActiveBudgetMaster?: boolean
): NewBudgetMaster {
	const defaultVariant = product.variants[0];

	const availableFrom = hasActiveBudgetMaster
		? addMonths(startOfMonth(new Date()), 1)
		: startOfMonth(new Date());

	const settings = product.getDefaultSettings();
	const availableUntil =
		product.id === "congrats"
			? addDays(
					availableFrom,
					(settings as CongratsSettings).validTimeInDays - 1
			  )
			: null;

	return {
		availableFrom,
		availableUntil,
		amounts: product.getDefaultAmounts(defaultVariant.id),
		userId: user.userId,
		balanceAccountId: null,
		categories: product.getDefaultCategories(defaultVariant.id),
		category: product.id,
		productVariantId: defaultVariant.id,
		settings,
		useBalanceAccount: false,
		useExcessBudget: false,
	};
}
