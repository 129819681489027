import * as apiService from "../api.service";
import { AssetDivision } from "@models/AssetDivision";
import { UpsertAssetDivisionError } from "./errors";

export async function getAssetDivisionById(
	divisionId: string
): Promise<AssetDivision | undefined> {
	const assetDivision = await apiService.GET("asset/division/" + divisionId);
	return assetDivision.data && AssetDivision.fromJson(assetDivision.data);
}

export async function upsertAssetDivision(assetDivision: AssetDivision) {
	try {
		await apiService.PUT(`asset/division`, assetDivision);
	} catch (error) {
		throw new UpsertAssetDivisionError();
	}
}
