import BudgetMaster from "../models/BudgetMaster";
import useStaticData from "./useStaticData";

export default function useBudgetMasterProduct(
	budgetMaster: Pick<BudgetMaster, "category" | "productVariantId">
) {
	const { products } = useStaticData();

	const product = products.find((p) => p.id === budgetMaster.category);
	if (!product) {
		throw new Error(`Product with id ${budgetMaster.category} not found`);
	}

	const variant = product.variants.find(
		(v) => v.id === budgetMaster.productVariantId
	);
	if (!variant) {
		throw new Error(
			`Variant ${budgetMaster.productVariantId} does not exist on product Product with id ${budgetMaster.category}`
		);
	}

	return { product, variant };
}
