import axios, { AxiosRequestConfig } from "axios";
import { getAuthorizationHeader } from "./auth/initAuth";
import { envConfig } from "./config";
import { MaintenanceServerError, UnauthorizedError } from "./errors";
import { InternalServerError, NetworkError } from "./errors";

export type AuthFailedHandler = () => Promise<void>;

const apiClient = axios.create({
	baseURL: envConfig.apiBaseUrl + envConfig.apiUrlPrefix,
});

apiClient.interceptors.request.use(
	async (config) => {
		if (
			!(config.auth as any)?.isPublicRoute &&
			config.headers &&
			!config.headers.Authorization
		) {
			config.headers.Authorization = await getAuthorizationHeader();
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Handles maintenance mode, else reject the error
apiClient.interceptors.response.use(
	(response) => response,
	(error) => {
		if (!error.response) {
			if (error.message === "Network Error") {
				throw new NetworkError();
			}

			throw error;
		}

		const response = error.response.data;

		if (response.statusCode === 403) {
			throw new UnauthorizedError();
		}

		if (
			response.statusCode === 503 &&
			response.message === "maintenance-mode"
		) {
			throw new MaintenanceServerError();
		}

		if (response.statusCode === 500) {
			throw new InternalServerError();
		}

		throw error;
	}
);

export async function api(path: string, options: Partial<AxiosRequestConfig>) {
	const defaultOptions: Partial<AxiosRequestConfig> = {
		method: "GET",
	};

	const fetchOptions = { ...defaultOptions, ...options };

	const response = await apiClient.request({ ...fetchOptions, url: path });
	return response;
}

export async function GET(path: string, options?: Partial<AxiosRequestConfig>) {
	return api(path, { ...options, method: "GET" });
}

export async function DELETE(
	path: string,
	data?: any,
	options?: Partial<AxiosRequestConfig>
) {
	return api(path, { ...options, method: "DELETE", data });
}

export async function POST(
	path: string,
	data?: any,
	options?: Partial<AxiosRequestConfig>
) {
	return api(path, { ...options, method: "POST", data });
}

export async function PUT(
	path: string,
	data?: any,
	options?: Partial<AxiosRequestConfig>
) {
	return api(path, { ...options, method: "PUT", data });
}
