import { Properties } from "@util/types";
import BudgetCategory from "./BudgetCategory";
import Category from "./Category";
import Product from "./Product";

export default class ProductVariant {
	constructor(props: Omit<Properties<ProductVariant>, "product">) {
		this.id = props.id;
		this.budgetCategories = props.budgetCategories;
	}

	readonly id: string;
	readonly budgetCategories: BudgetCategory[];

	private _product: Product<any> | undefined;

	get product(): Product<any> {
		if (!this._product) {
			throw new Error(
				`Product for variant ${this.id} has not been initialized`
			);
		}
		return this._product;
	}

	/**
	 * @private do not call directly, should only be called by the Product
	 */
	set product(product: Product<any>) {
		if (this._product) {
			throw new Error(
				`Product for variant ${this.id} has already been initialized`
			);
		}
		this._product = product;
		this.budgetCategories.forEach((c) => (c.product = product));
	}

	static fromJson(data: any): ProductVariant {
		return new ProductVariant({
			...data,
			budgetCategories: data.budgetCategories.map(BudgetCategory.fromJson),
		});
	}

	findCategory(categoryId: string): Category | undefined {
		for (const category of this.budgetCategories) {
			if (category.id === categoryId) {
				return category;
			}

			const found = category.findCategory(categoryId);
			if (found) return found;
		}
		return undefined;
	}
}
