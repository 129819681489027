import React from "react";
import MonthlyBudgetStatisticsSection from "./MonthlyBudgetStatisticsSection";
import { useMonthSummaryQuery } from "@hooks";
import { QueryStateIndicators } from "@components";
import { Budget, BudgetMaster } from "@models";
import MonthlyList from "./components/MonthlyList";

type Props = {
	product: Budget | BudgetMaster;
	date: Date;
};

export default function MonthSummaryDetails(props: Props) {
	const { product, date } = props;

	const monthSummaryQuery = useMonthSummaryQuery(
		product instanceof Budget ? product.budgetId : product.budgetMasterId,
		date
	);
	const isTravel = product.category.startsWith("travel");

	return (
		<>
			{monthSummaryQuery.state === "success" && (
				<>
					{product instanceof Budget && (
						<MonthlyBudgetStatisticsSection
							product={product}
							summary={monthSummaryQuery.data}
						/>
					)}
					{isTravel ? (
						<MonthlyList trips={monthSummaryQuery.data.trips} />
					) : (
						<>
							<MonthlyList contracts={monthSummaryQuery.data.contracts} />
							<MonthlyList vouchers={monthSummaryQuery.data.vouchers} />
						</>
					)}
				</>
			)}
			<QueryStateIndicators queryState={monthSummaryQuery} />
		</>
	);
}
