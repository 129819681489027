import { deserializeDate, serializeDate } from "@util";
import { Properties } from "@util/types";

export class Meals {
	constructor(props: Properties<Meals>) {
		this.date = props.date;
		this.breakfast = props.breakfast;
		this.lunch = props.lunch;
		this.dinner = props.dinner;
	}

	readonly date: Date;
	readonly breakfast: MealPaymentType;
	readonly lunch: MealPaymentType;
	readonly dinner: MealPaymentType;

	static convertForJson(meals: Properties<Meals>): any {
		return {
			...meals,
			date: serializeDate.date(meals.date),
		};
	}

	static fromJson(data: any) {
		function getMealPaymentType(paymentType: string) {
			switch (paymentType) {
				case "employee":
					return MealPaymentType.Employee;
				case "employer":
					return MealPaymentType.Employer;
				default:
					throw new Error(`Unknown meal payment type - ${paymentType}`);
			}
		}
		return new Meals({
			date: deserializeDate.date(data.date),
			breakfast: getMealPaymentType(data.breakfast),
			lunch: getMealPaymentType(data.lunch),
			dinner: getMealPaymentType(data.dinner),
		});
	}
}

export enum MealPaymentType {
	Employer = "employer",
	Employee = "employee",
}
