import { Properties } from "@util/types";

export class RouteLeg {
	constructor(props: Properties<RouteLeg>) {
		this.transportation = props.transportation;
	}

	readonly transportation: Transportation;

	static fromJson(data: any) {
		function getTransportation(data: any): Transportation {
			const transportationMode = data.transportation.mode;
			switch (transportationMode) {
				case "privateVehicle":
					return {
						mode: TransportationType.PrivateVehicle,
						distance: data.transportation.distance,
						overrideDistance: data.transportation.overrideDistance
							? {
									distance: data.transportation.overrideDistance.distance,
									reason: data.transportation.overrideDistance.reason,
							  }
							: null,
					};
				case "businessVehicle":
					return {
						mode: TransportationType.BusinessVehicle,
						distance: data.transportation.distance,
						overrideDistance: data.transportation.overrideDistance
							? {
									distance: data.transportation.overrideDistance.distance,
									reason: data.transportation.overrideDistance.reason,
							  }
							: null,
					};
				case "noCosts":
					return {
						mode: TransportationType.NoCosts,
					};
				case "other":
					return {
						mode: TransportationType.Other,
						voucherId: data.transportation.voucherId,
					};
				default:
					throw new Error(
						`Unknown transportation mode - ${transportationMode} in route leg`
					);
			}
		}
		return new RouteLeg({ transportation: getTransportation(data) });
	}
}

export type OverrideDistance = {
	distance: number;
	reason: string;
};

export type Transportation =
	| {
			mode:
				| TransportationType.PrivateVehicle
				| TransportationType.BusinessVehicle;
			distance: number;
			overrideDistance: OverrideDistance | null;
	  }
	| {
			mode: TransportationType.NoCosts;
	  }
	| {
			mode: TransportationType.Other;
			voucherId: string;
	  };

export enum TransportationType {
	PrivateVehicle,
	BusinessVehicle,
	Other,
	NoCosts,
}
