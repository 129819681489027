import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FlexCol, Icon, FlexRow } from "@components";
import { Voucher } from "@models";
import DuplicateReviewModal from "./DuplicateReviewModal";
import { useDuplicateVoucherCheck } from "../hooks/useDuplicateVoucherCheck";
import { colors } from "@theme/theming";
import { FieldValues, useFormContext } from "react-hook-form";

type Props = {
	voucher: Voucher;
	onReject?: () => void;
};

export default function DuplicateWarning(props: Props) {
	const { voucher, onReject } = props;
	const [openDuplicateModal, setOpenDuplicateModal] = useState(false);

	const { watch } = useFormContext<FieldValues>();
	const { amountAfterReview, dateOnVoucher, supplierName } = watch();

	const [debouncedValues, setDebouncedValues] = useState({
		amountAfterReview,
		dateOnVoucher,
		supplierName,
	});

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValues({
				amountAfterReview,
				dateOnVoucher,
				supplierName,
			});
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [amountAfterReview, dateOnVoucher, supplierName]);

	const memoizedVoucher = useMemo(() => {
		return {
			...voucher,
			...debouncedValues,
		};
	}, [voucher, debouncedValues]);

	const duplicateVouchers = useDuplicateVoucherCheck(memoizedVoucher);
	const openModal = () => setOpenDuplicateModal(true);

	return (
		<>
			{duplicateVouchers && duplicateVouchers.length > 0 ? (
				<AlertBox
					css={`
						cursor: pointer;
					`}
					onClick={openModal}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							e.preventDefault();
							openModal();
						}
					}}
					tabIndex={0}
				>
					<FlexCol>
						<FlexRow>
							<Icon.Warning
								css={`
									width: 20px;
									height: 20px;
									margin-right: 6px;
									color: ${colors.caution.c300};
								`}
							></Icon.Warning>

							<AlertBold>Es gibt potentiell Duplikate.</AlertBold>
						</FlexRow>
						<FlexRow>
							<AlertRegular>
								Gefunden: {duplicateVouchers.length}
								<br />
								Höchste Wahrscheinlichkeit:{" "}
								{duplicateVouchers[0].duplicateProbability}%
								<br />
								Bitte prüfen Sie die potentiellen Duplikate.
							</AlertRegular>
						</FlexRow>
					</FlexCol>
				</AlertBox>
			) : null}

			{!!(
				openDuplicateModal &&
				duplicateVouchers &&
				duplicateVouchers.length
			) && (
				<DuplicateReviewModal
					duplicateVouchers={duplicateVouchers}
					voucher={voucher}
					onReject={onReject}
					onClose={() => setOpenDuplicateModal(false)}
				/>
			)}
		</>
	);
}

const AlertBold = styled.h3`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: ${colors.caution.c400};
`;

const AlertRegular = styled.span`
	font-weight: 400;
	font-size: 14px;
	color: ${colors.caution.c400};
`;

const AlertBox = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	margin: 10px 0;
	gap: 12px;
	background: ${colors.caution.c100}40;
	border: 1px solid ${colors.caution.c400};
	border-radius: 0.25rem;
	grid-column: span 2 / span 2;
`;
