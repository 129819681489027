import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PaginationResult } from "@service/types";
import { getPaginationStateFromSearchParams } from "./utils";
import { OrderDirection } from "@hooks";

export type PaginationState = {
	totalPages: number;
	currentPage: number;
	previousPage: number | null;
	nextPage: number | null;
	limit: number | null;
	sort: string | undefined;
	order: OrderDirection;
	filter: Record<string, string> | null;
};

const DEFAULT_PAGINATION_SIZE = 15;

type RegisterPaginationResultFn = (result: PaginationResult<any>) => void;
type GoToPageFn = (page: number) => void;

export default function usePagination(): [
	PaginationState,
	RegisterPaginationResultFn,
	GoToPageFn
] {
	const history = useHistory();
	const location = useLocation();
	const search = new URLSearchParams(location.search);

	const { limit: urlLimit, page: urlPage } =
		getPaginationStateFromSearchParams(search);
	const hasPaginationParams = !!urlPage && !!urlLimit;
	const [isInitialRun, setInitialRun] = useState(true);

	const [state, setState] = useState<PaginationState>(() => {
		const {
			sort,
			order,
			limit: urlLimit,
			page: urlPage,
			filter,
		} = getPaginationStateFromSearchParams(search);

		const initialState = {
			totalPages: 0,
			currentPage: urlPage ? Number.parseInt(urlPage, 10) : 1,
			previousPage: null,
			nextPage: null,
			limit:
				urlLimit === "all"
					? null
					: urlLimit !== null
					? Number.parseInt(urlLimit, 10)
					: DEFAULT_PAGINATION_SIZE,
			sort: sort,
			order: (order ?? "asc") as OrderDirection,
			filter,
		};
		return initialState;
	});

	useEffect(() => {
		if (isInitialRun) {
			// avoid back-and-forth requests when setting the default query params
			setInitialRun(false);
		} else {
			const {
				sort,
				order,
				limit: urlLimit,
				page: urlPage,
				filter,
			} = getPaginationStateFromSearchParams(
				new URLSearchParams(location.search)
			);

			const newState: Partial<PaginationState> = {};

			if (urlPage) {
				newState.currentPage = Number.parseInt(urlPage, 10);
			}
			if (urlLimit && urlLimit !== "all") {
				newState.limit = Number.parseInt(urlLimit, 10);
			} else {
				newState.limit = null;
			}

			if (sort && order) {
				newState.sort = sort;
				newState.order = (order ?? "asc") as OrderDirection;
			}
			newState.filter = filter;
			setState((state) => ({ ...state, ...newState }));
		}
	}, [location.search, isInitialRun, setInitialRun]);

	const setPaginationResult = useCallback((result: PaginationResult<any>) => {
		setState((state) => ({
			currentPage: state.currentPage,
			nextPage: result.nextPage,
			previousPage: result.previousPage,
			totalPages: result.limit
				? Math.ceil(result.totalRecords / result.limit)
				: 1,
			limit: state.limit,
			sort: state.sort,
			order: state.order,
			filter: state.filter,
		}));
	}, []);

	if (!hasPaginationParams) {
		search.set("page", "1");
		search.set("limit", DEFAULT_PAGINATION_SIZE.toString());

		history.replace({ ...location, search: "?" + search.toString() });
	}

	const goToPage = (page: number) => {
		const search = new URLSearchParams(location.search);
		search.set("page", page.toString());
		history.replace({ ...location, search: "?" + search.toString() });
	};
	return [state, setPaginationResult, goToPage];
}
