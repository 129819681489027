import { useCallback } from "react";
import { QueryState, useFetchedData, useServices } from "@hooks";
import { DomainEvent } from "@models/DomainEvent";

export type Entities = "trip" | "voucher";

export default function useEntityHistory(
	entityId: string,
	entity: Entities
): QueryState<DomainEvent[]> {
	const { eventService } = useServices();

	const voucherHistoryQueryFn = useCallback(async () => {
		return await eventService.getEntityHistory(entity, entityId);
	}, []);

	return useFetchedData(voucherHistoryQueryFn)[0];
}
