import React from "react";
import { colors } from "@theme/theming";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from "./icons";

type Props = {
	to: string;
	text: string;
	hideArrow?: boolean;
	openInNewTab?: boolean;
};

export default (props: Props) => {
	return (
		<StyledLink
			to={props.to}
			target={props.openInNewTab ? "_blank" : undefined}
		>
			{props.text}
			{!props.hideArrow && <Icon.Link />}
		</StyledLink>
	);
};

const StyledLink = styled(Link)`
	color: ${colors.primary.p200};
	font-weight: 700;
	font-size: 0.85rem;
	display: flex;
	column-gap: 0.4rem;
	align-items: center;
	&:hover {
		color: ${colors.primary.p300};
	}
`;
