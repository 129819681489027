import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RootProductId, Voucher } from "@models";
import VoucherReviewImage from "./VoucherReviewImage";
import { useCategory } from "@hooks";
import { FaMoneyBillWave as AmountAfterReviewIcon } from "react-icons/fa";
import {
	FlexCol,
	Icon,
	FlexRow,
	NoTranslate,
	CategoryCell,
	IconContainer,
	ProductIcon,
	StatusIcon,
} from "@components";
import { Currency, formatCategoryName } from "@util";
import ContractVoucherMarker from "@components/vouchers/ContractVoucherMarker";
import { colors } from "@theme/theming";
import Routes from "@service/navigation/routes";
import UserComment from "./UserComment";

type Props = {
	voucher: Voucher;
	actionArea: JSX.Element;
};

export default function DuplicateVoucherColumn({ voucher, actionArea }: Props) {
	const category = useCategory(voucher.category);
	const userLink = Routes.UserDetails.makePath({
		customerId: voucher.customerId,
		divisionId: voucher.divisionId,
		userId: voucher.userId,
	});

	return (
		<DuplicateVoucherColumnContainer>
			<ComparisonCol>
				<ComparisonColHeader>
					<HeaderTop>
						<UserLink
							target="_blank"
							to={userLink ?? "#"}
							title="Benutzerseite aufrufen"
						>
							<Icon.Link />
							<NoTranslate>{voucher.userName}</NoTranslate>
						</UserLink>
						<ReviewedAmount>
							<AmountAfterReviewIcon />
							{Currency.format(voucher.amountAfterReview)}
							<VoucherStatusIconContainer>
								<StatusIcon voucher={voucher} />
							</VoucherStatusIconContainer>
						</ReviewedAmount>
					</HeaderTop>
					<CategoryInfo>
						<CategoryCell>
							<StyledIconContainer>
								<ProductIcon productId={category.root.id as RootProductId} />
							</StyledIconContainer>
							<CategoryDetails>
								<div>{formatCategoryName(category)}</div>
								{voucher.contractId && (
									<ContractVoucherMarker voucher={voucher} />
								)}
							</CategoryDetails>
						</CategoryCell>
					</CategoryInfo>
					<UserComment
						style={{ marginBottom: 0 }}
						comment={voucher.userComment ?? undefined}
					/>
				</ComparisonColHeader>
				<ImageContainer>
					<VoucherReviewImage style={{ width: "100%" }} voucher={voucher} />
				</ImageContainer>
			</ComparisonCol>
			{actionArea}
		</DuplicateVoucherColumnContainer>
	);
}

const DuplicateVoucherColumnContainer = styled.div`
	display: grid;
	grid-template-rows: calc(85vh - 39px) 39px;
	grid-template-columns: 100%;
	width: calc(50% - 16px);
	gap: 5px;
`;

const ComparisonCol = styled(FlexCol)`
	width: 100%;
	height: 100%;
	justify-content: space-between;
	gap: 5px;
	overflow: hidden;
`;

const ComparisonColHeader = styled(FlexRow)`
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
`;

const HeaderTop = styled(FlexRow)`
	justify-content: space-between;
	width: 100%;
	align-items: center;
	gap: 10px;
`;

const UserLink = styled(Link)`
	align-items: center;
	display: flex;

	svg {
		margin-right: 0.5ch;
	}
`;

const ReviewedAmount = styled(FlexRow)`
	align-items: center;
	color: ${colors.gray.g300};

	& > svg {
		width: 1rem;
		height: 1rem;
		margin-right: 0.5ch;
	}
`;

const CategoryInfo = styled(FlexRow)`
	margin-right: auto;
	height: 50px;
	font-size: 14px;
	display: flex;
	justify-content: center;

	svg {
		margin-right: 0.5ch;
	}
`;

const CategoryDetails = styled(FlexCol)`
	display: flex;
	flex-direction: column;
`;

const ImageContainer = styled(FlexRow)`
	flex: 1 0 auto;
`;

const StyledIconContainer = styled(IconContainer)`
	display: grid;
	place-items: center;
	margin-right: 0;
`;

const VoucherStatusIconContainer = styled(StyledIconContainer)`
	font-size: 1.5rem;
	margin-left: 0.5ch;
`;
