import React from "react";
import styled, { css } from "styled-components";

type Props = {
	/** this can be the `formState.errors.X` field from `react-hook-forms` */
	error?: {
		message?: string;
	};
	children: React.ReactNode;
};

/**
 * Wraps input components, shows an Error with an error message if the formState has an error for the field
 */
export default function InputValidation(props: Props) {
	const { error, children } = props;
	return (
		<ErrorContainer hasError={!!error}>
			{children}
			{error?.message && <ErrorText>{error?.message}</ErrorText>}
		</ErrorContainer>
	);
}

const ErrorContainer = styled.div`
	${({ hasError }: { hasError: boolean }) =>
		hasError &&
		css`
			border: 2px dashed red;
			padding: 4px 8px;
			border-radius: 4px;
			margin: 4px 8px;
		`}
`;

const ErrorText = styled.div`
	font-size: 0.8rem;
	color: red;
`;
