import { Voucher, BusinessTrip } from "@models";
import { EditableTrip } from "@models/travel/EditableTrip";
import {
	TripConflictError,
	VoucherConflictError,
} from "@service/review/errors";
import axios from "axios";
import * as apiService from "../api.service";
import { EditableVoucher } from "@models/EditableVoucher";
import { MonthClosedForChangeError } from "@service/common/errors";
import { checkKnownError } from "@service/util";

export const voucher = {
	save: saveVoucher,
	approve: approveVoucher,
	reject: rejectVoucher,
};

export const trip = {
	approve: approveTrip,
	save: saveTrip,
	reject: rejectTrip,
	reopen: reopenTrip,
};

async function saveVoucher(
	voucherId: string,
	voucher: EditableVoucher,
	reviewDuration: number,
	integrityToken: string
): Promise<Voucher> {
	try {
		const responseData = await apiService.PUT(`voucher/${voucherId}`, {
			voucher: EditableVoucher.convertForJson(voucher),
			reviewDuration,
			integrityToken,
		});

		return Voucher.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new VoucherConflictError();
			}
		}
		throw e;
	}
}

async function approveVoucher(
	voucherId: string,
	voucher: EditableVoucher,
	reviewDuration: number,
	integrityToken: string
): Promise<Voucher> {
	try {
		const responseData = await apiService.PUT(`voucher/${voucherId}/approve`, {
			voucher: EditableVoucher.convertForJson(voucher),
			reviewDuration,
			integrityToken,
		});

		return Voucher.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new VoucherConflictError();
			}
		}
		throw e;
	}
}

async function rejectVoucher(
	voucherId: string,
	voucher: EditableVoucher,
	reason: string | null,
	reviewDuration: number,
	integrityToken: string
): Promise<Voucher> {
	try {
		const responseData = await apiService.PUT(`voucher/${voucherId}/reject`, {
			voucher: EditableVoucher.convertForJson(voucher),
			reason,
			reviewDuration,
			integrityToken,
		});

		return Voucher.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new VoucherConflictError();
			}
		}
		throw e;
	}
}

async function approveTrip(
	tripId: string,
	trip: EditableTrip
): Promise<BusinessTrip> {
	try {
		const responseData = await apiService.PUT(`trip/${tripId}/approve`, {
			trip: EditableTrip.convertForJson(trip),
		});

		return BusinessTrip.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new TripConflictError();
			}
		}
		throw e;
	}
}

async function reopenTrip(tripId: string): Promise<BusinessTrip> {
	try {
		const responseData = await apiService.PUT(`trip/${tripId}/reopen`);
		return BusinessTrip.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new TripConflictError();
			}
		}
		throw e;
	}
}

async function saveTrip(
	tripId: string,
	trip: EditableTrip
): Promise<BusinessTrip> {
	try {
		const responseData = await apiService.PUT(
			`trip/${tripId}`,
			EditableTrip.convertForJson(trip)
		);

		return BusinessTrip.fromJson(responseData.data);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new TripConflictError();
			}
		}
		throw e;
	}
}

async function rejectTrip(
	tripId: string,
	trip: EditableTrip,
	reason: string | null
): Promise<BusinessTrip> {
	try {
		const responseData = await apiService.PUT(`trip/${tripId}/reject`, {
			reason,
			trip: EditableTrip.convertForJson(trip),
		});

		return BusinessTrip.fromJson(responseData.data);
	} catch (e: any) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 409) {
				checkKnownError(e, MonthClosedForChangeError);

				throw new TripConflictError();
			}
		}
		throw e;
	}
}
