import { NewBalanceAccount } from "@models";
import { getErrorFromResponse } from "@util/errors";
import { BalanceAccountUpdateType } from "models/BalanceAccount";
import * as apiService from "../api.service";
import { BalanceAccountInactiveError } from "./errors";

type BalanceAccountUpdate = {
	userId: string;
	balanceAccountId: string;
	updateType: BalanceAccountUpdateType;
	amount: number;
};

export async function createBalanceAccountForUser(
	userId: string,
	balanceAccount: NewBalanceAccount
) {
	await apiService.POST(`user/${userId}/balanceAccount`, balanceAccount);
}

export async function updateBalanceAccount(
	balanceAccountUpdate: BalanceAccountUpdate
) {
	const { updateType, amount, userId, balanceAccountId } = balanceAccountUpdate;
	const payload = { updateType, amount };
	await apiService.PUT(
		`user/${userId}/balanceAccount/${balanceAccountId}`,
		payload
	);
}

export async function updateBalanceBudgets(
	userId: string,
	balanceAccountId: string
) {
	try {
		await apiService.PUT(
			`user/${userId}/balanceAccount/${balanceAccountId}/balanceBudgets`
		);
	} catch (error) {
		const { type } = getErrorFromResponse(error);
		switch (type) {
			case "balance-account-inactive":
				throw new BalanceAccountInactiveError();
			default:
				throw error;
		}
	}
}

export async function updateBalanceAccountStatus(
	userId: string,
	balanceAccountId: string,
	isBalanceAccountActive: boolean
) {
	const payload = { isBalanceAccountActive };
	await apiService.PUT(
		`user/${userId}/balanceAccount/${balanceAccountId}/status`,
		payload
	);
}
