import styled from "styled-components";
import { DropdownButton } from "react-bootstrap";
import { colors } from "@theme/theming";

export default styled(DropdownButton)`
	.btn-outline-primary {
		background-color: ${colors.white} !important;
		border-color: ${colors.primary.p200} !important;
		color: ${colors.primary.p200} !important;
		&:hover,
		&:active,
		&:focus {
			border-color: ${colors.primary.p200} !important;
			background: ${colors.primary.p200} !important;
			box-shadow: 0 0 0 0.2rem ${colors.primary.p200}80 !important;
			color: ${colors.white} !important;
		}
	}
	.dropdown-item {
		&:active {
			background: ${colors.primary.p200} !important;
			color: ${colors.white} !important;
		}
	}
`;
