import React, { useState } from "react";
import styled from "styled-components";
import { VoucherReviewCategory } from "@models/Voucher";
import VoucherReviewGuidelineCategory from "./VoucherReviewGuidelineCategory";

type Props = {
	reviewCategories: VoucherReviewCategory[];
	onGuidelineClick: (guideline: string) => void;
};

export default function VoucherReviewGuidelinesCategories(props: Props) {
	const { reviewCategories, onGuidelineClick } = props;

	const firstIncompleteIndex = reviewCategories.findIndex((category) =>
		category.type === "OR"
			? category.guidelines.every((guideline) => guideline.done === "none")
			: category.guidelines.some((guideline) => guideline.done === "none")
	);

	const [activeCategory, setActiveCategory] = useState(
		firstIncompleteIndex > -1
			? reviewCategories[firstIncompleteIndex].header
			: ""
	);

	if (!reviewCategories.length) {
		return (
			<VoucherReviewGuidelinesCategoriesContainer>
				Not implemented
			</VoucherReviewGuidelinesCategoriesContainer>
		);
	}

	return (
		<VoucherReviewGuidelinesCategoriesContainer>
			{reviewCategories.map((category, categoryIdx) => {
				const isExpanded = category.header === activeCategory;
				const onExpanded = () => setActiveCategory(category.header);

				return (
					<VoucherReviewGuidelineCategory
						key={categoryIdx}
						category={category}
						isExpanded={isExpanded}
						onGuidelineClick={onGuidelineClick}
						onExpanded={onExpanded}
					/>
				);
			})}
		</VoucherReviewGuidelinesCategoriesContainer>
	);
}

const VoucherReviewGuidelinesCategoriesContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
