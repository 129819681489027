import { Card } from "@components";
import { Division, User } from "@models";
import { Roles, UserRole } from "@models/UserRole";
import React, { useState } from "react";
import RoleEntry from "./RoleEntry";

type Props = {
	user: User;
	division: Division;
	roles: UserRole[];
};

export default function UserDivisionRoles(props: Props) {
	const [selectedRoles, setSelectedRoles] = useState(
		new Set<Roles>(props.roles.map((r) => r.role))
	);

	const { division, user } = props;
	const cbProps = (role: Roles) => ({
		role: role,
		division: division,
		user: user,
		initialHasRole: selectedRoles.has(role),
		onChange: (checked: boolean) => {
			const copy = new Set<Roles>(selectedRoles);
			if (checked) {
				copy.add(role);
			} else {
				copy.delete(role);
			}
			setSelectedRoles(copy);
		},
	});

	return (
		<Card
			css={`
				margin-bottom: 16px;
			`}
		>
			<h4>{division.name}</h4>
			<hr />
			<RoleEntry
				label="Administrator"
				description="Hat implizit auch alle anderen Rollen"
				{...cbProps(Roles.Admin)}
			/>
			<RoleEntry
				label="Buchhaltung"
				description="Einsehen und Herunterladen von Buchungslisten"
				{...cbProps(Roles.Accounting)}
			/>
			{user.divisionId === division.divisionId && (
				<>
					<RoleEntry
						label="Reisekostenadministrator"
						description="Prüfen von Belegen und Dienstreisen aller Angestellten"
						{...cbProps(Roles.AdminReviewer)}
					/>
					<RoleEntry
						label="Reisekostenprüfer"
						description="Prüfen von Belegen und Dienstreisen"
						{...cbProps(Roles.Reviewer)}
					/>
					<RoleEntry
						label="Web-App Benutzer"
						description="Hat Zugriff auf die Web-App"
						{...cbProps(Roles.WebAppUser)}
					/>
				</>
			)}
		</Card>
	);
}
