import React from "react";
import { BudgetMaster, Customer, Division, User } from "@models";
import { ExpandCard } from "@components";
import UserDetailsHeader from "./UserDetailsHeader";
import ExpandedUserDetailsRow from "@components/users/ExpandedUserDetailsRow";

type Props = {
	user: User;
	budgetMasters: BudgetMaster[];
	customer: Customer;
	division: Division;
};

export default function UserDetailsCard({
	user,
	budgetMasters,
	customer,
	division,
}: Props) {
	return (
		<ExpandCard
			testId={"user-summary-card"}
			css={`
				margin-bottom: 20px;
				margin-right: 30px;
			`}
			header={
				<UserDetailsHeader
					user={user}
					customer={customer}
					division={division}
				/>
			}
			isInitiallyExpanded={false}
		>
			<ExpandedUserDetailsRow user={user} budgetMasters={budgetMasters} />
		</ExpandCard>
	);
}
