import * as apiService from "../api.service";
import { throwErrorWithMessages } from "./util";

export interface BalanceTransactionsPayload {
	csv: string | ArrayBuffer;
	product: string;
	fileFormat: string;
	month: string;
}

export async function addBalanceTransactions(
	divisionId: string,
	file: BalanceTransactionsPayload
) {
	try {
		await apiService.POST(`division/${divisionId}/balanceTransactions`, file);
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function getBalanceTransactionsTemplateFile(
	divisionId: string,
	product: string,
	month: string
) {
	return await apiService.GET(
		`division/${divisionId}/balanceTransactionsTemplateFile`,
		{ params: { product, month } }
	);
}
