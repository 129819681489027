import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BudgetMasterCard from "./BudgetMasterCard";
import { useProduct } from "@hooks";
import { Icon, ProductIcon, Button, NoContentContainer } from "@components";
import {
	BudgetMaster,
	NewBudgetMaster,
	getRootProductDescription,
	RootProductId,
} from "@models";
import UserDetailsPageContext from "../../../pages/user/UserDetailsPageContext";
import ExistingBudgetMasters from "./ExistingBudgetMasters";
import {
	copyBudgetMaster,
	createDefaultBudgetMaster,
	splitBudgetMasters,
} from "./util";
import BalanceAccountCard from "./balanceAccounts/BalanceAccountCard";
import BalanceAccountControlsDropdown from "./balanceAccounts/BalanceAccountControlsDropdown";
import { colors } from "@theme/theming";

type Props = {
	rootProductId: RootProductId;
	budgetMasters: BudgetMaster[];
	showActiveBudgetMasters: boolean;
};

export default function BudgetMasterCategorySection(props: Props) {
	const { rootProductId, budgetMasters, showActiveBudgetMasters } = props;
	const { name: productName } = getRootProductDescription(rootProductId);
	const product = useProduct(rootProductId);

	const [newBudgetMaster, setNewBudgetMaster] = useState<
		NewBudgetMaster | undefined
	>();

	const newCardRef = useRef<HTMLDivElement>(null);
	const content: React.ReactNode[] = [];

	const handleCopyBudgetMaster = (bm: BudgetMaster) => {
		setNewBudgetMaster(copyBudgetMaster(bm));
	};

	const { user } = useContext(UserDetailsPageContext);
	const balanceAccount = user.balanceAccounts.find(
		(ba) => ba.budgetCategory === rootProductId
	);

	if (newBudgetMaster) {
		content.push(
			<div key="new" ref={newCardRef}>
				<StyledBudgetMasterCard
					isInitiallyExpanded
					rootProductId={rootProductId}
					budgetMaster={newBudgetMaster}
					discardBudgetMaster={() => setNewBudgetMaster(undefined)}
				/>
			</div>
		);
	}

	if (balanceAccount) {
		content.push(
			<BalanceAccountCard
				key="balanceAccount"
				balanceAccount={balanceAccount}
				budgetMasters={budgetMasters}
			/>
		);
	}

	if (budgetMasters.length > 0) {
		content.push(
			<ExistingBudgetMasters
				key="exisiting"
				showActiveBudgetMasters={showActiveBudgetMasters}
				budgetMasters={budgetMasters}
				rootProductId={rootProductId}
				handleCopyBudgetMaster={handleCopyBudgetMaster}
			/>
		);
	}

	if (content.length === 0) {
		content.push(
			<NoContentContainer key="no-content">
				Der Benutzer hat kein {productName}-Modul
			</NoContentContainer>
		);
	}

	const handleAddBudgetMaster = () => {
		const { activeBudgetMasters } = splitBudgetMasters(budgetMasters);
		const hasActiveBudgetMaster = activeBudgetMasters.length > 0;
		setNewBudgetMaster(
			createDefaultBudgetMaster(
				product,
				user,
				product.id === "congrats" ? false : hasActiveBudgetMaster
			)
		);
	};

	useEffect(() => {
		// after creating the new BM, the card ref is not available right away, so we have to defer this until after the next render
		if (newBudgetMaster && newCardRef.current?.scrollIntoView) {
			newCardRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [newBudgetMaster, newCardRef]);

	return (
		<>
			<SectionHeader>
				<GridContainer
					style={{ justifyContent: "start", alignItems: "center" }}
				>
					<ProductIcon productId={rootProductId} />
					{productName}
				</GridContainer>
				<GridContainer style={{ justifyContent: "end" }}>
					<AddBudgetMasterButton onClick={handleAddBudgetMaster}>
						<Icon.Add /> Neues {productName}-Modul
					</AddBudgetMasterButton>
					<BalanceAccountControlsDropdown
						productId={rootProductId}
						balanceAccount={balanceAccount}
					/>
				</GridContainer>
			</SectionHeader>
			<BudgetMastersContainer>{content}</BudgetMastersContainer>
		</>
	);
}

export const StyledBudgetMasterCard = styled(BudgetMasterCard)`
	margin-bottom: 8px;
`;

const SectionHeader = styled.h3`
	margin-bottom: 8px;
	margin-top: 32px;

	:first-of-type {
		margin-top: 0;
	}

	color: ${colors.gray.g400};
	font-weight: normal;
`;

const BudgetMastersContainer = styled.div`
	display: grid;
	margin-bottom: 16px;
	gap: 20px;
`;

const AddBudgetMasterButton = styled(Button).attrs({
	variant: "outline-secondary",
	size: "sm",
})`
	width: fit-content;
	min-width: 20em;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 8px;
`;
