import React from "react";
import { Badge } from "react-bootstrap";
import Icon from "./icons";
import { colors } from "@theme/theming";

const Badges = {
	New: () => (
		<Badge
			variant="primary"
			css={`
				font-size: larger;
				background: ${colors.info.i400};
			`}
		>
			Neu
		</Badge>
	),
	Expired: () => <Badge variant="secondary">abgelaufen</Badge>,
	InFuture: () => <Badge variant="secondary">noch nicht aktiv</Badge>,
	Active: () => (
		<Badge variant="primary">
			<Icon.Active />
			aktiv
		</Badge>
	),
	Paused: () => (
		<Badge variant="warning">
			<Icon.Paused />
			pausiert
		</Badge>
	),
};

export default Badges;
