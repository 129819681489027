import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { CheckboxToggle, FormSectionContent } from "@components";
import { NewBudgetMaster, UpdatedBudgetMaster } from "@models";
import { ConfigBudgetMaster } from "../configuration/types";
import { Alert } from "react-bootstrap";
import { Currency } from "@util";

type Props = {
	budgetMaster: NewBudgetMaster | UpdatedBudgetMaster;
	isNewBudgetMaster: boolean;
};

type FormValues = Pick<ConfigBudgetMaster, "useBalanceAccount">;

export default function BalanceAccountConfiguration(props: Props) {
	const { control, setValue, watch } = useFormContext<FormValues>();
	const { budgetMaster, isNewBudgetMaster } = props;
	const budgetMasterAmount = Currency.format(
		budgetMaster.amounts[0].maxAmountMonth,
		{ hideZeroDecimals: true }
	);

	const ENABLE_BALANCE_ACCOUNT_INFO = `Das monatliche Budget wird jeden Monat mit bis zu ${budgetMasterAmount} mit dem Guthaben vom zugehörigen Guthabenkonto aufgeladen.`;

	const DISABLE_BALANCE_ACCOUNT_INFO = (
		<StyledText>
			Das monatliche Budget wird jeden Monat mit einem festen Betrag von{" "}
			{budgetMasterAmount} aufgeladen und <strong>nicht</strong> vom
			Guthabenkonto abgebucht
		</StyledText>
	);

	const getInfoMessage = () => {
		const useBalanceAccount = watch("useBalanceAccount");
		return useBalanceAccount
			? ENABLE_BALANCE_ACCOUNT_INFO
			: DISABLE_BALANCE_ACCOUNT_INFO;
	};

	return (
		<>
			<BalanceAccountContainer>
				<Controller
					name="useBalanceAccount"
					defaultValue={budgetMaster.useBalanceAccount}
					control={control}
					render={({ field }) => (
						<CheckboxToggle
							{...field}
							value={field.value.toString()}
							label={
								isNewBudgetMaster
									? "Guthabenkonto anlegen"
									: "Guthabenkonto verwenden"
							}
							checked={field.value}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setValue("useBalanceAccount", e.target.checked, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}}
						/>
					)}
				/>
			</BalanceAccountContainer>
			<AlertMessageContainer>
				<Alert variant="info">{getInfoMessage()}</Alert>
			</AlertMessageContainer>
		</>
	);
}

const BalanceAccountContainer = styled(FormSectionContent)`
	grid-column: span 3;
`;

const AlertMessageContainer = styled(FormSectionContent)`
	grid-column: span 3;
	margin-top: 8px;
`;

const StyledText = styled.p`
	margin-bottom: 0px;
`;
