import React, { useState } from "react";
import styled from "styled-components";
import AnimatedExpandIcon from "./AnimatedExpandIcon";
import Collapsed from "./Collapsed";

type Props = {
	isInitiallyExpanded?: boolean;
	title: React.ReactNode;
	children: React.ReactNode;
};

export default function ExpandSection(props: Props) {
	const { isInitiallyExpanded, title, children } = props;

	const [expanded, setExpanded] = useState(isInitiallyExpanded ?? false);
	return (
		<>
			<TitleContainer
				onClick={() => setExpanded(!expanded)}
				title={expanded ? "Einklappen" : "Aufklappen"}
			>
				<AnimatedExpandIcon expanded={expanded} />
				{title}
			</TitleContainer>
			<Collapsed isCollapsed={!expanded}>{children}</Collapsed>
		</>
	);
}

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
`;
