import React, { useState } from "react";
import { RootProductId, User } from "@models";
import { formatName } from "@service/util";
import BudgetMasterConfiguration from "../../products/components/BudgetMasterConfiguration";
import { createDefaultBudgetMaster } from "../../products/components/util";
import { useProduct } from "@hooks";
import { CommonModal } from "@components";

const MAX_EXPLICIT_USERS = 5;

type Props = {
	users: User[];
	productId: RootProductId;
	onClose: () => void;
};

export default function BulkAddBudgetMasters(props: Props) {
	const { users, productId, onClose } = props;
	const product = useProduct(productId);

	const [budgetMaster] = useState(createDefaultBudgetMaster(product, users[0]));
	const modelTitle = `Neue ${product.name}-Module anlegen`;

	return (
		<CommonModal handleClose={onClose} header={modelTitle}>
			<div>
				<span>
					{users.slice(0, MAX_EXPLICIT_USERS).map(formatName).join(", ")}
				</span>
				{users.length > MAX_EXPLICIT_USERS && (
					<span>und {users.length - MAX_EXPLICIT_USERS} weitere</span>
				)}
			</div>
			<BudgetMasterConfiguration
				onClose={onClose}
				budgetMaster={budgetMaster}
				users={users}
			/>
		</CommonModal>
	);
}
