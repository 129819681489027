import { serializeDate } from "@util";
import Voucher, { VatAmount } from "./Voucher";
import _ from "lodash";
import { Properties } from "@util/types";

export class EditableVoucher {
	constructor(voucher: Voucher) {
		this.dateOnVoucher = voucher.dateOnVoucher;
		this.amountAfterReview = voucher.amountAfterReview;
		this.vatAmounts = _.cloneDeep(voucher.vatAmounts);
		this.amountRefunded = voucher.amountRefunded;
		this.description = voucher.description;
		this.isFromThirdParty = voucher.isFromThirdParty;
		this.supplierName = voucher.supplierName;
		this.backofficeComment = voucher.backofficeComment;
	}

	readonly dateOnVoucher: Date;
	readonly amountAfterReview: number;
	readonly vatAmounts: VatAmount[] | null;
	readonly amountRefunded: number | null;
	readonly description: string | null;
	readonly isFromThirdParty: boolean;
	readonly supplierName: string | null;
	readonly backofficeComment: string | null;

	public static getUpdatedVoucher(
		fullVoucher: Voucher,
		editableVoucher: Properties<EditableVoucher>
	): Voucher {
		return new Voucher({
			...fullVoucher,
			...editableVoucher,
			vatAmounts: _.cloneDeep(editableVoucher.vatAmounts),
			amountAfterReview: computeAmountAfterReview(editableVoucher),
			dateOnVoucher: new Date(editableVoucher.dateOnVoucher),
		});
	}

	public static convertForJson(voucher: Properties<EditableVoucher>) {
		return {
			...voucher,
			vatAmounts: _.cloneDeep(voucher.vatAmounts),
			amountAfterReview: computeAmountAfterReview(voucher),
			dateOnVoucher: serializeDate.date(voucher.dateOnVoucher),
		};
	}
}

function computeAmountAfterReview(
	voucher: Properties<EditableVoucher>
): number {
	return voucher.vatAmounts !== null
		? voucher.vatAmounts.reduce((sum, curr) => sum + curr.vatAmount, 0)
		: voucher.amountAfterReview;
}
