import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import imageError from "../images/page-error.svg";

export default function ErrorImage() {
	return (
		<ImageContainer>
			<Image src={imageError} data-testid="image-error" fluid />
		</ImageContainer>
	);
}

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10%;
	max-height: 80vh;
`;
