import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Voucher } from "@models";
import { Tab, ChecklistProgress } from "@components";
import VoucherReviewControls from "./VoucherReviewControls/VoucherReviewControls";
import VoucherReviewForm from "./VoucherReviewForm";
import VoucherReviewImage from "./VoucherReviewImage";
import VoucherReviewMetaHeader from "./VoucherReviewMetaHeader";
import VoucherReviewGuidelinesCategories from "./VoucherReviewGuidelinesCategories";
import TripContext from "../../../../pages/trips/context/TripContext";
import useReviewCategories from "../hooks/useReviewCategories";
import { envConfig } from "@service/config";
import VoucherHistory from "./VoucherHistory";

type Props = {
	voucher: Voucher;
	onClose?: () => void;
};

export default function ConnectedReviewComponents(props: Props) {
	const { voucher, onClose } = props;
	const tripContext = useContext(TripContext);
	const [activeTab, setActiveTab] = useState("form");
	const {
		reviewCategories,
		guidelinesCount,
		passedGuidelinesCount,
		handleGuidelineClick,
	} = useReviewCategories(voucher);

	return (
		<>
			<Container>
				<VoucherReviewMetaHeader
					style={{ gridArea: "header" }}
					voucher={voucher}
				/>

				<VoucherReviewInspection style={{ gridArea: "inspection" }}>
					<Tab.Container
						css={`
							margin-bottom: 1rem;
						`}
					>
						<Tab.Item
							active={activeTab === "form"}
							onClick={() => setActiveTab("form")}
						>
							Beleginfos
						</Tab.Item>
						{envConfig.featureToggle.tcaGuidelines &&
							!tripContext &&
							!!reviewCategories.length && (
								<Tab.Item
									active={activeTab === "guidelines"}
									onClick={() => setActiveTab("guidelines")}
									style={{ display: "flex" }}
								>
									Prüfleitfäden
									<ChecklistProgress
										checked={passedGuidelinesCount}
										listLength={guidelinesCount}
										styles={{ marginLeft: "0.5rem" }}
									/>
								</Tab.Item>
							)}
						<Tab.Item
							active={activeTab === "history"}
							onClick={() => setActiveTab("history")}
							style={{ display: "flex" }}
						>
							Historie
						</Tab.Item>
					</Tab.Container>

					{activeTab === "form" && (
						<VoucherReviewForm
							style={{ padding: 8 }}
							voucher={voucher}
							onClose={onClose}
						/>
					)}

					{activeTab === "guidelines" && (
						<VoucherReviewGuidelinesCategories
							reviewCategories={reviewCategories}
							onGuidelineClick={handleGuidelineClick}
						/>
					)}

					{activeTab === "history" && <VoucherHistory voucher={voucher} />}
				</VoucherReviewInspection>

				<VoucherReviewImage voucher={voucher} style={{ gridArea: "image" }} />

				{!tripContext && (
					<VoucherReviewControls
						voucher={voucher}
						style={{ gridArea: "controls" }}
						onClose={onClose}
					/>
				)}
			</Container>
		</>
	);
}

const VoucherReviewInspection = styled.div`
	padding: 8px;
`;

const Container = styled.div`
	display: grid;

	grid-template-areas:
		"header"
		"image"
		"inspection"
		"controls";
	grid-template-rows: auto 1fr auto auto;
	grid-template-columns: 1fr;

	// TODO: use pre-defined constant here
	@media (min-width: 800px) {
		grid-template-areas:
			"header header"
			"inspection image"
			"controls controls";
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 1.4fr 2fr;
	}

	flex-shrink: 1;
	flex-grow: 1;
	// height: 100px; // NOTE: doesn't work without some arbitrary height for some reason
`;
