import { AccountingMonth } from "@models";
import { subMonths, startOfMonth } from "date-fns";
import React from "react";
import styled from "styled-components";
import AccountingMonthCard from "./AccountingMonthCard";
import LockPrevMonth from "./LockPrevMonth";
import { colors } from "@theme/theming";
import { CardsListContainer } from "@components";

type Props = {
	accountingMonths: AccountingMonth[];
	onRefreshNeeded: () => void;
};

export default function AccountingMonthList(props: Props) {
	const { accountingMonths, onRefreshNeeded } = props;

	const prevMonth = startOfMonth(subMonths(new Date(), 1));

	return (
		<>
			<h1>Monatsabschlüsse</h1>
			<CardsListContainer>
				<LockPrevMonth monthDate={prevMonth} onLockedMonth={onRefreshNeeded} />
				{accountingMonths.map((month) => (
					<AccountingMonthCard
						accountingMonth={month}
						key={month.month.toISOString()}
						onRefreshNeeded={onRefreshNeeded}
					/>
				))}
				{accountingMonths.length === 0 && (
					<NoContent>Noch keine Monatsabschlüsse vorhanden</NoContent>
				)}
			</CardsListContainer>
		</>
	);
}

const NoContent = styled.p`
	font-style: italic;
	text-align: center;
	color: ${colors.gray.g400};
`;
