import React from "react";
import { Button, Card, CommonModal, Text, Link } from "@components";
import styled from "styled-components";
import { User } from "@models";
import Routes from "@service/navigation/routes";

type Props = {
	show?: boolean;
	onCancel?: () => void;
	onContinue?: () => void;
	inactiveUsers: User[];
};
function InactiveUserExistsModal(props: Props) {
	const { inactiveUsers, show, onContinue } = props;
	const handleCancel = () => props.onCancel?.();
	const getUserProfileLink = (inactiveUser: User) => {
		return Routes.UserBasicData.makePath({
			userId: inactiveUser.userId,
			customerId: inactiveUser.customerId,
			divisionId: inactiveUser.divisionId,
		});
	};
	return (
		<CommonModal show={show} header="Hinweis" handleClose={handleCancel}>
			<Text>
				Ein inaktiver Nutzer mit derselben Personalnummer (
				<b>{inactiveUsers[0].personalNo}</b>) war bereits am gleichen Standort
				angelegt.
			</Text>
			<Text>
				Bitte prüfe vor Erstellung, ob es sich um einen Eingabefehler handelt.
			</Text>
			<Text>
				Falls es sich um dieselbe Person handelt, sollte die <b>Externe ID</b>{" "}
				ggf. übernommen werden, um die Datensätze miteinander zu verknüpfen.
			</Text>

			<InactiveUserList>
				{inactiveUsers.map((inactiveUser, index) => (
					<InActiveUser key={index}>
						<DataRow>
							<div>
								<h6>Vorname</h6>
								<span>{inactiveUser.firstname}</span>
							</div>
							<div>
								<h6>Nachname</h6>
								<span>{inactiveUser.lastname}</span>
							</div>
							<div>
								<h6>Email</h6>
								<span>{inactiveUser.email}</span>
							</div>
						</DataRow>
						<DataRow>
							<div>
								<h6>Kommentar intern</h6>
								<span>{inactiveUser.commentInternal}</span>
							</div>
							<div>
								<h6>Externe ID</h6>
								<span>{inactiveUser.externalId}</span>
							</div>
							<div>
								<Link
									to={getUserProfileLink(inactiveUser)}
									text="Nutzer aufrufen"
									openInNewTab
								/>
							</div>
						</DataRow>
					</InActiveUser>
				))}
			</InactiveUserList>
			<ButtonContainer>
				<Button variant="outline-danger" onClick={handleCancel}>
					Zurückkehren
				</Button>
				<Button onClick={onContinue}>Fortfahren</Button>
			</ButtonContainer>
		</CommonModal>
	);
}

export default InactiveUserExistsModal;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1.85rem;
	flex-wrap: wrap;
	column-gap: 1.3rem;
`;

const InActiveUser = styled(Card)`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 1rem;
`;

const DataRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
	> div {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: flex-start;
		&:last-child {
			justify-content: flex-end;
		}
	}
	h6 {
		font-weight: 600;
		margin-bottom: 0;
		font-size: 0.9rem;
	}
	span {
		font-size: 0.9rem;
	}
`;

const InactiveUserList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	margin-top: 1.85rem;
`;
