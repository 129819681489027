import React, { useRef, useState } from "react";
import { Dropdown, Spinner, Row } from "react-bootstrap";
import _ from "lodash";
import styled from "styled-components";
import { useServices, useAsyncCommand } from "@hooks";
import { Division } from "@models";
import { FormGroup, Input } from "@components";
import SearchResult from "./SearchResult";
import { colors } from "@theme/theming";

type Props = {
	onClickSearchResult: (division: Division) => void;
};

export default function SearchBar(props: Props) {
	const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchResult, setSearchResult] = useState<Division[] | null>(null);

	const { searchService } = useServices();
	const [fetchSearchResult, fetchSearchQuery] = useAsyncCommand(
		(limit: number, page: number, searchString: string) => {
			return searchService.getSearchResult({
				limit: limit,
				page: page,
				query: searchString,
			});
		}
	);

	const handleChangeDebounce = useRef(
		_.debounce(async (inputSearchValue) => {
			setIsSearchResultVisible(true);
			if (inputSearchValue !== "") {
				const result = await fetchSearchResult(5, 1, inputSearchValue);
				if (result.state === "success") {
					const { division } = result.data;
					setSearchResult(division.data);
				}
			}
		}, 1000)
	);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputSearchValue } = e.target;
		setSearchResult([]);
		setSearchValue(inputSearchValue);
		e.preventDefault();
		void handleChangeDebounce.current(inputSearchValue);
	};

	const onClickSearchResult = (item: Division) => {
		setSearchValue(item.name);
		setIsSearchResultVisible(false);
		props.onClickSearchResult(item);
	};

	return (
		<FormGroup as={Row}>
			<Input
				type="text"
				placeholder="Suche nach Standort"
				autoComplete="off"
				onChange={handleChange}
				value={searchValue}
			/>
			{!!searchValue && isSearchResultVisible && (
				<DropdownScrollable result={searchResult}>
					<>
						{fetchSearchQuery.state === "fetching" ||
						fetchSearchQuery.state === "initialFetching" ? (
							<Spinner animation="border" size="sm" />
						) : searchResult?.length === 0 ? (
							<Dropdown.Item>Keine Ergebnisse</Dropdown.Item>
						) : (
							searchResult?.map((element) => (
								<div key={element.divisionId}>
									<StyledDropdownItem
										onClick={() => onClickSearchResult(element)}
										data-testid={`searchResult-${element.divisionId}`}
									>
										<SearchResult searchResult={element} />
									</StyledDropdownItem>
									<Dropdown.Divider />
								</div>
							))
						)}
					</>
				</DropdownScrollable>
			)}
		</FormGroup>
	);
}

const DropdownScrollable = styled(Dropdown)`
	height: ${(props) => (props.result?.length > 3 ? "100px" : "auto")};
	overflow-y: ${(props) => (props.result?.length > 3 ? "scroll" : "auto")};
	border: 1px solid ${colors.gray.g300};
	border-radius: 5px;
	min-width: 100px;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	&:hover {
		color: ${colors.primary.p200};
		cursor: pointer;
	}
`;
