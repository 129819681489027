import { LargeText } from "@components";
import { BusinessTrip } from "@models";
import { EditableTrip } from "@models/travel/EditableTrip";
import { Currency } from "@util";
import React from "react";
import { useFormContext } from "react-hook-form";
import FlatRateOverride from "./FlatRateOverride";
import FlatRateValue from "./FlatRateValue";
import WaypointList from "./WaypointList";

type Props = {
	trip: BusinessTrip;
};

export default function RouteDetails(props: Props) {
	const { route } = props.trip;
	const { waypoints } = route;

	return (
		<>
			<LargeText>Reiseplan</LargeText>
			<RouteFlatRate trip={props.trip} />
			<WaypointList waypoints={waypoints} />
		</>
	);
}

function RouteFlatRate(props: { trip: BusinessTrip }) {
	const { trip } = props;

	const { watch } = useFormContext<EditableTrip>();
	const override = watch("overrides.routeFlatRate") as number;

	return (
		<div>
			<h5>
				Fahrtkostenpauschale: {Currency.format(trip.amounts.routeFlatRate)}
			</h5>
			<div>
				Errechnete Pauschale:{" "}
				<FlatRateValue
					amount={trip.calculatedAmounts.routeFlatRate}
					override={override}
				/>
			</div>
			<FlatRateOverride id="overrides.routeFlatRate" />
		</div>
	);
}
