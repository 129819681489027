import { Properties } from "@util/types";

export class ReportTypeSetting {
	constructor(props: Properties<ReportTypeSetting>) {
		this.name = props.name;
		this.value = props.value;
		this.path = props.path;
	}

	readonly name: string;
	readonly value: string;
	readonly path: string;

	public static fromJson(data: any): ReportTypeSetting {
		return new ReportTypeSetting({ ...data });
	}
}

export type ReportSettingItem = {
	path: string;
	value: string;
};

export type ReportUrl = string;
