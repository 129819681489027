import React, { useCallback } from "react";
import { ActionButton, QueryError, QueryLoadingIndicator } from "@components";
import {
	useAsyncCommand,
	useDialog,
	useFetchedData,
	useServices,
} from "@hooks";
import { useRouteMatch } from "react-router-dom";
import { DivisionRouteParams } from "@service/navigation/routes";
import { formatYearMonth } from "@util";
import { AccountingMonth } from "@models";
import { awaitWithDeferedCallback } from "@util/helpers";

type Props = {
	monthDate: Date;
	onLockedMonth: () => void;
};

export default function LockPrevMonth(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();

	const monthDisplayName = formatYearMonth(props.monthDate);

	const { accountingMonthService } = useServices();
	const fetchPrevAccountingMonth = useCallback(
		() =>
			accountingMonthService.getAccountingMonthsByDivisionAndMonth(
				params.divisionId,
				props.monthDate
			),
		[accountingMonthService, params.divisionId, props.monthDate]
	);
	const [prevAccountingMonthQuery] = useFetchedData(fetchPrevAccountingMonth);

	const [handleLockMonth, lockQueryState] = useAsyncCommand(
		() =>
			accountingMonthService.lockMonthForDivision(
				params.divisionId,
				props.monthDate
			),
		{
			rethrowError: false,
		}
	);

	const dlg = useDialog();
	const handleClickCloseButton = () => {
		dlg.showConfirmDialog({
			onConfirm: awaitWithDeferedCallback(handleLockMonth, props.onLockedMonth),
			title: "Monats sperren",
			text: `Monat ${monthDisplayName} sperren? Für diesen Monat können dann keine neuen Belege hochgeladen werden.`,
			confirmText: "Sperren",
		});
	};

	if (
		prevAccountingMonthQuery.state === "fetching" ||
		prevAccountingMonthQuery.state === "initialFetching"
	) {
		return <QueryLoadingIndicator queryState={prevAccountingMonthQuery} />;
	}

	const monthStatus =
		(prevAccountingMonthQuery.data instanceof AccountingMonth &&
			prevAccountingMonthQuery.data.status) ||
		"open";

	if (monthStatus === "closed") {
		// we already closed last month - render nothing
		return null;
	}

	return (
		<p>
			Monat {monthDisplayName} ist noch nicht abgeschlossen!
			{prevAccountingMonthQuery.data === undefined && (
				<ActionButton
					query={lockQueryState}
					css={`
						margin-left: 0.5rem;
					`}
					variant="outline-danger"
					size="sm"
					onClick={handleClickCloseButton}
				>
					{monthDisplayName} sperren
					<QueryError queryState={lockQueryState} />
				</ActionButton>
			)}
		</p>
	);
}
