import { createGlobalStyle } from "styled-components";
import { colors } from "./theming";

export const GlobalStyle = createGlobalStyle`
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: ${colors.text};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	overflow-y: scroll;

	&.modal-open {
		padding-right: unset !important;
		overflow-y: scroll !important;
	}
}

html {
	height: 100vh;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 150vh; // Workaround for loosing scroll state when switching sub-pages. How to solve better?
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
	color: ${colors.primary.p200};
}

#voucher-search-targets-tooltip .tooltip-inner {
	max-width: none;
}
`;
