import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { QueryState } from "@hooks";
type Props = {
	isLoading?: boolean;
	query?: QueryState<any>;
};

export default function ButtonLoadingIndicator(props: Props) {
	const isLoading = props.isLoading || props.query?.state === "fetching";
	if (isLoading) return <StyledSpinner animation="border" size="sm" />;
	return <></>;
}

const StyledSpinner = styled(Spinner)`
	margin-right: 4px;
`;
