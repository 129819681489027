import { BudgetMaster } from "@models";
import { useCallback } from "react";
import useFetchedData, { QueryState } from "./useFetchedData";
import useServices from "./useServices";

export default function useBudgetMasters(
	userId: string
): [
	QueryState<BudgetMaster[]>,
	() => Promise<void>,
	(value: BudgetMaster[]) => void
] {
	const { productService } = useServices();
	const getBudgetMasters = useCallback(
		() => productService.getBudgetMastersForUser(userId),
		[productService, userId]
	);

	return useFetchedData(getBudgetMasters);
}
