import { useLocation } from "react-router-dom";
import { DisplayTripMode } from "../pages/trips/TripReviewPage";
import { DisplayReviewMode } from "../pages/vouchers/FilterableReviewList";
import { endOfMonth, format, startOfMonth } from "date-fns";

export default function useModeLocation(
	mode: DisplayTripMode | DisplayReviewMode,
	prevMode?: DisplayTripMode | DisplayReviewMode
) {
	const location = useLocation();
	const vouchersQuery = new URLSearchParams(location.search);

	vouchersQuery.set("mode", mode);

	// when switching between tabs set default params
	if (prevMode && prevMode !== mode) {
		if (mode === "trips") {
			vouchersQuery.delete("date.from");
			vouchersQuery.set(
				"date.to",
				format(endOfMonth(new Date()), "yyyy-MM-dd")
			);

			// reset only for non-common sort targets
			// see SortHeader.tsx
			const sort = vouchersQuery.get("sort");
			if (["category", "created"].includes(sort ?? "")) {
				vouchersQuery.delete("sortDirection");
				vouchersQuery.delete("sort");
			}
		}

		if (mode === "vouchers") {
			vouchersQuery.set(
				"date.from",
				format(startOfMonth(new Date()), "yyyy-MM-dd")
			);
			vouchersQuery.set(
				"date.to",
				format(endOfMonth(new Date()), "yyyy-MM-dd")
			);
		}
	}

	return {
		...location,
		search: vouchersQuery.toString(),
	};
}
