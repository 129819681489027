import * as apiService from "../api.service";
import { v4 as uuidv4 } from "uuid";
import { DivisionNotFoundError } from "@service/division/errors";
import {
	CouponsAlreadyAssignedError,
	CouponsAmountMismatchError,
	DefaultCsvError,
	NoAcceptedCsvImportError,
	NoCouponsInCSVError,
	PayloadTooLargeError,
} from "./errors";
import axios, { AxiosError } from "axios";
import { tryConvertKnownError } from "@service/util";

const csvImportTypes = [
	"employeeImport",
	"budgetImport",
	"contractImport",
	"sachbezugImport",
] as const;
export type CsvImportType = typeof csvImportTypes[number];

function retrieveSubpathForCsvImport(type: CsvImportType): string {
	switch (type) {
		case "employeeImport":
			return "user/import/csv";
		case "budgetImport":
			return "budget/import/csv";
		case "contractImport":
			return "contract/import/csv";
		case "sachbezugImport":
			return "sachbezug/uploadCoupons/division";
	}
}

export type CsvError = {
	line: number;
	type: ErrorType;
	field?: string;
	message?: string;
};

export enum ErrorType {
	Required = "required",
	Invalid = "invalid",
	Duplicate = "duplicate",
	NotFound = "not-found",
	AmbiguousUpdate = "ambiguous-update",
	AccountingMonthClosed = "accounting-month-closed",
	InvalidThirdPartyStatus = "invalid-third-party-status",
	BatchUpdateForbidden = "batch-update-forbidden",
	InactiveUserExists = "inactive-user-exists",
	EndBeforeStart = "end-date-before-start-date",
	CyclicReference = "cyclic-reference",
	Unknown = "unknown",
	InactivePricingSetting = "module-billing-not-active",
}

export type ImportEmployeeResponse = {
	usersCreated: number;
	usersUpdated: number;
	usersSalutated: number;
	errors: CsvError[];
};

export type ImportBudgetResponse = {
	budgetsCreated: number;
	budgetsUpdated: number;
	errors: CsvError[];
};

export type ImportContractResponse = {
	contractsCreated: number;
	contractsUpdated: number;
	errors: CsvError[];
};

export type ImportSachbezugResponse = {
	message: string;
	couponsImported: number;
};

export async function importEntitiesFromCsv<T extends CsvImportType>(
	divisionId: string,
	csvFile: File,
	type: T,
	cancelToken: AbortSignal,
	setProgress: (progress: number | undefined) => void
): Promise<
	T extends "employeeImport"
		? ImportEmployeeResponse
		: T extends "budgetImport"
		? ImportBudgetResponse
		: T extends "contractImport"
		? ImportContractResponse
		: T extends "sachbezugImport"
		? ImportSachbezugResponse
		: never
> {
	if (!divisionId) throw new DivisionNotFoundError();

	const allowedAction = csvImportTypes.includes(type);
	if (!allowedAction) throw new NoAcceptedCsvImportError();

	const progressId = uuidv4();
	let checkProgress: NodeJS.Timeout | null = null;

	const initialWaitTime = 5000;
	const progressCheckInterval = 5000;

	const startCheckProgress = setTimeout(() => {
		checkProgress = setInterval(async () => {
			try {
				const progressResponse = await apiService.GET(
					`/progress/${progressId}`
				);
				const processingProgress = progressResponse.data.percentage;
				if (processingProgress >= 1 && checkProgress)
					clearInterval(checkProgress);
				setProgress(Math.round(processingProgress * 100));
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					console.log("404: No progress information");
				} else {
					throw error;
				}
			}
		}, progressCheckInterval);
	}, initialWaitTime);

	try {
		const formData = new FormData();
		formData.append("file", csvFile);
		formData.append("progressId", progressId);

		const result = await apiService.POST(
			`/${retrieveSubpathForCsvImport(type)}/${divisionId}`,
			formData,
			{ signal: cancelToken }
		);

		return result.data;
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.status === 413) {
				throw new PayloadTooLargeError();
			}

			if (e.response?.data.code === "not-first-upload") {
				throw new CouponsAlreadyAssignedError();
			}

			if (e.response?.data.code === "no-coupons-in-csv") {
				throw new NoCouponsInCSVError();
			}

			if (e.response?.data.code === "article-coupons-amount-mismatch") {
				throw new CouponsAmountMismatchError(e.response.data.data);
			}
		}

		throw tryConvertKnownError(e) ?? new DefaultCsvError();
	} finally {
		if (checkProgress) clearInterval(checkProgress);
		clearInterval(startCheckProgress);
	}
}
