import React from "react";
import styled from "styled-components";
import { GuidelineDoneReason } from "@models/Voucher";
import {
	FaCheck as GuidelineCheckIcon,
	FaTimes as GuidelineCrossIcon,
	FaLock as GuidelineLockedIcon,
} from "react-icons/fa";
import { Tooltip } from "@components";
import { colors } from "@theme/theming";

type Props = {
	value: string;
	done: GuidelineDoneReason;
	onToggle: (value: string) => void;
};

export default function VoucherReviewGuideline(props: Props) {
	const { value, done, onToggle } = props;

	return (
		<VoucherReviewGuidelineContainer
			style={{
				color: done === "none" ? colors.danger.d300 : colors.success.s300,
			}}
			onClick={() => onToggle(value)}
			iconDiameter={0.9}
		>
			{done === "tca" ? (
				<GuidelineCheckIcon />
			) : done === "form" ? (
				<Tooltip
					id="guidelinesLockTooltip"
					tooltip="Abgeleitet vom Eingabeformular"
				>
					<GuidelineLockedIcon color={colors.gray.g300} />
				</Tooltip>
			) : (
				<GuidelineCrossIcon />
			)}
			<GuidelineValue>{value}</GuidelineValue>
		</VoucherReviewGuidelineContainer>
	);
}

const VoucherReviewGuidelineContainer = styled.div<{ iconDiameter: number }>`
	--iconDiameterMultiplier: ${(props) => props.iconDiameter * 1.95}rem;

	cursor: pointer;
	position: relative;
	margin-bottom: 1.1rem;
	font-size: 0.85rem;

	display: grid;
	grid-template-columns: var(--iconDiameterMultiplier) auto;

	&:last-child {
		margin: 0;
	}

	svg {
		place-self: center;
		font-size: ${(props) => props.iconDiameter}rem;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: var(--iconDiameterMultiplier);
		height: var(--iconDiameterMultiplier);
		border-radius: 50%;
		border: 1px solid;
		border-color: ${colors.gray.g300};
		pointer-events: none;
		cursor: pointer;
	}
`;

const GuidelineValue = styled.div`
	display: grid;
	align-items: center;

	margin-left: 1ch;
	align-self: center;
	line-height: 1.3em;
`;
