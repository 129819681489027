import React, { useCallback } from "react";

import { useFetchedData, useServices } from "@hooks";
import useAsyncCommand from "../../../../hooks/useAsyncCommand";
import { Division } from "@models";

import { defer } from "@util/helpers";

import {
	ExpenseAccountSystem,
	FinancialAccountingSettings,
} from "@models/FinancialAccountingSettings";
import FinancialAccountForm from "./FinancialAccountForm";

type Props = {
	division: Division;
	onClickCancel: () => void;
	refreshPage: () => void;
};

export default function FinancialAccountSettings(props: Props) {
	const { division, refreshPage, onClickCancel } = props;
	const { reportService } = useServices();

	const getReportSettings = useCallback(
		(system?: ExpenseAccountSystem) =>
			reportService.getReportFinancialSettings(division.divisionId, system),
		[division.divisionId, reportService]
	);

	const [reportSettings] = useFetchedData(getReportSettings);

	const [updateReportSettings, reportSettingsQuery] = useAsyncCommand(
		(
			financialSoftwareId: string | null,
			financialAccounting: FinancialAccountingSettings | null
		) =>
			reportService.updateReportFinancialSettings({
				divisionId: division.divisionId,
				financialSoftwareId,
				financialAccounting,
			})
	);

	const handleOnSubmit = async (data: { [x: string]: string }) => {
		const financialSoftwareId =
			data.financialSoftwareId === "" ? null : data.financialSoftwareId;
		const financialAccounting = financialSoftwareId
			? FinancialAccountingSettings.fromJson(data)
			: null;

		const resultTrigger = await updateReportSettings(
			financialSoftwareId,
			financialAccounting
		);

		if (resultTrigger.state === "success") {
			refreshPage();
			await defer(props.onClickCancel);
		}
	};

	return (
		<>
			{reportSettings.state === "success" && (
				<FinancialAccountForm
					division={division}
					reportSettingsQuery={reportSettingsQuery}
					getReportSettings={getReportSettings}
					onClickCancel={onClickCancel}
					defaultValues={{
						financialAccounting: reportSettings.data.financialAccounting,
						financialSoftwareId: division.financialSoftwareId,
					}}
					expenseAccountNames={reportSettings.data.expenseAccountNames}
					onSubmit={handleOnSubmit}
				/>
			)}
		</>
	);
}
