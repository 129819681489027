import { format } from "date-fns";
import { useCallback } from "react";
import useFetchedData from "./useFetchedData";
import useServices from "./useServices";

export default function useMonthSummaryQuery(
	budgetOrModuleSettingId: string,
	date: Date
) {
	const { voucherService } = useServices();

	const fetchData = useCallback(
		() =>
			voucherService.getMonthSummaryByBudgetAndInterval(
				budgetOrModuleSettingId,
				format(date, "yyyy-MM-dd")
			),
		[budgetOrModuleSettingId, date, voucherService]
	);
	const [queryState] = useFetchedData(fetchData);
	return queryState;
}
