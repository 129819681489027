import { QueryOptions, SearchResult } from "../types";
import * as apiService from "../api.service";
import Division from "../../models/Division";
import { Customer, User } from "@models";
import { mapPaginationResponse } from "../util";
import { UserWithCustomerAndDivision } from "@models/User";

export async function getSearchResult(
	options: QueryOptions
): Promise<SearchResult> {
	try {
		const { limit, page, query } = options;
		const response = await apiService.GET(`search`, {
			params: {
				limit: limit,
				page: page,
				query: query ?? undefined,
			},
		});
		const customers = mapPaginationResponse(
			response.data.customers,
			Customer.fromJson
		);
		const users = mapPaginationResponse(
			response.data.users,
			(data: UserWithCustomerAndDivision) => ({
				...User.fromJson(data),
				division: Division.fromJson(data.division),
				customer: Customer.fromJson(data.customer),
			})
		);
		const division = mapPaginationResponse(
			response.data.division,
			Division.fromJson
		);
		return { customers, users, division };
	} catch (error) {
		console.log("getSearchResultError", error);
		throw error;
	}
}

export async function getUserFromDivision(
	divisionId: string,
	options: QueryOptions
): Promise<User[]> {
	try {
		const { query } = options;
		const response = await apiService.GET(`/search/${divisionId}/user`, {
			params: {
				query: query ?? undefined,
			},
		});

		const users = response.data.map((u: unknown) => User.fromJson(u));

		return users;
	} catch (error) {
		console.log(error);
		throw error;
	}
}
