import React from "react";
import styled from "styled-components";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { ActionButton, ErrorAlert } from "@components";
import { useAsyncCommand, useServices } from "@hooks";
import { User } from "@models";
import {
	InvalidEmailFormatError,
	SendingEmailError,
	UpdateUserError,
	UserNotFoundError,
} from "@service/user/errors";
import { QueryStateError } from "hooks/useFetchedData";

type Props = {
	user: User;
	className?: string;
};

export default function UserSalutationDropdown(props: Props) {
	const { user } = props;
	const { userService } = useServices();
	const [salutateUserFn, salutateQueryResult] = useAsyncCommand(() =>
		userService.salutateUser([user.userId])
	);

	const salutateUser = async (e: React.MouseEvent<any>) => {
		e.stopPropagation();
		e.preventDefault();
		await salutateUserFn();
	};

	const getErrorMessage = (queryState: QueryStateError) => {
		if (queryState.error instanceof UserNotFoundError) {
			return "Benutzer nicht gefunden";
		}
		if (queryState.error instanceof SendingEmailError) {
			return "E-Mail konnte nicht an den Benutzer gesendet werden";
		}
		if (queryState.error instanceof UpdateUserError) {
			return "Fehler beim Aktualisieren des Benutzers";
		}
		if (queryState.error instanceof InvalidEmailFormatError) {
			return "Die E-Mail-Adresse hat kein gültiges Format (z.B. Umlaute oder ungültige Zeichen)";
		}
		return "Es ist ein Fehler beim Einladen aufgetreten";
	};

	return (
		<>
			<Dropdown as={ButtonGroup} className={props.className}>
				<SalutateButton
					type="submit"
					query={salutateQueryResult}
					successContent={"Gespeichert"}
					onClick={salutateUser}
					className={props.className}
				>
					Einladen
				</SalutateButton>
			</Dropdown>
			{salutateQueryResult.state === "error" && (
				<ErrorAlert>{getErrorMessage(salutateQueryResult)}</ErrorAlert>
			)}
		</>
	);
}

const SalutateButton = styled(ActionButton).attrs({ size: "sm" })`
	margin-right: 0 !important;
`;
