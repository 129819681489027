import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import classNames from "classnames";
import {
	StyledCard,
	Collapsed,
	FlexCol,
	AnimatedExpandIcon,
} from "@components";
import styled from "styled-components";
import { colors } from "@theme/theming";

type Props = {
	testId?: string;
	className?: string;
	onCollapse?: () => boolean;
	onCollapsed?: () => void;
	onExpanded?: () => void;
	isActive?: boolean;
	isInitiallyExpanded?: boolean;
	header: React.ReactElement;
	children?: React.ReactNode | (() => React.ReactElement);
	hasError?: boolean;
};

export type ExpandCardRef = {
	collapse: () => void;
	expand: () => void;
};

const ExpandCard = React.forwardRef<ExpandCardRef, Props>(
	(props: Props, ref) => {
		const { isInitiallyExpanded, onCollapsed, onCollapse, onExpanded } = props;

		const [isExpanding, setExpanding] = useState(false);
		const [expanded, setExpanded] = useState(isInitiallyExpanded ?? false);

		const cardRef = useRef<HTMLDivElement>(null);

		const collapseCard = () => {
			setExpanded(false);
			if (onCollapsed) {
				onCollapsed();
			}
		};

		const onCloseEditMode = () => {
			if (!onCollapse || onCollapse()) {
				collapseCard();
			}
		};

		const expandCard = useCallback(() => {
			setExpanded(true);

			if (onExpanded) {
				onExpanded();
			}
		}, [setExpanded, onExpanded]);

		useEffect(() => {
			if (isInitiallyExpanded) {
				expandCard();
			} else {
				collapseCard();
			}
			// NOTE: expandCard is re-created on every render, so this effect always triggers if included as dependency
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isInitiallyExpanded]);

		const scrollToCard = () => {
			if (expanded && !isExpanding) return;
			const cardElement = cardRef.current;
			if (cardElement) {
				const cardRect = cardElement.getBoundingClientRect();
				const scrollPosition =
					cardRect.top +
					window.scrollY -
					window.innerHeight / 2 +
					cardRect.height / 2;
				window.scrollTo({ top: scrollPosition, behavior: "smooth" });
			}
		};

		const toggleExpand = () => (expanded ? onCloseEditMode() : expandCard());

		useImperativeHandle(
			ref,
			() =>
				({
					collapse: () => collapseCard(),
					expand: () => expandCard(),
				} as ExpandCardRef)
		);

		const header = React.cloneElement(props.header, { expanded });

		return (
			<StyledCard
				className={classNames(
					props.className,
					{ inactive: props.isActive === false },
					{ "no-overflow": isExpanding }
				)}
				data-testid={props.testId}
				ref={cardRef}
			>
				<CardHeader
					className={classNames({
						inactive: props.isActive === false,
						expanded,
					})}
					onClick={!props.hasError ? toggleExpand : undefined}
				>
					<FlexCol>
						<AnimatedExpandIcon expanded={expanded} />
					</FlexCol>

					{header}
				</CardHeader>

				<Collapsed
					isCollapsed={!expanded}
					onAnimationStart={() => setExpanding(true)}
					onAnimationEnd={() => {
						setExpanding(false);
						scrollToCard();
					}}
				>
					{typeof props.children === "function"
						? props.children() // TODO: not sure how useful this is, since React components are functions anyway
						: props.children}
				</Collapsed>
			</StyledCard>
		);
	}
);

export default ExpandCard;

const CardHeader = styled.div`
	display: flex;
	flex-direction: row;

	align-items: center;
	padding: 32px;
	cursor: pointer;

	&.expanded {
		padding-bottom: 0px;
	}

	&.inactive {
		opacity: 0.8;
		color: ${colors.gray.g400};
		font-style: italic;
	}

	& > * {
		margin-right: 16px;
	}
`;
