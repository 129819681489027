import React from "react";
import Button from "./Button";
import { QueryState } from "@hooks";
import { ComponentProps } from "@util/types";
import Icon from "./icons";
import { Spinner } from "react-bootstrap";

type Props = ComponentProps<typeof Button> & {
	icon?: React.ReactNode;
	successContent?: React.ReactNode;
	errorContent?: React.ReactNode;
	query?: QueryState<any>;
	disableAfterSuccess?: boolean;
};

// TODO: consolidate with QueryStateIndicator
function ActionButton(props: Props) {
	const {
		icon,
		query: state,
		children,
		successContent,
		errorContent,
		disableAfterSuccess,
		disabled,
		...buttonProps
	} = props;
	const isLoading = state?.state === "fetching";
	const isSuccess = state?.state === "success";
	const isError = state?.state === "error";
	const style: ComponentProps<typeof Button> = isSuccess
		? { variant: "success" }
		: isError
		? { variant: "danger" }
		: {};

	let iconComponent: React.ReactNode = icon;
	let content = children;
	if (isSuccess) {
		iconComponent = <Icon.Success />;
		content = successContent ?? content;
	}
	if (isLoading) {
		iconComponent = <Spinner animation="border" size="sm" />;
	}
	if (isError) {
		content = errorContent ?? content;
	}

	return (
		<Button
			{...buttonProps}
			{...style}
			disabled={
				disabled ||
				(isSuccess
					? disableAfterSuccess ?? true
					: isLoading || isSuccess || buttonProps.disabled)
			}
		>
			{!!iconComponent && (
				<span style={{ marginRight: 4 }}>{iconComponent}</span>
			)}
			{content}
		</Button>
	);
}

export default ActionButton;
