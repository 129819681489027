import { useCallback } from "react";
import useServices from "../../../../hooks/useServices";
import { QueryState, useFetchedData } from "@hooks";
import { Voucher, VoucherImageFile } from "@models";

type VoucherImages = VoucherImageFile[];

export function useVoucherImages(voucher: Voucher): QueryState<VoucherImages> {
	const { voucherService, userService } = useServices();

	const voucherQueryFn = useCallback(async () => {
		const images = await voucherService.getVoucherImages(voucher.voucherId);

		const imageFiles = await Promise.all(
			images.map((img) =>
				voucherService.getVoucherImageFile(
					voucher.voucherId,
					img.voucherImageId
				)
			)
		);

		return imageFiles;
	}, [voucherService, userService, voucher]);

	return useFetchedData(voucherQueryFn)[0];
}
