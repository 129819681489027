import React from "react";
import { Breadcrumbs } from "@components";
import { Customer, Division, User } from "@models";
import Routes from "@service/navigation/routes";

type NavItems = {
	title: string;
	routerLink: string;
	translatable?: boolean;
};

interface Props {
	customer?: Customer;
	division?: Division;
	user?: User;
}

export default function MainBreadcrumbs(props: Props) {
	const { customer, division, user } = props;
	const navItems: NavItems[] = [
		{
			title: "Kundenübersicht",
			routerLink: Routes.Customers.makePath(),
		},
	];
	customer &&
		navItems.push({
			title: customer.name,
			routerLink: `${Routes.Customers.route}?customer=${customer.customerId}`,
		});

	division &&
		navItems.push({
			title: division.name,
			routerLink: Routes.Users.makePath(division),
		});
	user &&
		navItems.push({
			title: user.firstname + " " + user.lastname,
			routerLink: Routes.UserDetails.makePath(user),
		});

	return (
		<Breadcrumbs
			css={`
				flex-grow: 1;
				margin-right: 32px;
			`}
			navItems={navItems}
		/>
	);
}
