import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxToggle } from "@components";
import { ConfigBudgetMaster } from "./types";
import { useStaticData } from "@hooks";
import { escapeCategoryId } from "../util";

// workaround: react-hook-form cannot handle some complex types, so we simplify here
type FormValues = Pick<ConfigBudgetMaster, "category" | "productVariantId">;

export default function MobilityVariantToggle() {
	const { watch, control, setValue } = useFormContext<FormValues>();
	const category = watch("category");

	const mobilityProduct = useStaticData().getProductOfId("mobility");

	const onChangeVariant = (variant: "split" | "combined") => {
		// make sure the single amount category is enabled, otherwise the user can't enable it themselves
		mobilityProduct.variantOfId("combined").budgetCategories.forEach((c) => {
			if (variant === "combined") {
				setValue(`amounts.${escapeCategoryId(c.id)}.enabled` as any, true);
			}
		});
	};

	return (
		<>
			{category === "mobility" && (
				<Controller
					control={control}
					name="productVariantId"
					render={({ field }) => (
						<CheckboxToggle
							{...field}
							checked={field.value === "split"}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const { checked } = e.target;
								const variant = checked ? "split" : "combined";
								field.onChange(variant);
								onChangeVariant(variant);
							}}
							label="Aufgeteiltes Mobilitäts-Budget"
						/>
					)}
				/>
			)}
		</>
	);
}
