import { ErrorBase } from "@util/errors";

export class CustomerNotFoundError extends ErrorBase {
	constructor() {
		super("Kunden wurde nicht gefunden");
	}
}

export class GetAllCustomersError extends Error {
	constructor() {
		super("Error while fetching all customers");
	}
}

export class GetCustomerByIdError extends Error {
	constructor() {
		super("Error while fetching customer by id");
	}
}

export class AddCustomerError extends Error {
	constructor() {
		super("Error while adding new customer");
	}
}

export class UpdateCustomerError extends Error {
	constructor() {
		super("Error while updating customer");
	}
}

export class DeleteCustomerWithVouchersError extends Error {
	constructor() {
		super("Cannot delete customer, vouchers uploaded by users");
	}
}
