import * as yup from "yup";

export const providerSettingsSchema = yup
	.object({
		jobrad: yup
			.object({
				frameContractId: yup.number().required("frameContractId is required"),
				companies: yup
					.array()
					.of(
						yup.object({
							id: yup.number().required("company id is required"),
							url: yup
								.string()
								.url("Invalid URL")
								.required("company url is required"),
						})
					)
					.min(1, "At least one company is required")
					.required("companies are required"),
			})
			.optional()
			.default(undefined),
	})
	.optional()
	.default(undefined);

export class AssetDivision {
	private constructor(
		readonly id: string,
		readonly name: string,
		readonly customerId: string,
		readonly providerSettings?: {
			readonly jobrad?: {
				readonly frameContractId: number;
				readonly companies: {
					id: number;
					url: string;
				}[];
			};
		}
	) {}

	private static schema = yup.object({
		id: yup
			.string()
			.uuid("Invalid divisionId")
			.required("divisionId is required"),
		customerId: yup
			.string()
			.uuid("Invalid customerId")
			.required("customerId is required"),
		name: yup.string().required("name is required"),
		providerSettings: providerSettingsSchema,
	});

	static fromJson(obj: any) {
		const validated = this.schema.validateSync(obj);
		return new AssetDivision(
			validated.id,
			validated.name,
			validated.customerId,
			validated.providerSettings
		);
	}
}
