import { format } from "date-fns";
import { RootProductId } from "@models";
import { DateInterval } from "@util/types";
import { TripStatus } from "@models/travel/BusinessTrip";
import { DisplayReviewMode } from "pages/vouchers/FilterableReviewList";

export type ReviewFilters = {
	userId?: string[];
	divisionId?: string[];
	reviewStatus?: ReviewStatus[];
	product?: RootProductId[];
	date?: Partial<DateInterval>;
	amountOnVoucher?: string[];
	search?: string;
};

export enum ReviewStatus {
	Approved = "approved",
	Rejected = "rejected",
	InReview = "in-review",
}

export function filtersFromQueryParams(params: URLSearchParams): ReviewFilters {
	const filters: ReviewFilters = {};
	const status = params.get("status")?.split(",");
	filters.reviewStatus = status as ReviewStatus[] | undefined;

	const products = params.get("product")?.split(",");
	filters.product = products as RootProductId[] | undefined;

	const dateStart = params.get("date.from");
	const dateEnd = params.get("date.to");
	if (dateStart || dateEnd) {
		const interval: Partial<DateInterval> = {};
		if (dateStart) interval.start = new Date(dateStart);
		if (dateEnd) interval.end = new Date(dateEnd);

		filters.date = interval;
	}

	filters.userId = params.get("user")?.split(",");
	filters.divisionId = params.get("division")?.split(",");
	filters.amountOnVoucher = params.get("amountOnVoucher")?.split(",");
	filters.search = params.get("search") ?? undefined;

	return filters;
}

export function filtersToQueryParams(
	type: DisplayReviewMode,
	filters: ReviewFilters,
	params: URLSearchParams
) {
	const {
		date,
		reviewStatus,
		product,
		userId,
		divisionId,
		amountOnVoucher,
		search,
	} = filters;

	if (date?.start) {
		params.set("date.from", format(date?.start, "yyyy-MM-dd"));
	} else {
		params.delete("date.from");
	}
	if (date?.end) {
		params.set("date.to", format(date?.end, "yyyy-MM-dd"));
	} else {
		params.delete("date.to");
	}

	if (reviewStatus) {
		const extendedFilters = mapStatusFilters(type, reviewStatus);
		params.set("status", extendedFilters.join(","));
	} else {
		params.delete("status");
	}

	if (product) {
		params.set("product", product.join(","));
	} else {
		params.delete("product");
	}

	if (userId) {
		params.set("user", userId.join(","));
	} else {
		params.delete("user");
	}

	if (divisionId) {
		params.set("division", divisionId.join(","));
	} else {
		params.delete("division");
	}

	if (amountOnVoucher) {
		params.set("amountOnVoucher", amountOnVoucher.join(","));
	} else {
		params.delete("amountOnVoucher");
	}

	if (search) {
		params.set("search", search);
	} else {
		params.delete("search");
	}
}

function mapStatusFilters(
	type: DisplayReviewMode,
	selection: ReviewStatus[]
): string[] {
	const hasStatus = selection.includes.bind(selection);
	const status: string[] = [];

	if (type === "trips") {
		if (hasStatus(ReviewStatus.Approved)) {
			status.push(
				ReviewStatus.Approved,
				TripStatus.ApprovedByEmployer,
				TripStatus.InReviewByEmployer
			);
		}

		if (hasStatus(ReviewStatus.InReview)) {
			status.push(ReviewStatus.InReview);
		}

		if (hasStatus(ReviewStatus.Rejected)) {
			status.push(ReviewStatus.Rejected, TripStatus.RejectedByEmployer);
		}
	} else if (type === "vouchers") {
		status.push(...selection);
	}

	return status;
}
