// NOTE: if we want to inspect Error subclasses with `instanceof`
// we have to subclass it not from a builtin type due to a Babel transpilation issue.
// Otherwise the code might work in Chrome but fail in the tests

// true story bro: https://stackoverflow.com/questions/30402287/extended-errors-do-not-have-message-or-stack-trace
// TODO: this should no longer be an issue once we target something newer than ES5/ES6 in tsconfig, isn't it?
export class ErrorBase {
	constructor(readonly message?: string) {
		if (Object.prototype.hasOwnProperty.call(Error, "captureStackTrace"))
			Error.captureStackTrace(this, this.constructor);
		else
			Object.defineProperty(this, "stack", {
				value: new Error().stack,
			});

		Object.defineProperty(this, "message", {
			value: message,
		});
	}

	/** this text is shown in the UI if the error is handled by the right components */
	get displayText(): string {
		return this.message ?? "Es ist ein Fehler aufgetreten.";
	}
}

/** an Error must conform to this interface to be handled automatically and must be added to the `knownErrors` list */
export type DeserializableFromResponse<T extends ErrorBase> = {
	/** this id is matched with the `type` field in the response body */
	errorId: string;
} & (
	| {
			fromErrorResponse(payload: Record<string, unknown>): T;
	  }
	| {
			new (): T;
	  }
);

export const REQUIRED_ERROR_MESSAGE = "Dieses Feld wird benötigt";

export function getErrorFromResponse(
	e: any
): { type: string; [k: string]: unknown } | { type: undefined } {
	const type = e.response?.data?.type;

	if (type && typeof type === "string") {
		return { type, ...e.response.data };
	}
	return { type: undefined };
}
