import React from "react";
import { motion } from "framer-motion";
import { FaChevronRight as ExpandIcon } from "react-icons/fa";

type Props = {
	expanded: boolean;
};

export default function AnimatedExpandIcon(props: Props) {
	const { expanded } = props;
	return (
		<motion.div
			animate={{
				rotate: expanded ? 90 : 0,
			}}
		>
			<ExpandIcon title={expanded ? "Einklappen" : "Aufklappen"} />
		</motion.div>
	);
}
