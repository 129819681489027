import { Properties } from "@util/types";

export default class ExpenseBeneficiary {
	constructor(props: Properties<ExpenseBeneficiary>) {
		this.firstName = props.firstName;
		this.lastName = props.lastName;
		this.role = props.role;
	}

	readonly firstName: string;
	readonly lastName: string;
	readonly role: BeneficiaryRole;

	static fromJson(data: any) {
		return new ExpenseBeneficiary(data);
	}
}

export type BeneficiaryRole =
	| {
			readonly company: string;
	  }
	| {
			readonly occupation: string;
	  };
