import * as apiService from "../api.service";
import BudgetMaster, {
	NewBudgetMaster,
	UpdatedBudgetMaster,
} from "../../models/BudgetMaster";
import { isAxiosError } from "axios";
import { ModuleBillingNotActive } from "./errors";

export async function getBudgetMastersForUser(
	userId: string
): Promise<BudgetMaster[]> {
	const response = await apiService.GET(`/user/${userId}/products`);

	return (response.data as any[]).map(BudgetMaster.fromJson);
}

export async function updateBudgetMaster(
	budgetMaster: UpdatedBudgetMaster
): Promise<void> {
	try {
		await apiService.PUT(
			`/budgetMaster/${budgetMaster.budgetMasterId}`,
			BudgetMaster.convertForJson(budgetMaster)
		);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export async function createNewBudgetMaster(
	budgetMasters: NewBudgetMaster[]
): Promise<void> {
	try {
		await apiService.POST(
			`/budgetMaster`,
			budgetMasters.map(BudgetMaster.convertForJson)
		);
	} catch (error) {
		if (isAxiosError(error)) {
			if (error.response?.data.type === "module-billing-not-active") {
				throw new ModuleBillingNotActive(error.response?.data.module);
			}
		}
		console.log(error);
		throw error;
	}
}

export async function deleteBudgetMaster(budgetMasterId: string) {
	try {
		await apiService.DELETE("budgetMaster/" + budgetMasterId);
	} catch (error) {
		console.log(error);
		throw error;
	}
}
