import React from "react";
import styled from "styled-components";
import { TravelExpense } from "@models";
import { FlexCol } from "@components";

type Props = {
	expense: TravelExpense;
};

export default function ExpenseCardHeader(props: Props) {
	return <StyledFlexCol>{props.expense.displayName}</StyledFlexCol>;
}

const StyledFlexCol = styled(FlexCol)`
	min-width: 16em;
`;
