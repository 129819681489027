import React from "react";
import ConfirmDialog from "./ConfirmDialog";

export default function CancelDialog(props: {
	showAlertDialog: boolean;
	cancelEditMode: () => void;
	hideDialog: () => void;
	children: React.ReactNode;
}) {
	if (!props.showAlertDialog) return null;
	return (
		<ConfirmDialog
			headerContent="Ungespeicherte Änderungen"
			okText="Verwerfen"
			handleClose={() => props.hideDialog()}
			handleOk={() => {
				props.cancelEditMode();
				props.hideDialog();
			}}
		>
			{props.children}
		</ConfirmDialog>
	);
}
