import React from "react";
import styled from "styled-components";
import { ProductIcon, Tooltip } from "@components";
import { BudgetMaster } from "@models";
import {
	groupBudgetMastersByProduct,
	summarizeBMs,
} from "@components/users/util";
import { colors } from "@theme/theming";

type Props = {
	budgetMasters: BudgetMaster[];
};

export default function UserProductSummary(props: Props) {
	const { budgetMasters } = props;
	const groupedBudgetMasters = groupBudgetMastersByProduct(budgetMasters);

	const tooltip = (
		<>
			{groupedBudgetMasters.map(({ id, budgetMasters, productName }) => (
				<div key={id}>{`${productName}: ${summarizeBMs(budgetMasters)}`}</div>
			))}
		</>
	);

	return (
		<Tooltip
			id="product-summary-tooltip"
			data-testid="product-summary-tooltip"
			tooltip={tooltip}
		>
			<span>
				{groupedBudgetMasters.map((b) => (
					<Icon
						key={b.id}
						data-testid={"product-icon-" + b.id}
						className={b.budgetMasters?.length > 0 ? "active" : "inactive"}
					>
						<ProductIcon productId={b.id} />
					</Icon>
				))}
			</span>
		</Tooltip>
	);
}

const Icon = styled.span`
	&.active {
		color: ${colors.primary.p200};
	}
	&.inactive {
		color: ${colors.gray.g300};
	}
	margin: 2px;
`;
