import { ConfigContract, Contract, NewContract, VehicleType } from "@models";
import { formatDate } from "@service/util";
import { EmployerPaidRailcardSettings } from "@models/EmployerPaidRailcardSettings";
import { isEmployerFinancedRailcardCategory } from "@models/Contract";

const CATEGORY_JOB_VEHICLE = "mobility.jobVehicle";

export function getUpdatedContract<T extends Contract | NewContract>(
	formValues: ConfigContract,
	contract: T
): T {
	const {
		leaseRate,
		serviceRate,
		flatTaxRate,
		insuranceRate,
		expectedPurchasePrice,
		expectedVendor,
	} = formValues;

	let amount = formValues.amount ?? contract.amount;
	let transferDate = contract.transferDate;

	let purchaseValues: Pick<
		Contract,
		"expectedVendor" | "expectedPurchasePrice"
	> = {
		expectedPurchasePrice: null,
		expectedVendor: null,
	};

	let commuteDistanceInKm = formValues.commuteDistanceInKm;

	if (isJobVehicleCategory(contract.category)) {
		amount =
			(leaseRate ?? 0) +
			(insuranceRate ?? 0) +
			(serviceRate ?? 0) +
			(flatTaxRate ?? 0);
		if (formValues.transferDate) {
			transferDate = formValues.transferDate;
		}

		if (expectedVendor === "employer" || expectedVendor === "provider") {
			purchaseValues = {
				expectedVendor: expectedVendor,
				expectedPurchasePrice: expectedPurchasePrice,
			};
		} else {
			purchaseValues = {
				expectedVendor: null,
				expectedPurchasePrice: null,
			};
		}

		if (formValues.vehicleType === VehicleType.Bike) {
			commuteDistanceInKm = null;
		}
	}
	let { settings } = formValues ?? contract;
	if (isEmployerFinancedRailcardCategory(contract.category)) {
		if (!settings || !settings.financing)
			throw new Error("settings field in contract is missing or incomplete");
		else {
			amount =
				settings.financing.commuting +
				settings.financing.expenses +
				settings.financing.fringeBenefits;
		}
		settings = new EmployerPaidRailcardSettings(settings);
	}

	const updatedContract: T = {
		...contract,
		...formValues,
		startDate: formValues.startDate,
		endDate: formValues.endDate,
		transferDate: transferDate,
		amount: amount,
		...purchaseValues,
		settings: settings,
		commuteDistanceInKm,
	};

	return updatedContract;
}

export function getVehicleType(category: string) {
	switch (category) {
		case "mobility.jobVehicle.bike":
			return VehicleType.Bike;
		case "mobility.jobVehicle.car":
			return VehicleType.Car;
		case "mobility.jobVehicle.kickScooter":
			return VehicleType.KickScooter;
		case "mobility.jobVehicle.motorScooter":
			return VehicleType.MotorScooter;
	}
}

export function isJobVehicleContract(contract: Contract | NewContract) {
	return isJobVehicleCategory(contract.category);
}

export function isJobVehicleCategory(category: string) {
	// TODO: this sucks, better whitelist the actual job vehicle category ids?
	return (
		category.startsWith(CATEGORY_JOB_VEHICLE + ".") &&
		category !== "mobility.jobVehicle.misc" &&
		category !== "mobility.jobVehicle.parkingRental"
	);
}

export function isNewContract(c: NewContract): c is NewContract {
	return !("contractId" in c);
}

export function setContractName(categoryId: string, date: Date): string {
	const withDate = (name: string) => `${name} vom ${formatDate(date)}`;

	switch (categoryId) {
		case "mobility.jobVehicle.bike":
			return withDate("Dienstfahrrad-Dauerbeleg");
		case "mobility.commute.vehicle.car":
		case "mobility.commute.vehicle.eCar":
		case "mobility.commute.vehicle.bike":
		case "mobility.commute.vehicle.motorCycle":
		case "mobility.commute.vehicle.eMotorCycle":
			return "Fahrtkosten Arbeitsweg";
		default:
			return withDate("Dauerbeleg");
	}
}

export const NO_CORRESPONDING_BUDGET_MESSAGE =
	"Für diesen Dauerbeleg gibt es aktuell kein passendes Budget bzw. Unterbudget. Es muss ein passendes Budget angelegt sein, damit der Dauerbeleg verarbeitet wird, auch wenn er das Budget vollständig ausfüllt. In diesem Fall können Sie auch en Budget von 0€ anlegen.";
