import { CheckboxToggle, HintText } from "@components";
import { RootProductId } from "@models";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ConfigBudgetMaster } from "./configuration/types";

type FormValues = Pick<
	ConfigBudgetMaster,
	"useExcessBudget" | "productVariantId" | "category"
>;

const applicableProductIds: RootProductId[] = [
	"mobility", // TODO: support for more products?
	"travel.budget",
	"homeAndWork",
];
const applicableVariants = ["default", "combined"];

export default function ExcessBudgetSettings() {
	const { control, watch, setValue } = useFormContext<FormValues>();

	const variant = watch("productVariantId");
	if (
		!applicableProductIds.includes(watch("category")) ||
		!applicableVariants.includes(variant)
	) {
		return null;
	}
	const isChecked = watch("useExcessBudget");

	return (
		<div>
			<Controller
				name="useExcessBudget"
				control={control}
				render={({ field }) => (
					<CheckboxToggle
						{...field}
						value={field.value.toString()}
						label="Überschuss-Budget verwenden"
						checked={field.value}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setValue("useExcessBudget", e.target.checked, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}}
					/>
				)}
			/>
			{isChecked && (
				<HintText>
					Das Überschuss-Budget ist aktiviert. Nicht verbrauchtes Budget wird
					bei Monatsabschluss auf das folgende Monatsbudget gebucht.
					Angesammeltes Budget verfällt zu einem Stichtag, wie er für den
					Standort konfiguriert ist.
				</HintText>
			)}
		</div>
	);
}
