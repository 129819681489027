import { Properties } from "@util/types";
import _ from "lodash";
import BusinessTrip from "./BusinessTrip";
import { Meals } from "./Meals";
import { EditableVoucher } from "@models/EditableVoucher";

export class EditableTrip {
	constructor(trip: BusinessTrip) {
		this.overrides = trip.amountOverrides;
		this.costCenter = trip.accountingDetails.costCenter;
		this.jobNumber = trip.accountingDetails.jobNumber;
		this.name = trip.name;
		this.occasion = trip.occasion;
		this.meals = trip.accomodation.meals;
		this.backofficeComment = trip.backofficeComment;
		this.integrityToken = trip.integrityToken;
		this.vouchers = _.mapValues(
			_.keyBy(trip.vouchers, (v) => v.voucherId),
			(v) => new EditableVoucher(v)
		);
	}

	readonly overrides: {
		readonly mealsFlatRate: number | null;
		readonly routeFlatRate: number | null;
		readonly overnightStaysFlatRate: number | null;
	};

	readonly name: string;
	readonly occasion: string;
	readonly costCenter: string | null;
	readonly jobNumber: string | null;
	readonly backofficeComment: string | null;

	readonly meals: ReadonlyArray<Meals>;

	readonly integrityToken: string;

	readonly vouchers: { [voucherId: string]: EditableVoucher };

	public static convertForJson(trip: Properties<EditableTrip>) {
		return {
			...trip,
			vouchers: _.mapValues(trip.vouchers, EditableVoucher.convertForJson),
		};
	}
}
