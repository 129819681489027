import styled from "styled-components";

interface Props {
	color?: string;
	bold?: boolean;
	fontSize?: number;
	letterSpacing?: number;
	uppercase?: boolean;
}

export default styled.p`
	font-weight: ${(props: Props) => (props.bold ? "bold" : "normal")};
	font-size: ${(props: Props) =>
		props.fontSize ? `${props.fontSize}rem` : "1rem"};
	letter-spacing: ${(props: Props) =>
		props.letterSpacing ? `${props.letterSpacing}rem` : "0rem"};
	text-transform: ${(props: Props) => (props.uppercase ? "uppercase" : "none")};
	margin: 0px;
`;
