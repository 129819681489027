import styled from "styled-components";

export const TableRow = styled.tr``;

export const TableColumn = styled.td.attrs({
	translate: "no",
})``;

export const TableHeading = styled.thead``;

export const TableBody = styled.tbody``;
