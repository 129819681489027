import React from "react";
import {
	FaWifi,
	FaHandHoldingUsd,
	FaMotorcycle,
	FaUtensils,
	FaGift,
	FaClock,
	FaCheck,
	FaPlus,
	FaCopy,
	FaFileInvoice,
	FaArrowRight,
	FaWallet,
	FaEnvelope,
	FaUpload as UploadedIcon,
	FaDownload as DownloadedIcon,
	FaPencilAlt as EditIcon,
	FaCoins,
	FaFileCsv,
	FaSave,
	FaArrowUp,
	FaUmbrellaBeach,
	FaTimes,
	FaSuitcaseRolling,
	FaLaptopHouse,
	FaWalking as WalkingIcon,
	FaMinus,
	FaQuestionCircle,
	FaRedo,
	FaCreditCard,
} from "react-icons/fa";
import { FiSettings as SettingsIcon, FiCheck, FiArchive } from "react-icons/fi";
import {
	AiOutlineWarning as WarningIcon,
	AiOutlinePause,
	AiOutlineMinusCircle,
	AiFillCar as VehicleIcon,
	AiOutlineExpandAlt,
} from "react-icons/ai";
import { CgDetailsMore as DetailsIcon } from "react-icons/cg";
import { BiTrain as PublicTransportIcon } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsStack } from "react-icons/bs";
import {
	ApprovedIcon,
	InReviewIcon,
	RejectedIcon,
	InReviewByEmployerIcon,
	ApprovedByEmployerIcon,
	RejectedByEmployerIcon,
} from "./StatusIcons";

const Arrow = (props: { direction: "left" | "right" | "up" | "down" }) => {
	const rotation = {
		left: "rotate(-90deg)",
		right: "rotate(90deg)",
		down: "rotate(180deg)",
		up: "rotate(0deg)",
	}[props.direction];

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			style={{
				width: "1em",
				height: "1em",
				transform: rotation,
				fill: "currentColor",
			}}
		>
			<path d="m26.934 28.641-10-26a1 1 0 0 0-1.868 0l-10 26A1 1 0 0 0 6.6 29.8l9.4-7.05 9.4 7.05a1 1 0 0 0 1.533-1.159z" />
		</svg>
	);
};

const Icon = {
	Product: {
		Web: FaWifi,
		Spend: FaHandHoldingUsd,
		Mobility: FaMotorcycle,
		Lunch: FaUtensils,
		Congrats: FaGift,
		ShortTime: FaClock,
		FitAndRelax: FaUmbrellaBeach,
		Expenses: FaWallet,
		Travel: FaSuitcaseRolling,
		HomeAndWork: FaLaptopHouse,
		Sachbezug: FaCreditCard,
	},
	Status: {
		Approved: ApprovedIcon,
		InReview: InReviewIcon,
		Rejected: RejectedIcon,
		InReviewByEmployer: InReviewByEmployerIcon,
		ApprovedByEmployer: ApprovedByEmployerIcon,
		RejectedByEmployer: RejectedByEmployerIcon,
	},
	Success: FaCheck,
	Error: FaTimes,
	Add: FaPlus,
	Remove: AiOutlineMinusCircle,
	Copy: FaCopy,
	Delete: RiDeleteBin5Line,
	Edit: EditIcon,
	Save: FaSave,
	Unselected: FaMinus,
	Reset: FaRedo,

	Details: DetailsIcon,
	Upload: UploadedIcon,
	Download: DownloadedIcon,
	Vouchers: FaFileInvoice,
	Mail: FaEnvelope,
	Budget: FaWallet,
	Modules: BsStack,
	Contracts: FaCopy,
	Settings: SettingsIcon,
	Warning: WarningIcon,
	Expand: AiOutlineExpandAlt,
	Link: FaArrowRight,
	BalanceAccount: FaCoins,
	Active: FiCheck,
	Paused: AiOutlinePause,
	CSV: FaFileCsv,
	Archive: FiArchive,
	ScrollUp: FaArrowUp,
	Help: FaQuestionCircle,
	TransportationType: {
		Vehicle: VehicleIcon,
		PublicTransport: PublicTransportIcon,
		Walk: WalkingIcon,
	},
	ArrowLeft: () => Arrow({ direction: "left" }),
	ArrowRight: () => Arrow({ direction: "right" }),
};

export default Icon;
