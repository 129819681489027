import { DropDownButton, Icon } from "@components";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { ReportSettingsType } from "./ReportList";

type Props = {
	onClickReportSettings: (reportSettingsType: ReportSettingsType) => void;
};

export default function ReportSettingsOptionsDropdown({
	onClickReportSettings,
}: Props) {
	const onSelectSettings = (reportSettingsType: ReportSettingsType) => {
		onClickReportSettings(reportSettingsType);
	};

	const dropdownTitle = (
		<>
			<Icon.Settings /> Einstellungen
		</>
	);
	return (
		<DropDownButton
			variant="outline-primary"
			id="reportSettingsDropdown"
			title={dropdownTitle}
			drop="down"
		>
			<Dropdown.Item
				onClick={() => onSelectSettings("payrollAccountingSettings")}
			>
				Lohnbuchhaltung
			</Dropdown.Item>
			<>
				<Dropdown.Divider />
				<Dropdown.Item
					onClick={() => onSelectSettings("financialAccountSettings")}
				>
					Finanzbuchhaltung
				</Dropdown.Item>
			</>
		</DropDownButton>
	);
}
