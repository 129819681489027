//
import { useFetchedData } from "@hooks";
import { useRouteMatch } from "react-router-dom";
import { useCallback } from "react";
import { DivisionRouteParams } from "@service/navigation/routes";
import useServices from "./useServices";

/**
 * can be called in division routes to fetch the Division object
 */
export default function useDivision() {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;

	const services = useServices();

	const fetchCustomerFn = useCallback(
		() => services.divisionService.getDivisionById(divisionId),
		[services, divisionId]
	);

	return useFetchedData(fetchCustomerFn);
}
