import { Product } from "@models";
import BudgetCategory from "@models/BudgetCategory";
import React from "react";
import { useFormContext } from "react-hook-form";
import BudgetCategoryDetails from "./BudgetCategoryDetails";
import BudgetCategoryToggle from "./BudgetCategoryToggle";
import { ConfigBudgetMaster } from "./types";

type Props = {
	category: BudgetCategory;
	product: Product;
};

export default function BudgetCategorySection(props: Props) {
	const { category, product } = props;
	const { watch } = useFormContext<ConfigBudgetMaster>();
	const productVariantId = watch("productVariantId");
	const currentVariant = product.variantOfId(productVariantId);
	const hasSubBudgets = currentVariant.budgetCategories.length > 1;

	const isInCurrentVariant = !!currentVariant.budgetCategories.find(
		(c) => c.id === category.id
	);

	const divStyle = isInCurrentVariant ? undefined : { display: "none" };
	return (
		<>
			<div style={divStyle}>
				<BudgetCategoryToggle
					category={category}
					isSingleBudget={!hasSubBudgets}
				/>
			</div>
			<div style={divStyle}>
				<BudgetCategoryDetails category={category} product={product} />
			</div>
		</>
	);
}
