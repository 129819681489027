import React from "react";
import { Voucher } from "@models";
import { FlexCol, Icon, FlexRow } from "@components";
import { colors } from "@theme/theming";
import { Alert } from "react-bootstrap";

type Props = {
	voucher: Voucher;
};

export default function FraudWarning(props: Props) {
	const { voucher } = props;
	return (
		<Alert
			variant="danger"
			style={{
				gridColumn: "span 2 / span 2",
				margin: "10px 0",
				padding: 16,
				fontSize: 14,
				border: `1px solid ${colors.danger.d400}`,
			}}
		>
			<FlexRow style={{ marginBottom: 8 }}>
				<FlexCol>
					<Icon.Warning
						css={`
							width: 20px;
							height: 20px;
							margin-right: 6px;
							color: ${colors.danger.d300};
						`}
					></Icon.Warning>
				</FlexCol>
				<FlexCol style={{ fontWeight: 600 }}>Möglicher Betrugsversuch.</FlexCol>
			</FlexRow>
			<FlexRow>
				Wahrscheinlichkeit: {voucher.fraudProbability}%
				<br />
				Bitte prüfen Sie das Bild aufmerksam
			</FlexRow>
		</Alert>
	);
}
