import { useServices } from "@hooks";
import { Voucher } from "@models";
import { useEffect, useState } from "react";
import { DuplicateVoucher } from "@models/Voucher";

export function useDuplicateVoucherCheck(voucher: Voucher) {
	const { voucherService } = useServices();

	const [duplicateVouchers, setDuplicateVouchers] = useState<
		DuplicateVoucher[] | null
	>(null);

	useEffect(() => {
		const fetchDuplicateVouchers = async () => {
			if (!voucher.amountAfterReview) return;
			const duplicates = await voucherService.getDuplicateVouchers(voucher);
			setDuplicateVouchers(duplicates);
		};

		void fetchDuplicateVouchers();
	}, [voucher]);

	return duplicateVouchers;
}
