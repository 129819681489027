import { Card } from "@components";
import { Division, User } from "@models";
import { CustomerRole, Roles, UserRole } from "@models/UserRole";
import React, { useCallback, useState } from "react";
import RoleEntry from "./RoleEntry";
import { useFetchedData, useServices } from "@hooks";

type Props = {
	user: User;
	division: Division;
	roles: UserRole[];
};

export default function UserCustomerRoles(props: Props) {
	const [selectedRoles, setSelectedRoles] = useState(
		new Set<Roles>(props.roles.map((r) => r.role))
	);
	const { customerService } = useServices();

	const fetchCustomer = useCallback(
		() => customerService.getCustomerById(division.customerId),
		[]
	);

	const [customerQuery] = useFetchedData(fetchCustomer);

	const { division, user } = props;
	const cbProps = (role: Roles) => ({
		role: role,
		division: division,
		user: user,
		initialHasRole: selectedRoles.has(role),
		onChange: (checked: boolean) => {
			const copy = new Set<Roles>(selectedRoles);
			if (checked) {
				copy.add(role);
			} else {
				copy.delete(role);
			}
			setSelectedRoles(copy);
		},
	});

	const customerRole = props.roles.find((role) => role instanceof CustomerRole);
	const isSelfRegistered =
		customerRole && (customerRole as CustomerRole).isSelfRegistered;

	return (
		<Card
			css={`
				margin-bottom: 16px;
			`}
		>
			{customerQuery.data && <h4>{customerQuery.data.name}</h4>}
			<hr />
			<RoleEntry
				disabled={!user.isKeycloakUser || isSelfRegistered}
				label="Arbeitgeberportal Admin"
				description={
					user.isKeycloakUser
						? isSelfRegistered
							? "Benutzer hat sich selbst registriert, daher kann die Arbeitgeberportal-Berechtigung nicht entzogen werden."
							: "Hat Zugriff auf die Arbeitgeberportal"
						: "Benutzer muss zu Keycloak migrieren, um zu Arbeitgeberportal-Admin gemacht zu werden"
				}
				{...cbProps(Roles.PortalAdmin)}
			/>
		</Card>
	);
}
