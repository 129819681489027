import React from "react";
import { Dropdown } from "react-bootstrap";
import { Category } from "@models";
import styled from "styled-components";
import useContractCategories from "../hooks/useContractCategories";
import { formatCategoryName } from "@util";
import { DropDownButton } from "@components";

type Props = {
	onClickAddContract: (category: Category) => void;
};

export default function ContractCategoriesDropdown(props: Props) {
	const categories = useContractCategories();

	const onSelectCategory = (key: string | null) => {
		if (key) {
			const category = categories.find((c) => c.id === key);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			props.onClickAddContract(category!);
		}
	};

	return (
		<DropDownButton
			variant="outline-primary"
			id="contractCategoryDropdown"
			title="Neuer Dauerbeleg"
			onSelect={onSelectCategory}
			drop="down"
		>
			<Container>
				{categories.map((category, idx) => (
					<div key={idx}>
						<Dropdown.Item key={category.id} eventKey={category.id}>
							{formatCategoryName(category)}
						</Dropdown.Item>
						<Dropdown.Divider />
					</div>
				))}
			</Container>
		</DropDownButton>
	);
}

const Container = styled.div`
	max-height: 400px;
	overflow-y: scroll;
`;
