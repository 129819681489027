import React, { CSSProperties, useState } from "react";
import { VoucherImageFile } from "@models";
import { Document, Page, pdfjs } from "react-pdf";
import type { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Span } from "@components/TableGeneric/SortControl";
import { Select, SmallText } from "@components";
import VoucherDocumentViewer from "./VoucherDocumentViewer";
import styled from "styled-components";

// taken from pdf-dist version 4.4.168
// which matches react-pdf version 9.1.0
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

type Props = {
	image: VoucherImageFile;
	containerSize: DOMRect;
	style?: CSSProperties;
};

export default function VoucherPdfViewer(props: Props) {
	const [pageNumber, setPageNumber] = useState(1);
	const [numPages, setNumPages] = useState(0);

	const onDocumentLoadSuccess = (pdf: DocumentCallback) => {
		setNumPages(pdf.numPages);
	};

	const navButtons = (
		<SmallSelect
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setPageNumber(Number.parseInt(e.target.value))
			}
		>
			{new Array(numPages).fill(0).map((_, idx) => (
				<option key={idx} value={idx + 1}>
					{idx + 1}
				</option>
			))}
		</SmallSelect>
	);

	return (
		<div style={{ ...props.style }}>
			<Container>
				<Flex>
					<SmallText>Seite:</SmallText>
					{navButtons}
				</Flex>
			</Container>

			<Document
				file={`data:application/pdf;base64,${props.image.imageData}`}
				onLoadSuccess={onDocumentLoadSuccess}
				error="PDF konnte nicht dargestellt werden."
				css={`
					flex: 1;
				`}
			>
				<VoucherDocumentViewer
					containerSize={props.containerSize}
					isPdf
					style={{ height: "100%", width: "100%" }}
				>
					<PageContainer>
						<Page
							pageNumber={pageNumber}
							scale={3}
							height={props.containerSize.height}
						/>
					</PageContainer>
				</VoucherDocumentViewer>
			</Document>
		</div>
	);
}

const Container = styled.div`
	display: flex;
	margin: 10px;
	:first-child {
		margin-right: 10px;
	}
`;

const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SmallSelect = styled(Select)`
	min-width: 20%;
	height: 90%;
`;

const Flex = styled(Span)`
	display: flex;
	align-items: center;
`;
