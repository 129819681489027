import { deserializeDate } from "@util";
import { Properties } from "@util/types";

export default class Report {
	constructor(props: Properties<Report>) {
		this.reportId = props.reportId;
		this.createdBy = props.createdBy;
		this.created = props.created;
		this.sourceMonth = props.sourceMonth;
		this.accountingMonth = props.accountingMonth;
		this.reportType = props.reportType;
		this.status = props.status;
		this.errorMessage = props.errorMessage;
		this.monthClosedEventId = props.monthClosedEventId;
		this.submitStatus = props.submitStatus;
	}

	readonly reportId: string;
	readonly createdBy: string;
	readonly created: Date;
	readonly sourceMonth: Date;
	readonly accountingMonth: Date;
	readonly reportType: string;
	readonly status: ReportStatus;
	readonly errorMessage: WageKeyMissingError | WageTypeMissingError | null;
	readonly monthClosedEventId: string | null;
	readonly submitStatus: "submitted" | "not_submitted" | "submit_failed";

	public static fromJson(data: any): Report {
		return new Report({
			...data,
			created: deserializeDate.date(data.created),
			sourceMonth: deserializeDate.date(data.sourceMonth),
			accountingMonth: deserializeDate.date(data.accountingMonth),
			status: mapStatus(data.status),
		});
	}
}

export enum ReportStatus {
	Processing = "processing",
	Complete = "complete",
	Error = "error",
}

export type WageKeyMissingError = {
	type: "wage-key-not-found";
	payload: {
		taxTypeId: string;
		taxTypeName: string;
		message: string;
	};
};

export type WageTypeMissingError = {
	type: "wage-type-not-found";
	payload: {
		taxTypeId: string;
		taxTypeName: string;
		message: string;
	};
};

function mapStatus(status: ReportStatus) {
	switch (status) {
		case "complete":
			return ReportStatus.Complete;
		case "processing":
			return ReportStatus.Processing;
		case "error":
			return ReportStatus.Error;
		default:
			throw new Error(`Unknown status - ${status} provided in report `);
	}
}
