import Voucher from "@models/Voucher";
import { deserializeDate } from "@util";
import { Properties } from "@util/types";
import { Accomodation } from "./Accomodation";
import { expenseClasses } from "./expenses";
import { Route } from "./Route";
import TravelExpense from "./TravelExpense";
import { TripAccountingDetails } from "./TripAccountingDetails";

export default class BusinessTrip {
	constructor(props: Properties<BusinessTrip>) {
		this.tripId = props.tripId;
		this.userId = props.userId;
		this.name = props.name;
		this.comment = props.comment;
		this.occasion = props.occasion;
		this.status = props.status;
		this.rejectReason = props.rejectReason;
		this.irrevocableApproval = props.irrevocableApproval;
		this.startTime = props.startTime;
		this.endTime = props.endTime;
		this.accountingDetails = props.accountingDetails;
		this.route = props.route;
		this.accomodation = props.accomodation;
		this.expenses = props.expenses;
		this.amounts = props.amounts;
		this.backofficeComment = props.backofficeComment;
		this.amountOverrides = props.amountOverrides;
		this.calculatedAmounts = props.calculatedAmounts;
		this.divisionName = props.divisionName;
		this.customerName = props.customerName;
		this.userName = props.userName;
		this.customerNo = props.customerNo;
		this.created = props.created;
		this.updated = props.updated;
		this.reviewedAt = props.reviewedAt;
		this.productId = props.productId;
		this.integrityToken = props.integrityToken;
		this.monthIsClosed = props.monthIsClosed;
		this.vouchers = props.vouchers;
		this.isReopenedTrip = props.isReopenedTrip;
	}

	readonly tripId: string;
	readonly userId: string;
	readonly name: string;
	readonly comment: string | null;
	readonly backofficeComment: string | null;
	readonly occasion: string;
	readonly status: TripStatus;
	readonly rejectReason: string | null;
	readonly irrevocableApproval: boolean;
	readonly startTime: Date;
	readonly endTime: Date;
	readonly accountingDetails: TripAccountingDetails;
	readonly route: Route;
	readonly accomodation: Accomodation;
	readonly expenses: ReadonlyArray<TravelExpense>;
	readonly amounts: TripAmounts;
	readonly calculatedAmounts: TripAmounts;

	readonly divisionName: string;
	readonly customerName: string;
	readonly userName: string;
	readonly customerNo: number;

	readonly created: Date;
	readonly updated: Date;
	readonly reviewedAt: Date | null;
	readonly productId: "travel" | "travel.budget";

	readonly integrityToken: string;

	readonly amountOverrides: {
		readonly mealsFlatRate: number | null;
		readonly routeFlatRate: number | null;
		readonly overnightStaysFlatRate: number | null;
	};

	readonly monthIsClosed: boolean;
	readonly vouchers: ReadonlyArray<Voucher>;
	readonly isReopenedTrip: boolean;

	public static fromJson(data: any): BusinessTrip {
		return new BusinessTrip({
			...data,
			startTime: deserializeDate.dateTime(data.startTime),
			endTime: deserializeDate.dateTime(data.endTime),
			created: deserializeDate.dateTime(data.created),
			updated: deserializeDate.dateTime(data.updated),
			reviewedAt: deserializeDate.nullable.dateTime(data.reviewedAt),
			accountingDetails: TripAccountingDetails.fromJson(data.accountingDetails),
			route: Route.fromJson(data.route),
			accomodation: Accomodation.fromJson(data.accomodation),
			expenses: data.expenses.map((expense: any) => {
				const expenseClass = expenseClasses.find(
					(c) => c.type === expense.type
				);
				if (!expenseClass) {
					throw new Error(`Unknown type of travel expense - ${expense.type}`);
				}
				return expenseClass.fromJson(expense);
			}),
			vouchers: data.vouchers.map(Voucher.fromJson),
			isReopenedTrip: data.isReopenedTrip,
		});
	}

	isByEmployer(): boolean {
		return (
			this.status === TripStatus.ApprovedByEmployer ||
			this.status === TripStatus.RejectedByEmployer ||
			this.status === TripStatus.InReviewByEmployer
		);
	}

	isReadOnly(): boolean {
		return this.isByEmployer() || this.monthIsClosed;
	}
}

export enum TripStatus {
	Approved = "approved",
	Rejected = "rejected",
	InReview = "in-review",
	ApprovedByEmployer = "e-approved",
	RejectedByEmployer = "e-rejected",
	InReviewByEmployer = "e-in-review",
}

type TripAmounts = {
	readonly total: number;
	readonly mealsFlatRate: number;
	readonly routeFlatRate: number;
	readonly overnightStaysFlatRate: number;
};
