import React from "react";
import { CardsListContainer } from "@components";
import { BusinessTrip, TravelExpense } from "@models";
import ExpenseCard from "./ExpenseCard";

type Props = {
	trip: BusinessTrip;
};

export default function TripExpenses(props: Props) {
	const { expenses } = props.trip;

	const noExpenses = <div>Keine Auslagen gefunden</div>;
	const expenseCards = expenses.map((expense: TravelExpense) => (
		<ExpenseCard expense={expense} key={expense.id} />
	));

	return (
		<CardsListContainer>
			{expenses.length === 0 ? noExpenses : expenseCards}
		</CardsListContainer>
	);
}
