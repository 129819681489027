import { getErrorFromResponse } from "@util/errors";
import {
	InvalidSalutationTemplateError,
	ResalutateUnexpectedStateError,
	SendingEmailError,
	UpdateUserError,
	UserConflictError,
	UserHasProductsError,
	UserNotFoundError,
	InvalidEmailFormatError,
	PersonalnoConflictError,
	CyclicReferenceError,
	OverlappingUsersError,
} from "./errors";

export function throwErrorWithMessages(e: any): never {
	const { type } = getErrorFromResponse(e);
	const user = e.response?.data?.user;
	switch (type) {
		case "user-not-found":
			throw new UserNotFoundError(`Benutzer wurde nicht gefunden ${user}`);
		case "user-has-products":
			throw new UserHasProductsError(
				`Das Löschen ist fehlgeschlagen. Bitte stellen Sie sicher, dass alle Produkte für diesen Benutzer gelöscht sind! User: ${user}`
			);
		case "duplicate-email":
			throw new UserConflictError("email");
		case "duplicate-personal-no":
			throw new UserConflictError("personalNo");
		case "duplicate-external-id":
			throw new UserConflictError("externalId");
		case "overlapping-users":
			throw new OverlappingUsersError(
				"Im angegebenen Zeitraum gibt es einen anderen Benutzer mit derselben externen ID. Die Start- und Endmonate zweier solcher Benutzer dürfen sich nicht überschneiden."
			);
		case "resalutate-unexpected-state":
			throw new ResalutateUnexpectedStateError();
		case "invalid-salutation-template":
			throw new InvalidSalutationTemplateError();
		case "sending-email-failed":
			throw new SendingEmailError();
		case "user-salutation-update-failed":
			throw new UpdateUserError();
		case "user-email-invalid-format":
			throw new InvalidEmailFormatError();
		case "personal-no-conflict":
			throw new PersonalnoConflictError(
				`Konnte die Arbeitnehmer nicht verschieben: PersonalNo ${e.response?.data?.personalNo} ist am Zielstandort bereits vergeben`
			);
		case "cyclic-reference":
			throw new CyclicReferenceError(
				"Mitarbeitende dürfen nicht zirkulär auf sich selbst verweisen. Bitte prüfe die Verweise der ausgewählten Reisekostenprüfer."
			);
		default:
			throw e;
	}
}
