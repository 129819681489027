import React, { Fragment } from "react";

import { TableRow, TableBody, TableHeading } from "./TableTags";
import TableHeader from "./TableHeader";
import TableBS from "react-bootstrap/Table";
import { Header } from "./TableTypes";
import { SortState } from "@hooks";

interface Props<T> {
	data: T[];
	headers: Header[];
	children: (row: T) => React.ReactNode;
	sortOptions?: SortState;

	allowSelect?: boolean;
	isSelectAll?: boolean;
	onSelectAll?: (selected: boolean) => void;
}

export default function TableGeneric<T>(props: Props<T>) {
	const { data, headers, children, sortOptions } = props;

	let rows: React.ReactNode = undefined;
	if (data && children && typeof children === "function") {
		rows = data.map((row, index) => (
			<Fragment key={index}>{children(row)}</Fragment>
		));
	}

	return (
		<>
			<TableBS
				style={{ position: "relative" }}
				bordered
				data-testid="genericTable"
			>
				<TableHeading data-testid="table-head">
					<TableRow>
						<TableHeader
							headers={headers}
							sort={sortOptions}
							allowSelect={props.allowSelect}
							isSelectAll={props.isSelectAll}
							onSelectAll={props.onSelectAll}
						/>
					</TableRow>
				</TableHeading>
				<TableBody data-testid="table-body">{rows}</TableBody>
			</TableBS>
		</>
	);
}
