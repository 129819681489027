import React from "react";
import { useServices, useAsyncCommand } from "@hooks";
import User, { UpdatedUser } from "../../../models/User";
import UserForm from "./UserForm";
import { defer } from "@util/helpers";
import { useHistory, useRouteMatch } from "react-router-dom";
import Routes, { UserRouteParams } from "@service/navigation/routes";

type Props = {
	user: User;
};

export default function EditUserPage(props: Props) {
	const { user } = props;
	const { userService } = useServices();
	const history = useHistory();
	const { params } = useRouteMatch<UserRouteParams>();
	const divisionUsersUrl = Routes.Users.makePath({
		customerId: params.customerId,
		divisionId: params.divisionId,
	});

	const [updateUser, updateUserQuery] = useAsyncCommand(
		async (updatedUser: UpdatedUser) => {
			return await userService.updateUser(updatedUser, user.userId);
		}
	);

	const handleUpdateUser = async (updatedUser: UpdatedUser) => {
		const result = await updateUser(updatedUser);
		if (result.state === "success") {
			await defer(() => {
				history.replace(divisionUsersUrl);
			});
		}
	};

	return (
		<UserForm
			user={user}
			handleOnSubmit={handleUpdateUser}
			cmdState={updateUserQuery}
		/>
	);
}
