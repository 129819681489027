import { AnimatePresence, motion } from "framer-motion";
import React from "react";

type Props = {
	isCollapsed: boolean;
	children: React.ReactNode;
	onAnimationStart?: () => void;
	onAnimationEnd?: () => void;
};

// TODO: can this be merged with the Accordion component?
export default function Collapsed(props: Props) {
	return (
		<AnimatePresence initial={false}>
			{!props.isCollapsed && (
				<motion.div
					initial="collapsed"
					animate="open"
					exit="collapsed"
					onAnimationStart={props.onAnimationStart}
					onAnimationComplete={props.onAnimationEnd}
					variants={{
						open: { opacity: 1, height: "auto" },
						collapsed: { opacity: 0, height: 0 },
					}}
					transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
				>
					{props.children}
				</motion.div>
			)}
		</AnimatePresence>
	);
}
