import React, { useEffect } from "react";
import { Alert, Col, FormGroup, Row } from "react-bootstrap";
import { addDays, differenceInCalendarDays, isBefore } from "date-fns";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { BudgetMaster, CongratsSettings, NewBudgetMaster } from "@models";
import { useBudgetMasterProduct } from "@hooks";

import {
	CurrencyInput,
	Input,
	InputValidation,
	Label,
	NoAutoFillInput,
	RequiredMarker,
	Select,
	TextArea,
} from "@components";

import { CongratsOccasions } from "../../../../models/CongratsOccasion";
import styled from "styled-components";
import DatePicker from "@components/forms/DatePicker";
import { ConfigBudgetMaster } from "../configuration/types";
import PostalCodes from "./PostalCodes";
import { ProductCongrats } from "@models/products/ProductCongrats";

type Props = {
	budgetMaster: BudgetMaster | NewBudgetMaster;
};

type CongratsBudgetMaster = ConfigBudgetMaster & {
	settings: CongratsSettings;
};

export default function CongratsCouponsConfiguration(props: Props) {
	const { budgetMaster } = props;
	const { settings } = budgetMaster;
	const { product } = useBudgetMasterProduct(budgetMaster);
	if (!(product instanceof ProductCongrats)) {
		throw new Error("product is no congrats product");
	}
	const allOccasions = product.congratsOccasions;
	const congratsCategories =
		product.variants[0].budgetCategories[0].subCategories;

	const { control, watch, formState, setValue, register } =
		useFormContext<CongratsBudgetMaster>();

	const occasion: string | undefined = watch("settings.occasion");
	const isBirthday = occasion === CongratsOccasions.Birthday;
	const isCustomOccasion = occasion === CongratsOccasions.Custom;

	const onChangeAvailableUntil = (newAvailableUntil: Date) => {
		const availableFrom = watch("availableFrom");

		// To trigger validation when date is cleared manually. When user is manually clearing the date, it will no longer
		// be a valid Date and day is set to undefined
		if (newAvailableUntil === undefined) {
			(setValue as any)("availableUntil", null, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}

		if (!isBirthday && newAvailableUntil) {
			setValue(
				"settings.validTimeInDays" as any,
				differenceInCalendarDays(newAvailableUntil, availableFrom) + 1
			);
		}
	};

	const validateDateUntil = (availableUntil: Date | null) => {
		const availableFrom: Date = watch("availableFrom");
		const occasion = watch("settings.occasion");

		if (
			availableFrom &&
			availableUntil &&
			!isBefore(availableFrom, availableUntil)
		) {
			return "Enddatum darf nicht vor Startdatum liegen";
		}

		if (occasion !== CongratsOccasions.Birthday && !availableUntil) {
			return "Dieses Feld ist für Anlässe außerhalb des Geburtstages erforderlich";
		}
		return true;
	};

	const validateRestrictPostalCodes = (value: string[] | undefined) => {
		if (value) {
			const codes = value.map((s) => s.trim());

			if (codes.length > 0) {
				const validCodes = codes.filter((c) => /^\d{5}$/.test(c));

				if (validCodes.length !== codes.length)
					return "Die Postleitzahl sollte aus 5 Ziffern bestehen";
			}
		}
		return true;
	};

	const onChangeValidTimeInDays = () => {
		const availableFrom: Date = watch("availableFrom");
		const validTimeInDays = watch("settings.validTimeInDays");

		if (isBirthday || !availableFrom || !validTimeInDays) return;
		(setValue as any)(
			"availableUntil",
			addDays(availableFrom, validTimeInDays - 1),
			{
				shouldDirty: true,
				shouldValidate: true,
			}
		);
	};

	useEffect(() => {
		if (occasion) {
			const greetingText =
				settings?.occasion && settings?.occasion === occasion
					? settings?.greetingText
					: ProductCongrats.getGreetingText(occasion);

			setValue("settings.greetingText" as any, greetingText);
		}
	}, [setValue, occasion, settings]);

	return (
		<>
			<div css={isBirthday ? "display:none" : ""}>
				<InputValidation error={formState.errors.settings?.occasion}>
					<FormGroup as={Row}>
						<Label htmlFor="occasion" column sm={4}>
							Anlass <RequiredMarker />
						</Label>
						<Col sm={8}>
							<Select
								id="occasion"
								{...register("settings.occasion" as any)}
								defaultValue={settings?.occasion}
							>
								{allOccasions?.map((occasion) => (
									<option value={occasion.id} key={occasion.id}>
										{occasion.name}
									</option>
								))}
							</Select>
						</Col>
					</FormGroup>
				</InputValidation>
			</div>

			{isCustomOccasion && (
				<InputValidation error={formState.errors.settings?.customOccasion}>
					<FormGroup as={Row}>
						<Label htmlFor="customOccasion" column sm={4}>
							Benutzerdefinierter Anlass <RequiredMarker />
						</Label>
						<Col sm={8}>
							<Controller
								name={"settings.customOccasion" as any}
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<NoAutoFillInput id="customOccasion" {...field} />
								)}
								defaultValue={settings?.customOccasion ?? ""}
							/>
						</Col>
					</FormGroup>
				</InputValidation>
			)}
			<InputValidation error={formState.errors.availableFrom}>
				<FormGroup as={Row}>
					<Label htmlFor="availableFrom" column sm={4}>
						Verfügbar ab <RequiredMarker />
					</Label>
					<Col sm={4}>
						<Controller
							name="availableFrom"
							control={control}
							rules={{
								required: true,
							}}
							defaultValue={budgetMaster.availableFrom}
							render={({ field }) => (
								<DatePicker {...field} id="availableFrom" />
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
			<InputValidation error={formState.errors.availableUntil}>
				<FormGroup as={Row}>
					<Label htmlFor="availableUntil" column sm={4}>
						Verfügbar bis <RequiredMarker />
					</Label>
					<Col sm={4}>
						<Controller
							name="availableUntil"
							control={control}
							defaultValue={budgetMaster.availableUntil}
							rules={{
								validate: validateDateUntil,
							}}
							render={({ field }) => (
								<DatePicker
									{...field}
									id="availableUntil"
									canClearDate
									onClearDate={() =>
										(setValue as any)("availableUntil", null, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
									onDayChange={onChangeAvailableUntil}
								/>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
			{isBirthday && (
				<FormGroup as={Row}>
					<Col sm={4}></Col>
					<Col sm={8}>
						<Alert variant={"info"}>
							{`Geburtstags-Gutscheine werden zum Geburtsdatum automatisch
                        erzeugt. Es wird jedes Jahr am Geburtstag des Nutzers ein neuer
                        Geburtstags-Gutschein erzeugt, so lange wie hier angegeben. Neue
                        Gutscheine haben eine Gültigkeit von ${watch(
													"settings.validTimeInDays"
												)} Tagen`}
							.
						</Alert>
					</Col>
				</FormGroup>
			)}
			<InputValidation error={formState.errors.settings?.validTimeInDays}>
				<FormGroup as={Row}>
					<Label htmlFor="validTimeInDays" column sm={4}>
						Gültigkeit <RequiredMarker />
					</Label>
					<Col sm={4}>
						<Controller
							name={"settings.validTimeInDays" as any}
							control={control}
							defaultValue={settings?.validTimeInDays ?? 30}
							render={({ field }) => (
								<Input
									id="validTimeInDays"
									type="number"
									min="1"
									{...field}
									value={field.value ?? ""}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let convertedValue: any = null;
										if (e.target.value !== "") {
											convertedValue = Number.parseInt(e.target.value);
										}
										field.onChange(convertedValue);
										onChangeValidTimeInDays();
									}}
								/>
							)}
						/>
					</Col>
					<Col sm={2}>Tage</Col>
				</FormGroup>
			</InputValidation>
			<InputValidation
				error={formState.errors.amounts?.congrats?.maxAmountMonth}
			>
				<FormGroup as={Row}>
					<Label htmlFor="maxAmountMonth" column sm={4}>
						Betrag <RequiredMarker />
					</Label>
					<Col sm={4}>
						<Controller
							name={"amounts.congrats.maxAmountMonth" as any}
							control={control}
							rules={{
								required: true,
								min: {
									value: 0.01,
									message: "Der Mindestwert beträgt 0,01",
								},
							}}
							render={({ field }) => (
								<CurrencyInput {...field} id="maxAmountMonth" min={0.01} />
							)}
						/>
					</Col>
					<Col sm={2}>€</Col>
				</FormGroup>
			</InputValidation>
			<InputValidation error={formState.errors.category}>
				<FormGroup as={Row}>
					<Label htmlFor="congratsCategory" column sm={4}>
						Kategorie <RequiredMarker />
					</Label>
					<Col sm={8}>
						<Controller
							name={"categories.0"}
							control={control}
							defaultValue={budgetMaster.categories[0]}
							render={({ field }) => (
								<Select id="congratsCategory" {...field}>
									{congratsCategories?.map((category) => (
										<option value={category.id} key={category.id}>
											{category.name}
										</option>
									))}
								</Select>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
			<FormGroup as={Row}>
				<Label htmlFor="greetingText" column sm={4}>
					Grußtext
				</Label>
				<Col sm={8}>
					<Controller
						name={"settings.greetingText" as any}
						control={control}
						defaultValue={settings?.greetingText ?? ""}
						render={({ field }) => (
							<TextArea {...field} id="greetingText" rows="5" />
						)}
					/>
				</Col>
			</FormGroup>
			<SectionHeader>Beschränkung</SectionHeader>
			<FormGroup as={Row}>
				<Label htmlFor="restrictSupplier" column sm={4}>
					Händler
				</Label>
				<Col sm={8}>
					<Controller
						name={"settings.restrictSupplier" as any}
						render={({ field }) => (
							<NoAutoFillInput id="restrictSupplier" {...field} />
						)}
						control={control}
						defaultValue={settings?.restrictSupplier ?? ""}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="restrictStreet" column sm={4}>
					Straße
				</Label>
				<Col sm={8}>
					<Controller
						name={"settings.restrictStreet" as any}
						render={({ field }) => (
							<NoAutoFillInput id="restrictStreet" {...field} />
						)}
						control={control}
						defaultValue={settings?.restrictStreet ?? ""}
					/>
				</Col>
			</FormGroup>

			<InputValidation
				error={
					formState.errors.settings?.restrictPostalCodes as
						| FieldError
						| undefined
				}
			>
				<FormGroup as={Row}>
					<Label htmlFor="restrictPostalCodes" column sm={4}>
						Postleitzahlen
					</Label>
					<Col sm={8}>
						<Controller
							name={"settings.restrictPostalCodes" as any}
							control={control}
							rules={{
								validate: validateRestrictPostalCodes,
							}}
							defaultValue={settings?.restrictPostalCodes ?? []}
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							render={({ field: { ref, ...props } }) => (
								<PostalCodes
									{...props}
									id="restrictPostalCodes"
									name={"settings.restrictPostalCodes"}
								/>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
			<FormGroup as={Row}>
				<Col sm={4}></Col>
				<Col sm={8}>Postleitzahlen mit Komma (,) getrennt eingeben</Col>
			</FormGroup>
		</>
	);
}

const SectionHeader = styled.h1`
	grid-column: 1 / span 2;
	font-size: 1.25rem;
	font-weight: bold;
	margin-top: 8px;
`;
