import BusinessTrip from "@models/travel/BusinessTrip";
import { PaginationResult, QueryOptions } from "@service/types";
import { mapPaginationResponse } from "@service/util";
import {
	filtersToQueryParams,
	ReviewFilters,
} from "@service/review/ReviewFilters";
import * as apiService from "../api.service";

export async function getTripById(tripId: string): Promise<BusinessTrip> {
	const trip = await apiService.GET("trip/" + tripId);
	return BusinessTrip.fromJson(trip.data);
}

export async function queryTrips(
	options: QueryOptions,
	filters: ReviewFilters
): Promise<PaginationResult<BusinessTrip>> {
	const queryParams: any = {
		limit: options.limit,
		page: options.page,
		sort: options.sort,
		order: options.order,
	};

	const filterParams = new URLSearchParams();
	filtersToQueryParams("trips", filters, filterParams);
	filterParams.forEach((value, key) => (queryParams[key] = value));

	// Don't pass amount as sort param to businessTrip as it doesn't have the amount
	if (queryParams.sort === "amount") {
		queryParams.sort = undefined;
	}

	const responseData = await apiService.GET("trip", {
		params: queryParams,
	});

	return mapPaginationResponse(responseData.data, BusinessTrip.fromJson);
}
