import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useServices, useAsyncCommand } from "@hooks";
import { NewUser } from "../../../models/User";
import Routes, { UserRouteParams } from "@service/navigation/routes";
import UserForm from "./UserForm";
import { defer } from "@util/helpers";

export default function AddUserPage() {
	const { params } = useRouteMatch<UserRouteParams>();
	const { customerId, divisionId } = params;
	const { userService } = useServices();
	const history = useHistory();
	const divisionUsersUrl = Routes.Users.makePath({
		customerId: params.customerId,
		divisionId: params.divisionId,
	});
	const [addUser, addUserQuery] = useAsyncCommand(async (user: NewUser) => {
		const newUser: NewUser = { ...user, customerId, divisionId };
		return await userService.addUser(newUser);
	});

	const handleAddUser = async (user: NewUser) => {
		const result = await addUser(user);
		if (result.state === "success") {
			const user = result.data;
			await defer(() => {
				history.replace(
					`${divisionUsersUrl}?user=${user}`,
					Routes.Users.makeState({ newUser: user })
				);
			});
		}
	};

	return <UserForm handleOnSubmit={handleAddUser} cmdState={addUserQuery} />;
}
