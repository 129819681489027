import { Input } from "@components";
import React, { useCallback } from "react";

type Props = {
	value: string[] | undefined;
	id?: string;
	name?: string;
	onChange?: (codes: string[]) => void;
};

const PostalCodes = React.forwardRef<HTMLInputElement, Props>(
	(props: Props, ref) => {
		const { value, id, name, onChange } = props;
		const selectedPostalCodes = (value ?? []).join(",");

		const customOnChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				let targetValue: string[] = [];
				if (e.target.value !== "") {
					targetValue = e.target.value.split(",").map((s) => s.trim());
				}
				if (onChange) {
					onChange(targetValue);
				}
			},
			[onChange]
		);

		return (
			<Input
				id={id}
				name={name}
				ref={ref}
				value={selectedPostalCodes}
				onChange={customOnChange}
			/>
		);
	}
);

export default PostalCodes;
