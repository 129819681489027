import { FlexRow } from "@components";
import styled from "styled-components";

export const Container = styled.section`
	display: grid;
	row-gap: 1rem;
	h4 {
		margin-left: -2rem;
		margin-bottom: 0;
	}
`;

export const Row = styled(FlexRow)`
	column-gap: 1rem;
`;
