import styled from "styled-components";
import { Form } from "react-bootstrap";
import { colors } from "@theme/theming";

const defaultProps = {
	onWheel: (e: React.WheelEvent<HTMLInputElement>) => {
		if (e.target instanceof HTMLInputElement && e.target.type === "number") {
			e.target.blur();
		}
	},
};

export const Input = styled(Form.Control).attrs({
	className: "form-control",
	...defaultProps,
})`
	border-radius: 5px;
	transition: 0.3s ease all;
	color: ${colors.text};

	&::placeholder {
		color: ${colors.gray.g300};
	}

	&:focus,
	&:active {
		border-color: ${colors.primary.p100};
		transition: 0.3s ease all;
		color: ${colors.text};
		box-shadow: 0 0 0 0.2rem ${colors.primary.p200}80;
	}
`;

export const AutoFillInput = styled(Input).attrs({
	autoComplete: "on",
	translate: "no",
	...defaultProps,
})``;

export const NoAutoFillInput = styled(Input).attrs({
	/* SIC: browsers now ignore autocomplete: "off"; see here https://stackoverflow.com/questions/15738259/disabling-chrome-autofill */
	autoComplete: "new-password",
	translate: "no",
	...defaultProps,
})``;
