import { Category, NewContract, Voucher } from "@models";
import { isEmployerFinancedRailcardCategory } from "@models/Contract";
import { EmployerPaidRailcardSettings } from "@models/EmployerPaidRailcardSettings";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { setContractName } from "../util";

export function createVoucherWithAttachedContract(
	voucher: Voucher,
	category: Category
): NewContract {
	return {
		...createDefaultContract(category, voucher.userId, voucher),
		amount: Math.min(voucher.amountOnVoucher, voucher.amountAfterReview),
	};
}

export function createDefaultContract(
	category: Category,
	userId: string,
	voucher?: Voucher
): NewContract {
	const startDate = startOfMonth(new Date());

	const contract: NewContract = {
		userId: userId,
		name: setContractName(category.id, startDate),
		comment: null,
		startDate: startDate,
		endDate: voucher ? endOfMonth(addMonths(voucher.dateOnVoucher, 11)) : null,
		leaseStartDate: null,
		leaseEndDate: null,
		category: category.id,
		amount: 0,
		listPrice: null,
		leaseRate: null,
		insuranceRate: null,
		serviceRate: null,
		flatTaxRate: null,
		purchasePrice: null,
		transferDate: null,
		vehicleType: null,
		expectedVendor: null,
		expectedPurchasePrice: null,
		type: null,
		settings: getDefaultSettings(category),
		netDeduction: null,
		grossDeduction: null,
		serviceIntervalInMonths: null,
		expectServiceVerification: null,
		commuteDistanceInKm: null,
		overflowBudgetAmountIn: null,
		externalId: null,
		isFromThirdParty: voucher?.isFromThirdParty ?? false,
		attachedVoucher: voucher,
	};
	return contract;
}

function getDefaultSettings(category: Category): NewContract["settings"] {
	if (isEmployerFinancedRailcardCategory(category.id)) {
		return new EmployerPaidRailcardSettings({
			financing: {
				commuting: 0,
				expenses: 0,
				fringeBenefits: 0,
			},
		});
	}
	return null;
}
