import {
	Checkbox,
	CurrencyInput,
	Input,
	Label,
	RequiredMarker,
} from "@components";
import { Division } from "@models";
import PricingRecord from "@models/PricingRecord";
import React, { BaseSyntheticEvent } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { PricingCardProps } from "./IPricingCardProps";
import { useCustomField } from "./useCustomField";

export default function FlatFeePerEmployeeSettings(props: PricingCardProps) {
	const { control } = useFormContext<Division>();
	const { index, pricingSettings } = props;

	const pricingRecord: PricingRecord = pricingSettings[index];

	const flatFeePerEmployee = pricingRecord?.flatFeePerEmployee;

	const { setFieldChecked, isChecked } = useCustomField<
		PricingRecord["flatFeePerEmployee"]
	>(`pricingSettings.${index}.flatFeePerEmployee`, {
		amount: 100,
		description: "",
	});

	return (
		<>
			<Controller
				name={`pricingSettings.${index}.flatFeePerEmployee`}
				control={control}
				defaultValue={flatFeePerEmployee || null}
				render={({ field }) => (
					<Checkbox
						value={!!field.value}
						label="Festpreis pro Mitarbeiter"
						onChange={(e: BaseSyntheticEvent) =>
							setFieldChecked(!!e.target?.checked)
						}
					/>
				)}
			/>
			<br />
			{isChecked && (
				<div>
					<FormGroup as={Row}>
						<Label style={{ marginLeft: 24 }} column sm="3">
							Betrag (€)
						</Label>
						<Col sm="5">
							<Controller
								control={control}
								name={`pricingSettings.${index}.flatFeePerEmployee.amount`}
								defaultValue={flatFeePerEmployee?.amount || 0}
								render={({ field }) => <CurrencyInput {...field} min={0.0} />}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label style={{ marginLeft: 24 }} column sm="3">
							Beschreibung <RequiredMarker />
						</Label>
						<Col sm="5">
							<Controller
								control={control}
								name={`pricingSettings.${index}.flatFeePerEmployee.description`}
								defaultValue={flatFeePerEmployee?.description || ""}
								rules={{ required: true }}
								render={({ field }) => (
									<Input placeholder="Bitte Beschreibung eingeben" {...field} />
								)}
							/>
						</Col>
					</FormGroup>
					<br />
				</div>
			)}
		</>
	);
}
