import { Icon, Label } from "@components";
import { colors } from "@theme/theming";
import React from "react";
import styled from "styled-components";

interface CheckLabelProps {
	text: string;
	value: boolean;
}

export default function CheckedLabel(props: CheckLabelProps) {
	const { text, value } = props;

	return (
		<>
			{value ? (
				<div>
					<SelectedIcon />
					<Label>{text}</Label>
				</div>
			) : (
				<div>
					<UnselectedIcon />
					<UnCheckedLabel>{text}</UnCheckedLabel>
				</div>
			)}
		</>
	);
}

const SelectedIcon = styled(Icon.Success)`
	color: ${colors.success.s300};
	margin-right: 2px;
`;

const UnselectedIcon = styled(Icon.Unselected)`
	color: ${colors.gray.g300};
	margin-right: 2px;
`;

const UnCheckedLabel = styled(Label)`
	color: ${colors.gray.g400};
`;
