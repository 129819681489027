import React, { useState } from "react";
import _ from "lodash";
import { rootProductIds, RootProductId } from "@models";
import BudgetMaster from "../../../models/BudgetMaster";
import BudgetMasterCategorySection from "./BudgetMasterCategorySection";
import { Checkbox } from "@components";
import styled from "styled-components";

type Props = {
	budgetMasters: BudgetMaster[];
};

export default function BudgetMastersList(props: Props) {
	const { budgetMasters } = props;
	const groupedBudgetMasters = _.groupBy(budgetMasters, (bm) => bm.category);
	const [showActiveBudgetMasters, setShowActiveBudgetMasters] = useState(true);

	return (
		<>
			<StyledCheckbox
				id="showActiveProducts"
				label="Nur aktive zeigen"
				value={showActiveBudgetMasters}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setShowActiveBudgetMasters(e.target.checked)
				}
			/>
			{rootProductIds.map((rootId: RootProductId) => (
				<BudgetMasterCategorySection
					key={rootId}
					rootProductId={rootId}
					budgetMasters={groupedBudgetMasters[rootId] ?? []}
					showActiveBudgetMasters={showActiveBudgetMasters}
				/>
			))}
		</>
	);
}

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 16px;
`;
