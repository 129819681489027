import styled from "styled-components";
import ActionButton from "./ActionButton";

export const TransparentStatusIndicator = styled(ActionButton).attrs(() => ({
	variant: "no-outline",
	size: "sm",
}))`
	border-radius: 50%;
	width: 30px;
	height: 30px;
`;
