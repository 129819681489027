import { CheckboxToggle } from "@components";
import { UpdatedDivision } from "@models/Division";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Container } from "../StyledComponents";

export default function OtherSettings() {
	const { register } = useFormContext<UpdatedDivision>();

	return (
		<Container>
			<h4>Weitere Einstellungen</h4>
			<CheckboxToggle
				label="Benutzer des Standorts können die Web-App verwenden"
				data-testid="division-form-hasWebAppAccess"
				{...register("hasWebAppAccess")}
			/>

			<CheckboxToggle
				label="Nutzer dürfen Beleg für Drittpersonen einreichen. Wenn aktiviert, können die Nutzer in der App über ein Häkchen angeben, dass der Beleg von einer Drittperson ist."
				data-testid="division-form-allowThirdPartyVouchers"
				{...register("allowThirdPartyVouchers")}
			/>
		</Container>
	);
}
