import {
	DropDownButton,
	Icon,
	QueryStateIndicators,
	Tooltip,
} from "@components";
import React, { useContext } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import ContractsPageContext from "../ContractsPageContext";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { defer } from "@util/helpers";
import { Contract } from "@models";
import { alertStyle } from "@components/ErrorAlert";

type Props = {
	contract: Contract;
};

export default function ContractControlsDropdown(props: Props) {
	const pageContext = useContext(ContractsPageContext);
	const { contractService } = useServices();
	const [deleteContractFn, deleteQueryResult] = useAsyncCommand(() =>
		contractService.deleteContract(props.contract)
	);

	const deleteContractDialog = useDialog();
	const onSelectDeleteContract = () => {
		deleteContractDialog.showConfirmDialog({
			title: "Dauerbeleg löschen",
			text: "Dauerbeleg wirklich löschen? Dadurch wird es auch aus bereits verarbeiteten Budgets entfernt und alle mit dem Dauerbeleg verbundenen Belege werden unwiderruflich gelöscht. Es wird dringend empfohlen, stattdessen die Verfügbarkeit anzupassen.",
			confirmText: "Löschen",
			onConfirm: async () => {
				const result = await deleteContractFn();
				if (result.state === "success") {
					await defer(() => pageContext.triggerRefresh());
				}
			},
		});
	};

	return (
		<>
			<DropDownButton
				variant="outline-primary"
				id="contractControlsDropdown"
				title={
					<Tooltip
						id="contractControlsDropdownButton"
						tooltip={"Einstellungen"}
					>
						<Icon.Settings />
					</Tooltip>
				}
				size="sm"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<Dropdown.Item onSelect={onSelectDeleteContract}>
					Dauerbeleg Löschen
				</Dropdown.Item>
			</DropDownButton>
			<QueryStateIndicators queryState={deleteQueryResult} />

			{deleteQueryResult.state === "success" && (
				<Alert style={alertStyle} variant="success">
					Dauerbeleg erfolgreich gelöscht
				</Alert>
			)}
		</>
	);
}
