import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";
import { colors } from "@theme/theming";

export const buttonStyleMixin = css`
	:not(.btn-sm) {
		padding: 8px;
		margin: 2px;
	}

	font-weight: bold;

	transition: 0.3s ease all;
	text-transform: uppercase;

	&.btn-outline-secondary {
		border-style: none;
	}
	&.btn {
		border-radius: 5px;
		font-size: 14px;
		padding-inline: 20px;
	}

	&.btn-success {
		background: ${colors.success.s300};
	}
	&.btn-danger {
		background: ${colors.danger.d300};
	}
	&.btn-warning {
		background: ${colors.caution.c300};
	}
	&.btn-primary {
		background: ${colors.primary.p200} !important;
		color: ${colors.white} !important;
		border-color: ${colors.primary.p200} !important;
		&:hover,
		&:focus,
		&:active {
			background: ${colors.primary.p300} !important;
			box-shadow: 0 0 0 0.2rem ${colors.primary.p200}80 !important;
		}
		&:disabled,
		&:hover:disabled {
			background: ${colors.primary.p200} !important;
			box-shadow: unset !important;
		}
	}
	&.btn-outline-primary {
		background: ${colors.white} !important;
		border-color: ${colors.primary.p200} !important;
		color: ${colors.primary.p200} !important;
		&:hover,
		&:active,
		&:focus {
			border-color: ${colors.primary.p200} !important;
			background: ${colors.primary.p200} !important;
			box-shadow: 0 0 0 0.2rem ${colors.primary.p200}80 !important;
			color: ${colors.white} !important;
		}
	}
	&.btn-outline-danger {
		background: ${colors.white} !important;
		border-color: ${colors.danger.d300} !important;
		color: ${colors.danger.d300} !important;
		&:hover,
		&:active,
		&:focus {
			border-color: ${colors.danger.d300} !important;
			background: ${colors.danger.d300} !important;
			box-shadow: 0 0 0 0.2rem ${colors.danger.d300}80 !important;
			color: ${colors.white} !important;
		}
	}
`;

export default styled(Button)`
	${buttonStyleMixin}
`;
