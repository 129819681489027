import { ErrorBase } from "@util/errors";

export class NoAcceptedCsvImportError extends ErrorBase {
	constructor() {
		super("Error: Not an accepted CSV Import Method.");
	}
}

export class DefaultCsvError extends ErrorBase {
	constructor() {
		super("Error: Ein Fehler ist aufgetreten.");
	}
}

export class PayloadTooLargeError extends ErrorBase {
	constructor() {
		super(
			"Die ausgewählte Datei ist zu groß. Überprüfe, ob du die richtige Datei ausgewählt hast"
		);
	}
}

export class CouponsAlreadyAssignedError extends ErrorBase {
	constructor() {
		super(
			"Es gibt bereits zugewiesene Coupons für diese Division und den Abrechnungsmonat."
		);
	}
}
export class NoCouponsInCSVError extends ErrorBase {
	constructor() {
		super(
			"Keine Coupons konnten in der hochgeladenen CSV-Datei gefunden werden."
		);
	}
}
export class CouponsAmountMismatchError extends ErrorBase {
	constructor(
		props: {
			extArticleId: string;
			orderedCoupons: number;
			receivedCoupons: number;
		}[]
	) {
		const formatCouponCount = (coupons: number) => {
			return `${coupons} ${coupons > 1 ? "Coupons" : "Coupon"}`;
		};
		const articlesErrorMessage = props.reduce(
			(acc, { extArticleId, orderedCoupons, receivedCoupons }) =>
				acc +
				`Artikel ${extArticleId}: Es wurden ${formatCouponCount(
					orderedCoupons
				)} in der CSV-Datei erwartet, jedoch wurden ${formatCouponCount(
					receivedCoupons
				)} erhalten\n`,
			""
		);
		super(articlesErrorMessage);
	}
}
