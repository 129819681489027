import React from "react";
import {
	Card,
	CardContent,
	CardsListContainer,
	ExpandCard,
	FlexCol,
	FlexRow,
	IconContainer,
	Text,
} from "@components";
import { Waypoint } from "@models/travel/Waypoint";
import {
	OverrideDistance,
	RouteLeg,
	TransportationType,
} from "@models/travel/RouteLeg";
import { isDefined } from "@util/isDefined";
import styled from "styled-components";
import TransportModeIcon from "./TransportModeIcon";
import VoucherSection from "./VoucherSection";

type Props = {
	waypoints: ReadonlyArray<Waypoint>;
};

type RouteDetail = {
	startPointAddress: string;
	startPointCity: string;
	endPointAddress: string;
	endPointCity: string;
	distance: number | null;
	overrideDistance: OverrideDistance | null;
	mode: TransportationType;
	voucherId: string | null;
};

export default function WaypointList(props: Props) {
	const { waypoints } = props;

	const routeDetails: RouteDetail[] = waypoints
		.map((waypoint: Waypoint, index: number) => {
			if (waypoint.legToNext !== null) {
				return {
					startPointAddress: waypoint.address.address,
					startPointCity: waypoint.address.city,
					endPointAddress: waypoints[index + 1].address.address,
					endPointCity: waypoints[index + 1].address.city,
					distance: getDistance(waypoint.legToNext),
					overrideDistance: getOverrideDistance(waypoint.legToNext),
					mode: waypoint.legToNext.transportation.mode,
					voucherId:
						waypoint.legToNext.transportation.mode === TransportationType.Other
							? waypoint.legToNext.transportation.voucherId
							: null,
				};
			}
			return undefined;
		})
		.filter(isDefined);

	return (
		<CardsListContainer>
			<h5>Route</h5>
			{routeDetails.map((leg: RouteDetail, index: number) => {
				if (leg.overrideDistance)
					return (
						<ExpandCard
							key={index}
							testId={"route-card"}
							header={<RouteCard leg={leg} />}
						>
							{leg.overrideDistance && (
								<RouteCardContent>
									<FlexCol gap={0.5}>
										<FlexRow gap={2}>
											<Text bold>vom System übermittelt: </Text>
											<span>{leg.distance} km</span>
										</FlexRow>
										<FlexRow gap={2}>
											<Text bold>vom Nutzer übermittelt:</Text>
											<span>{leg.overrideDistance.distance} km</span>
										</FlexRow>
									</FlexCol>
									<FlexRow gap={2}>
										<Text bold>Kommentar:</Text>
										<p>{leg.overrideDistance.reason}</p>
									</FlexRow>
								</RouteCardContent>
							)}
						</ExpandCard>
					);
				return (
					<Card key={index} data-testid={"route-card"}>
						<RouteCard leg={leg} />
					</Card>
				);
			})}
			{routeDetails.length === 0 && <div>Keine Route angegeben</div>}
		</CardsListContainer>
	);
}

const RouteCard = ({ leg }: { leg: RouteDetail }) => {
	return (
		<RouteCardHeder>
			<FlexContainer>
				<div>
					<div>{leg.startPointAddress}</div>
					<div style={{ marginBottom: "10px" }}>{leg.startPointCity}</div>
					<div>{leg.endPointAddress}</div>
					<div>{leg.endPointCity}</div>
				</div>

				<Container>
					<div>
						<StyledIconContainer>
							<TransportModeIcon mode={leg.mode} />
							{getTransportationMode(leg.mode)}
						</StyledIconContainer>

						{leg.overrideDistance && (
							<DistanceContainer>
								{leg.overrideDistance.distance} km
							</DistanceContainer>
						)}
						{leg.distance && (
							<DistanceContainer strike={!!leg.overrideDistance}>
								{leg.distance} km
							</DistanceContainer>
						)}
					</div>
				</Container>
			</FlexContainer>
			{leg.mode === TransportationType.Other && (
				<>
					<hr />
					{leg.voucherId ? (
						<VoucherSection voucherId={leg.voucherId} />
					) : (
						<span>Beleg bereits in Hinfahrt enthalten</span>
					)}
				</>
			)}
		</RouteCardHeder>
	);
};

const RouteCardHeder = styled.div`
	width: 100%;
`;

const RouteCardContent = styled(CardContent)`
	display: flex;
	padding: 2rem 2rem 2rem 4rem;
	> * {
		flex: 1;
	}
`;

const FlexContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
`;

const Container = styled.div`
	display: flex;
	margin-left: auto;
`;

const DistanceContainer = styled.div<{ strike?: boolean }>`
	text-align: right;
	font-weight: ${(props) => (props.strike ? "normal" : "bold")};
	font-size: 1rem;
	${(props) => props.strike && "text-decoration: line-through"};
`;

const StyledIconContainer = styled(IconContainer)`
	font-size: 1.5rem;
`;

function getDistance(leg: RouteLeg): number | null {
	if (
		leg.transportation.mode === TransportationType.PrivateVehicle ||
		leg.transportation.mode === TransportationType.BusinessVehicle
	) {
		return leg.transportation.distance / 1000;
	}
	return null;
}

function getOverrideDistance(
	leg: RouteLeg
): { distance: number; reason: string } | null {
	if (
		leg.transportation.mode === TransportationType.PrivateVehicle ||
		leg.transportation.mode === TransportationType.BusinessVehicle
	) {
		return (
			leg.transportation.overrideDistance && {
				distance: leg.transportation.overrideDistance.distance / 1000,
				reason: leg.transportation.overrideDistance.reason,
			}
		);
	}
	return null;
}

function getTransportationMode(mode: TransportationType) {
	switch (mode) {
		case TransportationType.PrivateVehicle:
			return "Privates Fahrzeug";
		case TransportationType.BusinessVehicle:
			return "Geschäfts Fahrzeug";
		case TransportationType.NoCosts:
			return "Keine Kosten"; // TODO: confirm
		case TransportationType.Other:
			return "ÖPNV";
		default:
			return null;
	}
}
