import React from "react";
import styled from "styled-components";
import { Contract, NewContract } from "@models";
import {
	SmallText,
	LargeText,
	FlexCol,
	Icon,
	Tooltip,
	Badges,
} from "@components";
import { formatDate } from "../../../../components/forms/datePickerUtils";
import { ProductIcon } from "@components";
import { getDateFlags, Currency, formatCategoryName } from "@util";
import { isNewContract } from "../util";
import ContractControlsDropdown from "./ContractControlsDropdown";
import { NO_CORRESPONDING_BUDGET_MESSAGE } from "../util";
import { useStaticData } from "@hooks";
import { colors } from "@theme/theming";

type Props = {
	contract: Contract | NewContract;
	hasCorrespondingBudget: boolean;
	expanded?: boolean;
	className?: string;
};

export function ContractCardHeader(props: Props) {
	const { contract, hasCorrespondingBudget } = props;

	const { isExpired, isInFuture } = getDateFlags(
		contract.startDate,
		contract.endDate
	);

	const isNew = isNewContract(contract);
	const { getProductFor, getCategory } = useStaticData();
	const product = getProductFor(contract.category);
	const category = getCategory(contract.category);

	return (
		<>
			<FlexCol
				css={`
					min-width: 8em;
				`}
			>
				<Amount title="Betrag">
					{Currency.format(contract.amount, { hideZeroDecimals: true })}
				</Amount>
			</FlexCol>
			<FlexCol
				css={`
					min-width: 2em;
					font-size: 2em;
				`}
			>
				<ProductIcon productId={product.id} />
			</FlexCol>
			<FlexCol
				css={`
					min-width: 20em;
				`}
			>
				<LargeText>{formatCategoryName(category)}</LargeText>
				<SmallText translate="no">{contract.name}</SmallText>
			</FlexCol>
			<FlexCol
				css={`
					min-width: 10em;
				`}
			>
				<SmallText>
					<span title="von">von: {formatDate(contract.startDate)}</span>
				</SmallText>
				<SmallText title="bis">
					bis: {contract.endDate ? formatDate(contract.endDate) : "unbefristet"}
				</SmallText>
			</FlexCol>
			{isNew && <Badges.New />}
			{isExpired && <Badges.Expired />}
			{isInFuture && <Badges.InFuture />}
			{!hasCorrespondingBudget && !props.expanded && (
				<FlexCol
					css={`
						min-width: 10em;
					`}
				>
					<Tooltip
						id="no-budget-warning"
						tooltip={NO_CORRESPONDING_BUDGET_MESSAGE}
					>
						<div style={{ color: colors.caution.c300 }}>
							<span
								css={`
									font-size: 2em;
								`}
							>
								<Icon.Warning /> Kein Budget!
							</span>
						</div>
					</Tooltip>
				</FlexCol>
			)}

			<Spacer />
			{/* If more options are added in the dropdwon then this condition should be applied only to delete contract option */}
			{"contractId" in contract && (
				<ContractControlsDropdown contract={contract} />
			)}
		</>
	);
}

const Amount = styled.div`
	font-size: 1.5rem;
`;

const Spacer = styled.div`
	width: 100%;
`;
