type NonFunctionPropertyNames<T> = {
	[K in keyof T]: T[K] extends (...props: any[]) => any ? never : K;
}[keyof T];
type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

/** all of the properties/attributes/fields in T without functions */
export type Properties<T> = NonFunctionProperties<T>;

/* eslint-disable @typescript-eslint/ban-types */
/** recursively makes all properties optional */
export type DeepPartial<T> = T extends Function
	? T
	: T extends object
	? { [P in keyof T]?: DeepPartial<T[P]> }
	: T;
/* eslint-enable @typescript-eslint/ban-types */

/** removes the `readonly` flag from the attributes of a type */
export type Mutable<T> = {
	-readonly [k in keyof T]: T[k];
};

/** recursively removes the `readonly` flag from the attributes of a type */
export type DeepMutable<T> = {
	-readonly [P in keyof T]: DeepMutable<T[P]>;
};

const None = Symbol();

type FullValue<T> = {
	value: T;
};

/**
 * Can be used to declare a type that can have a value or be empty without using undefined or null,
 * e.g. when fetching some data from the backend that can be present or absent.
 *
 * @example
 * ```ts
 * if (<item exists>) {
 * 	myOptional = Optional.some(item);
 * } else {
 * 	myOptional = Optional.none<ItemType>();
 * }
 *
 * if (myOptional.hasValue()) {
 * 	// use myOptional.value
 * }
 * ```
 */
export class Optional<T> {
	private constructor(private _value: T | typeof None) {}

	hasValue(): this is FullValue<T> {
		return this._value !== None;
	}

	get value() {
		return this._value;
	}

	static some<T>(value: T) {
		return new Optional(value);
	}

	static none<T>() {
		return new Optional<T>(None);
	}
}

/**
 * Returns the type of the Properties of a React function component, assuming it's the first parameter
 */
export type ComponentProps<T extends (...args: any) => any> = Parameters<T>[0];

export type ElementType<T extends ReadonlyArray<unknown>> =
	T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export type DateInterval = {
	start: Date;
	end: Date;
};
