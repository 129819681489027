import { ErrorAlert, ErrorImage, PageContainer } from "@components";
import React from "react";

export default function UnauthorizedPage() {
	return (
		<PageContainer>
			<ErrorAlert>
				Du hast nicht die notwendigen Berechtigungen um diese App zu benutzen.
			</ErrorAlert>
			<ErrorImage />
		</PageContainer>
	);
}
