import React from "react";
import styled from "styled-components";
import { NewBudgetMaster, UpdatedBudgetMaster } from "@models";
import { Currency } from "@util/formatting";

export default function BudgetMasterAmountsSummary(props: {
	budgetMaster: UpdatedBudgetMaster | NewBudgetMaster;
}) {
	const amount = getAmountSums(props.budgetMaster);
	return (
		<>
			<Amount title="Monatliches Budget">
				{Currency.format(amount.monthly, { hideZeroDecimals: true })}
			</Amount>
			{amount.daily && (
				<Amount daily title="Tägliches Budget">
					{Currency.format(amount.daily, { hideZeroDecimals: true })} / Tag
				</Amount>
			)}
		</>
	);
}

const Amount = styled.div`
	font-size: ${(props: { daily?: boolean }) =>
		props.daily ? "1.1rem" : "1.5rem"};
`;

function getAmountSums(budgetMaster: UpdatedBudgetMaster | NewBudgetMaster) {
	return budgetMaster.amounts.reduce(
		(sum, curr) => {
			return {
				monthly: sum.monthly + curr.maxAmountMonth,
				daily: curr.maxAmountDay
					? (sum.daily ?? 0) + curr.maxAmountDay
					: sum.daily,
			};
		},
		{ monthly: 0, daily: undefined } as {
			monthly: number;
			daily: number | undefined;
		}
	);
}
