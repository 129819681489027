import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { assertDefined, tryConvertKnownError } from "@service/util";
import { QueryState } from "./useFetchedData";

type PromiseFn<P extends any[], R> = (...args: P) => Promise<R>;
type TriggerFn<P extends any[], R> = (...args: P) => Promise<QueryState<R>>;

type AsyncCmdOptions = {
	/**
	 * If this option is `true`, when an Error is thrown while executing `queryFn` the error will be rethrown.
	 * Otherwise it will only be returned to the `QueryState`
	 *
	 * Defaults to `true`
	 * */
	rethrowError: boolean;
};

export default function useAsyncCommand<R, P extends any[]>(
	queryFn: PromiseFn<P, R>,
	options?: AsyncCmdOptions
): [
	TriggerFn<Parameters<typeof queryFn>, R>,
	QueryState<R>,
	Dispatch<SetStateAction<number | undefined>>
] {
	const [state, setState] = useState<QueryState<R>["state"]>("idle");
	const [fetchedData, setFetchedData] = useState<R | undefined>(undefined);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [progress, setProgress] = useState<number | undefined>(undefined);

	const trigger = useCallback(
		async (...args: Parameters<typeof queryFn>) => {
			try {
				setState("fetching");

				const queryResult = await queryFn(...args);
				setFetchedData(queryResult);

				setError(undefined);
				setProgress(100);
				setState("success");

				return getQueryState("success", undefined, queryResult);
			} catch (error) {
				const convertedError = tryConvertKnownError(error) ?? error;
				console.error(convertedError);

				setError(convertedError as Error);
				setState("error");

				if (options?.rethrowError ?? true) throw convertedError;
				return getQueryState<R>("error", convertedError as Error, undefined);
			}
		},
		[queryFn, options]
	);

	const queryState = getQueryState(state, error, fetchedData, progress);

	return [trigger, queryState, setProgress];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
function getQueryState<T>(
	state: string,
	error?: Error,
	fetchedData?: T,
	progress?: number
): QueryState<T> {
	switch (state) {
		case "error":
			return {
				state: "error",
				error: assertDefined(error),
				data: null,
			};
		case "fetching":
			return {
				state: "fetching",
				data: fetchedData!,
				progress: progress,
			};
		case "success":
			return {
				state: "success",
				data: fetchedData!,
			};
		case "idle":
			return { state: "idle", data: null };
		default:
			throw new Error(`unknown state ${state}`);
	}
}
