import { CongratsOccasion, CongratsOccasions, CongratsSettings } from "@models";
import { Properties } from "@util/types";
import Product from "../Product";
import { ProductDeserializer } from "./ProductDeserializer";

export class ProductCongrats extends Product<CongratsSettings> {
	constructor(props: Properties<ProductCongrats>) {
		super(props);
		this.congratsOccasions = props.congratsOccasions;
	}

	readonly congratsOccasions: CongratsOccasion[];

	static fromJson(data: any): ProductCongrats {
		return new ProductCongratsDeserializer().deserialize(data);
	}

	override getDefaultSettings(): CongratsSettings {
		const congratsSettings: CongratsSettings = {
			occasion: "anniversary-5-years",
			validTimeInDays: 30,
			greetingImageFileKey: null,
			greetingVideoFileKey: null,
			customOccasion: null,
			restrictStreet: null,
			restrictPostalCodes: null,
			restrictSupplier: null,
			greetingText: ProductCongrats.getGreetingText("anniversary-5-years"),
		};
		return congratsSettings;
	}

	static getGreetingText(occasion: string) {
		switch (occasion) {
			case CongratsOccasions.BirthOfChild: {
				return "Wir gratulieren Euch recht herzlich zur Geburt Eures Kindes. Wir wünschen Euch und Eurem neuen Familienzuwachs alles Gute, Gesundheit und einen guten Start in Euer gemeinsames Leben. Es kommt eine spannende Zeit auf Euch zu. Dafür wünschen wir Euch Ausdauer, Kraft und ganz viel Freude.";
			}
			case CongratsOccasions.Birthday: {
				return "Im Auftrag Deines Arbeitgebers 🏭 haben wir 👬 die tolle Aufgabe, Dir heute ganz herzlich zu Deinem Geburtstag 🎁 zu gratulieren. Wir wünschen Dir, dass auch das kommende Lebensjahr Dich mit vielen schönen Erfahrungen beschenken wird und wünschen Dir auf diesem Wege weiterhin alles Gute 👍, Erfolg 🥇, Gesundheit 💪 und Lebensfreude 🥳. Bitte lade einen Beleg vom nächsten Restaurantbesuch mit Freunden oder Einkauf leckerer Speisen und Getränke innerhalb der nächsten 30 Tage bei LOFINO 📲 hoch.";
			}
			case CongratsOccasions.Anniversary5Years: {
				return "Wir gratulieren zu Deinem 5-jährigen Jubiläum! Wir sagen Danke für fünf Jahre engagierte Mitarbeit. Durch Deinen persönlichen Einsatz und großes Engagement hast Du in den letzten Jahren in besonderer Weise zum Erfolg unserer Firma beigetragen. Wir wünschen Dir für die Zukunft weiterhin viel Spaß und Erfolg bei uns im Unternehmen.";
			}
			case CongratsOccasions.Wedding: {
				return "Herzliche Glückwünsche zur Hochzeit! Für Euren gemeinsamen Lebensweg wünschen wir Euch von Herzen alles Gute und eine glückliche gemeinsame Zukunft.";
			}
			case CongratsOccasions.Bonus: {
				return "Wir möchten Dir hiermit für Deinen Arbeitseinsatz und Deine erbrachten Leistungen danken. Wir sind überzeugt, dass Du Dich auch künftig mit dem gleichen Engagement für die Ziele unseres Unternehmens einsetzen wirst.";
			}
			case CongratsOccasions.Custom: {
				return "Wir gratulieren und wünschen Dir für Deine Zukunft alles Gute und weiterhin viel Spaß und Erfolg bei uns im Unternehmen.";
			}
		}
		return "";
	}
}

class ProductCongratsDeserializer extends ProductDeserializer<
	CongratsSettings,
	ProductCongrats
> {
	deserialize(data: any): ProductCongrats {
		return new ProductCongrats(
			this.deserializeProps({
				...data,
				congratsOccasions: data.congratsOccasions.map(
					CongratsOccasion.fromJson
				),
			})
		);
	}
}
