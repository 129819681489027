import { CurrencyInput, InputValidation, Label } from "@components";
import DatePicker from "@components/forms/DatePicker";
import { Division } from "@models";
import PricingRecord from "@models/PricingRecord";
import { isAfter, isBefore } from "date-fns";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import FlatFeePerEmployeeSettings from "./FlatFeePerEmployeeSettings";
import FlatFeePerModuleAndEmployeeSettings from "./FlatFeePerModuleAndEmployeeSettings";
import FlatFeeSettings from "./FlatFeeSettings";
import { PricingCardProps } from "./IPricingCardProps";

export default function PricingRecordContent(
	props: PricingCardProps & { dateOverlapError?: { message: string } }
) {
	const { pricingSettings, index, dateOverlapError } = props;
	const { setValue, control } = useFormContext<Division>();
	const pricingRecord: PricingRecord = pricingSettings[index];

	return (
		<Container>
			<FormGroup key={"availableFromUntil"}>
				<InputValidation error={dateOverlapError}>
					<span>
						<Label htmlFor={"availableFrom"}>Gültig von</Label>
						<Controller
							control={control}
							name={`pricingSettings.${index}.availableFrom`}
							defaultValue={pricingRecord?.availableFrom || null}
							rules={{
								validate: () => !dateOverlapError,
							}}
							render={({ field }) => (
								<DatePicker
									{...field}
									isDayDisabled={(day) =>
										!!pricingRecord.availableUntil &&
										isAfter(day, pricingRecord.availableUntil)
									}
									ref={field.ref}
									placeholder="unbefristet"
									canClearDate
									onClearDate={() =>
										setValue(`pricingSettings.${index}.availableFrom`, null, {
											shouldDirty: true,
										})
									}
								/>
							)}
						/>
						<Label htmlFor={"availableUntil"}>bis</Label>{" "}
						<Controller
							control={control}
							name={`pricingSettings.${index}.availableUntil`}
							defaultValue={pricingRecord?.availableUntil || null}
							rules={{
								validate: () => !dateOverlapError,
							}}
							render={({ field }) => (
								<DatePicker
									isDayDisabled={(day) =>
										!!pricingRecord.availableFrom &&
										isBefore(day, pricingRecord.availableFrom)
									}
									{...field}
									ref={field.ref}
									canClearDate
									placeholder="unbefristet"
									onClearDate={() =>
										setValue(`pricingSettings.${index}.availableUntil`, null, {
											shouldDirty: true,
										})
									}
								/>
							)}
						/>
					</span>
				</InputValidation>
			</FormGroup>
			<br />
			<FormGroup as={Row}>
				<Label column sm="3">
					Mindesthonorar (€)
				</Label>
				<Col style={{ marginLeft: 24 }} sm="5">
					<Controller
						control={control}
						name={`pricingSettings.${index}.minimumFee`}
						defaultValue={pricingRecord?.minimumFee || 0}
						rules={{
							required: true,
							min: 0.0,
						}}
						render={({ field }) => (
							<CurrencyInput
								placeholder="Bitte eintragen"
								{...field}
								min={0.0}
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<FlatFeePerEmployeeSettings {...props} />
			<FlatFeePerModuleAndEmployeeSettings {...props} />
			<FlatFeeSettings {...props} />
		</Container>
	);
}

const Container = styled.div`
	padding: 2rem;
`;
