import { BudgetMaster, NewBudgetMaster } from "@models";
import {
	isBefore,
	isSameDay,
	isSameYear,
	startOfDay,
	startOfMonth,
	subDays,
} from "date-fns";
import { collisionErrorMessage, isDateRangeWithin, isYearlyBudget } from ".";

type Result = {
	isValid: boolean;
	message: string | undefined;
	isWarning: boolean;
};

export function checkCollidingBudgetMasters(
	budgetMasters: BudgetMaster[],
	editingBudgetMaster: NewBudgetMaster | BudgetMaster,
	availableFrom: Date,
	availableUntil: Date | null
): Result {
	let otherBudgetMasters = budgetMasters.filter(
		(bm) => bm.category === editingBudgetMaster.category
	);

	if ("budgetMasterId" in editingBudgetMaster) {
		otherBudgetMasters = otherBudgetMasters.filter(
			(bm) => bm.budgetMasterId !== editingBudgetMaster.budgetMasterId
		);
	}

	const colliding = otherBudgetMasters.filter((bm: BudgetMaster) =>
		isDateRangeWithin(availableFrom, availableUntil, bm)
	);

	let endDate = undefined;
	let message: string | undefined;
	let isWarning = false;
	const errorMessage = collisionErrorMessage.default;
	if (colliding.length === 1) {
		if (isBefore(colliding[0].availableFrom, startOfDay(availableFrom))) {
			endDate = subDays(availableFrom, 1);
			if (!isSameDay(availableFrom, startOfMonth(availableFrom))) {
				message = collisionErrorMessage.monthCollision(availableFrom);
				isWarning = false;
			} else {
				message = collisionErrorMessage.monthCollisionChangedEndDate(
					availableFrom,
					endDate
				);
				isWarning = true;
			}
		} else {
			message = errorMessage;
			isWarning = false;
		}
	}

	if (colliding.length > 1) {
		message = errorMessage;
		isWarning = false;
	}

	const hasAnnualCollision = checkCollidingYearlyBudget(
		editingBudgetMaster,
		otherBudgetMasters,
		availableFrom,
		availableUntil
	);

	if (hasAnnualCollision) {
		message = collisionErrorMessage.annualCollision;
		isWarning = false;
	}

	return {
		isValid: message === undefined,
		message,
		isWarning,
	};
}

function checkCollidingYearlyBudget(
	bg: NewBudgetMaster | BudgetMaster,
	otherBudgetMasters: BudgetMaster[],
	availableFrom: Date,
	availableUntil: Date | null
): boolean {
	if (!isYearlyBudget(bg.category)) {
		return false;
	}

	return (
		otherBudgetMasters.filter((bm: BudgetMaster) => {
			return (
				(availableUntil && isSameYear(availableUntil, bm.availableFrom)) ||
				(bm.availableUntil && isSameYear(availableFrom, bm.availableUntil)) ||
				(!bm.availableUntil && !availableUntil)
			);
		}).length > 0
	);
}
