import React from "react";

type ReadonlyProps = { value: string; [k: string]: unknown };

export const ReadonlyText = React.forwardRef<HTMLDivElement, ReadonlyProps>(
	(props, ref) => {
		const { value, ...rest } = props;
		return (
			<div ref={ref} {...rest}>
				{value}
			</div>
		);
	}
);
