import React, { useCallback } from "react";
import styled from "styled-components";
import { SectionHeader } from "@components/Cards";
import { AccountingMonth, Report } from "@models";
import { formatDateTime } from "@util";
import { AccountingMonthEvent } from "@models/AccountingMonth";
import { useFetchedData, useServices } from "@hooks";
import { QueryLoadingIndicator } from "@components";
import ReportsTable from "../reports/components/ReportsTable";

type Props = { accountingMonth: AccountingMonth };

export default function AccountingMonthCardBody({ accountingMonth }: Props) {
	const { reportService } = useServices();

	const fetchReportsFn = useCallback(
		() => reportService.getReportsByAccountingMonth(accountingMonth),
		[accountingMonth, reportService]
	);
	const [reportsQueryResult] = useFetchedData(fetchReportsFn);
	// show events in descending chronological order
	const reversedEvents = [...accountingMonth.events].reverse();

	return (
		<>
			<QueryLoadingIndicator queryState={reportsQueryResult} />
			{reportsQueryResult.data && (
				<>
					<SectionHeader>Ereignisse</SectionHeader>
					<EventsGrid>
						{reversedEvents.map((evt) => (
							<AccountingMonthEventRow
								key={evt.id}
								event={evt}
								reports={getReports(reportsQueryResult.data, evt.id)}
							/>
						))}
					</EventsGrid>
				</>
			)}
		</>
	);
}

function getReports(reports: Report[], eventId: string): Report[] {
	return reports.filter((r) => r.monthClosedEventId === eventId);
}

type AccountingMonthEventProps = {
	event: AccountingMonthEvent;
	reports: Report[];
};

function AccountingMonthEventRow(props: AccountingMonthEventProps) {
	const { event, reports } = props;
	const expectsReports = event.type === "closed";

	return (
		<>
			<span>{formatDateTime(event.time)}</span> <span>{event.displayName}</span>
			{expectsReports && (
				<SingleRow>
					<ReportsTable reports={reports} />
				</SingleRow>
			)}
		</>
	);
}

const EventsGrid = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	width: 100%;
	column-gap: 1rem;
	margin-left: 1rem;
`;

const SingleRow = styled.div`
	grid-column: 1 / span 2;
	padding-left: 2rem;
	padding-bottom: 1rem;
`;
