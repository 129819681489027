import * as yup from "yup";

import Product from "../Product";
import { ProductDeserializer } from "./ProductDeserializer";

export type TravelSettings = {
	mealsFlatrate: boolean;
	overnightStaysFlatrate: boolean;
	transportFlatrate: boolean;
};

export class ProductTravel extends Product<TravelSettings> {
	static fromJson(data: any): Product<any> {
		return new ProductTravelDeserializer().deserialize(data);
	}

	static deserializeSettings(data: any): TravelSettings {
		return new ProductTravelDeserializer().deserializeSettings(data);
	}

	getDefaultSettings() {
		const settings: TravelSettings = {
			mealsFlatrate: true,
			overnightStaysFlatrate: true,
			transportFlatrate: true,
		};
		return settings;
	}
}

class ProductTravelDeserializer extends ProductDeserializer<
	TravelSettings,
	ProductTravel
> {
	deserialize(data: any): ProductTravel {
		return new ProductTravel(this.deserializeProps(data));
	}

	deserializeSettings(data: any): TravelSettings {
		return settingsSchema.validateSync(data);
	}
}

const settingsSchema = yup.object().shape({
	mealsFlatrate: yup.boolean().required(),
	overnightStaysFlatrate: yup.boolean().required(),
	transportFlatrate: yup.boolean().required(),
});
