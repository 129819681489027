import React from "react";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import { colors } from "@theme/theming";

type Props = {
	tooltip?: string;
} & React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

export default React.forwardRef<HTMLButtonElement, Props>(
	(props: Props, ref) => {
		const { tooltip, ...rest } = props;

		const button = <Button type="button" {...rest} ref={ref} />;
		if (tooltip) {
			return (
				<Tooltip id="tooltip-icon-button" tooltip={tooltip}>
					{button}
				</Tooltip>
			);
		} else {
			return button;
		}
	}
);

const Button = styled.button`
	display: inline;
	color: ${colors.primary.p200};
	background: transparent;
	padding: 5px;
	border-radius: 100%;
	border: 0;

	svg {
		color: ${colors.primary.p200};
	}

	&:hover&:not(:disabled) {
		cursor: pointer;

		border-radius: 20%;
		background-color: ${colors.primary.p200};
		color: ${colors.white};

		svg {
			color: ${colors.white};
		}
	}

	&:disabled {
		border-radius: 20%;
		background-color: ${colors.gray.g100};
		svg {
			color: ${colors.gray.g400};
		}

		cursor: not-allowed;
	}
	margin: 5px;
`;
