import { usePaginatedFetchedData, useServices } from "@hooks";
import { Voucher } from "@models";
import { QueryOptions } from "@service/types";
import { useCallback, useEffect, useState } from "react";
import { ReviewFilters } from "../components/FilterControls";

export function useVoucherList(activeFilters: ReviewFilters) {
	const { voucherService } = useServices();
	const [vouchers, setVouchers] = useState<Voucher[] | null>(null);
	const fetchVouchers = useCallback(
		(options: QueryOptions) =>
			voucherService.queryVouchers(options, activeFilters),
		[voucherService, JSON.stringify(activeFilters)]
	);
	const [voucherQuery, triggerVoucherQuery] =
		usePaginatedFetchedData(fetchVouchers);

	useEffect(() => {
		if (voucherQuery.data && voucherQuery.state === "success") {
			setVouchers(voucherQuery.data);
		}
	}, [voucherQuery.data, voucherQuery.state]);

	const overrideVoucher = (voucher: Voucher) => {
		if (vouchers) {
			const newVouchers = vouchers.map((v) =>
				v.voucherId === voucher.voucherId ? voucher : v
			);
			setVouchers(newVouchers);
		}
	};

	return { vouchers, voucherQuery, triggerVoucherQuery, overrideVoucher };
}
