import { FormGroup, Label, Select } from "@components";
import { UpdatedBudgetMaster, NewBudgetMaster } from "@models";
import { PublicTransportTaxVariant } from "@models/products/ProductMobility";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ConfigBudgetMaster } from "./types";

type Props = {
	budgetMaster: UpdatedBudgetMaster | NewBudgetMaster;
};
export default function BudgetMasterMobilitySettings(props: Props) {
	const { control } = useFormContext<ConfigBudgetMaster>();
	return (
		<FormGroup as={Row}>
			<Label column sm={4} htmlFor="publicTransport">
				ÖPNV-Versteuerung
			</Label>
			<Col sm={8}>
				<Controller
					name={"settings.publicTransportTaxVariant" as any}
					control={control}
					defaultValue={props.budgetMaster.settings?.publicTransportTaxVariant}
					render={({ field }) => (
						<Select id="publicTransport" {...field} value={field.value ?? ""}>
							<option value=""></option>
							<option value={PublicTransportTaxVariant.Taxfree}>
								Steuerfrei
							</option>
							<option value={PublicTransportTaxVariant.Tax25}>
								25% pauschalversteuert
							</option>
						</Select>
					)}
				/>
			</Col>
		</FormGroup>
	);
}
