import { Properties } from "@util/types";

export class TripAccountingDetails {
	constructor(props: Properties<TripAccountingDetails>) {
		this.costCenter = props.costCenter;
		this.clientNumber = props.clientNumber;
		this.jobNumber = props.jobNumber;
	}

	readonly costCenter: string | null;
	readonly clientNumber: string | null;
	readonly jobNumber: string | null;

	static fromJson(data: any) {
		return new TripAccountingDetails(data);
	}
}
