import { Properties } from "@util/types";
import * as yup from "yup";

export class BudgetCorrection {
	private constructor(props: Properties<BudgetCorrection>) {
		this.correctionAmount = props.correctionAmount;
		this.date = props.date;
	}

	readonly correctionAmount: number;
	readonly date: Date;

	public static fromJson(data: any): BudgetCorrection {
		return new BudgetCorrection(schema.validateSync(data));
	}
}

const schema = yup.object().shape({
	correctionAmount: yup.number().required(),
	date: yup.date().required(),
});
