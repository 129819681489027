import { CardContent, ExpandCard } from "@components";
import { Voucher } from "@models";
import ConnectedReviewComponents from "./review/components/ConnectedReviewComponents";
import React, { useEffect, useRef, useState } from "react";
import { ExpandCardRef } from "components/ExpandCard";
import VoucherCardHeader from "./VoucherCardHeader";
import { FormProvider, useForm } from "react-hook-form";
import { EditableVoucher } from "@models/EditableVoucher";
import { useServices } from "@hooks";

type Props = {
	voucher: Voucher;
	isInitiallyExpanded?: boolean;
};

export default function VoucherCard(props: Props) {
	const [voucher, setVoucher] = useState(props.voucher);

	const form = useForm({
		defaultValues: new EditableVoucher(props.voucher),
	});

	useEffect(() => {
		// reset the form every time the voucher input changes
		form.reset(new EditableVoucher(voucher));
	}, [voucher]);

	const services = useServices();

	const handleOnExpand = () => {
		// refresh to get recent state from backend, to make sure most current data is reviewed
		void services.voucherService
			.getVoucherById(voucher.voucherId)
			.then((freshVoucher) => {
				setVoucher(freshVoucher);
			});
	};

	useEffect(() => {
		// clear local state when input prop changes
		setVoucher(props.voucher);
	}, [props.voucher]);

	return (
		<FormProvider {...form}>
			<VoucherCardEmbedded
				{...props}
				voucher={voucher}
				onExpand={handleOnExpand}
			/>
		</FormProvider>
	);
}

export function VoucherCardEmbedded(props: Props & { onExpand?: () => void }) {
	const { voucher, isInitiallyExpanded, onExpand } = props;
	const [expanded, setExpanded] = useState(false);

	const header = <VoucherCardHeader voucher={voucher} expanded={expanded} />;

	const cardRef = useRef<ExpandCardRef>(null);

	const handleClose = () => {
		cardRef.current?.collapse?.();
	};
	return (
		<ExpandCard
			testId={"voucher-card-" + voucher.voucherId}
			ref={cardRef}
			header={header}
			onExpanded={() => {
				setExpanded(true);
				onExpand?.();
			}}
			onCollapsed={() => {
				setExpanded(false);
			}}
			isInitiallyExpanded={isInitiallyExpanded}
		>
			{() => (
				<CardContent>
					<ConnectedReviewComponents voucher={voucher} onClose={handleClose} />
				</CardContent>
			)}
		</ExpandCard>
	);
}
