import { DomainEvent } from "@models/DomainEvent";
import * as apiService from "../api.service";
import { Entities } from "@hooks";

async function getVoucherHistory(voucherId: string): Promise<DomainEvent[]> {
	try {
		const responseData = await apiService.GET(`voucher/${voucherId}/events`);

		return responseData.data.map((domainEvents: any) =>
			DomainEvent.fromJson(domainEvents)
		);
	} catch (e: any) {
		console.log(e);
		throw e;
	}
}

export async function getEntityHistory(
	entity: Entities,
	entityId: string
): Promise<DomainEvent[]> {
	switch (entity) {
		case "voucher":
			return await getVoucherHistory(entityId);
		case "trip":
			throw new Error("not implemented");
	}
}
