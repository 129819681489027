import React, { ComponentProps } from "react";
import { QueryState } from "../hooks/useFetchedData";
import LoadingIndicator from "./LoadingIndicator";
import QueryError, { MapErrorToElementFn } from "./QueryError";

type Props = {
	queryState: QueryState<any> | QueryState<any>[];
	mapError?: MapErrorToElementFn;
} & ComponentProps<typeof LoadingIndicator>;

export function QueryStateIndicators(props: Props) {
	const { queryState, mapError, ...rest } = props;

	let hasLoading = false;
	let hasError = false;

	const states = Array.isArray(queryState) ? queryState : [queryState];
	states.forEach((state) => {
		if (state.state === "fetching" || state.state === "initialFetching") {
			hasLoading = true;
		}
		if (state.state === "error") {
			hasError = true;
		}
	});

	if (hasLoading) {
		return <LoadingIndicator {...rest} />;
	}

	if (hasError) {
		return (
			<QueryError sticky queryState={queryState}>
				{mapError}
			</QueryError>
		);
	}
	return null;
}
