import React from "react";
import styled from "styled-components";
import {
	Button,
	ButtonContainer,
	Icon,
	LoadingIndicator,
	Pagination,
	QueryError,
	TableGeneric,
	TableStylesPrimary,
} from "@components";
import UserDetailsRow from "./UserRow/UserDetailsRow";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import Routes, {
	DivisionRouteParams,
	UsersListPageState,
} from "@service/navigation/routes";
import { Header } from "../../../components/TableGeneric/TableTypes";
import { useSelection } from "./hooks";
import BulkUserActionButton from "./BulkUserActionButton";
import BalanceAccountSettingsDropdown from "./BalanceAccount/BalanceAccountSettingsDropdown";
import { useDivisionUsers, useSortQueryParams } from "@hooks";
import { Alert } from "react-bootstrap";
import UsersListContext from "../UsersListContext";

export default function UsersList() {
	const routeParams = useRouteMatch<DivisionRouteParams>().params;
	const [usersQuery, triggerUsersQuery] = useDivisionUsers(
		routeParams.divisionId
	);
	const users = usersQuery.data;
	const sortQueryParams = useSortQueryParams();
	const routerState = useLocation<UsersListPageState>();
	const newUser = routerState.state?.newUser;

	const {
		isSelected,
		isSelectedAll,
		handleSelectAll,
		selectUser,
		selectedUsers,
	} = useSelection(usersQuery.data);

	const headers: Header[] = [
		{
			title: "PersonalNr",
			isSortable: true,
			accessor: "personalNo",
		},
		{
			title: "Vorname",
			isSortable: true,
			accessor: "firstname",
		},
		{
			title: "Nachname",
			isSortable: true,
			accessor: "lastname",
		},
		{
			title: "Email",
			isSortable: true,
			accessor: "email",
		},
		{
			title: "Kommentar intern",
		},
		{
			title: "Module",
		},
		{
			title: "Status",
		},
		{
			title: "",
		},
	];

	return (
		<>
			{usersQuery.state === "success" && !!users && (
				<UsersListContext.Provider
					value={{ users: users, triggerRefresh: triggerUsersQuery }}
				>
					<ButtonContainer>
						<Container>
							<BulkUserActionButton
								disabled={selectedUsers.length === 0}
								selectedUsers={selectedUsers}
							/>
						</Container>
						<Link
							data-testid="userspage-add-user-btn"
							to={Routes.AddUser.makePath(routeParams)}
						>
							<ButtonMiddle>Benutzer hinzufügen</ButtonMiddle>
						</Link>
						<Container>
							<BalanceAccountSettingsDropdown />
						</Container>
					</ButtonContainer>

					{!!newUser && (
						<StyledAlert>
							{`Benutzer ${newUser.firstname} ${newUser.lastname} wurde angelegt.`}

							<Link
								to={Routes.UserDetails.makePath({
									...routeParams,
									userId: newUser.userId,
								})}
							>
								<Icon.Settings /> Details
							</Link>

							<Link
								to={Routes.UserProducts.makePath({
									...routeParams,
									userId: newUser.userId,
								})}
							>
								<Icon.Modules /> Module
							</Link>
						</StyledAlert>
					)}

					<DataContainer>
						<UserListTable>
							<TableStylesPrimary>
								<TableGeneric
									headers={headers}
									data={users}
									sortOptions={sortQueryParams}
									allowSelect
									onSelectAll={handleSelectAll}
									isSelectAll={isSelectedAll}
								>
									{(user) => (
										<UserDetailsRow
											isSelected={isSelected(user)}
											onSelect={(selected) => selectUser(user, selected)}
											key={user.userId}
											user={user}
										/>
									)}
								</TableGeneric>
							</TableStylesPrimary>
						</UserListTable>

						<Pagination queryState={usersQuery} />
						<QueryError queryState={usersQuery} />

						{usersQuery.state !== "success" && <LoadingIndicator />}
					</DataContainer>
				</UsersListContext.Provider>
			)}
		</>
	);
}

const ButtonMiddle = styled(Button).attrs({ size: "sm" })`
	margin: 8px;
`;

const DataContainer = styled.div`
	position: relative;
`;

const Container = styled.div`
	margin: 8px;
`;

const StyledAlert = styled(Alert).attrs({
	variant: "success",
})`
	display: flex;
	justify-content: space-around;
`;

const UserListTable = styled.div`
	table {
		table-layout: fixed;
	}
	th {
		width: 10%;
	}
	th:first-child {
		width: 2rem;
	}
	th:nth-child(5) {
		width: 16%;
	}
	th:nth-child(6) {
		width: 20%;
	}

	th:nth-child(8),
	th:nth-child(9) {
		width: 9rem;
	}
	th,
	td {
		overflow-wrap: break-word;
	}

	@media (max-width: 1550px) {
		> div {
			padding: 0;
		}
		th:nth-child(7) {
			width: 6.7rem;
		}
		th:nth-child(8) {
			width: 7.5rem;
		}
	}
`;
