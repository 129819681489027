import React from "react";
import { PageContainer } from "@components";
import { QueryStateIndicators } from "../../components/QueryStateIndicators";
import { User } from "@models";
import BudgetMastersList from "./components/BudgetMastersList";
import ProductsPageContext from "./ProductsPageContext";
import { useBudgetMasters } from "@hooks";

type Props = {
	user: User;
};

export default function ProductsPage(props: Props) {
	const { user } = props;
	const [budgetMastersQuery, refreshBudgetMasters] = useBudgetMasters(
		user.userId
	);
	const budgetMasters = budgetMastersQuery.data;

	return (
		<PageContainer>
			<QueryStateIndicators queryState={budgetMastersQuery} />
			{!!budgetMasters && (
				<ProductsPageContext.Provider
					value={{
						budgetMasters: budgetMasters,
						triggerRefresh: refreshBudgetMasters,
					}}
				>
					<BudgetMastersList budgetMasters={budgetMasters} />
				</ProductsPageContext.Provider>
			)}
		</PageContainer>
	);
}
