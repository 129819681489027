import {
	NewBudgetMaster,
	BudgetMasterAmounts,
	UpdatedBudgetMaster,
	Product,
} from "@models";
import { ConfigBudgetMaster } from "../configuration/types";
import { unescapeCategoryId } from "./util";

export function configToBudgetMaster<
	T extends NewBudgetMaster | UpdatedBudgetMaster
>(config: ConfigBudgetMaster, defaults: T, product: Product): T {
	const amounts: BudgetMasterAmounts[] = [];

	const variant = product.variantOfId(
		config.productVariantId ?? defaults.productVariantId
	);
	const selectedCategoryIds = config.categories ?? defaults.categories;
	const selectedCategoriesInVariant = selectedCategoryIds
		.map(unescapeCategoryId)
		.filter((c) => !!variant.findCategory(c));

	// TODO: select instead of exclude
	Object.entries(config.amounts).forEach(([category, amount]) => {
		const { enabled, ...rest } = amount;
		const categoryId = unescapeCategoryId(category);
		const isInSelectedVariant = !!variant.budgetCategories.find(
			(c) => c.id === categoryId
		);
		if ((isInSelectedVariant && enabled) || defaults.category === "congrats") {
			amounts.push({ ...rest, category: categoryId });
		}
	});

	let settings = config.settings;
	if (
		defaults.category === "mobility" &&
		(config.settings?.publicTransportTaxVariant === null ||
			config.settings?.publicTransportTaxVariant === "")
	) {
		settings = null;
	}

	return {
		...defaults,
		...config,
		categories: selectedCategoriesInVariant,
		amounts,
		settings: settings,
	};
}
