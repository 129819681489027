import _ from "lodash";
import { BudgetMasterAmounts } from "@models";
import { ElementType, Properties } from "@util/types";
import { flattenCategories } from "./Category";
import { ProductDefaultDeserializer } from "./products/ProductDeserializer";
import ProductVariant from "./ProductVariant";

export const rootProductIds = [
	"shortTime",
	"lunch",
	"mobility",
	"spend",
	"web",
	"congrats",
	"fitAndRelax",
	"travel",
	"travel.budget",
	"homeAndWork",
	"sachbezug",
] as const;

export type RootProductId = ElementType<typeof rootProductIds>;

export const rootProductDescriptions: {
	[k in RootProductId]: { name: string };
} = {
	shortTime: {
		name: "Kurzarbeit",
	},
	congrats: { name: "Congrats" },
	lunch: { name: "Lunch" },
	mobility: { name: "Mobility" },
	spend: { name: "Spend" },
	web: { name: "Web" },
	fitAndRelax: { name: "Fit & Relax" },
	travel: { name: "Expenses" },
	["travel.budget"]: { name: "Travel Budget" },
	homeAndWork: { name: "Home & Work" },
	sachbezug: { name: "Sachbezug" },
};

export function getRootProductDescription(rootId: RootProductId) {
	return rootProductDescriptions[rootId];
}

export default abstract class Product<
	TSettings extends Record<string, unknown> | null = any
> {
	constructor(props: Properties<Product<TSettings>>) {
		this.name = props.name;
		this.id = props.id;
		this.variants = props.variants;
		this.variants.forEach((v) => (v.product = this));
	}

	readonly name: string;
	readonly id: RootProductId;
	readonly variants: ProductVariant[];

	variantOfId(variantId: string): ProductVariant {
		const variant = this.variants.find((v) => v.id === variantId);
		if (!variant)
			throw new Error(`variant ${variantId} not found on product ${this.id}`);
		return variant;
	}

	getDefaultAmounts(variantId = "default"): BudgetMasterAmounts[] {
		return this.variantOfId(variantId).budgetCategories.map((c) => ({
			category: c.id,
			maxAmountDay: null,
			maxAmountMonth: c.maxAmountMonth ?? 20000,
		}));
	}

	getDefaultCategories(variantId = "default"): string[] {
		const variant = this.variantOfId(variantId);
		if (this.id === "sachbezug") {
			// Sachbezug must have zero selected category.
			return [];
		}

		if (this.id === "spend" || this.id === "congrats") {
			// Spend & congrats must only have one selected category.
			const defaultCategory = variant.budgetCategories[0].subCategories?.[0];
			return defaultCategory ? [defaultCategory.id] : [];
		}

		const flattenedCategories = _.flatMap(
			variant.budgetCategories,
			flattenCategories
		);
		return flattenedCategories.filter((c) => !c.isDeprecated).map((c) => c.id);
	}

	hasBudgets(): boolean {
		return (
			this.variants.some((v) => v.budgetCategories.length > 0) &&
			this.id !== "shortTime"
		);
	}

	abstract getDefaultSettings(): TSettings;
}

export class DefaultProduct extends Product<null> {
	getDefaultSettings(): null {
		return null;
	}

	static fromJson(data: any) {
		return new ProductDefaultDeserializer().deserialize(data);
	}
}
