import { CardsListContainer, LargeText } from "@components";
import VoucherList from "@components/vouchers/VoucherList";
import { BusinessTrip, Contract, Voucher } from "@models";
import TripList from "../../../../trips/components/TripList";
import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import MonthlyContractCardHeader from "../ContractCard/MonthlyContractCardHeader";

type Props =
	| {
			vouchers: Voucher[];
	  }
	| {
			contracts: Contract[];
	  }
	| {
			trips: BusinessTrip[];
	  };

export default function MonthlyList(props: Props) {
	return (
		<Container>
			<LargeText
				css={`
					margin: 1em;
				`}
			>
				{getHeader(props)}
			</LargeText>

			{"vouchers" in props ? (
				props.vouchers.length > 0 ? (
					<ListContainer>
						<VoucherList vouchers={props.vouchers} />
					</ListContainer>
				) : (
					<StyledAlert>
						Für dieses Budget wurde kein Beleg hochgeladen
					</StyledAlert>
				)
			) : (
				<></>
			)}

			{"contracts" in props ? (
				props.contracts.length > 0 ? (
					<CardsListContainer>
						{props.contracts.map((contract: Contract) => (
							<MonthlyContractCardHeader
								key={contract.contractId}
								contract={contract}
							/>
						))}
					</CardsListContainer>
				) : (
					<StyledAlert>Für dieses Budget existiert kein Dauerbeleg</StyledAlert>
				)
			) : (
				<></>
			)}

			{"trips" in props ? (
				props.trips.length > 0 ? (
					<ListContainer>
						<TripList trips={props.trips} />
					</ListContainer>
				) : (
					<StyledAlert>Für dieses Budget existieren keine Reisen</StyledAlert>
				)
			) : (
				<></>
			)}
		</Container>
	);
}

function getHeader(props: Props) {
	if ("vouchers" in props) {
		return "Einzelbelege";
	} else if ("contracts" in props) {
		return "Dauerbelege";
	} else {
		return "Reisen";
	}
}

const StyledAlert = styled(Alert).attrs({ variant: "warning" })`
	margin: 0 2em;
`;

const ListContainer = styled.div`
	margin: 0 2em 2em 2em;
`;

const Container = styled.div`
	margin-bottom: 2em;
`;
