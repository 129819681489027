import DialogContext, { DialogContextValue } from "../context/DialogContext";
import { useContext } from "react";

/**
 * Hook to imperatively show a simple dialog.
 *
 * Usage:
 *
 * ```tsx
 * // MyComponent.tsx
 * const dlg = useDialog<User>(); // or: useDialog() if you have no payload
 *
 * // call this to show the dialog
 * dlg.showConfirmDialog({
 *    ...,
 *    onConfirm: (cachedUser: User) => { // do something with argument  },
 *    payload: user // or: leave empty if you have no payload
 * });
 * ```
 */
export default function useDialog<
	// eslint-disable-next-line @typescript-eslint/ban-types
	TPayload = undefined
>(): DialogContextValue<TPayload> {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return useContext(DialogContext)! as DialogContextValue<TPayload>;
}
