import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { QueryStateIndicators } from "@components";
import { useServices, useCustomer, useAsyncCommand } from "@hooks";
import Routes, { CustomerRouteParams } from "@service/navigation/routes";
import { NewCustomer } from "models/Customer";
import CustomerForm from "./CustomerForm";
import { defer } from "@util/helpers";

export default function EditCustomerPage() {
	const history = useHistory();
	const { params } = useRouteMatch<CustomerRouteParams>();
	const { customerId } = params;
	const { customerService } = useServices();

	const customerQuery = useCustomer();
	const [updateCustomer, customerResult] = useAsyncCommand(
		async (customer: NewCustomer) =>
			await customerService.updateCustomer(customerId, customer)
	);

	const handleOnSubmit = async (customer: NewCustomer) => {
		const resultTrigger = await updateCustomer(customer);
		if (resultTrigger.state === "success") {
			await defer(() => {
				history.replace(Routes.Customers.makePath());
			});
		}
	};

	return (
		<>
			{customerQuery.state === "success" && (
				<CustomerForm
					customer={customerQuery.data}
					handleOnSubmit={handleOnSubmit}
					cmdState={customerResult}
				/>
			)}
			<QueryStateIndicators queryState={customerQuery} absolutePosition />
		</>
	);
}
