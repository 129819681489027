import React from "react";
import { Form } from "react-bootstrap";
import { ComponentProps } from "@util/types";
import styled from "styled-components";
import { colors } from "@theme/theming";

type Props = ComponentProps<typeof Form.Check> & {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: boolean;
	id?: string;
	label: string;
	className?: string;
};

const Checkbox = React.forwardRef((props: Props, ref) => {
	const { onChange, value, id, label, className, ...rest } = props;
	return (
		<Form.Check type="checkbox" className={className}>
			<Form.Check.Label>
				<StyledCheckBox
					type={
						"checkbox" as any /* Intended cast to any, there seems to be a bug in the type declarations */
					}
					{...rest}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onChange(e);
					}}
					checked={value}
					id={id}
					ref={ref}
				/>
				<span
					onClick={(e: React.MouseEvent<HTMLInputElement>) =>
						e.stopPropagation()
					}
				/>
				{!!label && <div style={{ marginLeft: "10px" }}>{label}</div>}
			</Form.Check.Label>
		</Form.Check>
	);
});

const StyledCheckBox = styled(Form.Check.Input)`
	position: absolute;
	top: 0;
	& ~ span {
		border: 2px solid ${colors.gray.g300};
		background: ${colors.white};
		border-radius: 3px;
		height: 20px;
		width: 20px;
		display: block;
		position: absolute;
		left: 0;
		top: 1px;
		margin-right: 10px;
	}
	&:checked ~ span {
		border-color: ${colors.primary.p200};
		background-color: ${colors.primary.p200};
		&::after {
			content: "";
			position: absolute;
			left: 4px;
			bottom: 3px;
			width: 8px;
			height: 15px;
			border: solid white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
`;

export default Checkbox;
