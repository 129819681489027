import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { colors } from "@theme/theming";

interface Props {
	"data-testid"?: string;
	expandedContent: React.ReactNode;
	children: React.ReactNode;
	onExpand?: () => void;
	expanded?: boolean;
	className?: string;
}

export default function ExpandableTableRow({
	expandedContent,
	children,
	onExpand,
	expanded,
	className,
	...rest
}: Props) {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<>
			<motion.tr
				style={{ cursor: "pointer" }}
				initial={expanded || false}
				animate={{
					fontSize: isOpen ? "1.2rem" : "1rem",
					fontWeight: isOpen ? "bolder" : "normal",
					backgroundColor: isOpen ? colors.gray.g100 : colors.gray.g100 + "30",
				}}
				onClick={() => {
					setIsOpen(!isOpen);
					if (onExpand) {
						onExpand();
					}
				}}
				data-testid="accordionHeader-table-row"
				{...rest}
			>
				{children}
			</motion.tr>
			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.tr
						data-testid="accordionCollapsed"
						className={className}
						key="content"
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { opacity: 1, height: "auto" },
							collapsed: { opacity: 0, height: 0 },
						}}
						transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
					>
						{expandedContent}
					</motion.tr>
				)}
			</AnimatePresence>
		</>
	);
}
