import { endOfDay, isAfter, isBefore } from "date-fns";

function utcDateTimeToLocal(dateStr: string): Date {
	if (!dateStr) throw new Error(`date must not be falsy, was ${dateStr}`);

	return new Date(dateStr);
}

function utcDateTimeToLocalNullable(dateStr: string | null): Date | null {
	if (!dateStr) return null;
	return utcDateTimeToLocal(dateStr);
}

function utcDateToLocal(dateStr: string): Date {
	if (!dateStr) throw new Error(`date must not be falsy, was ${dateStr}`);

	const date = new Date(dateStr);
	return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

function utcDateToLocalNullable(dateStr: string | null): Date | null {
	if (!dateStr) return null;
	return utcDateToLocal(dateStr);
}

function localDateToUtc(date: Date): string {
	if (!date) throw new Error(`date must not be falsy, was ${date}`);

	const utcDate = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		0,
		0,
		0,
		0
	);

	return new Date(utcDate).toISOString();
}

function localDateToUtcNullable(date: Date | null): string | null {
	if (!date) return null;

	return localDateToUtc(date);
}

export const deserializeDate = {
	date: utcDateToLocal,
	dateTime: utcDateTimeToLocal,
	nullable: {
		date: utcDateToLocalNullable,
		dateTime: utcDateTimeToLocalNullable,
	},
};

export const serializeDate = {
	date: localDateToUtc,
	nullable: {
		date: localDateToUtcNullable,
	},
};

export function getDateFlags(startDate: Date, endDate: Date | null) {
	const today = new Date();
	const isExpired = endDate && isBefore(endOfDay(endDate), today);
	const isInFuture = isAfter(startDate, today);
	const isActive = !isExpired && !isInFuture;
	return { isActive, isExpired, isInFuture };
}
