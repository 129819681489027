import React from "react";
import {
	Checkbox,
	FormGroup,
	InputValidation,
	Label,
	RadioButton,
	Text,
} from "@components";
import { Alert, Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "@components/forms/DatePicker";
import { formatDate } from "@components/forms/datePickerUtils";
import { MoveUsersConfig } from "./MoveUsersForm";
import { isFirstDayOfMonth, isFuture } from "date-fns";

export type MoveUserMode = "updateReference" | "createCopy";

export default function MoveUsersSelection() {
	const { control, formState, setValue, watch } =
		useFormContext<MoveUsersConfig>();

	const targetStartingDate = watch("targetStartingDate");

	const validateTargetStartingDate = (
		startingDate: Date | null | undefined
	) => {
		const moveVouchers = watch("moveVouchers");
		const copyBudgetMasterSettings = watch("copyBudgetMasterSettings");
		const targetStartingDate =
			startingDate === null ? watch("targetStartingDate") : startingDate;
		if (moveVouchers || copyBudgetMasterSettings) {
			if (!targetStartingDate) {
				return "Bitte wählen Sie ein gültiges Datum aus, um eine Kopie zu erstellen";
			}
			if (isFuture(targetStartingDate)) {
				return "Das Datum muss in der Vergangenheit liegen";
			}
			if (!isFirstDayOfMonth(targetStartingDate)) {
				return "Eine Umstellung ist erst ab dem ersten Tag des Monats möglich";
			}
		}
		return true;
	};

	const mode = watch("mode");
	const isModeCreateCopy = mode === "createCopy";
	const isModeUpdateReference = mode === "updateReference";

	return (
		<>
			<FormGroup as={Row}>
				<RadioButton
					id="createCopy"
					label="Kopie des alten Mitarbeiters im bisherigen Unternehmen lassen"
					value={mode === "createCopy"}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						e.target.checked &&
						setValue("mode", "createCopy", { shouldDirty: true })
					}
				/>
			</FormGroup>

			{isModeCreateCopy && (
				<>
					<FormGroup as={Row}>
						<Alert variant="info">
							<Text>
								<p>
									Die ausgewählten Mitarbeiter-Einträge werden kopiert und die
									Kopie dem neuen Arbeitgeber zugeordnet. Bestehende Daten
									bleiben daher erhalten und dem Eintrag beim alten Arbeitgeber
									zugeordnet. Alte Belege sind für die Mitarbeiter in der App
									nicht mehr sichtbar. Werden neue Belege hochgeladen, geschieht
									dies für den neuen Arbeitgeber. Die Umstellung erfolgt{" "}
									<strong>sofort</strong>. Die Benutzer loggen sich weiterhin
									mit ihrer bisherigen Mailadresse ein.
								</p>
								<p>
									Die Daten können nur von einem früheren Zeitpunkt kopiert
									werden. Die Übertragung ist nicht planbar - in diesem Fall
									nehmen Sie die Umstellung bitte im nächsten Monat vor.
								</p>
								<p>
									Beim neuen Arbeitgeber muss dem Mitarbeiter bei Bedarf manuell
									ein neuer Reisekostenprüfer zugeordnet werden.
								</p>
								<p>
									<strong>Achtung:</strong> Nach dem Kopieren wird der bisherige
									Nutzer deaktiviert und kann keine Reisen mehr freigeben. Bitte
									trage bei den Nutzern, bei denen der nun inaktive Nutzer als
									Reisekostenprüfer eingetragen war, einen neuen
									Reisekostenprüfer ein.
								</p>
							</Text>
						</Alert>
					</FormGroup>
					<FormGroup as={Row}>
						<Controller
							name="moveVouchers"
							control={control}
							defaultValue={false}
							rules={{
								validate: () => validateTargetStartingDate(null),
							}}
							render={({ field }) => (
								<Checkbox
									label="Belege umziehen"
									value={field.value}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue("moveVouchers", e.target.checked, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
								/>
							)}
						/>
					</FormGroup>
					<FormGroup as={Row}>
						<Controller
							control={control}
							name="copyBudgetMasterSettings"
							defaultValue={false}
							rules={{
								validate: () => validateTargetStartingDate(null),
							}}
							render={({ field }) => (
								<Checkbox
									{...field}
									id="copyBudgetMasterSettings"
									value={field.value}
									label="Budgeteinstellungen kopieren"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue("copyBudgetMasterSettings", e.target.checked, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
								/>
							)}
						/>
					</FormGroup>

					<InputValidation error={formState.errors.targetStartingDate}>
						<FormGroup as={Row}>
							<Label htmlFor="targetStartingDate" column sm={3}>
								Kopieren von
							</Label>
							<Col sm={4}>
								<Controller
									name="targetStartingDate"
									control={control}
									rules={{
										validate: validateTargetStartingDate,
									}}
									defaultValue={null}
									render={({ field }) => (
										<DatePicker
											{...field}
											id="targetStartingDate"
											canClearDate
											onClearDate={() => {
												setValue("targetStartingDate", null, {
													shouldDirty: true,
													shouldValidate: true,
												});
											}}
											onDayChange={(date: Date) => {
												// This is needed to trigger validation if user manually clears the input
												if (date === undefined) {
													setValue("targetStartingDate", null, {
														shouldDirty: true,
														shouldValidate: true,
													});
												}
											}}
										/>
									)}
								/>
							</Col>
						</FormGroup>
						{!!targetStartingDate && (
							<FormGroup as={Row}>
								<Alert variant="info">
									Belege und Budgets (wenn ausgewählt) ab dem{" "}
									{formatDate(targetStartingDate)} werden zu dem neuen AG
									umgezogen. Ältere Belege bleiben dem altem Mitarbeiter-Eintrag
									zugeordnet.
								</Alert>
							</FormGroup>
						)}
					</InputValidation>
				</>
			)}
			<FormGroup as={Row}>
				<RadioButton
					id="updateReference"
					label="Mitarbeiter verschieben und alle Daten mitnehmen (Belege, Budgets, ...)"
					value={mode === "updateReference"}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						e.target.checked &&
						setValue("mode", "updateReference", { shouldDirty: true })
					}
				/>
			</FormGroup>
			{isModeUpdateReference && (
				<FormGroup>
					<Alert variant="info">
						<p>
							Die ausgewählten Mitarbeiter werden dem neuen Arbeitgeber
							zugeordnet, als wären sie immer dort angelegt gewesen. Alle
							zugehörigen Daten werden mit umgezogen, auch etwaige bestehende
							Belege. Diese Option sollte nur gewählt werden, wenn die
							Mitarbeiter noch keine Belege hochgeladen haben oder diese noch
							nicht für den alten Arbeitgeber abgerechnet wurden. Andernfalls
							"verschwinden" diese für den Arbeitgeber, was bei einer
							Steuerprüfung durch das Finanzamt sicher unangenehm wird.
						</p>
						<p>
							Beim neuen Arbeitgeber muss dem Mitarbeiter bei Bedarf manuell ein
							neuer Reisekostenprüfer zugeordnet werden.
						</p>
						<p>
							<strong>Achtung:</strong> Stelle sicher, dass der Mitarbeiter beim
							alten Arbeitgeber in keinem User als Reisekostenprüfer eingetragen
							ist. Trage dort <strong>unbedingt vor</strong> dem Verschieben
							einen neuen Reisekostenprüfer ein. Andernfalls bleiben nach dem
							Verschieben Benutzer ohne Reisekostenprüfer zurück.
						</p>
					</Alert>
				</FormGroup>
			)}
		</>
	);
}
