import { Properties } from "@util/types";
import { Meals } from "./Meals";
import { OvernightStay } from "./OvernightStay";

export class Accomodation {
	constructor(props: Properties<Accomodation>) {
		this.meals = props.meals;
		this.overnightStays = props.overnightStays;
	}

	readonly meals: ReadonlyArray<Meals>;
	readonly overnightStays: ReadonlyArray<OvernightStay>;

	static fromJson(data: any) {
		return new Accomodation({
			meals: data.meals.map(Meals.fromJson),
			overnightStays: data.overnightStays.map(OvernightStay.fromJson),
		});
	}
}
