import { Properties } from "@util/types";
import * as yup from "yup";

export class DivisionRole {
	constructor(props: Properties<DivisionRole>) {
		this.divisionId = props.divisionId;
		this.role = props.role;
	}

	static fromJson(data: any): DivisionRole {
		if (!divisionRoleSchema.isValidSync(data)) {
			throw new Error("validation failed");
		}

		return new DivisionRole({
			divisionId: data.divisionId,
			role: data.role,
		});
	}

	readonly divisionId: string;
	readonly role: Roles;
}

export class CustomerRole {
	constructor(props: Properties<CustomerRole>) {
		this.customerId = props.customerId;
		this.role = props.role;
		this.isSelfRegistered = props.isSelfRegistered;
	}

	static fromJson(data: any): CustomerRole {
		if (!customerRoleSchema.isValidSync(data)) {
			throw new Error("validation failed");
		}

		return new CustomerRole({
			customerId: data.customerId,
			role: data.role,
			isSelfRegistered: data.isSelfRegistered,
		});
	}

	readonly customerId: string;
	readonly role: Roles;
	readonly isSelfRegistered?: boolean;
}

export type UserRole = DivisionRole | CustomerRole;

export enum Roles {
	Admin = "admin",
	Accounting = "accounting",
	Reviewer = "reviewer",
	AdminReviewer = "admin-reviewer",
	WebAppUser = "web-app-user",
	PortalAdmin = "portal-admin",
}

const divisionRoles = Object.values(Roles).filter(
	(role) => role !== Roles.PortalAdmin
);

const divisionRoleSchema = yup.object().shape({
	divisionId: yup.string().required(),
	role: yup.string().required().oneOf(divisionRoles),
});

const customerRoleSchema = yup.object().shape({
	customerId: yup.string().required(),
	role: yup.string().required().oneOf([Roles.PortalAdmin]),
	isSelfRegistered: yup.boolean(),
});
