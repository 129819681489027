import { ErrorAlert } from "@components";
import { VoucherCardEmbedded } from "@components/vouchers/VoucherCard";
import React, { useContext } from "react";
import TripContext from "../context/TripContext";

type Props = {
	voucherId: string;
};
export default function VoucherSection(props: Props) {
	const { voucherId } = props;

	const tripContext = useContext(TripContext);
	if (!tripContext) {
		return <ErrorAlert sticky={false} />;
	}

	const voucher = tripContext.currentTrip.vouchers.find(
		(v) => v.voucherId === voucherId
	);
	if (!voucher) {
		return <ErrorAlert sticky={false} />;
	}

	return <VoucherCardEmbedded voucher={voucher} isInitiallyExpanded={false} />;
}
