import { CurrencyInput, FormGroup, Label, RequiredMarker } from "@components";

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

export default function EmployerFinancedRailcard() {
	const { control } = useFormContext();

	return (
		<>
			<FormGroup as={Row}>
				<Label htmlFor="expenses" column sm={4}>
					Reisekosten / Auslagen <RequiredMarker />
				</Label>
				<Col sm={8}>
					<Controller
						name="settings.financing.expenses"
						control={control}
						rules={{
							required: true,
							min: 0.0,
						}}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="expenses"
								placeholder="€/Monat"
								min={0.0}
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="commuting" column sm={4}>
					Fahrt Wohnung-Arbeitsstätte <RequiredMarker />
				</Label>
				<Col sm={8}>
					<Controller
						name="settings.financing.commuting"
						control={control}
						rules={{
							required: true,
							min: 0.0,
						}}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="commuting"
								placeholder="€/Monat"
								min={0.0}
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="fringeBenefits" column sm={4}>
					Sachbezug <RequiredMarker />
				</Label>
				<Col sm={8}>
					<Controller
						name="settings.financing.fringeBenefits"
						control={control}
						rules={{
							required: true,
							min: 0.0,
						}}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="fringeBenefits"
								placeholder="€/Monat"
								min={0.0}
							/>
						)}
					/>
				</Col>
			</FormGroup>
		</>
	);
}
