import { AxiosResponse } from "axios";

export function getExampleFile(response: AxiosResponse<any>) {
	const fileName = (response.headers["content-disposition"] as string)
		.split("filename=")[1]
		?.replace(/"/g, "");

	return new File([response.data], fileName, {
		type: "text/plain;charset=utf-8",
	});
}
