import React from "react";
import { CardContent, ExpandCard } from "@components";
import { GiftExpense, HospitalityExpense, TravelExpense } from "@models";
import ExpenseCardHeader from "./ExpenseCardHeader";
import HospitalityExpenseDetails from "./HospitalityExpenseDetails";
import GiftExpenseDetails from "./GiftExpenseDetails";
import VoucherSection from "./VoucherSection";

type Props = {
	expense: TravelExpense;
};

export default function ExpenseCard(props: Props) {
	const { expense } = props;

	const header = <ExpenseCardHeader expense={expense} />;

	return (
		<ExpandCard testId={"expense-card-" + expense.id} header={header}>
			<CardContent>
				{expense instanceof HospitalityExpense && (
					<HospitalityExpenseDetails expense={expense} />
				)}
				{expense instanceof GiftExpense && (
					<GiftExpenseDetails expense={expense} />
				)}
				{expense.paymentSource === "employer" && (
					<div>Bezahlt vom Arbeitgeber</div>
				)}
				{!!expense.voucherId && (
					<VoucherSection voucherId={expense.voucherId} />
				)}
			</CardContent>
		</ExpandCard>
	);
}
