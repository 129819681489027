import React from "react";
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useRouteMatch,
} from "react-router-dom";

import {
	MainBreadcrumbs,
	PageContainer,
	QueryStateIndicators,
	ScrollToTop,
	ScrollUpButton,
	Tab,
	ErrorImage,
} from "@components";
import { useUserDetails, useUserSummary } from "@hooks";
import Routes, { UserRouteParams } from "@service/navigation/routes";
import ProductsPage from "../products/ProductsPage";
import VouchersPage from "../vouchers/DefaultFilterableVoucherList";
import EditUserPage from "./components/EditUserPage";
import ContractsPage from "./contracts/ContractsPage";
import UserDetailsPageContext from "./UserDetailsPageContext";
import UserStatisticsPage from "./components/UserStatistics/UserStatisticsPage";
import UserDetailsCard from "./components/UserDetailsCard";
import UserRolesPage from "./components/UserRoles/UserRolesPage";

export default function UserDetailsPage() {
	const { params } = useRouteMatch<UserRouteParams>();
	const cachedLocation = useLocation();

	const isBasicData = !!useRouteMatch(Routes.UserBasicData.route);
	const isProducts = !!useRouteMatch(Routes.UserProducts.route);
	const isVouchers = !!useRouteMatch(Routes.UserVouchers.route);
	const isContracts = !!useRouteMatch(Routes.UserContracts.route);
	const isStatistics = !!useRouteMatch(Routes.Statistics.route);
	const isRoles = !!useRouteMatch(Routes.UserRoles.route);

	const [userQuery, refreshUser] = useUserDetails(params.userId);
	const user = userQuery.data;

	const userSummary = useUserSummary(params.userId);

	return (
		<PageContainer>
			{userSummary.state === "error" ? (
				<ErrorImage />
			) : (
				<>
					<ScrollToTop />
					<ScrollUpButton />

					{userQuery.data && userSummary.data && (
						<>
							<MainBreadcrumbs
								customer={userSummary.data.customer}
								division={userSummary.data.division}
								user={userQuery.data}
							/>
							<UserDetailsCard
								user={userQuery.data}
								budgetMasters={userSummary.data.budgetMasters}
								customer={userSummary.data.customer}
								division={userSummary.data.division}
							/>
						</>
					)}
					<Tab.Container
						css={`
							margin-bottom: 1rem;
						`}
					>
						<Tab.Item
							active={isBasicData}
							to={Routes.UserDetails.makePath(params)}
						>
							Stammdaten
						</Tab.Item>
						<Tab.Item
							active={isProducts}
							to={Routes.UserProducts.makePath(params)}
						>
							Module
						</Tab.Item>
						<Tab.Item
							active={isVouchers}
							to={Routes.UserVouchers.makePath(params)}
						>
							Belege
						</Tab.Item>
						<Tab.Item
							active={isContracts}
							to={Routes.UserContracts.makePath(params)}
						>
							Dauerbelege
						</Tab.Item>
						<Tab.Item active={isRoles} to={Routes.UserRoles.makePath(params)}>
							Berechtigungen
						</Tab.Item>
						<Tab.Item
							active={isStatistics}
							to={Routes.Statistics.makePath(params)}
						>
							Monatsübersicht
						</Tab.Item>
					</Tab.Container>
					<div data-testid="subpage-content">
						{userQuery.state === "success" && !!user && (
							<UserDetailsPageContext.Provider
								value={{
									user,
									triggerRefresh: refreshUser,
								}}
							>
								<Switch location={cachedLocation}>
									<Route exact path={Routes.UserBasicData.route}>
										<EditUserPage user={user} />
									</Route>
									<Route path={Routes.UserVouchers.route}>
										<VouchersPage
											requiredFilters={{
												userId: [user.userId],
											}}
										/>
									</Route>
									<Route path={Routes.UserProducts.route}>
										<ProductsPage user={user} />
									</Route>
									<Route path={Routes.UserContracts.route}>
										<ContractsPage user={user} />
									</Route>
									<Route path={Routes.UserRoles.route}>
										<UserRolesPage user={user} />
									</Route>
									<Route path={Routes.Statistics.route}>
										<UserStatisticsPage user={user} />
									</Route>
									<Redirect to={Routes.UserBasicData.makePath(params)} />;
								</Switch>
							</UserDetailsPageContext.Provider>
						)}
					</div>
				</>
			)}
			<QueryStateIndicators queryState={userSummary} />
		</PageContainer>
	);
}
