//
import { Currency } from "@util";
import React from "react";

type Props = {
	amount: number;
	override?: number | null;
};

export default function FlatRateValue(props: Props) {
	return (
		<span
			css={`
				text-decoration: ${props.override !== null &&
				props.override !== undefined
					? "line-through"
					: "none"};
			`}
		>
			{Currency.format(props.amount)}
		</span>
	);
}
