import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { useServices, useAsyncCommand, useBudgetMasterProduct } from "@hooks";
import {
	ActionButton,
	Button,
	ButtonContainer,
	Form,
	QueryError,
} from "@components";
import {
	NewBudgetMaster,
	RootProductId,
	UpdatedBudgetMaster,
	User,
} from "@models";
import { defer } from "@util/helpers";
import ProductsPageContext from "../ProductsPageContext";
import AvailabilityDates from "./configuration/AvailabilityDates";
import BudgetMasterMobilitySettings from "./configuration/BudgetMasterMobilitySettings";
import { ConfigBudgetMaster } from "./configuration/types";
import {
	canConfigureBalanceAccount,
	configToBudgetMaster,
	getBalanceAccount,
	useDefaultConfigBudgetMaster,
	isNewBudgetMaster,
} from "./util";
import { FormGrid, FormSection, FormSectionContent } from "@components";
import { checkCollidingBudgetMasters } from "./util/checkCollidingBudgetMasters";
import CouponConfigurationSelection from "./congrats/CouponConfigurationSelection";
import BalanceAccountConfiguration from "./balanceAccounts/BalanceAccountConfiguration";
import UserDetailsPageContext from "../../user/UserDetailsPageContext";
import MobilityVariantToggle from "./configuration/MobilityVariantToggle";
import BudgetCategorySection from "./configuration/BudgetCategorySection";
import _ from "lodash";
import BudgetMasterTravelSettings from "./configuration/BudgetMasterTravelSettings";
import ExcessBudgetSettings from "./ExcessBudgetSettings";

type Props = {
	budgetMaster: UpdatedBudgetMaster | NewBudgetMaster;
	users?: User[];
	onClose?: (needsConfirmation: boolean) => void;
	onFormDirty?: (dirty: boolean) => void;
};

export default function BudgetMasterConfiguration(props: Props) {
	const { budgetMaster, users, onFormDirty } = props;
	const [collisionError, setCollisionError] = useState(false);
	const [collisionMessage, setCollisionMessage] = useState<string | undefined>(
		undefined
	);
	const [alertVariant, setAlertVariant] = useState<"warning" | "danger">(
		"warning"
	);

	const { product } = useBudgetMasterProduct(budgetMaster);

	const formMethods = useForm<ConfigBudgetMaster>({
		defaultValues: useDefaultConfigBudgetMaster(budgetMaster) as any,
		mode: "onChange",
		shouldFocusError: false,
	});

	const { productService } = useServices();

	const pageContext = useContext(ProductsPageContext);

	const isNew = isNewBudgetMaster(budgetMaster);
	const isMobility = budgetMaster.category === "mobility";
	const isTravel = budgetMaster.category.startsWith("travel");
	const hasBudgets = product.hasBudgets();

	const { formState, handleSubmit, watch } = formMethods;
	const productVariantId = watch("productVariantId");
	const availableFrom = watch("availableFrom");
	const availableUntil = watch("availableUntil");

	const variant = product.variantOfId(productVariantId);
	const currentBudgetCategories = variant.budgetCategories;
	const hasSubBudgets = currentBudgetCategories.length > 1;

	const allBudgetCategories = _.flatMap(
		product.variants,
		(v) => v.budgetCategories
	);

	const [triggerSave, saveQuery] = useAsyncCommand(
		(bm: UpdatedBudgetMaster | NewBudgetMaster, users?: User[]) => {
			if (isNewBudgetMaster(bm)) {
				let newBMs = [bm];
				if (users) {
					// copy BudgetMaster, user for all selected users
					newBMs = users.map((u) => ({ ...bm, userId: u.userId }));
				}
				return productService.createNewBudgetMaster(newBMs);
			} else {
				return productService.updateBudgetMaster(bm);
			}
		},
		{
			rethrowError: false,
		}
	);

	const handleChangeAvailableDates = useCallback(() => {
		setCollisionMessage(undefined);
		setCollisionError(false);

		const budgetMasters = pageContext.budgetMasters;
		const { isValid, message, isWarning } = checkCollidingBudgetMasters(
			budgetMasters,
			budgetMaster,
			availableFrom,
			availableUntil
		);
		if (!isValid && saveQuery.state !== "success") {
			setCollisionMessage(message);
			setCollisionError(!isWarning);
			setAlertVariant(isWarning ? "warning" : "danger");
		}
	}, [
		saveQuery,
		availableFrom,
		availableUntil,
		budgetMaster,
		pageContext.budgetMasters,
	]);

	useEffect(() => {
		// note: this is quite ugly, but we want to make sure to prevent accidentally closing the card if the form is dirty
		onFormDirty?.(formState.isDirty);
	}, [onFormDirty, formState.isDirty]);

	useEffect(() => {
		if (
			budgetMaster.category !== "congrats" &&
			(formState.dirtyFields.availableFrom ||
				formState.dirtyFields.availableUntil ||
				isNew)
		) {
			handleChangeAvailableDates();
		}
	}, [
		formState.dirtyFields.availableFrom,
		formState.dirtyFields.availableUntil,
		isNew,
		handleChangeAvailableDates,
		budgetMaster.category,
	]);

	const handleSubmitForm = handleSubmit(async (formValues) => {
		const updatedValues = configToBudgetMaster(
			formValues,
			budgetMaster,
			product
		);

		const state = await triggerSave(updatedValues, users);
		if (state.state === "success") {
			pageContext.triggerRefresh();

			await defer(() => {
				props.onClose?.(false);
			});
		}
	});

	// formState.isValid is not turning to true once it became false when all categories are unselected, so formState.errors is used instead
	const isSaveButtonDisabled =
		!!Object.keys(formState.errors).length ||
		!(formState.isDirty || isNew) ||
		collisionError;

	const { user } = useContext(UserDetailsPageContext);

	const canUseBalanceAccount = () => {
		if (!isNew) {
			const hasBalanceAccount = getBalanceAccount(user, budgetMaster);
			const bm: UpdatedBudgetMaster = budgetMaster; // WORKAROUND: budgetMaster is narrowed to `never` for some reason
			return (
				canConfigureBalanceAccount(bm.category as RootProductId) &&
				hasBalanceAccount
			);
		} else {
			return canConfigureBalanceAccount(budgetMaster.category as RootProductId);
		}
	};

	return (
		<Container data-testid="budgetMaster-config-container">
			<QueryError queryState={saveQuery} />

			<FormProvider {...formMethods}>
				<Form onSubmit={handleSubmitForm}>
					{budgetMaster.category === "congrats" ? (
						<CouponConfigurationSelection budgetMaster={budgetMaster} />
					) : (
						<>
							{!!collisionMessage && (
								<StyledFormSectionContent>
									<Alert variant={alertVariant}>{collisionMessage}</Alert>
								</StyledFormSectionContent>
							)}
							<FormGrid>
								{/* general settings */}
								<FormSection>Einstellungen</FormSection>
								<StyledFormSectionContent>
									<AvailabilityDates />
									<ExcessBudgetSettings />
									{isMobility && (
										<BudgetMasterMobilitySettings budgetMaster={budgetMaster} />
									)}
									{isTravel && <BudgetMasterTravelSettings />}
								</StyledFormSectionContent>
								{/* Balance Account configuration */}
								{canUseBalanceAccount() && (
									<BalanceAccountConfiguration
										budgetMaster={budgetMaster}
										isNewBudgetMaster={isNew}
									/>
								)}
								{/* product variant */}
								{isMobility && (
									<>
										<FormSection>Variante</FormSection>

										<MobilityVariantToggle />
									</>
								)}

								{/* budget configuration */}
								{hasBudgets && (
									<>
										<FormSection>
											{hasSubBudgets ? "Budgets" : "Budget"}
										</FormSection>

										<FormSectionContent>
											{allBudgetCategories.map((bcat) => (
												<BudgetCategorySection
													key={bcat.id}
													product={product}
													category={bcat}
												/>
											))}
										</FormSectionContent>
									</>
								)}
							</FormGrid>
						</>
					)}
					{/* form buttons */}
					<ButtonContainer>
						<Button
							variant="outline-secondary"
							onClick={() => props.onClose?.(true)}
						>
							Abbrechen
						</Button>
						<ActionButton
							type="submit"
							disabled={isSaveButtonDisabled}
							query={saveQuery}
							variant={isNew ? undefined : "warning"}
							successContent={"Gespeichert"}
						>
							{isNew ? "Speichern" : "Überschreiben"}
						</ActionButton>
					</ButtonContainer>
				</Form>
			</FormProvider>
		</Container>
	);
}

const Container = styled.div`
	padding: 16px;
`;

const StyledFormSectionContent = styled(FormSectionContent)`
	grid-column: span 2;
	margin-top: 8px;
	display: block;
`;
