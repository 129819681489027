import React from "react";
import { useHistory } from "react-router-dom";
import { QueryStateIndicators } from "@components";
import { useServices, useAsyncCommand } from "@hooks";
import Routes from "@service/navigation/routes";
import { NewCustomer } from "models/Customer";
import CustomerForm from "./CustomerForm";
import { defer } from "@util/helpers";

export default function AddCustomerPage() {
	const history = useHistory();

	const { customerService } = useServices();

	const [addCustomer, customerResult] = useAsyncCommand(
		customerService.addCustomer
	);

	const handleOnSubmit = async (customer: NewCustomer) => {
		const resultTrigger = await addCustomer(customer);

		if (resultTrigger.state === "success") {
			const customerId = resultTrigger.data.customerId;
			await defer(() => {
				history.replace(`${Routes.Customers.route}?customer=${customerId}`);
			});
		}
	};

	return (
		<>
			<CustomerForm handleOnSubmit={handleOnSubmit} cmdState={customerResult} />
			<QueryStateIndicators queryState={customerResult} absolutePosition />
		</>
	);
}
