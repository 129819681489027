import { PaginationResult, QueryOptions } from "../types";
import * as apiService from "../api.service";
import { mapPaginationResponse } from "../util";
import Report from "../../models/Report";
import {
	ReportSettingItem,
	ReportTypeSetting,
	ReportUrl,
} from "../../models/ReportTypeSetting";
import { AccountingMonthClosed, ReportsTypeError } from "./errors";
import {
	ExpenseAccountSystem,
	FinancialAccountingSettings,
} from "@models/FinancialAccountingSettings";
import axios from "axios";
import { AccountingMonth } from "@models";
import { format } from "date-fns";

export async function getReportsByDivision(
	divisionId: string,
	options: QueryOptions
): Promise<PaginationResult<Report>> {
	const queryParams: any = {
		limit: options.limit,
		page: options.page,
		sort: options.sort,
		order: options.order,
	};
	const responseData = await apiService.GET("report?divisionId=" + divisionId, {
		params: queryParams,
	});
	return mapPaginationResponse(responseData.data, Report.fromJson);
}

export async function getReportById(reportId: string): Promise<Report> {
	const report = await apiService.GET(`report/${reportId}`);
	return Report.fromJson(report.data);
}

export async function getReportsByAccountingMonth(
	accountingMonth: AccountingMonth
): Promise<Report[]> {
	const response = await apiService.GET(
		`division/${accountingMonth.divisionId}/accountingMonth/${format(
			accountingMonth.month,
			"yyyy-MM"
		)}/reports`
	);
	return response.data.map(Report.fromJson);
}

export async function downloadReport(reportId: string): Promise<ReportUrl> {
	const response = await apiService.GET(`report/${reportId}/file`);
	return response.data;
}

export async function generateReports(
	divisionId: string,
	yearMonth: string
): Promise<void> {
	try {
		await apiService.POST("report/generate", {
			divisionId,
			yearMonth,
		});
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.data.type === "invalid-report-settings") {
				throw new ReportsTypeError();
			}

			if (e.response?.data.type === "accounting-month-closed") {
				throw new AccountingMonthClosed();
			}
		}
		throw e;
	}
}

export type ReportPayrollSettingsResponse = {
	payrollAccountingItems: ReportTypeSetting[];
};
export type ReportFinancialSettingsResponse = {
	financialAccounting: FinancialAccountingSettings | null;
	expenseAccountNames: Record<string, string>;
};

export async function getReportPayrollSettings(
	divisionId: string,
	reportTypeId: string
): Promise<ReportPayrollSettingsResponse> {
	const { data } = await apiService.GET(
		`division/${divisionId}/reportsettings/payroll`,
		{
			params: {
				reportTypeId,
			},
		}
	);

	return {
		payrollAccountingItems: data.payrollAccountingItems
			? (data.payrollAccountingItems as any).map(ReportTypeSetting.fromJson)
			: [],
	};
}
export async function getReportFinancialSettings(
	divisionId: string,
	system?: ExpenseAccountSystem
): Promise<ReportFinancialSettingsResponse> {
	const { data } = await apiService.GET(
		`division/${divisionId}/reportsettings/financial`,
		{
			params: {
				system,
			},
		}
	);

	return {
		financialAccounting: data.financialAccounting
			? FinancialAccountingSettings.fromJson(data.financialAccounting)
			: null,
		expenseAccountNames: data.expenseAccountNames,
	};
}

type ReportPayrollSettingsProps = {
	divisionId: string;
	reportTypeId?: string | null;
	settings?: ReportSettingItem[];
};

export async function updateReportPayrollSettings(
	data: ReportPayrollSettingsProps
): Promise<void> {
	const { divisionId, settings, reportTypeId } = data;
	await apiService.PUT(`division/${divisionId}/reportsettings/payroll`, {
		reportTypeId,
		settings,
	});
}

type ReportFinancialSettingsProps = {
	divisionId: string;
	financialSoftwareId?: string | null;
	financialAccounting?: FinancialAccountingSettings | null;
};

export async function updateReportFinancialSettings(
	data: ReportFinancialSettingsProps
): Promise<void> {
	const { divisionId, financialSoftwareId, financialAccounting } = data;
	await apiService.PUT(`division/${divisionId}/reportsettings/financial`, {
		financialSoftwareId,
		financialAccounting,
	});
}
