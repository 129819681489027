import { getErrorFromResponse } from "@util/errors";
import {
	BalanceAccountNotFoundError,
	CreateBalanceTransactionError,
	InvalidFileFormatError,
	UpdateBalanceAccountError,
	UpdateBalanceBudgetError,
	UserNotFoundError,
} from "./errors";

export function throwErrorWithMessages(e: any) {
	const { type } = getErrorFromResponse(e);
	switch (type) {
		case "user-not-found":
			throw new UserNotFoundError(
				`Benutzer nicht gefunden ${e.response?.data?.user}, PersonalNummer: ${e.response?.data?.personalNo}`
			);
		case "balance-account-not-found":
			throw new BalanceAccountNotFoundError(
				`Benutzer ${e.response?.data?.user}, PersonalNummer  ${e.response?.data?.personalNo} hat kein Guthabenkonto`
			);
		case "invalid-balance-file-format":
			throw new InvalidFileFormatError(
				`Das Format der Datei ist ungültig: ${e.response?.data?.message}`
			);
		case "create-balance-transaction":
			throw new CreateBalanceTransactionError(
				"Fehler beim Erstellen der Guthabentransaktion"
			);
		case "update-balance-account":
			throw new UpdateBalanceAccountError(
				"Fehler beim Aktualisieren des Guthabenkontos"
			);
		case "update-balance-budget":
			throw new UpdateBalanceBudgetError(
				"Fehler beim Ändern des Budget-Guthabens"
			);
	}
}
