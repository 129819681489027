import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Label, Select } from "@components";
import { Category, RootProductId } from "@models";
import NestedCategorySelection from "./NestedCategorySelection";
import { ConfigBudgetMaster } from "./types";

type Props = {
	productId: RootProductId;
	budgetCategory: Category;
};

export default function CategoriesSelection(props: Props) {
	const { productId, budgetCategory } = props;
	const { control, watch } = useFormContext<ConfigBudgetMaster>();

	if (productId === "spend") {
		return (
			<>
				<Label htmlFor={`${productId}-category-select`}>Kategorie</Label>
				<Controller
					name={"categories.0"}
					control={control}
					render={({ field }) => (
						<Select
							id={`${productId}-category-select`}
							{...field}
							value={watch("categories.0")}
						>
							{budgetCategory.subCategories?.map((category) => (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							))}
						</Select>
					)}
				/>
			</>
		);
	}

	if (
		productId === "mobility" ||
		productId === "fitAndRelax" ||
		productId === "homeAndWork"
	) {
		return (
			<Controller
				name="categories"
				control={control}
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				render={({ field: { ref, ...fieldRest } }) => (
					<NestedCategorySelection
						{...fieldRest}
						budgetCategory={budgetCategory}
					/>
				)}
			/>
		);
	}

	return <></>;
}
