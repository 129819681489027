import React from "react";

import * as staticDataService from "@service/staticData/staticData.service";
import * as customerService from "@service/customer/customer.service";
import * as userService from "@service/user/user.service";
import * as voucherService from "@service/voucher/voucher.service";
import * as searchService from "@service/search/search.service";
import * as authService from "@service/auth/auth.service";
import * as divisionService from "@service/division/division.service";
import * as reportService from "@service/report/report.service";
import * as productService from "@service/product/product.service";
import * as contractService from "@service/contract/contract.service";
import * as validationService from "@service/validation/validation.service";
import * as balanceAccountService from "@service/balanceAccount/balanceAccount.service";
import * as balanceTransactionService from "@service/balanceTransaction/balanceTransaction.service";
import * as tripService from "@service/trip/trip.service";
import * as accountingMonthService from "@service/accountingMonth/accountingMonth.service";
import * as reviewService from "@service/review/review.service";
import * as eventService from "@service/event/event.service";

export const defaultServices = {
	staticDataService,
	customerService,
	userService,
	voucherService,
	searchService,
	divisionService,
	reportService,
	authService,
	productService,
	contractService,
	validationService,
	balanceAccountService,
	balanceTransactionService,
	tripService,
	accountingMonthService,
	reviewService,
	eventService,
};

export type ContextServices = typeof defaultServices;

export const ServiceContext =
	React.createContext<ContextServices>(defaultServices);
