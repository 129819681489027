import { UserWithBalanceAccounts } from "@models";
import { useCallback } from "react";
import useFetchedData, { QueryState } from "./useFetchedData";
import useServices from "./useServices";

export default function useUserDetails(
	userId: string
): [
	QueryState<UserWithBalanceAccounts>,
	() => Promise<void>,
	(value: UserWithBalanceAccounts) => void
] {
	const { userService } = useServices();

	const fetchUsersFn = useCallback(
		() => userService.getUserById(userId),
		[userId, userService]
	);

	return useFetchedData(fetchUsersFn);
}
