import styled from "styled-components";

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px;

	> :first-child {
		margin-bottom: 16px;
	}
`;
