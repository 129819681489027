import React from "react";
import styled from "styled-components";

import { FaComment as CommentIcon } from "react-icons/fa";
import { FlexCol } from "@components";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import { colors } from "@theme/theming";

export default function UserComment(props: {
	comment?: string;
	style?: React.CSSProperties;
}) {
	const { style, comment } = props;

	return (
		<Alert variant="info" style={{ width: "100%", ...(style ? style : {}) }}>
			<FlexCol>
				<h6 style={{ color: colors.primary.p300 }}>Nutzerkommentar</h6>
				<div title="Nutzerkommentar" translate="no">
					<IconWrapper>
						<CommentIcon />
					</IconWrapper>
					<UserCommentText>
						{comment ? `„${_.unescape(comment)}“` : "Kein Nutzerkommentar"}
					</UserCommentText>
				</div>
			</FlexCol>
		</Alert>
	);
}

const IconWrapper = styled.span`
	color: ${colors.gray.g400};
	margin-right: 0.5ch;
`;

const UserCommentText = styled.span`
	font-size: 0.8rem;
	color: ${colors.gray.g400};
	font-style: italic;
`;
