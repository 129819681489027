import React, { useCallback } from "react";
import { User } from "@models";
import { useFetchedData, useServices } from "@hooks";
import { QueryStateIndicators } from "../../../components/QueryStateIndicators";
import { PageContainer } from "@components";
import ContractsList from "./components/ContractsList";
import ContractsPageContext from "./ContractsPageContext";
import { useBudgetMasters } from "@hooks";
import { useLocation } from "react-router-dom";

type Props = {
	user: User;
};
export default function ContractsPage(props: Props) {
	const { user } = props;
	const { contractService } = useServices();
	const getContracts = useCallback(
		() => contractService.getContractsForUser(user.userId),
		[contractService, user.userId]
	);

	const [contractsQuery, refreshContracts] = useFetchedData(getContracts);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const contractId = searchParams.get("contract");

	const [budgetMastersQuery] = useBudgetMasters(user.userId);
	const budgetMasters = budgetMastersQuery.data;

	return (
		<PageContainer>
			<ContractsPageContext.Provider
				value={{ triggerRefresh: refreshContracts }}
			>
				<QueryStateIndicators queryState={contractsQuery} />
				<QueryStateIndicators queryState={budgetMastersQuery} />
				{contractsQuery.state === "success" && budgetMasters && (
					<ContractsList
						contracts={contractsQuery.data}
						budgetMasters={budgetMasters}
						initiallyExpanded={contractId ?? undefined}
					/>
				)}
			</ContractsPageContext.Provider>
		</PageContainer>
	);
}
