import { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useFetchedData, useServices } from "@hooks";
import { DivisionRouteParams } from "@service/navigation/routes";
import { Division } from "@models";

export default function useCustomerAndDivision() {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { customerId, divisionId } = params;

	const services = useServices();

	const fetchCustomerFn = useCallback(async () => {
		const [customer, division] = await Promise.all([
			services.customerService.getCustomerById(customerId),
			services.divisionService.getDivisionById(divisionId),
		]);
		return { customer, division };
	}, [services, customerId, divisionId]);

	const [fetchedData, , setManually] = useFetchedData(fetchCustomerFn);

	const setDivision = (division: Division) => {
		setManually({
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			customer: fetchedData.data!.customer,
			division,
		});
	};

	return {
		...fetchedData,
		setDivision,
	};
}
