import React, { useRef, useState } from "react";
import { Form, Overlay, Popover } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useServices, useAsyncCommand } from "@hooks";
import { NoAutoFillInput } from "./forms/Input";
import LoadingIndicator from "./LoadingIndicator";
import { Link } from "react-router-dom";
import Routes from "@service/navigation/routes";
import { Customer, Division } from "@models";
import { formatName } from "@service/util";
import { UserWithCustomerAndDivision } from "@models/User";
import { colors } from "@theme/theming";

interface FormInput {
	searchString: string;
}

export default function SearchBar() {
	const { register, handleSubmit } = useForm<FormInput>({
		mode: "onChange",
	});
	const { searchService } = useServices();
	const [showOverlay, setShowOverlay] = useState(false);

	const [fetchSearchResult, searchQuery] = useAsyncCommand(
		(limit: number, page: number, searchString: string) => {
			return searchService.getSearchResult({
				limit: limit,
				page: page,
				query: searchString,
			});
		}
	);

	const onSubmitSearch = handleSubmit((data: FormInput) => {
		setShowOverlay(true);
		void fetchSearchResult(5, 1, data.searchString);
	});

	let popoverContent = (
		<StyledPopoverContent>Keine Ergebnisse</StyledPopoverContent>
	);

	if (searchQuery.state === "fetching") {
		popoverContent = (
			<StyledPopoverContent>
				<LoadingIndicator absolutePosition={false} />
			</StyledPopoverContent>
		);
	}

	if (searchQuery.state === "success") {
		const searchResult = searchQuery.data;
		const { customers, division, users } = searchResult;

		if (customers.data.length + users.data.length + division.data.length > 0) {
			popoverContent = (
				<Results
					customers={customers.data}
					divisions={division.data}
					users={users.data}
					onClickLink={() => setShowOverlay(false)}
				/>
			);
		}
	}

	const overlayTriggerRef = useRef(null);

	const handleFocusInput = () => {
		if (searchQuery.data) {
			setShowOverlay(true);
		}
	};

	return (
		<>
			<Form inline onSubmit={onSubmitSearch} ref={overlayTriggerRef}>
				<NoAutoFillInput
					id="searchString"
					{...register("searchString")}
					type="text"
					placeholder="Suchen"
					className="mr-sm-2"
					autoComplete="search"
					data-testid="searchInput"
					onFocus={handleFocusInput}
				/>
			</Form>
			<Overlay
				placement="bottom"
				rootClose={true}
				show={showOverlay}
				onHide={() => setShowOverlay(false)}
				target={overlayTriggerRef}
			>
				<Popover id="search-results">{popoverContent}</Popover>
			</Overlay>
		</>
	);
}

type LinkProps = {
	onClickLink: () => void;
};

function Results(
	props: {
		customers: Customer[];
		users: UserWithCustomerAndDivision[];
		divisions: Division[];
	} & LinkProps
) {
	const { customers, users, divisions, ...linkProps } = props;
	const displayCustomers = customers.map((c) => (
		<CustomerResult customer={c} key={c.customerId} {...linkProps} />
	));

	const displayUsers = users.map((u) => (
		<UserResult user={u} key={u.userId} {...linkProps} />
	));

	const displayDivision = divisions.map((d) => (
		<DivisionResult division={d} key={d.divisionId} {...linkProps} />
	));

	return (
		<>
			{!!displayUsers.length && (
				<>
					<Popover.Title as="h3">Benutzer</Popover.Title>
					{displayUsers}
				</>
			)}
			{!!displayCustomers.length && (
				<>
					<Popover.Title as="h3">Kunden</Popover.Title>
					{displayCustomers}
				</>
			)}
			{!!displayDivision.length && (
				<>
					<Popover.Title as="h3">Standorte</Popover.Title>
					{displayDivision}
				</>
			)}
		</>
	);
}

function DivisionResult({
	division,
	onClickLink,
}: { division: Division } & LinkProps) {
	return (
		<Link
			to={Routes.Users.makePath({
				customerId: division.customerId,
				divisionId: division.divisionId,
			})}
			onClick={onClickLink}
		>
			<SelectablePopoverContent key={division.divisionId}>
				{division.name}
			</SelectablePopoverContent>
		</Link>
	);
}

function UserResult({
	user,
	onClickLink,
}: { user: UserWithCustomerAndDivision } & LinkProps) {
	return (
		<Link
			to={Routes.UserDetails.makePath({
				userId: user.userId,
				customerId: user.customerId,
				divisionId: user.divisionId,
			})}
			onClick={onClickLink}
		>
			<StyledPopoverContent
				translate="no"
				data-testid="display-users"
				key={user.userId}
			>
				<span>{formatName(user)}</span>
				<SmallFont>{user.email}</SmallFont>
				<SmallFont>{user.customer.name}</SmallFont>
				<SmallFont>{user.division.name}</SmallFont>
			</StyledPopoverContent>
		</Link>
	);
}

function CustomerResult({
	customer,
	onClickLink,
}: { customer: Customer } & LinkProps) {
	return (
		<Link
			to={`${Routes.Customers.route}?customer=${customer.customerId}`}
			onClick={onClickLink}
		>
			<SelectablePopoverContent
				data-testid="display-customers"
				key={customer.customerId}
			>
				{customer.name}
			</SelectablePopoverContent>
		</Link>
	);
}

const StyledPopoverContent = styled(Popover.Content)`
	display: flex;
	flex-direction: column;
	margin: 10px;
`;
const SmallFont = styled.span`
	font-size: 0.9em;
	color: ${colors.gray.g400};
`;

const SelectablePopoverContent = styled(StyledPopoverContent)`
	&:hover {
		color: ${colors.primary.p200};
		cursor: pointer;
	}
`;
