import { BudgetMaster, NewBudgetMaster } from "@models";
import _ from "lodash";

export default function copyBudgetMaster(
	budgetMaster: BudgetMaster
): NewBudgetMaster {
	const deepCopy = _.cloneDeep(budgetMaster);
	const cleaned = _.omit(deepCopy, "budgetMasterId");
	return {
		...cleaned,
		// cloning Dates seems to give some issues with validating propTypes for some reason, so we'll do it manually
		availableFrom: new Date(budgetMaster.availableFrom),
		availableUntil: budgetMaster.availableUntil
			? new Date(budgetMaster.availableUntil)
			: null,
	};
}
