import { ErrorBase } from "@util/errors";

export class ReportsTypeError extends ErrorBase {
	constructor() {
		super("Nicht alle verwendeten Lohnarten wurden ausgefüllt");
	}
}

export class AccountingMonthClosed extends ErrorBase {
	constructor() {
		super(
			"Reports können für einen abgeschlossenen Monat nicht erstellt werden. Bitte entsperre den Monat und schließe ihn erneut, wenn du neue Reports generieren musst."
		);
	}
}
