import React from "react";
import { isBefore } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { InputValidation, Label } from "@components";
import DatePicker from "../../../../components/forms/DatePicker";
import { ConfigBudgetMaster } from "./types";

type FormValues = Pick<ConfigBudgetMaster, "availableFrom" | "availableUntil">;

export default function AvailabilityDates() {
	const { control, watch, setValue, formState } = useFormContext<FormValues>();

	return (
		<div>
			<InputValidation
				error={
					formState.errors.availableFrom ?? formState.errors.availableUntil
				}
			>
				<Label>
					Verfügbar ab
					<Controller
						name="availableFrom"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => <DatePicker {...field} />}
					/>
				</Label>
				<Label>
					Verfügbar bis
					<Controller
						name="availableUntil"
						control={control}
						rules={{
							validate: (availableUntil: Date | null) => {
								const availableFrom = watch("availableFrom");
								if (
									availableFrom &&
									availableUntil &&
									!isBefore(availableFrom, availableUntil)
								) {
									return "Enddatum darf nicht vor Startdatum liegen";
								}
							},
						}}
						render={({ field }) => (
							<DatePicker
								{...field}
								canClearDate
								placeholder="unbefristet"
								onClearDate={() => {
									setValue("availableUntil", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}}
							/>
						)}
					/>
				</Label>
			</InputValidation>
		</div>
	);
}
