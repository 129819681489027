import React, { useEffect, useState } from "react";
import { useServices } from "@hooks";
import { CardContent, ExpandCard } from "@components";
import BusinessTrip from "@models/travel/BusinessTrip";
import TripCardHeader from "./TripCardHeader";
import TripReviewPage from "../TripReviewPage";

type Props = {
	trip: BusinessTrip;
	isInitiallyExpanded?: boolean;
};

export default function TripCard(props: Props) {
	const [trip, setTrip] = useState(props.trip);
	const [expanded, setExpanded] = useState(false);
	const [editable, setEditable] = useState(false);

	const services = useServices();

	const handleOnExpanded = () => {
		setExpanded(true);
		// refresh to get recent state from backend, to make sure most current data is reviewed
		void services.tripService.getTripById(trip.tripId).then((freshTrip) => {
			setTrip(freshTrip);
			setEditable(true);
		});
	};

	const handleOnCollapsed = () => {
		setExpanded(false);
		setEditable(false);
	};

	useEffect(() => {
		// clear local state when input prop changes
		setTrip(props.trip);
	}, [props.trip]);

	return (
		<ExpandCard
			testId={"trip-card-" + trip.tripId}
			header={<TripCardHeader trip={trip} expanded={expanded} />}
			onExpanded={handleOnExpanded}
			onCollapsed={handleOnCollapsed}
			isInitiallyExpanded={props.isInitiallyExpanded}
		>
			<CardContent>
				<TripReviewPage trip={trip} isEditable={editable} />
			</CardContent>
		</ExpandCard>
	);
}
