import React, { useRef, useState } from "react";
import { CancelDialog, ExpandCard } from "@components";
import { NewBudgetMaster, UpdatedBudgetMaster, RootProductId } from "@models";
import { BudgetMasterCardHeader } from "./BudgetMasterCardHeader";
import BudgetMasterConfiguration from "./BudgetMasterConfiguration";
import { isNewBudgetMaster } from "./util";
import { ExpandCardRef } from "components/ExpandCard";
import { getDateFlags } from "@util";
import styles from "@theme/styles";

type Props = {
	budgetMaster: UpdatedBudgetMaster | NewBudgetMaster;
	rootProductId: RootProductId;
	isInitiallyExpanded?: boolean;
	className?: string;
	discardBudgetMaster?: () => void;
	onCopy?: () => void;
};

export default function BudgetMasterCard(props: Props) {
	const { budgetMaster, rootProductId, isInitiallyExpanded } = props;

	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [mustShowCloseWarning, setMustShowCloseWarning] = useState(false);
	const expandCardRef = useRef<ExpandCardRef>(null);

	const isNew = isNewBudgetMaster(budgetMaster);

	const collapseCard = () => {
		expandCardRef?.current?.collapse();
		if (isNew && props.discardBudgetMaster) {
			props.discardBudgetMaster();
		}
	};

	const onCloseEditMode = (needsConfirmation: boolean) => {
		if (needsConfirmation && (mustShowCloseWarning || isNew)) {
			setShowCancelDialog(true);
			return false;
		} else {
			collapseCard();
			return true;
		}
	};

	const uniqueId =
		"budgetMasterId" in budgetMaster ? budgetMaster.budgetMasterId : "new";

	const { isActive } = getDateFlags(
		budgetMaster.availableFrom,
		budgetMaster.availableUntil
	);

	const header = (
		<BudgetMasterCardHeader
			budgetMaster={budgetMaster}
			rootProductId={rootProductId}
			onCopy={() => {
				if (props.onCopy) props.onCopy();
			}}
		/>
	);

	return (
		<ExpandCard
			className={props.className}
			testId={"budgetMasterCard-" + uniqueId}
			header={header}
			isInitiallyExpanded={isInitiallyExpanded}
			ref={expandCardRef}
			isActive={isActive}
			onCollapse={() => onCloseEditMode(true)}
			css={isNew ? styles.NewCard : undefined}
		>
			<BudgetMasterConfiguration
				key={"budgetMasterConfig-" + uniqueId}
				budgetMaster={budgetMaster}
				onClose={onCloseEditMode}
				onFormDirty={setMustShowCloseWarning}
			/>

			<CancelDialog
				showAlertDialog={showCancelDialog}
				cancelEditMode={collapseCard}
				hideDialog={() => setShowCancelDialog(false)}
			>
				Sie haben ungespeicherte Änderungen in der Produktkonfiguration.
				Änderungen wirklich verwerfen?
			</CancelDialog>
		</ExpandCard>
	);
}
