import { Properties } from "@util/types";
import { Address } from "./Address";
import { RouteLeg } from "./RouteLeg";

export class Waypoint {
	constructor(props: Properties<Waypoint>) {
		this.address = props.address;
		this.legToNext = props.legToNext;
	}

	readonly address: Address;
	readonly legToNext: RouteLeg | null;

	static fromJson(data: any) {
		return new Waypoint({
			...data,
			legToNext: data.legToNext ? RouteLeg.fromJson(data.legToNext) : null,
		});
	}
}
