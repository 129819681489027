import { BusinessTrip } from "@models";
import { FaThumbsUp as ApproveIcon } from "react-icons/fa";
import React, { useContext } from "react";
import {
	ActionButton,
	Button,
	ButtonLoadingIndicator,
	Icon,
	QueryError,
} from "@components";
import styled from "styled-components";
import RejectButton from "@components/vouchers/review/components/VoucherReviewControls/RejectButton";
import { useAsyncCommand, useServices } from "@hooks";
import ReviewContext from "../context/ReviewContext";
import { useFormContext } from "react-hook-form";
import { EditableTrip } from "@models/travel/EditableTrip";
import { Properties } from "@util/types";
import { Alert } from "react-bootstrap";
import useTripReviewContext from "../hooks/useTripReviewContext";

type Props = {
	trip: BusinessTrip;
};

export default function TripReviewControls(props: Props) {
	const { trip } = props;

	const { isTripEditable } = useTripReviewContext();
	const isReadOnly = !isTripEditable;
	const isReopenable = trip.isByEmployer() && !trip.monthIsClosed;

	const form = useFormContext<EditableTrip>();

	const reviewContext = useContext(ReviewContext);
	if (!reviewContext) return null;

	const { reviewService } = useServices();

	function prepareTrip() {
		const formValues = form.getValues();
		const cmd: Properties<EditableTrip> = {
			...formValues,
			meals: formValues.meals.map((value, index) => ({
				...value,
				date: trip.accomodation.meals[index].date,
			})),
			integrityToken: trip.integrityToken,
		};
		return cmd;
	}

	const [reopenCmd, reopenQuery] = useAsyncCommand(
		async (trip: BusinessTrip) => {
			const updatedTrip = await reviewService.trip.reopen(trip.tripId);
			reviewContext.onChanged(updatedTrip);
			return updatedTrip;
		}
	);

	const [rejectCmd, rejectQuery] = useAsyncCommand(
		async (trip: BusinessTrip, reason: string | null) => {
			const formTrip = prepareTrip();
			const updatedTrip = await reviewService.trip.reject(
				trip.tripId,
				formTrip,
				reason
			);
			reviewContext.onChanged(updatedTrip);
			return updatedTrip;
		}
	);

	const [approveCmd, approveQuery] = useAsyncCommand(
		async (trip: BusinessTrip) => {
			const formTrip = prepareTrip();
			const updatedTrip = await reviewService.trip.approve(
				trip.tripId,
				formTrip
			);
			reviewContext.onChanged(updatedTrip);
			return updatedTrip;
		}
	);

	const [saveCmd, saveQuery] = useAsyncCommand(async (trip: BusinessTrip) => {
		const formTrip = prepareTrip();
		const updatedTrip = await reviewService.trip.save(trip.tripId, formTrip);
		reviewContext.onChanged(updatedTrip);
		return updatedTrip;
	});

	const handleReopen = async () => {
		await reopenCmd(trip);
	};

	const handleReject = async (reason: string | null) => {
		await rejectCmd(trip, reason);
	};

	const handleApprove = async () => {
		await approveCmd(trip);
	};

	const handleSave = async () => {
		await saveCmd(trip);
	};

	const reopenButton = (
		<ReopenButton onClick={handleReopen} disabled={!isReopenable}>
			<ButtonLoadingIndicator query={reopenQuery} />
			<Icon.Reset />
			<ButtonText>WIEDER ERÖFFNEN</ButtonText>
		</ReopenButton>
	);

	const rejectReasons = [
		"Angegebene Daten stimmen nicht überein",
		"Belege bitte vollständig einreichen",
		"Datum oder Uhrzeit nicht erkennbar. Bitte erneut hochladen",
		"Beleg ist nicht lesbar. Bitte erneut hochladen",
	];

	const rejectButton = (
		<RejectButton
			onReject={handleReject}
			customRejectReasons={rejectReasons}
			rejectQuery={rejectQuery}
			isDisabled={isReadOnly}
		/>
	);

	const approveButton = (
		<ApproveButton onClick={handleApprove} disabled={isReadOnly}>
			<ButtonLoadingIndicator query={approveQuery} />
			<ApproveIcon />
			<ButtonText>Akzeptieren</ButtonText>
		</ApproveButton>
	);

	const saveButton = (
		<ActionButton
			onClick={handleSave}
			query={saveQuery}
			disableAfterSuccess={false}
			disabled={isReadOnly}
		>
			<Icon.Save />
		</ActionButton>
	);

	return (
		<Container>
			{trip.monthIsClosed ? (
				<Alert variant="warning">
					Keine Bearbeitung mehr möglich, da der Monat für die Reise bereits
					abgeschlossen ist.
				</Alert>
			) : isReopenable ? (
				reopenButton
			) : (
				<>
					{approveButton}
					{rejectButton}
					{saveButton}
				</>
			)}
			<QueryError queryState={[approveQuery, rejectQuery, saveQuery]} />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

const ReopenButton = styled(Button).attrs({ variant: "warning" })`
	display: flex;
	align-items: center;
`;

const ApproveButton = styled(Button).attrs({ variant: "success" })`
	display: flex;
	align-items: center;
`;

export const ButtonText = styled.span`
	margin-left: 4px;
`;
