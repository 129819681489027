import { Label, Select } from "@components";
import { Division } from "@models";
import { UpdatedDivision } from "@models/Division";
import { reflect } from "@util";
import _ from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { numericSelectProps } from "./utils";
import { Container, Row } from "../StyledComponents";

const NULL_VALUE = "null";
const FIELD_NAME_AUTO_LOCK = reflect<Division>().nameof("autoLockDayOfMonth");

export default function TimerSettings() {
	const { control } = useFormContext<UpdatedDivision>();

	return (
		<Container>
			<h4>Stichtage</h4>
			<Row>
				<Label column sm={4} htmlFor={FIELD_NAME_AUTO_LOCK}>
					Automatisches Sperren des Vormonats
				</Label>
				<Controller
					control={control}
					name={FIELD_NAME_AUTO_LOCK}
					defaultValue={null}
					render={({ field }) => (
						<Select
							id={FIELD_NAME_AUTO_LOCK}
							{...field}
							{...numericSelectProps(field, NULL_VALUE)}
						>
							<option value={NULL_VALUE}>deaktiviert</option>
							{_.range(1, 16).map((dayOfMonth) => (
								<option key={dayOfMonth} value={dayOfMonth}>
									am {dayOfMonth}. des Folgemonats
								</option>
							))}
						</Select>
					)}
				/>
			</Row>
		</Container>
	);
}
