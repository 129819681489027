import React from "react";
import { RootProductId } from "@models";
import Icon from "./icons";

export default function ProductIcon({
	productId,
}: {
	productId: RootProductId;
}) {
	switch (productId) {
		case "web":
			return <Icon.Product.Web />;
		case "spend":
			return <Icon.Product.Spend />;
		case "lunch":
			return <Icon.Product.Lunch />;
		case "congrats":
			return <Icon.Product.Congrats />;
		case "shortTime":
			return <Icon.Product.ShortTime />;
		case "mobility":
			return <Icon.Product.Mobility />;
		case "fitAndRelax":
			return <Icon.Product.FitAndRelax />;
		case "travel":
			return <Icon.Product.Expenses />;
		case "travel.budget":
			return <Icon.Product.Travel />; // TODO: change icon
		case "homeAndWork":
			return <Icon.Product.HomeAndWork />;
		case "sachbezug":
			return <Icon.Product.Sachbezug />;
		default:
			return null;
	}
}
