import { Properties } from "@util/types";
import TravelExpense from "./TravelExpense";

export default class ShortDistanceExpense extends TravelExpense {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: Properties<ShortDistanceExpense>) {
		super(props);
	}

	static readonly type = "shortDistance";

	override get displayName() {
		return "Kurzstreckenbeleg (Taxi / Sharing / ÖPNV)";
	}

	static fromJson(data: any) {
		return new ShortDistanceExpense(TravelExpense.fromJson(data));
	}
}
