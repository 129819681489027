import { CardsListContainer, Icon } from "@components";
import { Division } from "@models";
import PricingRecord from "@models/PricingRecord";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import styled from "styled-components";
import PricingRecordCard from "./PricingRecordCard";
import { Container } from "../StyledComponents";

export default function PricingSettingsList() {
	const { control, watch } = useFormContext<Division>();
	const {
		fields: priceSettingField,
		append,
		remove,
	} = useFieldArray({
		name: "pricingSettings",
		control,
	});
	const pricingSettings = watch("pricingSettings") ?? [];
	const [newPricingSettingIndex, setNewPricingSettingIndex] = useState<
		number | undefined
	>();
	const addNewPricingRecord = () => {
		setNewPricingSettingIndex(priceSettingField.length);
		append(
			new PricingRecord({
				availableFrom: null,
				availableUntil: null,
				minimumFee: 9900,
				feePerModuleAndEmployee: null,
				flatFee: null,
				flatFeePerEmployee: null,
			})
		);
	};

	const onCopy = (index: number) => {
		setNewPricingSettingIndex(priceSettingField.length);
		append(priceSettingField[index]);
	};

	const onDelete = (index: number) => {
		remove(index);
	};

	const onCollapse = () => {
		setNewPricingSettingIndex(undefined);
	};

	return (
		<Container>
			<h4>Honorar-Einstellungen</h4>
			<StyledCardsListContainer>
				{priceSettingField?.map((field, index) => (
					<PricingRecordCard
						key={field.id}
						isExpanded={index === newPricingSettingIndex}
						{...{
							control,
							index,
							field,
							onCopy,
							onDelete,
							pricingSettings,
							onCollapse,
						}}
					/>
				))}
			</StyledCardsListContainer>
			<AddPricingRecordButton onClick={addNewPricingRecord}>
				<Icon.Add /> Neue Honorareinstellung
			</AddPricingRecordButton>
		</Container>
	);
}

const StyledCardsListContainer = styled(CardsListContainer)`
	margin-block: 0;
`;

const AddPricingRecordButton = styled(Button).attrs({
	variant: "outline-secondary",
	size: "sm",
})`
	width: fit-content;
	min-width: 20em;
`;
