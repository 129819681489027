import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { Contract, ConfigContract, VehicleType, NewContract } from "@models";
import {
	Checkbox,
	CurrencyInput,
	FormGroup,
	Label,
	NoAutoFillInput,
	Select,
} from "@components";
import { isJobVehicleContract } from "../util";

type Props = {
	contract: Contract | NewContract;
};

// TODO: rename component
export default function ContractSegments(props: Props) {
	const { contract } = props;

	const { control, setValue, watch } = useFormContext<ConfigContract>();

	const overflowBudgetAmountIn = watch("overflowBudgetAmountIn");
	const needsCommuteDistance =
		watch("vehicleType") !== VehicleType.Bike && isJobVehicleContract(contract);

	useEffect(() => {
		if ((overflowBudgetAmountIn as string) === "")
			setValue("overflowBudgetAmountIn", null);
	}, [overflowBudgetAmountIn]);

	return (
		<>
			<FormGroup as={Row}>
				<Label htmlFor="netDeduction" column sm={4}>
					Nettobeteiligung
				</Label>
				<Col sm={8}>
					<Controller
						name="netDeduction"
						control={control}
						render={({ field }) => (
							<CurrencyInput {...field} allowNull id="netDeduction" />
						)}
					/>
				</Col>
			</FormGroup>

			<FormGroup as={Row}>
				<Label htmlFor="grossDeduction" column sm={4}>
					Bruttobeteiligung
				</Label>
				<Col sm={8}>
					<Controller
						name={`grossDeduction`}
						control={control}
						render={({ field }) => (
							<CurrencyInput {...field} allowNull id="grossDeduction" />
						)}
					/>
				</Col>
			</FormGroup>

			{isJobVehicleContract(contract) && (
				<>
					<FormGroup as={Row}>
						<Label htmlFor="serviceIntervalInMonths" column sm={4}>
							Service-Interval (Monate)
						</Label>
						<Col sm={8}>
							<Controller
								name={`serviceIntervalInMonths`}
								render={({ field }) => (
									<NoAutoFillInput
										{...field}
										type="number"
										min="0"
										max="36"
										id="serviceIntervalInMonths"
									/>
								)}
								control={control}
							/>
							<Form.Text className="text-muted">
								Wie oft soll der Benutzer an die Wartung des Fahrzeugs erinnert
								werden, ausgehend vom Überlassungsdatum? <br />
								Wenn hier 0 eingetragen wird, wird keine Erinnerung versandt.
							</Form.Text>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<Col sm={4}></Col>
						<Col sm={8}>
							<Controller
								name={`expectServiceVerification`}
								control={control}
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								render={({ field: { ref, ...fieldRest } }) => (
									<Checkbox
										{...fieldRest}
										value={fieldRest.value}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setValue(`expectServiceVerification`, e.target.checked)
										}
										id="expectServiceVerification"
										label="Mitarbeiter soll Bestätigung von Serviceinspektion hochladen"
									/>
								)}
							/>
							<Form.Text className="text-muted">
								Wenn aktiviert, wird der Benutzer in der Erinnerungsmail auch
								aufgefordert, einen Nachweis für die Inspektion hochzuladen.{" "}
								<br />
								Andernfalls dient die Erinnerungsmail nur als Erinnerung.
							</Form.Text>
						</Col>
					</FormGroup>
				</>
			)}

			{needsCommuteDistance && (
				<FormGroup as={Row}>
					<Label htmlFor="commuteDistanceInKm" column sm={4}>
						Entfernung Wohnung-Arbeitsstätte (km)
					</Label>
					<Col sm={8}>
						<Controller
							name={`commuteDistanceInKm`}
							render={({ field }) => (
								<NoAutoFillInput
									{...field}
									type="number"
									min="0"
									max="9999"
									id="commuteDistanceInKm"
								/>
							)}
						/>
					</Col>
				</FormGroup>
			)}

			<FormGroup as={Row}>
				<Label htmlFor="overflowBudgetAmountIn" column sm={4}>
					Überlauf aus Budget übertragen in
				</Label>
				<Col sm={8}>
					<Controller
						name={`overflowBudgetAmountIn`}
						control={control}
						render={({ field }) => (
							<Select id="overflowBudgetAmountIn" {...field}>
								<option selected></option>
								<option value="netDeduction">Nettoabzug</option>
								<option value="grossDeduction">Bruttoabzug</option>
							</Select>
						)}
					/>
				</Col>
			</FormGroup>
		</>
	);
}
