import React, { useEffect, useState } from "react";
import { ButtonsContainer, Button } from "@components";
import { splitBudgetMasters } from "./util";
import { BudgetMaster, RootProductId } from "@models";
import styled from "styled-components";
import { StyledBudgetMasterCard } from "./BudgetMasterCategorySection";

type Props = {
	showActiveBudgetMasters: boolean;
	budgetMasters: BudgetMaster[];
	rootProductId: RootProductId;
	handleCopyBudgetMaster: (bm: BudgetMaster) => void;
};

export default function ExistingBudgetMasters(props: Props) {
	const { showActiveBudgetMasters, budgetMasters, rootProductId } = props;
	const [showExpired, setShowExpired] = useState(false);
	const [showInFuture, setShowInFuture] = useState(false);

	useEffect(() => {
		setShowExpired(!showActiveBudgetMasters);
		setShowInFuture(!showActiveBudgetMasters);
	}, [showActiveBudgetMasters]);

	const { activeBudgetMasters, expiredBudgetMasters, futureBudgetMasters } =
		splitBudgetMasters(budgetMasters);

	const showExpiredBudgetMastersButton =
		!showExpired && expiredBudgetMasters.length > 0 && showActiveBudgetMasters;
	const showFutureBudgetMastersButton =
		!showInFuture && futureBudgetMasters.length > 0 && showActiveBudgetMasters;
	return (
		<>
			{showFutureBudgetMastersButton && (
				<StyledButtonContainer>
					<StyledButton
						type="button"
						onClick={() => {
							setShowInFuture(true);
						}}
						data-testid="showFutureBM-button"
					>
						... {futureBudgetMasters.length} zukünftige anzeigen
					</StyledButton>
				</StyledButtonContainer>
			)}
			{showInFuture &&
				futureBudgetMasters.map((bm) => (
					<StyledBudgetMasterCard
						key={bm.budgetMasterId}
						rootProductId={rootProductId}
						budgetMaster={bm}
						onCopy={() => props.handleCopyBudgetMaster(bm)}
					/>
				))}
			{activeBudgetMasters.map((bm) => (
				<StyledBudgetMasterCard
					key={bm.budgetMasterId}
					rootProductId={rootProductId}
					budgetMaster={bm}
					onCopy={() => props.handleCopyBudgetMaster(bm)}
				/>
			))}
			{showExpiredBudgetMastersButton && (
				<StyledButtonContainer>
					<StyledButton
						type="button"
						onClick={() => {
							setShowExpired(true);
						}}
						data-testid="showExpiredBM-button"
					>
						... {expiredBudgetMasters.length} ausgelaufene anzeigen
					</StyledButton>
				</StyledButtonContainer>
			)}
			{showExpired &&
				expiredBudgetMasters.map((bm) => (
					<StyledBudgetMasterCard
						key={bm.budgetMasterId}
						rootProductId={rootProductId}
						budgetMaster={bm}
						onCopy={() => props.handleCopyBudgetMaster(bm)}
					/>
				))}
		</>
	);
}

const StyledButtonContainer = styled(ButtonsContainer)`
	margin: 16px;
`;

const StyledButton = styled(Button).attrs({
	variant: "outline-secondary",
})``;
