import { colors } from "@theme/theming";
import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import styled from "styled-components";

type NavItems = {
	title: string;
	routerLink: string;
	translatable?: boolean;
};

interface Props {
	navItems: NavItems[];
	className?: string;
}

export default function Breadcrumbs(props: Props) {
	return (
		<StyledBreadcrumb className={props.className}>
			{props.navItems.map((item, index) => {
				return (
					<Breadcrumb.Item
						key={index}
						href={item.routerLink}
						linkAs={Link}
						linkProps={{
							to: item.routerLink,
						}}
					>
						<span translate={item.translatable === false ? "no" : undefined}>
							{item.title}
						</span>
					</Breadcrumb.Item>
				);
			})}
		</StyledBreadcrumb>
	);
}

const StyledBreadcrumb = styled(Breadcrumb)`
	ol {
		background: ${colors.gray.g100};
		a {
			color: ${colors.primary.p200};
		}
		.breadcrumb-item + .breadcrumb-item {
			&::before {
				color: ${colors.gray.g300};
			}
		}
	}
`;
