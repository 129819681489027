import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
	Button,
	Label,
	NoAutoFillInput,
	CheckboxToggle,
	ActionButton,
	Input,
	InputContainer,
	ButtonsContainer,
	RequiredMarker,
	TextArea,
} from "@components";
import { QueryState } from "../../../hooks/useFetchedData";
import Routes from "@service/navigation/routes";
import { DivisionStatus, NewDivision } from "@models/Division";
import JobRadSettings from "./JobRadSettings";

interface Props {
	customerId: string;
	onSubmit: (division: NewDivision) => void;
	cmdState: QueryState<any>;
}

// TODO: integrate this into AddDivisionPage page
function DivisionForm({ onSubmit: handleOnSubmit, cmdState }: Props) {
	const [isJobRad, setIsJobRad] = useState(false);
	const [isTrialDivision, setIsTrialDivision] = useState(true);
	const methods = useForm<NewDivision>({
		mode: "onChange",
		defaultValues: { status: DivisionStatus.Trial },
	});
	const {
		control,
		getValues,
		formState,
		register,
		handleSubmit,
		unregister,
		setValue,
	} = methods;

	const toggleJobRadSetting = () => {
		setIsJobRad((preVal) => {
			if (preVal) {
				unregister("providerSettings.jobrad");
			}
			return !preVal;
		});
	};

	const toggleStatus = () => {
		setIsTrialDivision((preVal) => {
			setValue("status", preVal ? DivisionStatus.Active : DivisionStatus.Trial);
			return !preVal;
		});
	};

	return (
		<FormProvider {...methods}>
			<FormContainer
				className="form"
				onSubmit={handleSubmit(() => handleOnSubmit(getValues()))}
			>
				<FlexRowContainer>
					<ColumnContainer>
						<InputContainer>
							<Label htmlFor="name">
								Name <RequiredMarker />
							</Label>
							<Input
								id="name"
								as={NoAutoFillInput}
								control={control}
								{...register("name", { required: true })}
								data-testid="division-form-name"
							/>
						</InputContainer>
						<InputContainer>
							<Label htmlFor="phone">Telefon</Label>
							<Input
								id="phone"
								as={NoAutoFillInput}
								control={control}
								{...register("phone")}
								data-testid="division-form-phone"
							/>
						</InputContainer>
						<InputContainer>
							<Label htmlFor="fax">Telefax</Label>
							<Input
								id="fax"
								as={NoAutoFillInput}
								control={control}
								{...register("fax")}
								data-testid="division-form-fax"
							/>
						</InputContainer>
						<InputContainer>
							<Label htmlFor="stid">Steuernummer</Label>
							<Input
								id="stid"
								as={NoAutoFillInput}
								control={control}
								{...register("stid")}
								data-testid="division-form-stid"
							/>
						</InputContainer>
					</ColumnContainer>
					<ColumnContainer>
						<InputContainer>
							<Label htmlFor="street">Straße</Label>
							<Input
								id="street"
								as={NoAutoFillInput}
								control={control}
								{...register("street")}
								data-testid="division-form-street"
							/>
						</InputContainer>
						<FlexRowContainer>
							<InputContainer>
								<Label htmlFor="zip">PLZ</Label>
								<Input
									id="zip"
									as={NoAutoFillInput}
									control={control}
									{...register("zip")}
									data-testid="division-form-zip"
								/>
							</InputContainer>
							<InputContainer>
								<Label htmlFor="city">Ort</Label>
								<Input
									id="city"
									as={NoAutoFillInput}
									control={control}
									{...register("city")}
									data-testid="division-form-city"
								/>
							</InputContainer>
						</FlexRowContainer>
						<InputContainer>
							<Label htmlFor="country">Land</Label>
							<Input
								id="country"
								as={NoAutoFillInput}
								control={control}
								{...register("country")}
								data-testid="division-form-country"
							/>
						</InputContainer>
						<InputContainer>
							<Label htmlFor="ustid">Umsatzsteuer-Identifikationsnummer</Label>
							<Input
								id="ustid"
								as={NoAutoFillInput}
								control={control}
								{...register("ustid")}
								data-testid="test-ustid"
							/>
						</InputContainer>
					</ColumnContainer>
				</FlexRowContainer>
				<FlexRowContainer>
					<CheckboxToggle
						label="Ist Hauptsitz"
						data-testid="division-form-isMainDivision"
						{...register("isMainDivision")}
					/>
					<CheckboxToggle
						label="Ist Trialstandort"
						data-testid="division-form-isTrialDivision"
						onChange={toggleStatus}
						checked={isTrialDivision}
					/>
					<CheckboxToggle label="Nutzt JobRad" onChange={toggleJobRadSetting} />
				</FlexRowContainer>
				{isJobRad && <JobRadSettings />}
				<InputContainer>
					<Label htmlFor="comment">Kommentar</Label>
					<TextArea
						id="comment"
						as={NoAutoFillInput}
						control={control}
						{...register("comment")}
						data-testid="division-form-comment"
					/>
				</InputContainer>

				<ButtonsContainer>
					<ActionButton
						type="submit"
						disabled={!formState.isValid || !formState.isDirty}
						data-testid="update-division-button"
						query={cmdState}
					>
						Speichern
					</ActionButton>
					<Link to={Routes.Customers.makePath()}>
						<Button type="submit" data-testid="to-back-button">
							Zurück
						</Button>
					</Link>
				</ButtonsContainer>
			</FormContainer>
		</FormProvider>
	);
}

const FlexRowContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
`;

const FormContainer = styled.form`
	max-width: 1100px;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	margin-inline: auto;
`;
const ColumnContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

export default DivisionForm;
