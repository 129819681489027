import { Voucher } from "@models";
import { serializeDate, deserializeDate } from "@util";
import { Mutable, Properties } from "@util/types";
import { EmployerPaidRailcardSettings } from "./EmployerPaidRailcardSettings";

export default class Contract {
	constructor(props: Properties<Contract>) {
		this.contractId = props.contractId;
		this.userId = props.userId;
		this.externalId = props.externalId;
		this.name = props.name;
		this.comment = props.comment;
		this.category = props.category;
		this.startDate = props.startDate;
		this.endDate = props.endDate;
		this.leaseStartDate = props.leaseStartDate;
		this.leaseEndDate = props.leaseEndDate;
		this.amount = props.amount;
		this.listPrice = props.listPrice;
		this.leaseRate = props.leaseRate;
		this.insuranceRate = props.insuranceRate;
		this.serviceRate = props.serviceRate;
		this.flatTaxRate = props.flatTaxRate;
		this.purchasePrice = props.purchasePrice;
		this.transferDate = props.transferDate;
		this.vehicleType = props.vehicleType;
		this.expectedPurchasePrice = props.expectedPurchasePrice;
		this.expectedVendor = props.expectedVendor;
		this.settings = props.settings;
		this.type = props.type;
		this.netDeduction = props.netDeduction;
		this.grossDeduction = props.grossDeduction;
		this.serviceIntervalInMonths = props.serviceIntervalInMonths;
		this.expectServiceVerification = props.expectServiceVerification;
		this.commuteDistanceInKm = props.commuteDistanceInKm;
		this.overflowBudgetAmountIn = props.overflowBudgetAmountIn;
		this.isFromThirdParty = props.isFromThirdParty;
	}

	readonly contractId: string;
	readonly userId: string;
	readonly externalId: string | null;
	readonly name: string;
	readonly comment: string | null;
	readonly category: string;
	readonly startDate: Date;
	readonly endDate: Date | null;
	readonly leaseStartDate: Date | null;
	readonly leaseEndDate: Date | null;
	readonly amount: number;
	readonly listPrice: number | null;
	readonly leaseRate: number | null;
	readonly insuranceRate: number | null;
	readonly serviceRate: number | null;
	readonly flatTaxRate: number | null;
	readonly purchasePrice: number | null;
	readonly transferDate: Date | null;
	readonly vehicleType: string | null;
	readonly expectedVendor: "employer" | "provider" | null;
	readonly expectedPurchasePrice: number | null;
	readonly settings: EmployerPaidRailcardSettings | null;
	readonly type: string | null;
	readonly netDeduction: number | null;
	readonly grossDeduction: number | null;
	readonly serviceIntervalInMonths: number | null;
	readonly expectServiceVerification: boolean | null;
	readonly commuteDistanceInKm: number | null;
	readonly overflowBudgetAmountIn: OverflowBudgetTarget | null;
	readonly isFromThirdParty: boolean;

	public static convertForJson(contract: Contract | NewContract) {
		const converted = {
			...contract,
			startDate: serializeDate.date(contract.startDate),
			endDate: serializeDate.nullable.date(contract.endDate),
			leaseStartDate: serializeDate.nullable.date(contract.leaseStartDate),
			leaseEndDate: serializeDate.nullable.date(contract.leaseEndDate),
			transferDate: serializeDate.nullable.date(contract.transferDate),
		};
		return converted;
	}

	public static fromJson(data: any): Contract {
		let settings: Contract["settings"] = null;
		if (isEmployerFinancedRailcardCategory(data.category)) {
			settings = EmployerPaidRailcardSettings.fromJson(data.settings);
		}

		return new Contract({
			...data,
			startDate: deserializeDate.date(data["startDate"]),
			endDate: deserializeDate.nullable.date(data["endDate"]),
			leaseStartDate: deserializeDate.nullable.date(data["leaseStartDate"]),
			leaseEndDate: deserializeDate.nullable.date(data["leaseEndDate"]),
			transferDate: deserializeDate.nullable.date(data["transferDate"]),
			settings,
		});
	}
}

export type ConfigContract = Mutable<
	Omit<Properties<Contract>, "contractId" | "userId">
>;

export enum VehicleType {
	Bike = "bike",
	MotorScooter = "motorScooter",
	Car = "car",
	KickScooter = "kickScooter",
}

export enum OverflowBudgetTarget {
	NetDeduction = "netDeduction",
	GrossDeduction = "grossDeduction",
}

export type NewContract = Omit<Properties<Contract>, "contractId"> & {
	attachedVoucher?: Voucher;
};

export function isEmployerFinancedRailcardContract(
	contract: Contract | NewContract
) {
	return isEmployerFinancedRailcardCategory(contract.category);
}

export function isEmployerFinancedRailcardCategory(category: string) {
	return category === "mobility.longDistance.railcardEmployerFinanced";
}
