import { OrderDirection } from "@hooks";
import { User, Voucher } from "@models";

class RouteInfo<TParams, TState = undefined> {
	constructor(readonly route: string, readonly queryParams?: URLSearchParams) {}

	makePath<
		TInferedParams extends TParams extends undefined ? undefined : never
	>(noParams?: TInferedParams): string;

	makePath<TInferedParams extends TParams extends undefined ? never : TParams>(
		params: TInferedParams
	): string;

	/**
	 * Constructs a path by replacing the route with concrete values of the placeholders
	 */

	makePath(params: TParams | undefined): string {
		let path = this.route;
		if (params) {
			Object.entries(params).forEach(
				([p, value]) => (path = path.replace(":" + p, String(value)))
			);
		}

		return this.queryParams ? `${path}?${this.queryParams.toString()}` : path;
	}

	makeState(state: TState) {
		return state; // just for type checking
	}
}

type SortOptions = {
	sort: string;
	sortDirection: OrderDirection;
};

function defineRoute<TParams, TState = undefined>(
	route: string,
	options?: SortOptions
): RouteInfo<TParams, TState> {
	if (options) {
		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");
		queryParams.set("limit", "15");
		queryParams.set("sort", options.sort);
		queryParams.set("sortDirection", options.sortDirection ?? "asc");
		return new RouteInfo(route, queryParams);
	}
	return new RouteInfo(route);
}

export type CustomerRouteParams = {
	customerId: string;
};

export type UserRouteParams = {
	customerId: string;
	divisionId: string;
	userId: string;
};

export type DivisionRouteParams = {
	customerId: string;
	divisionId: string;
};

export type ContractsPageState = {
	attachedVoucher?: Voucher;
	reviewDuration?: number;
};

export type UsersListPageState = { newUser?: User };

const userRoutes = {
	UserDetails: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId"
	),
	UserVouchers: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/vouchers"
	),
	Statistics: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/statistics"
	),
	UserBasicData: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/basicData"
	),
	EditUser: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/edit"
	),
	AddUser: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/user/new"
	),
	Budgets: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/budgets"
	),
	UserProducts: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/products"
	),
	UserContracts: defineRoute<UserRouteParams, ContractsPageState>(
		"/customer/:customerId/division/:divisionId/user/:userId/contracts"
	),
	UserRoles: defineRoute<UserRouteParams>(
		"/customer/:customerId/division/:divisionId/user/:userId/roles"
	),
};

const divisionRoutes = {
	Division: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/"
	),
	Users: defineRoute<DivisionRouteParams, UsersListPageState>(
		"/customer/:customerId/division/:divisionId/users",
		{
			sort: "lastname",
			sortDirection: "asc",
		}
	),
	AddDivision: defineRoute<CustomerRouteParams>(
		"/customer/:customerId/division/new"
	),
	Reports: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/reports"
	),
	DivisionVouchers: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/vouchers"
	),
	AccountingMonths: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/accountingMonths"
	),
	Settings: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/settings"
	),
	CsvImports: defineRoute<DivisionRouteParams>(
		"/customer/:customerId/division/:divisionId/csv-imports"
	),
};

const Routes = {
	Root: "/",
	Home: "/home",
	AddCustomer: "/customer/new",
	EditCustomer: defineRoute<CustomerRouteParams>("/customer/:customerId/edit"),
	Customers: defineRoute("/customer", {
		sort: "customerNo",
		sortDirection: "asc",
	}),
	Review: "/review",
	AllVouchers: "/vouchers",
	...userRoutes,
	...divisionRoutes,
	// TODO: cluster routes, like Routes.Division.Settings instead of Routes.Settings
};

export default Routes;
