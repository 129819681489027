import { Currency } from "@util";
import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReviewContext from "../context/ReviewContext";
import { ReadonlyText } from "./ReadonlyText";
import { CurrencyInput } from "@components";

export default function VoucherReviewAmount(props: {
	id: string;
	name: string;
}) {
	const hasReviewContext = !!useContext(ReviewContext);

	const { control } = useFormContext();

	return (
		<Controller
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			render={({ field: { ref, ...fieldRest } }) =>
				hasReviewContext ? (
					<CurrencyInput id={props.id} {...fieldRest} />
				) : (
					<ReadonlyText value={Currency.format(fieldRest.value)} />
				)
			}
			control={control}
			name={props.name}
		/>
	);
}
