import { differenceInMilliseconds } from "date-fns";
import { useCallback, useRef } from "react";

/**
 *
 * @returns the duration in milliseconds (ms) between the start and end of the timer
 */
export default function useTimer() {
	const startTimeRef = useRef<Date | null>(null);

	const startTimer = useCallback(() => {
		startTimeRef.current = new Date();
	}, []);

	const getElapsedMs = useCallback(() => {
		if (startTimeRef.current) {
			const endTime = Date.now();
			const durationMs = differenceInMilliseconds(
				endTime,
				startTimeRef.current
			);
			return durationMs;
		}
		return 0;
	}, []);

	return { startTimer, getElapsedMs };
}
