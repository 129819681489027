import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import { colors } from "@theme/theming";

type Props = {
	absolutePosition?: boolean;
};

export default function LoadingIndicator(props: Props) {
	return (
		<LoadingBackground absolutePosition={props.absolutePosition}>
			<SpinnerWrapper>
				<StyledSpinner animation="border" />
			</SpinnerWrapper>
		</LoadingBackground>
	);
}

const LoadingBackground = styled.div`
	position: ${(props: Props) =>
		props.absolutePosition ?? true ? "absolute" : "relative"};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	background: ${colors.gray.g100}80;
	width: 100%;
	height: 100%;
`;

const SpinnerWrapper = styled.div``;

const StyledSpinner = styled(Spinner)`
	top: 50vh;
	left: 50vw;
	color: ${colors.primary.p200};
`;
