import { Properties } from "@util/types";
import * as yup from "yup";
import _ from "lodash";

export enum PerModuleFeeType {
	PayPerUse = "pay-per-use",
	PayOnDemand = "pay-on-demand",
	FlatFee = "flatFee",
	FlatPerUse = "flat-per-use",
	FlatPerLoggedIn = "flat-per-logged-in",
}

export type PricingSettings = PricingRecord[];

export default class PricingRecord {
	constructor(props: Omit<Properties<PricingRecord>, "id">) {
		this.availableFrom = props.availableFrom;
		this.availableUntil = props.availableUntil;
		this.minimumFee = props.minimumFee;
		this.flatFee = props.flatFee;
		this.flatFeePerEmployee = props.flatFeePerEmployee;
		this.feePerModuleAndEmployee = props.feePerModuleAndEmployee;
		this.id = _.uniqueId("pricingRecord_");
	}

	readonly availableFrom: Date | null;
	readonly availableUntil: Date | null;
	readonly minimumFee: number;
	readonly id?: string;

	readonly flatFee: null | {
		amount: number;
		description: string | null;
	};

	readonly flatFeePerEmployee: null | {
		amount: number;
		description: string | null;
	};

	readonly feePerModuleAndEmployee: null | {
		[id: string]: FeePerModuleAndEmployee;
	};

	public static fromJson(
		data: yup.InferType<typeof pricingRecordSchema>
	): PricingRecord {
		return new PricingRecord(data);
	}
}

export type FeePerModuleAndEmployee = {
	type: PerModuleFeeType | null;
	amount: number | null;
};

const flatFeeSchema = yup
	.object({
		amount: yup.number().required(),
		description: yup.string().defined().nullable(),
	})
	.nullable()
	.default(null);

const flatFeePerEmployeeSchema = yup
	.object({
		amount: yup.number().required(),
		description: yup.string().defined().nullable(),
	})
	.nullable()
	.default(null);

const feePerModuleAndEmployeeSchema = (
	obj: PricingRecord["feePerModuleAndEmployee"]
) => {
	return yup
		.object(
			_.mapValues(obj, () =>
				yup
					.object({
						type: yup
							.mixed<PerModuleFeeType>()
							.oneOf(Object.values(PerModuleFeeType))
							.required(),
						amount: yup.number().required(),
					})
					.required()
			)
		)
		.default(null)
		.nullable()
		.strict(true);
};

const pricingRecordSchema = yup.object({
	availableFrom: yup.date().defined().nullable(),
	availableUntil: yup.date().defined().nullable(),
	minimumFee: yup.number().required(),
	flatFee: flatFeeSchema,
	flatFeePerEmployee: flatFeePerEmployeeSchema,
	feePerModuleAndEmployee: yup.lazy((value) =>
		feePerModuleAndEmployeeSchema(value).nullable()
	),
});

export const pricingSettingsSchema = yup
	.array(pricingRecordSchema)
	.default(null)
	.notRequired();
