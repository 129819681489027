import { deserializeDate } from "@util";
import { Properties } from "@util/types";

export default class Budget {
	constructor(props: Properties<Budget>) {
		this.budgetId = props.budgetId;
		this.category = props.category;
		this.availableFrom = props.availableFrom;
		this.availableUntil = props.availableUntil;
		this.maxAmount = props.maxAmount;
		this.maxAmountDay = props.maxAmountDay;
		this.usedAmount = props.usedAmount;
		this.pendingAmount = props.pendingAmount;
		this.remainingAmount = props.remainingAmount;
	}

	readonly budgetId: string;
	readonly category: string;
	readonly availableFrom: Date;
	readonly availableUntil: Date;
	readonly maxAmount: number;
	readonly maxAmountDay: number | null;
	readonly usedAmount: number;
	readonly pendingAmount: number;
	readonly remainingAmount: number;

	static fromJson(data: any) {
		return new Budget({
			...data,
			availableFrom: deserializeDate.date(data.availableFrom),
			availableUntil: deserializeDate.date(data.availableUntil),
		});
	}
}
