import React from "react";
import styled, { CSSProperties } from "styled-components";
import { Link } from "react-router-dom";
import { VoucherStatus, Voucher } from "@models";
import { FaStopwatch as WorkingDaysIcon } from "react-icons/fa";
import { FlexRow, Icon, IconButton, NoTranslate } from "@components";
import UserComment from "./UserComment";
import Routes from "@service/navigation/routes";
import { Alert } from "react-bootstrap";
import { colors } from "@theme/theming";

type Props = {
	voucher: Voucher;
	style?: CSSProperties;
};

export default function VoucherReviewMetaHeader(props: Props) {
	const { voucher } = props;
	const { customerId, divisionId, userId, userName, userWorkingDaysPerWeek } =
		voucher;

	const userDetailsPath = Routes.UserDetails.makePath({
		customerId,
		divisionId,
		userId,
	});

	const contractPath =
		Routes.UserContracts.makePath({ customerId, divisionId, userId }) +
		"?contract=" +
		voucher.contractId;

	return (
		<Container style={props.style}>
			<FlexRow
				css={`
					align-items: baseline;
				`}
			>
				<StyledFlexCol>
					<Link
						target="_blank"
						to={userDetailsPath}
						title="Benutzerseite aufrufen"
					>
						<IconButton>
							<Icon.Link />
						</IconButton>
						<NoTranslate>{userName}</NoTranslate>
					</Link>
				</StyledFlexCol>
				{!!voucher.contractId && (
					<StyledFlexCol>
						<Link to={contractPath} title="Dauerbeleg aufrufen">
							<IconButton>
								<Icon.Link />
							</IconButton>
							Zum Dauerbeleg
						</Link>
					</StyledFlexCol>
				)}
				{!!userWorkingDaysPerWeek && (
					<StyledFlexCol>
						<Label title="Arbeitstage pro Woche">
							<IconWrapper>
								<WorkingDaysIcon />
							</IconWrapper>
							{userWorkingDaysPerWeek} Tage/Woche
						</Label>
					</StyledFlexCol>
				)}
			</FlexRow>
			{!!voucher.userComment && <UserComment comment={voucher.userComment} />}
			<LimitedAmountWarning voucher={voucher} />
			<hr style={{ marginTop: 8 }} />
		</Container>
	);
}

function LimitedAmountWarning(props: { voucher: Voucher }) {
	if ("budget" in props.voucher) {
		// NOTE: this is rather hacky, we should output a flag on the `voucher` whether it has been limited or not

		const { budget, amountAfterReview, category, reviewStatus } = props.voucher;
		if (
			budget &&
			amountAfterReview > budget.remainingAmount &&
			category.startsWith("homeAndWork") &&
			reviewStatus === VoucherStatus.InReview
		) {
			return (
				<Alert variant="warning">
					Der Betrag des Belegs passt nicht vollständig ins Budget
				</Alert>
			);
		}
	}

	return null;
}

const Container = styled.div`
	padding-left: 8px;
`;

const IconWrapper = styled.span`
	color: ${colors.gray.g400};
	margin-right: 2px;
`;

const Label = styled.label`
	font-size: 0.8rem;
	margin: 0px 0px 0px 4px;
	color: ${colors.gray.g400};
`;

const StyledFlexCol = styled.span`
	margin: 0px 8px;
`;
