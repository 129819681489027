import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ComponentProps } from "@util/types";
import { colors } from "@theme/theming";

const Container = styled(Nav).attrs({
	variant: "tabs",
	className: "justify-content-center",
})``;

const NavItem = styled(Nav.Item)`
	a {
		color: ${colors.primary.p200};
		&.active {
			color: ${colors.text};
		}
	}
`;

function TabItem(
	props:
		| (ComponentProps<typeof Nav.Link> & ComponentProps<Link>)
		| ComponentProps<typeof Nav.Link>
) {
	return (
		<>
			{props.to ? (
				<NavItem>
					<Nav.Link as={Link} {...props} />
				</NavItem>
			) : (
				<NavItem>
					<Nav.Link {...props} />
				</NavItem>
			)}
		</>
	);
}

const Tab = {
	Container: Container,
	Item: TabItem,
};

export default Tab;
