import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "../../../../components/forms/DatePicker";
import {
	Label,
	NoAutoFillInput,
	CurrencyInput,
	TextArea,
	FormGroup,
	InputValidation,
	RequiredMarker,
	CheckboxToggle,
	Form,
} from "@components";
import { Col, Row } from "react-bootstrap";
import { addYears, isBefore, isSameDay, subDays } from "date-fns";
import { ConfigContract, Contract, NewContract } from "@models";

import JobVehicleTypes from "./jobVehicles/JobVehicleTypes";
import { isJobVehicleContract, isNewContract, setContractName } from "../util";
import JobVehiclePurchase from "./jobVehicles/JobVehiclePurchase";
import { isEmployerFinancedRailcardContract } from "@models/Contract";
import JobVehicleLeaseDates from "./jobVehicles/JobVehicleLeaseDates";

type Props = {
	contract: Contract | NewContract;
};

export default function ContractSettings(props: Props) {
	const { control, register, watch, formState, setValue, getValues } =
		useFormContext<ConfigContract>();
	const [contractNameChanged, setContractNameChanged] = useState(false);
	const { contract } = props;
	const isJobVehicle = isJobVehicleContract(contract);
	const isEmployerFinancedRailcard =
		isEmployerFinancedRailcardContract(contract);

	const onChangeStartDate = (date: Date) => {
		if (!contractNameChanged && getValues().name.includes("Dauerbeleg")) {
			const contractName = setContractName(contract.category, date);
			setValue("name", contractName);
		}
	};

	return (
		<>
			{!!contract.externalId && (
				<FormGroup as={Row}>
					<Label htmlFor="externalId" column sm={4}>
						Externe ID
					</Label>
					<Col sm={8}>
						<Controller
							name="externalId"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<NoAutoFillInput
									id="externalId"
									{...field}
									readonly={"readonly"}
								/>
							)}
						/>
					</Col>
				</FormGroup>
			)}

			<FormGroup as={Row}>
				<Label htmlFor="name" column sm={4}>
					Name <RequiredMarker />
				</Label>
				<Col sm={8}>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<NoAutoFillInput
								id="name"
								{...field}
								onChange={(e: any) => {
									setContractNameChanged(true);
									field.onChange(e);
								}}
							/>
						)}
						rules={{
							required: true,
						}}
					/>
				</Col>
			</FormGroup>

			<InputValidation error={formState.errors.startDate}>
				<FormGroup as={Row}>
					<Label htmlFor="startDate" column sm={4}>
						Verfügbar ab <RequiredMarker />
					</Label>
					<Col sm={4}>
						<Controller
							name="startDate"
							control={control}
							rules={{
								required: true,
								validate: (startDate: Date) => {
									const endDate = watch("endDate");
									if (!isSameDay(startDate, contract.startDate)) {
										if (
											isNewContract(contract) &&
											isJobVehicle &&
											!formState.dirtyFields["endDate"] &&
											endDate &&
											isBefore(startDate, endDate)
										) {
											const newEndDate = subDays(addYears(startDate, 3), 1);
											setValue("endDate", newEndDate);
										}
									}
									return true;
								},
							}}
							render={({ field }) => (
								<DatePicker
									{...field}
									id="startDate"
									onDayChange={onChangeStartDate}
								/>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>

			<InputValidation error={formState.errors.endDate}>
				<FormGroup as={Row}>
					<Label htmlFor="endDate" column sm={4}>
						Verfügbar bis
					</Label>
					<Col sm={4}>
						<Controller
							name="endDate"
							control={control}
							rules={{
								validate: (endDate: Date | null) => {
									const startDate = watch("startDate");
									if (startDate && endDate && !isBefore(startDate, endDate)) {
										return "Enddatum darf nicht vor Startdatum liegen";
									}
								},
							}}
							render={({ field }) => (
								<DatePicker
									{...field}
									onClearDate={() =>
										setValue("endDate", null, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
									placeholder="unbefristet"
									canClearDate
									id="endDate"
								/>
							)}
						/>
						<Form.Text className="text-muted">
							Das Start/Enddatum bezieht sich auf den zeitlichen Bereich, in dem
							der Dauerbeleg in das Budget des Mitarbeitenden eingehen soll.
						</Form.Text>
					</Col>
				</FormGroup>
			</InputValidation>

			{!isJobVehicle && !isEmployerFinancedRailcard && (
				<InputValidation error={formState.errors.amount}>
					<FormGroup as={Row}>
						<Label htmlFor="amount" column sm={4}>
							Monatlicher Betrag <RequiredMarker />
						</Label>
						<Col sm={8}>
							<Controller
								name="amount"
								control={control}
								rules={{
									required: true,
									min: {
										value: 0.01,
										message: "Der Vertragsbetrag sollte nicht Null sein",
									},
								}}
								render={({ field }) => (
									<CurrencyInput {...field} id="amount" min={0.01} />
								)}
							/>
						</Col>
					</FormGroup>
				</InputValidation>
			)}

			{isJobVehicle && (
				<>
					<JobVehicleLeaseDates />
					<JobVehicleTypes contract={props.contract} />
					<JobVehiclePurchase />
				</>
			)}
			<FormGroup as={Row}>
				<Label htmlFor="isFromThirdParty" column sm={4}>
					Dauerbeleginhaber
				</Label>
				<Col sm={8}>
					<CheckboxToggle
						label="Dauerbeleg von Drittperson"
						data-testid="isFromThirdParty"
						{...register("isFromThirdParty")}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="comment" column sm={4}>
					Kommentar
				</Label>
				<Col sm={8}>
					<Controller
						name="comment"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextArea id="comment" {...field} rows="5" />
						)}
					/>
				</Col>
			</FormGroup>
		</>
	);
}
