import { Properties } from "@util/types";
import * as yup from "yup";

export const externalUser = ["appUser", "employer"] as const;
export const internalUser = [
	"internal",
	"publicAPI",
	"job",
	"migration",
	"portalAdmin",
] as const;

type DomainEventPrincipalWithDetails =
	| {
			userId: string;
			userType: typeof externalUser[number];
			fullName: string;
			customerId: string;
			divisionId: string;
	  }
	| {
			userType: typeof internalUser[number];
			userId: string;
	  };

export class DomainEvent {
	constructor(props: Properties<DomainEvent>) {
		this.occurredAt = props.occurredAt;
		this.principal = props.principal;
		this.type = props.type;
		this.data = props.data;
	}

	readonly occurredAt: Date;
	readonly principal: DomainEventPrincipalWithDetails;
	readonly type: string;
	readonly data: object;

	static fromJson(data: any): DomainEvent {
		const domainEvent = domainEventSchema.validateSync(data);
		return new DomainEvent({
			occurredAt: new Date(domainEvent.occurredAt),
			principal: domainEvent.principal,
			type: domainEvent.type,
			data: domainEvent.data,
		});
	}
}

const principalSchema = yup.lazy((value: any) => {
	if (value.userType === "appUser" || value.userType === "employer") {
		return yup
			.object()
			.shape({
				userId: yup.string().required(),
				userType: yup.string().oneOf(externalUser).required(),
				fullName: yup.string().required(),
				customerId: yup.string().uuid().required(),
				divisionId: yup.string().uuid().required(),
			})
			.required();
	} else {
		return yup
			.object()
			.shape({
				userId: yup.string().uuid().required(),
				userType: yup.string().oneOf(internalUser).required(),
			})
			.required();
	}
});

const domainEventSchema = yup.object({
	occurredAt: yup.date().required().typeError("invalid date"),
	principal: principalSchema,
	type: yup.string().required(),
	data: yup.mixed().required(),
});
