import React from "react";
import styled from "styled-components";
import { Card } from "@components";
import { formatDate } from "@components/forms/datePickerUtils";
import { BusinessTrip } from "@models";
import { OvernightStay } from "@models/travel/OvernightStay";
import VoucherSection from "./VoucherSection";
import { useFormContext } from "react-hook-form";
import { EditableTrip } from "@models/travel/EditableTrip";
import FlatRateValue from "./FlatRateValue";
import FlatRateOverride from "./FlatRateOverride";
import { Currency } from "@util";

type Props = {
	trip: BusinessTrip;
};

export default function TripAccomodation(props: Props) {
	const { overnightStays } = props.trip.accomodation;

	return (
		<>
			<OvernightStaysFlatRate trip={props.trip} />
			{overnightStays.map((stay: OvernightStay, index: number) => (
				<Card
					style={{ marginLeft: "10px" }}
					data-testid="stay-card"
					key={index}
				>
					<Container>
						{formatDate(stay.checkinDate)} - {formatDate(stay.checkoutDate)}
					</Container>
					<Container>{stay.name}</Container>
					{!!stay.voucherId && (
						<>
							<hr />
							<VoucherSection voucherId={stay.voucherId} />
						</>
					)}
				</Card>
			))}
		</>
	);
}

const Container = styled.div`
	margin-left: 16px;
`;

function OvernightStaysFlatRate(props: { trip: BusinessTrip }) {
	const { watch } = useFormContext<EditableTrip>();
	const override = watch("overrides.overnightStaysFlatRate") as number;

	return (
		<div>
			<h5>
				Übernachtungspauschale{" "}
				{Currency.format(props.trip.amounts.overnightStaysFlatRate)}
			</h5>
			<div>
				Errechnete Pauschale:{" "}
				<FlatRateValue
					amount={props.trip.calculatedAmounts.overnightStaysFlatRate}
					override={override}
				/>
			</div>
			<FlatRateOverride id="overrides.overnightStaysFlatRate" />
		</div>
	);
}
