import { Properties } from "@util/types";
import BalanceTransaction from "./BalanceTransaction";

export default class BalanceAccount {
	constructor(props: Properties<BalanceAccount>) {
		this.balanceAccountId = props.balanceAccountId;
		this.userId = props.userId;
		this.budgetCategory = props.budgetCategory;
		this.balance = props.balance;
		this.isActive = props.isActive;
	}

	readonly balanceAccountId: string;
	readonly userId: string;
	readonly budgetCategory: string;
	readonly balance: number;
	readonly isActive: boolean;

	public static fromJson(data: any): BalanceAccount {
		return new BalanceAccount(data);
	}
}

export type NewBalanceAccount = Omit<
	Properties<BalanceAccount>,
	"balanceAccountId"
>;

export type BalanceAccountWithTransactions = BalanceAccount & {
	transactions: BalanceTransaction[];
};

export enum BalanceAccountUpdateType {
	Difference = "difference",
	Absolute = "absolute",
}
