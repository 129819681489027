import { CurrencyInput, FormGroup, Label, Select } from "@components";
import { Contract, NewContract } from "@models";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

export default function JobVehiclePurchase() {
	const { register, watch, control } = useFormContext<Contract | NewContract>();
	const expectedVendor = watch("expectedVendor");

	return (
		<>
			<FormGroup as={Row}>
				<Label htmlFor="expectedVendor" column sm={4}>
					Voraussichtlicher Verkäufer
				</Label>
				<Col sm={8}>
					<Select id="expectedVendor" {...register("expectedVendor")}>
						<option value=""></option>
						<option value="employer">Arbeitgeber</option>
						<option value="provider">Provider</option>
					</Select>
				</Col>
			</FormGroup>
			{!!expectedVendor && (
				<FormGroup as={Row}>
					<Label htmlFor="expectedPurchasePrice" column sm={4}>
						Voraussichtlicher Kaufpreis
					</Label>
					<Col sm={8}>
						<Controller
							name="expectedPurchasePrice"
							control={control}
							rules={{
								required: false,
								min: {
									value: 0.01,
									message: "Der Kaufpreis sollte nicht Null sein",
								},
							}}
							render={({ field }) => (
								<CurrencyInput
									{...field}
									id="expectedPurchasePrice"
									min={0.01}
									allowNull
								/>
							)}
						/>
					</Col>
				</FormGroup>
			)}
		</>
	);
}
