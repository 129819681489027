import React from "react";
import TripsReviewContext from "../trips/context/ReviewContext";
import { useTripList } from "../trips/hooks/useTripList";
import TripList from "../trips/components/TripList";
import { Pagination, QueryStateIndicators } from "@components";
import { ReviewFilters } from "./components/FilterControls";

type Props = {
	activeFilters: ReviewFilters;
};

const TripListWithPagination = (props: Props) => {
	const { activeFilters } = props;

	const { trips, tripQuery, overrideTrip, refreshTrip } =
		useTripList(activeFilters);

	return (
		<TripsReviewContext.Provider
			value={{
				onChanged: overrideTrip,
				refreshTrip: refreshTrip,
			}}
		>
			<QueryStateIndicators queryState={tripQuery} />
			{trips && (
				<>
					<TripList trips={trips} />
					<Pagination queryState={tripQuery} allowShowAll={false} />
				</>
			)}
		</TripsReviewContext.Provider>
	);
};

export default TripListWithPagination;
