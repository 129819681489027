import { MonthSummary } from "@service/user/type";
import { isSameMonth } from "date-fns";

export const getYearsOptions = () => {
	let yearOptions: number[] = [];
	let startYear = 2017;
	const endYear = getCurrentYear();

	for (startYear; startYear <= endYear; startYear++) {
		yearOptions = [startYear, ...yearOptions];
	}

	return yearOptions;
};

export const getCurrentYear = () => new Date(Date.now()).getFullYear();

export const filterMonthSummary = (monthSummary: MonthSummary) => ({
	date: monthSummary.date,
	products: monthSummary.budgets.filter((product) => {
		if (product.category.includes("congrats")) {
			return isSameMonth(product.availableFrom, monthSummary.date);
		}

		return true;
	}),
	moduleSettings: monthSummary.moduleSettings,
});
