import GiftExpense from "./GiftExpense";
import HospitalityExpense from "./HospitalityExpense";
import MiscExpense from "./MiscExpense";
import ShortDistanceExpense from "./ShortDistanceExpense";

export const expenseClasses = [
	HospitalityExpense,
	MiscExpense,
	GiftExpense,
	ShortDistanceExpense,
];
