import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import {
	defaultGlobalState,
	GlobalStateContext,
} from "../context/GlobalContext";
import Routes from "@service/navigation/routes";
import { logout } from "@service/auth/auth.service";
import Logo from "./Logo";
import ErrorAlert from "./ErrorAlert";
import SearchBar from "./SearchBar";
import { colors } from "@theme/theming";

export default function Header() {
	const { globalState, updateGlobalState } = useContext(GlobalStateContext);
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const [expanded, setExpanded] = useState(false);
	const history = useHistory();

	const onLogoutClick = async () => {
		try {
			await logout();
			updateGlobalState({
				auth: defaultGlobalState.auth,
			});
			history.replace(Routes.Root);
		} catch (error) {
			console.error(error);
			setHasError(true);
			setErrorMessage("Abmeldung nicht erfolgreich. Fehler bei der Abmeldung.");
		}
	};

	return (
		<>
			<Navbar
				style={{ paddingLeft: 32, marginTop: 20 }}
				expand={false}
				expanded={expanded}
				onToggle={(isExpanded: boolean) => setExpanded(isExpanded)}
			>
				<Link to={Routes.Home}>
					<Navbar.Brand>
						<Logo />
					</Navbar.Brand>
				</Link>
				<SearchBar />

				{globalState.auth.isLoggedIn && (
					<>
						<StyledToggle className="ml-auto" aria-controls="navbar-collapse" />
						<StyledCollapse id="navbar-collapse">
							<StyledBurgerMenuNav>
								<Nav.Link onClick={onLogoutClick}>Logout</Nav.Link>
							</StyledBurgerMenuNav>
						</StyledCollapse>
					</>
				)}
			</Navbar>
			{hasError && <ErrorAlert>{errorMessage}</ErrorAlert>}
		</>
	);
}

const StyledCollapse = styled(Navbar.Collapse)`
	position: fixed;
	top: 80px;
	right: 32px;
	z-index: 24;
	background: ${colors.white};
	border: 1px solid ${colors.gray.g300};
	padding: 8px;
	border-radius: 16px;
`;

const StyledBurgerMenuNav = styled(Nav)`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
`;

const StyledToggle = styled(Navbar.Toggle)`
	border: 0;

	&:focus {
		outline: 0px;
	}
`;
