export default class CongratsOccasion {
	constructor(readonly id: string, readonly name: string) {}

	static fromJson(data: any) {
		return new CongratsOccasion(data.id, data.name);
	}
}

export enum CongratsOccasions {
	Birthday = "birthday",
	BirthOfChild = "birth-of-child",
	Anniversary5Years = "anniversary-5-years",
	Wedding = "wedding",
	Bonus = "bonus",
	Custom = "custom",
}
