import Product, { DefaultProduct } from "@models/Product";
import { Properties } from "@util/types";
import { ProductCongrats } from "./ProductCongrats";
import { ProductMobility } from "./ProductMobility";
import { ProductTravel } from "./ProductTravel";

export function deserializeProduct(data: any): Product<any> {
	// TODO: validate settings shape
	const props = data as Properties<Product>;
	switch (props.id) {
		case "congrats":
			return ProductCongrats.fromJson(data);
		case "mobility":
			return ProductMobility.fromJson(data);
		case "travel.budget":
		case "travel":
			return ProductTravel.fromJson(data);
		case undefined:
		case null:
			throw new Error(`product has no id`);
		default:
			return DefaultProduct.fromJson(data);
	}
}
