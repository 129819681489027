import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxToggle } from "@components";
import { Category } from "@models";
import { ConfigBudgetMaster } from "./types";
import { escapeCategoryId } from "../util/util";

type Props = {
	category: Category;
	isSingleBudget: boolean;
};

export default function BudgetCategoryToggle(props: Props) {
	const { category, isSingleBudget } = props;
	const { setValue } = useFormContext<ConfigBudgetMaster>();
	const namePrefix = `amounts.${escapeCategoryId(category.id)}`;

	return (
		<Controller
			name={`${namePrefix}.enabled`}
			render={({ field }) => {
				return (
					<CheckboxToggle
						{...field}
						checked={field.value}
						onChange={(e: any) => {
							setValue(`${namePrefix}.enabled` as any, e.target.checked, {
								shouldDirty: true,
							});
						}}
						label={category.name}
						disabled={isSingleBudget}
						data-testid={`budgetCategoryToggle-${category.id}`}
					/>
				);
			}}
		/>
	);
}
