import React from "react";
import { Tooltip as BSTooltip, OverlayTrigger } from "react-bootstrap";

type Props = {
	children: React.ReactElement | React.ReactNode;
	id: string;
	placement?: "top" | "right" | "left" | "bottom";
	tooltip: React.ReactNode;
	propagateLabel?: boolean;
	delay?: { show: number; hide: number };
};

export default function Tooltip(props: Props) {
	return (
		<OverlayTrigger
			placement={props.placement}
			delay={props.delay}
			overlay={
				props.tooltip ? (
					<BSTooltip id={props.id}>{props.tooltip}</BSTooltip>
				) : (
					<span></span>
				)
			}
		>
			{React.isValidElement(props.children)
				? React.cloneElement(
						props.children as React.ReactElement,
						(props.propagateLabel ?? true) && typeof props.tooltip === "string"
							? { "aria-label": props.tooltip }
							: {}
				  )
				: () => props.children}
		</OverlayTrigger>
	);
}
