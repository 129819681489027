import {
	CurrencyInput,
	FormGroup,
	InputValidation,
	Label,
	RequiredMarker,
} from "@components";
import DatePicker from "../../../../../components/forms/DatePicker";

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

export default function JobVehicleFinancing() {
	const { control, formState, setValue } = useFormContext();
	const onChangeDate = (day: Date) => {
		if (day === undefined) {
			setValue("transferDate", null, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
	};

	return (
		<>
			<FormGroup as={Row}>
				<Label htmlFor="listPrice" column sm={4}>
					Bruttolistenpreis
				</Label>
				<Col sm={8}>
					<Controller
						name="listPrice"
						control={control}
						rules={{
							min: 0.01,
						}}
						render={({ field }) => (
							<CurrencyInput {...field} id="listPrice" min={0.01} />
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="leaseRate" column sm={4}>
					Finanzierungsrate <RequiredMarker />
				</Label>
				<Col sm={8}>
					<Controller
						name="leaseRate"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => (
							<CurrencyInput {...field} id="leaseRate" placeholder="€/Monat" />
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="insuranceRate" column sm={4}>
					Versicherungsrate
				</Label>
				<Col sm={8}>
					<Controller
						name="insuranceRate"
						control={control}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="insuranceRate"
								placeholder="€/Monat"
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="serviceRate" column sm={4}>
					Wartungsrate
				</Label>
				<Col sm={8}>
					<Controller
						name="serviceRate"
						control={control}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="serviceRate"
								placeholder="€/Monat"
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<FormGroup as={Row}>
				<Label htmlFor="flatTaxRate" column sm={4}>
					Pauschalsteuer
				</Label>
				<Col sm={8}>
					<Controller
						name="flatTaxRate"
						control={control}
						render={({ field }) => (
							<CurrencyInput
								{...field}
								id="flatTaxRate"
								placeholder="€/Monat"
							/>
						)}
					/>
				</Col>
			</FormGroup>
			<InputValidation error={formState.errors.transferDate}>
				<FormGroup as={Row}>
					<Label htmlFor="transferDate" column sm={4}>
						Überlassungsdatum
					</Label>
					<Col sm={8}>
						<Controller
							name="transferDate"
							control={control}
							render={({ field }) => (
								<DatePicker
									{...field}
									id="transferDate"
									onDayChange={onChangeDate}
								/>
							)}
						/>
					</Col>
				</FormGroup>
			</InputValidation>
		</>
	);
}
