import React from "react";
import styled from "styled-components";
import { BusinessTrip } from "@models";
import { MealPaymentType, Meals } from "@models/travel/Meals";
import { formatDate } from "@components/forms/datePickerUtils";
import { Select } from "@components";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { EditableTrip } from "@models/travel/EditableTrip";
import FlatRateValue from "./FlatRateValue";
import FlatRateOverride from "./FlatRateOverride";
import { Currency } from "@util";
import useTripReviewContext from "../hooks/useTripReviewContext";

type Props = {
	trip: BusinessTrip;
};

export default function TripMeals(props: Props) {
	const { trip } = props;
	const { meals } = trip.accomodation;

	const { isTripEditable } = useTripReviewContext();

	if (meals.length === 0) {
		return <div>Keine Mahlzeiten gefunden</div>;
	}

	const form = useFormContext<EditableTrip>();

	return (
		<FormProvider {...form}>
			<Container>
				<MealsFlatRate trip={props.trip} />
				{meals.map((meal: Meals, index: number) => (
					<React.Fragment key={index}>
						<DateContainer>{formatDate(meal.date)}</DateContainer>
						<GridContainer>
							<GridContent>Frühstück</GridContent>
							<GridContent data-testid="breakfast-details">
								<MealController
									index={index}
									hasReviewContext={isTripEditable}
									meal={meal}
									mealType="breakfast"
								/>
							</GridContent>
							<GridContent>Mittagessen</GridContent>
							<GridContent data-testid="lunch-details">
								<Controller
									name={`meals.${index}.lunch` as any}
									control={form.control}
									render={({ field }) =>
										isTripEditable ? (
											<Select {...field}>
												<option value={MealPaymentType.Employer}>
													von Arbeitgeber bezahlt
												</option>
												<option value={MealPaymentType.Employee}>
													selbst bezahlt
												</option>
											</Select>
										) : (
											<div>{getMealPaymentType(meal.lunch)}</div>
										)
									}
								/>
							</GridContent>
							<GridContent>Abendessen</GridContent>
							<GridContent data-testid="dinner-details">
								<Controller
									name={`meals.${index}.dinner` as any}
									control={form.control}
									render={({ field }) =>
										isTripEditable ? (
											<Select {...field}>
												<option value={MealPaymentType.Employer}>
													von Arbeitgeber bezahlt
												</option>
												<option value={MealPaymentType.Employee}>
													selbst bezahlt
												</option>
											</Select>
										) : (
											<div>{getMealPaymentType(meal.dinner)}</div>
										)
									}
								/>
							</GridContent>
						</GridContainer>
					</React.Fragment>
				))}
			</Container>
		</FormProvider>
	);
}

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	width: fit-content;
	row-gap: 2px;
	margin-bottom: 8px;
`;

export const GridContent = styled.div`
	margin-left: 30px;
`;

const DateContainer = styled.div`
	font-weight: bold;
`;

const Container = styled.div`
	overflow-y: scroll;
	max-height: 400px;
`;

function MealController(props: {
	index: number;
	hasReviewContext: boolean;
	meal: Meals;
	mealType: "lunch" | "dinner" | "breakfast";
}) {
	const { hasReviewContext, index, meal, mealType } = props;
	const form = useFormContext<EditableTrip>();

	return (
		<Controller
			name={`meals.${index}.${mealType}` as any}
			control={form.control}
			render={({ field }) =>
				hasReviewContext ? (
					<Select {...field}>
						<option value={MealPaymentType.Employer}>
							von Arbeitgeber bezahlt
						</option>
						<option value={MealPaymentType.Employee}>selbst bezahlt</option>
					</Select>
				) : (
					<div>{getMealPaymentType(meal[mealType])}</div>
				)
			}
		/>
	);
}

function getMealPaymentType(mealPaymentType: MealPaymentType): string {
	switch (mealPaymentType) {
		case MealPaymentType.Employee:
			return "selbst bezahlt";
		case MealPaymentType.Employer:
			return "von Arbeitgeber bezahlt";
		default:
			throw new Error(`Unknown meal payment type - ${mealPaymentType}`);
	}
}

function MealsFlatRate(props: { trip: BusinessTrip }) {
	const { watch } = useFormContext<EditableTrip>();
	const override = watch("overrides.mealsFlatRate") as number;

	return (
		<div>
			<h5>
				Verpflegungsmehraufwand:{" "}
				{Currency.format(props.trip.amounts.mealsFlatRate)}
			</h5>
			<div>
				Errechnete Pauschale:{" "}
				<FlatRateValue
					amount={props.trip.calculatedAmounts.mealsFlatRate}
					override={override}
				/>
			</div>
			<FlatRateOverride id="overrides.mealsFlatRate" />
		</div>
	);
}
