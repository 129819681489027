import DialogContext, { ConfirmDialogConfig } from "../context/DialogContext";
import React, { useState } from "react";
import ConfirmDialog from "./ConfirmDialog";

type Props = {
	children: React.ReactNode;
};

type State = ConfirmDialogConfig<any> & { dialogType: "confirm" };

export default function DialogProvider(props: Props) {
	const [state, setState] = useState<State>();

	const handleShowConfirmDialog = (config: ConfirmDialogConfig<any>) => {
		setState({ ...config, dialogType: "confirm" });
	};

	const handleConfirm = () => {
		state?.onConfirm(state.payload);
		setState(undefined);
	};

	const handleCancel = () => {
		state?.onCancel?.(state.payload);
		setState(undefined);
	};

	return (
		<DialogContext.Provider
			value={{
				showConfirmDialog: handleShowConfirmDialog,
			}}
		>
			{state?.dialogType === "confirm" && (
				<ConfirmDialog
					headerContent={state.title}
					handleOk={handleConfirm}
					handleClose={handleCancel}
					cancelText={state.cancelText}
					okText={state.confirmText}
				>
					{state.text}
				</ConfirmDialog>
			)}
			{props.children}
		</DialogContext.Provider>
	);
}
