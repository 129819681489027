const colors = {
	primary: {
		p100: "#2DB8D0",
		p200: "#0A95AD",
		p300: "#0E5E6F",
		p400: "#05404D",
	},
	highlight: {
		h100: "#FFB2BD",
		h200: "#FF8091",
		h300: "#F54A62",
		h400: "#CC2940",
	},
	success: {
		s100: "#79F2B1",
		s200: "#52CC8A",
		s300: "#35B26F",
		s400: "#1A8048",
	},
	caution: {
		c100: "#FFE699",
		c200: "#F2D479",
		c300: "#D9B64C",
		c400: "#B29336",
	},
	danger: {
		d100: "#FF99A6",
		d200: "#FA7D8D",
		d300: "#F26174",
		d400: "#CC3D50",
	},
	info: {
		i100: "#99DDFF",
		i200: "#7DD0FA",
		i300: "#61C2F2",
		i400: "#3D9CCC",
	},
	gray: {
		g100: "#E6F0F2",
		g200: "#CFDBE5",
		g300: "#ACB6BF",
		g400: "#77828C",
	},
	text: "#0F2830",
	black: "#000000",
	white: "#ffffff",
};

export { colors };
