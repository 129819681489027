import { Properties } from "@util/types";
import { Waypoint } from "./Waypoint";

export class Route {
	constructor(props: Properties<Route>) {
		this.waypoints = props.waypoints;
	}
	readonly waypoints: ReadonlyArray<Waypoint>;

	static fromJson(data: any) {
		return new Route({
			waypoints: data.waypoints.map(Waypoint.fromJson),
		});
	}
}
