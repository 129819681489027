import _ from "lodash";
import { BudgetMaster, NewBudgetMaster, Category } from "@models";
import { ConfigBudgetMaster } from "../configuration/types";
import { escapeCategoryId, getDefaultBudgetAmounts } from "./util";
import { useBudgetMasterProduct } from "@hooks";

export function useDefaultConfigBudgetMaster(
	budgetMaster: BudgetMaster | NewBudgetMaster
): ConfigBudgetMaster {
	const { product } = useBudgetMasterProduct(budgetMaster);

	const amounts: ConfigBudgetMaster["amounts"] = {};

	for (const variant of product.variants) {
		for (const cat of variant.budgetCategories) {
			const existingAmount = budgetMaster.amounts.find(
				(a) => a.category === cat.id
			);
			const escapedName = escapeCategoryId(cat.id);

			if (existingAmount) {
				amounts[escapedName] = {
					...existingAmount,
					enabled: true,
				};
			} else {
				amounts[escapedName] = {
					...getDefaultBudgetAmounts(cat),
					enabled: false,
				};
			}
		}
	}

	return { ...budgetMaster, amounts };
}

export function flattenCategories(category: Category): string[] {
	function flattenRec(category: Category): string[] {
		if (category.subCategories) {
			const subs = _.flatMap(category.subCategories, (c) => flattenRec(c));
			return [category.id, ...subs];
		}
		return [category.id];
	}

	return _.flatMap(category.subCategories, (c) => flattenRec(c));
}
