import React, { useCallback } from "react";
import { Input } from "./Input";

type Props = {
	id?: string;
	name?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, "as" | "value"> &
	(
		| {
				allowNull: true;
				value: number | null;
		  }
		| {
				allowNull?: false;
				value: number; // if `allowNull` is not set to `true`, this cannot be `null`
		  }
	);

const PercentageInput = React.forwardRef((props: Props, ref) => {
	const { onChange, value, allowNull, ...rest } = props;

	const customOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let value: any = null;
			if (e.target.value !== null && e.target.value !== "") {
				value = Number.parseInt(e.target.value);
				if (value > 100) value = 100;
			} else {
				if (!allowNull) {
					value = 0;
				}
			}
			if (onChange) {
				onChange({
					...e,
					target: { ...e.target, value },
				});
			}
		},
		[onChange, allowNull]
	);

	return (
		<Input
			data-testid="percentage-input"
			type="number"
			step={1}
			min={0}
			max={100}
			onChange={customOnChange}
			value={value}
			ref={ref}
			{...rest}
		/>
	);
});

export default PercentageInput;
