import React from "react";
import { Alert } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import ErrorAlert, { alertStyle } from "@components/ErrorAlert";
import { useServices, useCustomer, useAsyncCommand } from "@hooks";
import { NewDivision } from "../../../models/Division";
import Routes, { DivisionRouteParams } from "@service/navigation/routes";
import DivisionForm from "./DivisionForm";
import { defer } from "@util/helpers";
import { MainBreadcrumbs, PageContainer } from "@components";

export function AddDivisionPage() {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { customerId } = params;
	const { divisionService } = useServices();
	const history = useHistory();

	const [triggerAddDivision, addQueryResult] = useAsyncCommand(
		(division: NewDivision) =>
			divisionService.addDivision(customerId, division),
		{ rethrowError: false }
	);

	const addDivision = async (division: NewDivision) => {
		const createdDivision = await triggerAddDivision(division);
		if (createdDivision.state === "success") {
			const path = Routes.Users.makePath({
				customerId: customerId,
				divisionId: createdDivision.data.divisionId,
			});

			await defer(() => {
				history.replace(path);
			});
		}
	};

	const customerQuery = useCustomer();

	return (
		<>
			{customerQuery.data && (
				<PageContainer>
					<MainBreadcrumbs customer={customerQuery.data} />
					<h2 css={"text-align:center"}>Neuer Standort</h2>
					<DivisionForm
						customerId={customerId}
						onSubmit={addDivision}
						cmdState={addQueryResult}
					/>
					{addQueryResult.state === "success" && (
						<Alert style={alertStyle} variant="success">
							Standort hinzugefügt
						</Alert>
					)}
					{addQueryResult.state === "error" && <ErrorAlert />}
				</PageContainer>
			)}
		</>
	);
}
