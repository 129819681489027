import { ActionButton, QueryError } from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { AccountingMonth } from "@models";
import { DivisionRouteParams } from "@service/navigation/routes";
import { formatYearMonth } from "@util";
import { awaitWithDeferedCallback } from "@util/helpers";
import React from "react";
import { useRouteMatch } from "react-router-dom";

type Props = {
	accountingMonth: AccountingMonth;
	divisionId: string;
	onChangedStatus: () => void;
};

export default function UncloseMonthButton(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { accountingMonthService } = useServices();
	const [handleLockMonth, lockQueryState] = useAsyncCommand(
		() =>
			accountingMonthService.updateStatus(
				params.divisionId,
				props.accountingMonth.month,
				"locked"
			),
		{
			rethrowError: true,
		}
	);

	const monthDisplayName = formatYearMonth(props.accountingMonth.month);
	const dlg = useDialog();

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		dlg.showConfirmDialog({
			onConfirm: awaitWithDeferedCallback(
				handleLockMonth,
				props.onChangedStatus
			),
			title: "Monat wieder sperren",
			text: `Monat ${monthDisplayName} wieder sperren? Diese Aktion ändert den Status des Abrechnungsmonats 
				auf "Gesperrt". Damit können Belegkorrekturen vorgenommen werden , ohne dass die Benefit-User 
				neue Belege für den betreffenden Abrechnungsmonat über die App hochladen können.`,
			confirmText: "Wieder sperren",
		});
	};

	return (
		<ActionButton
			variant="outline-danger"
			onClick={handleClick}
			query={lockQueryState}
		>
			Monat wieder sperren
			<QueryError queryState={lockQueryState} />
		</ActionButton>
	);
}
