import { Properties } from "@util/types";

export default class VoucherImage {
	constructor(props: Properties<VoucherImage>) {
		this.voucherImageId = props.voucherImageId;
	}
	readonly voucherImageId: string;

	public static fromJson(data: any): VoucherImage {
		return new VoucherImage({ voucherImageId: data.voucherImageId });
	}
}
