import React from "react";
import { ProductIcon, Tooltip } from "@components";
import {
	getRootProductDescription,
	RootProductId,
	rootProductIds,
} from "@models";
import { Button, Group } from "./SelectReviewStatusControl";

type Props = {
	selection: RootProductId[];
	onSelectionChanged: (value: RootProductId[]) => void;
};

export default function SelectProductControl(props: Props) {
	return (
		<Group
			type="checkbox"
			variant="outline"
			value={props.selection}
			onChange={props.onSelectionChanged}
		>
			{rootProductIds.map((rootId) => (
				<Button
					key={rootId}
					value={rootId}
					title={getRootProductDescription(rootId).name}
				>
					<Tooltip
						id={`select-product-${rootId}`}
						tooltip={getRootProductDescription(rootId).name}
					>
						<div>
							<ProductIcon productId={rootId} />
						</div>
					</Tooltip>
				</Button>
			))}
		</Group>
	);
}
