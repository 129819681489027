import { Category } from "@models";
import { format } from "date-fns";

import LocaleDE from "date-fns/locale/de";

function formatCurrency(
	amountInCents: number,
	options?: {
		hideCurrency?: boolean;
		currency?: string;
		hideZeroDecimals?: boolean;
	}
) {
	const amountInEuros = amountInCents / 100;
	let amountStr = amountInEuros.toFixed(2).split(".").join(",");
	if (options?.hideZeroDecimals) {
		amountStr = amountInEuros.toString();
	}

	return options?.hideCurrency
		? amountStr
		: amountStr + (options?.currency ?? "€");
}

export const Currency = {
	format: formatCurrency,
};

export function formatCategoryName(category?: Category) {
	if (!category) return "unbekannt";

	const categoryName = category.longName ?? "unbekannt";
	if (category.id === category.product.id) {
		return category.longName;
	}
	const productName = category.product.name;
	return productName + " - " + categoryName;
}

export function formatYearMonth(date: Date) {
	return format(date, "MMMM yy", {
		locale: LocaleDE,
	});
}

export function formatDateTime(date: Date) {
	return format(date, "dd.MM.yyyy HH:mm:ss", {
		locale: LocaleDE,
	});
}
