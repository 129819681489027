import { useServices } from "@hooks";
import React, { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LoginPageOidc() {
	const { authService } = useServices();

	useEffect(() => {
		// do nothing other than redirect to the keycloak login url
		void authService.login();
	}, [authService]);

	return <></>;
}
