import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
	ActionIndicator,
	Checkbox,
	ExpandableTableRow,
	Icon,
	IconButton,
	IconsContainer,
	TableColumn,
} from "@components";
import { UserWithBudgetMasters } from "@models";
import Routes from "@service/navigation/routes";
import { useServices, useAsyncCommand } from "@hooks";
import UserProductSummary from "./UserProductSummary";
import UserStatusSummary from "./UserStatusSummary";
import UserOptionsDropdown from "./UserOptionsDropdown";
import { ResalutateUnexpectedStateError } from "@service/user/errors";
import UsersListContext from "../../UsersListContext";
import { defer } from "@util/helpers";
import ExpandedUserDetailsRow from "@components/users/ExpandedUserDetailsRow";

type Props = {
	user: UserWithBudgetMasters;
	isSelected: boolean;

	onSelect: (isSelected: boolean) => void;
};

export default function UserDetailsRow({ user, isSelected, onSelect }: Props) {
	const { userService } = useServices();

	const [deleteUserFn, deleteQueryResult] = useAsyncCommand(() =>
		userService.deleteUser([user.userId])
	);

	const [resalutateUserFn, resalutateUserQuery] = useAsyncCommand(
		() => userService.resalutate(user.userId),
		{ rethrowError: false }
	);

	const userPathParams = {
		customerId: user.customerId,
		divisionId: user.divisionId,
		userId: user.userId,
	};

	const usersListContext = useContext(UsersListContext);
	const refreshUsersList = () => defer(usersListContext.triggerRefresh);

	const userButtons = (
		<IconsContainer>
			<Link to={Routes.UserVouchers.makePath(userPathParams)}>
				<IconButton tooltip="Belege">
					<Icon.Vouchers />
				</IconButton>
			</Link>

			<Link to={Routes.UserProducts.makePath(userPathParams)}>
				<IconButton tooltip="Module">
					<Icon.Modules />
				</IconButton>
			</Link>

			<UserOptionsDropdown
				user={user}
				onDelete={() => {
					void deleteUserFn();
					void refreshUsersList();
				}}
				onResalutate={resalutateUserFn}
			/>
		</IconsContainer>
	);

	const row = (
		<>
			<TableColumn data-testid="user-column-name">
				<Checkbox
					value={isSelected}
					onClick={(e: React.MouseEvent<HTMLInputElement>) =>
						e.stopPropagation()
					}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						onSelect(e.target.checked)
					}
				/>
			</TableColumn>
			<ClickableColumn data-testid="user-column-personalNo">
				{user.personalNo}
			</ClickableColumn>
			<ClickableColumn data-testid="user-column-firstname">
				{user.firstname}
			</ClickableColumn>
			<ClickableColumn data-testid="user-column-lastname">
				{user.lastname}
			</ClickableColumn>

			<ClickableColumn>{user.email}</ClickableColumn>
			<ClickableColumn>{user.commentInternal}</ClickableColumn>
			<ClickableColumn>
				<UserProductSummary budgetMasters={user.budgetMasters} />
			</ClickableColumn>
			<ClickableColumn data-testid="userspage-isActive-user">
				<UserStatusSummary user={user} />
			</ClickableColumn>
			<TableColumn>
				<ActionIndicator
					css={`
						margin-left: 2em;
						width: 100%;
					`}
					queries={[
						{
							query: resalutateUserQuery,
							successText: "Gesendet",
							activeText: "Einladen...",
							mapError: (e) =>
								e instanceof ResalutateUnexpectedStateError
									? "Fehler: Benutzerkonto ist im falschen Zustand (z.B. bereits angemeldet)"
									: undefined,
						},
						{
							query: deleteQueryResult,
							successText: "Gelöscht",
							activeText: "Löschen...",
						},
					]}
				>
					{userButtons}
				</ActionIndicator>
			</TableColumn>
		</>
	);

	const expandedContent = (
		<td
			data-testid={`table-user-row-expanded-${user.userId}`}
			colSpan={9}
			style={{ border: "none" }}
		>
			<ExpandedUserDetailsRow user={user} budgetMasters={user.budgetMasters} />
		</td>
	);

	return (
		<UserAccordion
			expandedContent={expandedContent}
			data-testid={`table-user-row-${user.userId}`}
		>
			{row}
		</UserAccordion>
	);
}

const ClickableColumn = styled(TableColumn)`
	cursor: pointer;
`;

const UserAccordion = styled(ExpandableTableRow)`
	background: white !important;
	box-shadow: none !important;
`;

export const RelativeContainer = styled.div`
	position: relative;
	min-height: 50;
`;
