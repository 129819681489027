import styled from "styled-components";
import Card from "./Card";
import { colors } from "@theme/theming";

export const StyledCard = styled(Card)`
	display: flex;
	flex-direction: column;
	padding: 0;

	&.no-overflow {
		// we want to clip the collapsed content to the container box during animation.
		// However, we have to go back to overflow:visible to make the DatePicker popup visible
		overflow: hidden;
	}
	&.inactive {
		border-color: ${colors.gray.g200}30;
	}
`;

export const CardHeader = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	padding: 32px;
	cursor: pointer;

	&.inactive {
		background-color: ${colors.gray.g100};
		color: ${colors.gray.g400};
		font-style: italic;
	}
`;
export const SectionHeader = styled.h3`
	margin-bottom: 8px;
	margin-top: 32px;

	:first-of-type {
		margin-top: 0;
	}

	color: ${colors.gray.g400};
	font-weight: normal;
`;

export const LargeText = styled.div`
	font-size: 1.5rem;
`;

export const SmallText = styled.div`
	font-size: 1.1rem;
`;

export const CardHeaderContainer = styled.div`
	display: grid;
	width: 100%;
	gap: 16px;
	grid-template-columns: 12em 7em 12em 1fr auto 12em auto;
`;

export const IconWrapper = styled.span`
	color: ${colors.gray.g300};
	margin-right: 2px;
`;

export const CategoryCell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const IconContainer = styled.div`
	color: ${colors.gray.g400};
	font-size: 2rem;
	margin-right: 4px;
`;

export const CardContent = styled.div`
	padding: 0 16px 16px 16px;
`;

export const CardsListContainer = styled.div`
	margin-block: 8px 20px;
	display: grid;
	gap: 16px;
`;
