import { areIntervalsOverlapping, isAfter } from "date-fns";

type OpenDateInterval = {
	start: Date | null;
	end: Date | null;
};

export function areOpenIntervalsOverlapping(
	a: OpenDateInterval,
	b: OpenDateInterval
): boolean {
	if (a.start && a.end && b.start && b.end) {
		return areIntervalsOverlapping(
			{ start: a.start, end: a.end },
			{ start: b.start, end: b.end },
			{ inclusive: true }
		);
	}

	if (!a.start) {
		if (a.end) {
			// a: (unlimited, limited]
			if (b.start) {
				// a: (unlimited, limited]
				// b: [limited, unknown}]
				return !isAfter(b.start, a.end);
			} else {
				// a: (unlimited, limited]
				// b: (unlimited, unknown]
				return true;
			}
		} else {
			// a: (unlimited, unlimited)
			return true;
		}
	} else {
		// a: [limited, unknown)
		if (a.end) {
			// a: [limited, limited]
			if (b.start) {
				// a: [limited, limited]
				// b: [limited, unknown)
				return !isAfter(b.start, a.end);
			} else {
				// a: [limited, limited]
				// b: (unlimited, unknown)
				if (b.end) {
					// a: [limited, limited]
					// b: (unlimited, limited]
					return !isAfter(a.start, b.end);
				} else {
					// a: [limited, limited]
					// b: (unlimited, unlimited)
					return true;
				}
			}
		} else {
			// a: [limited, unlimited)
			if (b.start) {
				// a: [limited, unlimited)
				// b: [limited, unknown)

				if (b.end) {
					// a: [limited, unlimited)
					// b: [limited, limited]
					return !isAfter(a.start, b.end);
				} else {
					// a: [limited, unlimited)
					// b: [limited, unlimited)
					return true;
				}
			} else {
				// a: [limited, unlimited)
				// b: (unlimited, unknown)
				if (b.end) {
					// a: [limited, unlimited)
					// b: (unlimited, limited]
					return !isAfter(a.start, b.end);
				} else {
					// a: [limited, unlimited)
					// b: (unlimited, unlimited)
					return true;
				}
			}
		}
	}
}
