import { SetStateAction, useCallback, useEffect, useState } from "react";
import { assertNotNull } from "@service/util";

type QueryStateInitialFetching = {
	state: "initialFetching";
	data: null;
};

type QueryStateFetching<T> = {
	state: "fetching";
	data: T;
	progress?: number;
};

type QueryStateSuccess<T> = {
	state: "success";
	data: T;
};

export type QueryStateError = {
	state: "error";
	data: null;
	error: Error;
};

type QueryStateIdle = {
	data: null;
	state: "idle";
};

export type FetchQueryOptions = {
	/**
	 * If this option is `true`, when an Error is thrown while executing `queryFn` the error will be rethrown.
	 * Otherwise it will only be returned to the `QueryState`
	 *
	 * Defaults to `false`
	 * */
	rethrowError?: boolean;
};

export type QueryState<T> =
	| QueryStateInitialFetching
	| QueryStateFetching<T>
	| QueryStateSuccess<T>
	| QueryStateError
	| QueryStateIdle;

/**
 * Performs a backend query using pagination.
 *
 * @returns the fetched paginated data as well as the pagination state
 */
export default function useFetchedData<T>(
	queryFn: () => Promise<T>,
	options?: FetchQueryOptions
): [QueryState<T>, () => Promise<void>, (value: T) => void] {
	const [state, setState] = useState<QueryState<T>["state"]>("initialFetching");

	const [fetchedData, setFetchedData] = useState<T | undefined>(undefined);
	const [error, setError] = useState<Error | undefined>(undefined);

	const fetchData = useCallback(async () => {
		try {
			const queryResult = await queryFn();
			setFetchedData(queryResult);
			setError(undefined);
			setState("success");
		} catch (error) {
			console.error(error);
			setError(error as Error);
			setState("error");
			if (options?.rethrowError ?? false) {
				throw error;
			}
		}
	}, [queryFn, options]);

	useEffect(() => {
		setState((state) =>
			state === "initialFetching" ? "initialFetching" : "fetching"
		);
		setError(undefined);
		void fetchData();
	}, [fetchData]);

	const queryState = getQueryState<T>(state, error, fetchedData);

	const setManually = (setData: SetStateAction<T | undefined>) => {
		setFetchedData(setData);
		setError(undefined);
		setState("success");
	};

	return [queryState, fetchData, setManually];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
function getQueryState<T>(
	state: string,
	error: Error | undefined,
	fetchedData: T | undefined
): QueryState<T> {
	switch (state) {
		case "error":
			return {
				state: "error",
				error: assertNotNull(error)!,
				data: null,
			};
		case "initialFetching":
			return {
				state: "initialFetching",
				data: null,
			};
		case "fetching":
			return {
				state: "fetching",
				data: fetchedData!,
			};
		case "success":
			return {
				state: "success",
				data: fetchedData!,
			};
		default:
			throw new Error(`unknown state ${state}`);
	}
}
