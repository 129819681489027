import { useCustomer } from "@hooks";
import { Division } from "@models";
import React from "react";

type Props = {
	searchResult: Division;
};

export default function SearchResult(props: Props) {
	const { searchResult } = props;
	const customerQuery = useCustomer(searchResult.customerId);

	return (
		<>
			{customerQuery.state === "success" && (
				<>
					<div>Kunde: {customerQuery.data.name}</div>
					<div>Standort: {searchResult.name}</div>
				</>
			)}
		</>
	);
}
