import styled from "styled-components";

export const Amount = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.5rem;
	:nth-child(1) {
		margin: 10px;
	}
`;
