import { ExpenseAccountSystem } from "@models/FinancialAccountingSettings";
import React from "react";
import { Dropdown, SplitButton } from "react-bootstrap";

interface Props {
	handleSetDefault: (type: ExpenseAccountSystem) => void;
}

export default function FinancialOptionsDropdown({ handleSetDefault }: Props) {
	const options: ExpenseAccountSystem[] = ["skr03", "skr04"];

	return (
		<SplitButton
			menuAlign={{ xl: "left" }}
			title="Füllen Sie mit Standardwerten aus"
			id="financialOptionsDropdown"
			variant="secondary"
			css={`
				margin-right: 16px;
			`}
		>
			{options.map((option) => (
				<Dropdown.Item key={option} onSelect={() => handleSetDefault(option)}>
					{option.toLocaleUpperCase()}
				</Dropdown.Item>
			))}
		</SplitButton>
	);
}
