import React, { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Pagination, QueryStateIndicators } from "@components";

import { usePaginatedFetchedData, useServices, useDivision } from "@hooks";
import { DivisionRouteParams } from "@service/navigation/routes";
import ReportList from "./components/ReportList";
import { QueryOptions } from "@service/types";

export default function ReportsPage() {
	const [divisionQuery, triggerDivisionQuery] = useDivision();
	const { reportService } = useServices();
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;
	const [refreshPage, setRefreshPage] = useState(false);

	const fetchReports = useCallback(
		(options: QueryOptions) =>
			reportService.getReportsByDivision(divisionId, options),
		[divisionId, reportService]
	);

	const [reportQuery, triggerReportQuery] =
		usePaginatedFetchedData(fetchReports);

	if (refreshPage) {
		void triggerDivisionQuery();
		void triggerReportQuery();
		setRefreshPage(false);
	}

	return (
		<DataContainer>
			<QueryStateIndicators queryState={reportQuery} />

			{divisionQuery.state === "success" && reportQuery.state === "success" && (
				<>
					<Container>
						<ReportList
							reports={reportQuery.data}
							pagination={reportQuery.pagination}
							refreshPage={() => setRefreshPage(true)}
							division={divisionQuery.data}
						/>
					</Container>
					<Pagination queryState={reportQuery} />
				</>
			)}
		</DataContainer>
	);
}

const Container = styled.div`
	margin: 15px;
`;

const DataContainer = styled.div`
	position: relative;
`;
