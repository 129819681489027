import React from "react";
import { useForm } from "react-hook-form";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import {
	ActionButton,
	Button,
	Form,
	Input,
	InputValidation,
	Label,
	RequiredMarker,
	ScrollToTop,
	Select,
} from "@components";
import Routes, { CustomerRouteParams } from "@service/navigation/routes";
import { QueryState, useStaticData } from "@hooks";
import Customer, { NewCustomer } from "../../../models/Customer";
import { REQUIRED_ERROR_MESSAGE } from "@util/errors";

type Props = {
	customer?: NewCustomer;
	handleOnSubmit: (customer: NewCustomer) => void;
	cmdState: QueryState<Customer>;
};

export default function CustomerForm(props: Props) {
	const { params } = useRouteMatch<CustomerRouteParams>();
	const { customerId } = params;
	const { customer, handleOnSubmit, cmdState } = props;
	const { legalForms } = useStaticData();

	const newCustomer: NewCustomer = props.customer ?? {
		legalForm: legalForms[0].description,
		name: "",
		isArchived: false,
	};

	const { formState, register, handleSubmit } = useForm<NewCustomer>({
		defaultValues: customer ?? {
			legalForm: legalForms[0].description,
			name: "",
			isArchived: false,
		},
		mode: "onChange",
	});

	const onSubmitCustomerForm = handleSubmit((data: NewCustomer) => {
		handleOnSubmit({ ...newCustomer, ...data });
	});

	return (
		<>
			<ScrollToTop />
			<FormContainer>
				{customerId ? (
					<Heading>Kunden bearbeiten</Heading>
				) : (
					<Heading>Neuer Kunde</Heading>
				)}
				<Form onSubmit={onSubmitCustomerForm}>
					<InputValidation error={formState.errors.name}>
						<InputContainer className="form-group">
							<Label htmlFor="name">
								Name <RequiredMarker />
							</Label>
							<Input
								{...register("name", { required: REQUIRED_ERROR_MESSAGE })}
								type="text"
								id="name"
							/>
						</InputContainer>
					</InputValidation>
					<InputContainer className="form-group">
						<Label htmlFor="legalForm">
							Rechtsform <RequiredMarker />
						</Label>
						<Select
							{...register("legalForm", { required: true })}
							id="legalForm"
						>
							{legalForms.map((l) => (
								<option key={l.legalFormId} value={l.description}>
									{l.description}
								</option>
							))}
						</Select>
					</InputContainer>
					<InputContainer>
						<ActionButton
							type="submit"
							query={cmdState}
							successContent={"Gespeichert"}
						>
							Speichern
						</ActionButton>
						<Link to={Routes.Customers.makePath()}>
							<Button type="button" data-testid="loginButton">
								Zurück
							</Button>
						</Link>
					</InputContainer>
				</Form>
			</FormContainer>
		</>
	);
}

const InputContainer = styled.div``;

const Heading = styled.h3`
	text-align: center;
`;

const FormContainer = styled.div`
	margin: 0 auto;
	width: 350px;
`;
