import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
	DropDownToggleButton,
	DropDownWithIcon,
	Icon,
	Tooltip,
} from "@components";
import { useDialog } from "@hooks";
import { User } from "@models";
import Routes from "@service/navigation/routes";
import DropDownMenu from "@components/DropDownMenu";

type Props = {
	user: User;

	onDelete: () => void;
	onResalutate: () => void;
};

export default function UserOptionsDropdown(props: Props) {
	const { user } = props;

	const dialog = useDialog();

	const userPathParams = {
		customerId: user.customerId,
		divisionId: user.divisionId,
		userId: user.userId,
	};

	const handleDelete = (
		key: string | null,
		e: React.SyntheticEvent<MouseEvent>
	) => {
		e.stopPropagation();
		e.preventDefault();

		dialog.showConfirmDialog({
			text: "Sind Sie sicher, dass Sie den Benutzer löschen möchten?",
			confirmText: "Löschen",
			onConfirm: props.onDelete,
		});
	};

	const navigateToLinks = [
		{
			title: "Bearbeiten",
			icon: <Icon.Edit />,
			to: Routes.UserBasicData.makePath(userPathParams),
		},
		{
			title: "Belege",
			icon: <Icon.Vouchers />,
			to: Routes.UserVouchers.makePath(userPathParams),
		},
		{
			title: "Module",
			icon: <Icon.Modules />,
			to: Routes.UserProducts.makePath(userPathParams),
		},
		{
			title: "Dauerbelege",
			icon: <Icon.Contracts />,
			to: Routes.UserContracts.makePath(userPathParams),
		},
	];

	return (
		<Dropdown
			css={`
				align-self: center;
				cursor: default;
			`}
		>
			<ButtonGroup
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<DropDownToggleButton variant="outline-primary" size="sm">
					<Tooltip id="tooltip-userOptionsDropdown" tooltip={"Einstellungen"}>
						<Icon.Settings />
					</Tooltip>
				</DropDownToggleButton>
			</ButtonGroup>

			<DropDownMenu
				data-testid="userOptionsDropdown"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				{navigateToLinks.map((link, index) => (
					<DropDownLink key={index} to={link.to} icon={link.icon}>
						{link.title}
					</DropDownLink>
				))}

				<Dropdown.Divider />

				<DropDownWithIcon onSelect={props.onResalutate}>
					<Icon.Mail />
					<span>Erneut einladen</span>
				</DropDownWithIcon>

				<DropDownWithIcon onSelect={handleDelete} className="danger">
					<Icon.Delete />
					<span>Benutzer löschen</span>
				</DropDownWithIcon>
			</DropDownMenu>
		</Dropdown>
	);
}

const DropDownLink = (props: {
	to: string;
	children: string;
	icon?: React.ReactElement;
}) => {
	return (
		<DropDownWithIcon as="div">
			<Link to={props.to}>
				{props.icon}
				<label
					css={`
						margin-bottom: 0;
						cursor: pointer;
					`}
				>
					{props.children}
				</label>
			</Link>
		</DropDownWithIcon>
	);
};
