import styled from "styled-components";

export default styled.div`
	padding: 10px;
	border-radius: 5px;
	max-width: 1000px;
	margin: 10px;
	margin-left: auto;
	margin-right: auto;
`;
