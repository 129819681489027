import { ErrorBase } from "@util/errors";

export class MaintenanceServerError extends ErrorBase {
	constructor() {
		super(
			"Das System befindet sich im Wartungsmodus. Bitte in einigen Minuten nochmal probieren."
		);
	}
}

export class UnauthorizedError extends ErrorBase {
	constructor() {
		super("Du bist nicht berechtigt diese Aktion auszuführen.");
	}
}

export class NetworkError extends ErrorBase {
	constructor() {
		super("Bitte prüfe deine Internetverbindung.");
	}
}

export class InternalServerError extends ErrorBase {
	constructor() {
		super(
			"Serverfehler - Bitte aktualisiere die Seite und versuche es erneut."
		);
	}
}
