import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { BudgetMaster, Category, Contract, NewContract } from "@models";
import ContractCard from "./ContractCard";
import { LargeText, NoContentContainer } from "@components";
import ContractCategoriesDropdown from "./ContractCategoriesDropdown";
import {
	createDefaultContract,
	createVoucherWithAttachedContract,
} from "../util/createDefaultContract";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
	ContractsPageState,
	UserRouteParams,
} from "@service/navigation/routes";
import { useStaticData } from "@hooks";

type Props = {
	contracts: Contract[];
	budgetMasters: BudgetMaster[];
	initiallyExpanded?: string;
};

export default function ContractsList(props: Props) {
	const { contracts, budgetMasters, initiallyExpanded } = props;
	const { params } = useRouteMatch<UserRouteParams>();

	const currentDate = new Date();
	const sortedContracts = _.sortBy(contracts, (contract) =>
		contract.endDate ? (contract.endDate > currentDate ? -1 : 1) : -1
	);

	const newCardRef = useRef<HTMLDivElement>(null);
	const content: React.ReactNode[] = [];

	const [newContract, setNewContract] = useNewContract();

	if (newContract) {
		content.push(
			<div key="new" ref={newCardRef}>
				<ContractCard
					contract={newContract}
					isInitiallyExpanded
					discardContract={() => setNewContract(undefined)}
					budgetMasters={budgetMasters}
				/>
			</div>
		);
	}

	if (sortedContracts.length > 0) {
		content.push(
			<div key="existing">
				{sortedContracts.map((contract: Contract) => (
					<ContractCard
						key={contract.contractId}
						contract={contract}
						budgetMasters={budgetMasters}
						isInitiallyExpanded={contract.contractId === initiallyExpanded}
					/>
				))}
			</div>
		);
	}

	if (content.length === 0) {
		content.push(
			<NoContentContainer key="no-content">
				Der Benutzer hat keine Dauerbelege
			</NoContentContainer>
		);
	}

	const handleAddContract = (selectedCategory: Category) => {
		setNewContract(
			createDefaultContract(selectedCategory, params.userId, undefined)
		);
	};

	useEffect(() => {
		// after creating the new contract, the card ref is not available right away, so we have to defer this until after the next render
		if (newContract && newCardRef.current?.scrollIntoView) {
			newCardRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [newContract, newCardRef]);

	return (
		<>
			<Container>
				<Header>Dauerbelege</Header>
				<ContractCategoriesDropdown onClickAddContract={handleAddContract} />
			</Container>
			{content}
		</>
	);
}

const Header = styled(LargeText)`
	margin-bottom: 16px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 2px;
	margin-bottom: 10px;
`;

function useNewContract() {
	const { getCategory } = useStaticData();
	const routerState = useLocation<ContractsPageState>();
	const attachedVoucher = routerState.state?.attachedVoucher;

	return useState<NewContract | undefined>(() => {
		if (attachedVoucher) {
			const category = getCategory(attachedVoucher.category);
			if (category) {
				const contract = createVoucherWithAttachedContract(
					attachedVoucher,
					category
				);
				return contract;
			}
		}
	});
}
