import React, { useImperativeHandle } from "react";
import { format } from "date-fns";
import { jsonToCSV } from "react-papaparse";
import {
	BudgetMaster,
	BudgetMasterAmounts,
	UserWithBudgetMasters,
} from "@models";
import { useServices, useStaticData } from "@hooks";
import { StaticData } from "../../../context/StaticDataContext";
import { Currency, getDateFlags } from "@util";
import { useRouteMatch } from "react-router";
import { DivisionRouteParams } from "@service/navigation/routes";
import { saveAs } from "file-saver";

export const exportUsersCSVHeader = [
	"Personalnummer",
	"Vorname",
	"Nachname",
	"Email",
	"Telefon",
	"Geburtsdatum",
	"Befristet bis",
	"Belegeeinreichung erlauben",
	"Entgeldverzicht",
	"Kostenstelle",
	"Test-Benutzer",
];

export type ExportUsersCSVHandle = {
	export: (selected: UserWithBudgetMasters[]) => void;
};

const ExportUsersCSV = React.forwardRef<ExportUsersCSVHandle>((_, ref) => {
	const staticData = useStaticData();
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;

	const { divisionService } = useServices();

	const handleExport = async (selected: UserWithBudgetMasters[]) => {
		const csvFile = createCSVUsers(selected, staticData);
		const division = await divisionService.getDivisionById(divisionId);
		const filename = `${division.name}-Benutzer.csv`;
		await saveAs(new Blob([csvFile], { type: "text/csv" }), filename);
	};

	useImperativeHandle(
		ref,
		() => ({
			export: handleExport,
		}),
		[handleExport]
	);

	return <></>;
});

export default ExportUsersCSV;

export function createCSVUsers(
	users: UserWithBudgetMasters[],
	staticData: StaticData
) {
	const usersJson = users.map((u) => createUserJson(u, staticData));

	return jsonToCSV(usersJson, {
		delimiter: ";",
		columns: [
			...exportUsersCSVHeader,
			...staticData.products.map((p) => p.name),
		],
	});
}

function createUserJson(user: UserWithBudgetMasters, staticData: StaticData) {
	const products =
		user.budgetMasters.length === 0
			? []
			: formatProductsOfUser(user.budgetMasters, staticData);

	return Object.assign(
		{
			Personalnummer: user.personalNo,
			Vorname: user.firstname,
			Nachname: user.lastname,
			Email: user.email,
			Telefon: user.phone,
			Geburtsdatum: user.dateOfBirth
				? format(user.dateOfBirth, "dd.MM.yyyy")
				: "",
			"Befristet bis": user.contractEnd
				? format(user.contractEnd, "dd.MM.yyyy")
				: "",
			"Belegeeinreichung erlauben": user.ownVouchersEnabled ? "Ja" : "Nein",
			Entgeldverzicht: user.paymentReduction,
			Kostenstelle: user.costCenter,
			"Test-Benutzer": user.isTestUser ? "Ja" : "Nein",
		},
		...products
	);
}

function formatProductsOfUser(
	budgetMasters: BudgetMaster[],
	staticData: StaticData
) {
	const categories = budgetMasters
		.filter((bg) => getDateFlags(bg.availableFrom, bg.availableUntil).isActive)
		.map((bg) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const product = staticData.products.find((p) => p.id === bg.category)!;
			return {
				category: product.name,
				amount: getTotalAmount(bg.amounts),
			};
		});

	return categories.map((product) => ({
		[product.category]: product.amount,
	}));
}

export function getTotalAmount(amounts: BudgetMasterAmounts[]) {
	return Currency.format(
		amounts.reduce((sum, current) => sum + current.maxAmountMonth, 0),
		{ hideCurrency: true }
	);
}
