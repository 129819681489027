import React from "react";
import styled from "styled-components";
import {
	FormGroup,
	Label,
	NoAutoFillInput,
	Select,
	Button,
	ButtonsContainer,
	ActionButton,
	FormContainer,
	QueryStateIndicators,
	Form,
} from "@components";
import { Row, Col } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ReportTypeSetting } from "@models/ReportTypeSetting";
import { ReportType } from "@models/ReportType";

import { QueryState } from "@hooks";

type Props = {
	reportTypeId: string;
	setReportTypeId: (id: string) => void;
	reportTypeSettings: ReportTypeSetting[];
	payrollReportTypes: ReportType[];
	reportSettingsQuery: QueryState<void>;
	onClickCancel: () => void;
	onSubmit: (data: { [x: string]: string }) => void;
};

export default function PayrollAccountForm({
	reportTypeId,
	reportTypeSettings,
	setReportTypeId,
	payrollReportTypes,
	reportSettingsQuery,
	onClickCancel,
	onSubmit,
}: Props) {
	const formMethods = useForm({
		mode: "onChange",
	});

	const { handleSubmit, control, register, formState } = formMethods;

	const { isValid, isDirty } = formState;

	return (
		<FormProvider {...formMethods}>
			<FormContainer className="form">
				<QueryStateIndicators queryState={reportSettingsQuery} />
				<Form onSubmit={handleSubmit(onSubmit)}>
					<h2>Lohnbuchhaltung</h2>
					<Container>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="reportType">
								Reportart
							</Label>
							<Col sm={8}>
								<Controller
									name="reportType"
									defaultValue={reportTypeId}
									render={({ field }) => (
										<Select
											id="reportType"
											{...field}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setReportTypeId(e.target.value);
												field.onChange(e);
											}}
										>
											<option value=""></option>
											{payrollReportTypes.map((reportType) => (
												<option value={reportType.id} key={reportType.id}>
													{reportType.name}
												</option>
											))}
										</Select>
									)}
								></Controller>
							</Col>
						</FormGroup>

						{reportTypeSettings &&
							reportTypeSettings.map((setting) => (
								<FormGroup key={setting.name} as={Row}>
									<Label htmlFor={setting.name} column sm={4}>
										{setting.name}
									</Label>
									<Col sm={8}>
										{setting.path === "common\\accountingMonth" ? (
											<>
												<Select
													id={setting.name}
													defaultValue={setting.value}
													{...register(setting.path)}
												>
													<option value="current-month">
														Ausgewählter Monat
													</option>
													<option value="next-month">Folgemonat</option>
												</Select>
											</>
										) : (
											<Controller
												control={control}
												render={({ field }) => (
													<NoAutoFillInput
														id={setting.name}
														{...field}
													></NoAutoFillInput>
												)}
												name={setting.path.replace(/\./g, "_")}
												defaultValue={setting.value}
											/>
										)}
									</Col>
								</FormGroup>
							))}
						<ButtonsContainer>
							<ActionButton
								type="submit"
								query={reportSettingsQuery}
								successContent={"Gespeichert"}
								disabled={!isDirty || !isValid}
							>
								Speichern
							</ActionButton>
							<Button type="button" onClick={onClickCancel}>
								Zurück
							</Button>
						</ButtonsContainer>
					</Container>
				</Form>
			</FormContainer>
		</FormProvider>
	);
}

const Container = styled.div`
	padding: 2rem;
`;
