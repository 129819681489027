import React, { CSSProperties, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import {
	TransformWrapper,
	TransformComponent,
	ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import "./VoucherReviewImage.css";
import { Button } from "@components";

import {
	FaSearchPlus as ZoomInIcon,
	FaSearchMinus as ZoomOutIcon,
	FaTimes as ResetIcon,
	FaUndo as RotateLeftIcon,
	FaRedo as RotateRightIcon,
} from "react-icons/fa";

type Props = {
	containerSize: DOMRect;
	children: React.ReactElement;
	isPdf?: boolean;
	style?: CSSProperties;
};

export default function VoucherDocumentViewer(props: Props) {
	const { children, containerSize, isPdf } = props;
	const [rotation, setRotation] = useState(0);
	const rotate = useCallback(
		(direction: number) => {
			setRotation((rotation) => (rotation + 90 * direction) % 360);
		},
		[setRotation]
	);
	const [isFocused, setFocused] = useState(false);
	const defaultScaleAndPositionPdf = {
		...(isPdf && {
			defaultScale: 0.25,
			initialPositionX: 0,
			initialPositionY: containerSize?.height ? containerSize?.height : 0,
		}),
	};
	const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);
	return (
		<div
			onClick={() => setFocused(true)}
			onMouseLeave={() => setFocused(false)}
			style={{ ...props.style }}
		>
			<TransformWrapper
				ref={transformWrapperRef}
				limitToBounds={false}
				minScale={isPdf ? 0.1 : 0.3}
				wheel={{
					wheelDisabled: !isFocused,
				}}
				centerOnInit={!isPdf}
				{...defaultScaleAndPositionPdf}
			>
				<TransformChild>
					<ButtonContainer>
						<SmallButton
							onClick={() => transformWrapperRef.current?.zoomIn()}
							title="Hereinzoomen"
						>
							<ZoomInIcon />
						</SmallButton>
						<SmallButton
							onClick={() => transformWrapperRef.current?.zoomOut()}
							title="Herauszoomen"
						>
							<ZoomOutIcon />
						</SmallButton>

						<SmallButton onClick={() => rotate(-1)} title="Drehen links">
							<RotateLeftIcon />
						</SmallButton>
						<SmallButton onClick={() => rotate(1)} title="Drehen rechts">
							<RotateRightIcon />
						</SmallButton>
						<SmallButton
							onClick={() => {
								setRotation(0);
								transformWrapperRef.current?.resetTransform();
							}}
							title="Zurücksetzen"
						>
							<ResetIcon />
						</SmallButton>
					</ButtonContainer>

					<div style={{ position: "relative", flex: 1 }}>
						{isFocused && (
							<div style={{ position: "absolute", margin: 4, zIndex: 1 }}>
								Zoom mit Mausrad
							</div>
						)}
						<TransformComponent
							wrapperClass="image-wrapper"
							contentClass="image-content"
						>
							<Transformed rotate={rotation}>
								{React.cloneElement(children, {
									style: {
										objectFit: "contain",
										height: containerSize?.height || 300, // workaround: race condition might make the container huge
										width: containerSize?.width || 200, // if this is rendered before `containerSize` has been computed
									},
								})}
							</Transformed>
						</TransformComponent>
					</div>
				</TransformChild>
			</TransformWrapper>
		</div>
	);
}

const TransformChild = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	column-gap: 0.5rem;
`;

const ButtonContainer = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	height: fit-content;
	margin-top: 0.5rem;
	margin-left: 0.5rem;

	button {
		margin: 2px;
	}
`;

const SmallButton = styled(Button)`
	padding: 8px;
`;

const Transformed = styled.div`
	transform: rotate(${(props: { rotate: number }) => props.rotate}deg);
`;
