import { LoadingIndicator, RelativeContainer } from "@components";
import { useFetchedData, useServices } from "@hooks";
import { User, BudgetMaster } from "@models";
import React, { useCallback } from "react";
import ExpandedUserDetails from "./ExpandedUserDetails";

type Props = {
	user: User;
	budgetMasters: BudgetMaster[];
};

export default function ExpandedUserDetailsRow(props: Props) {
	const { user, budgetMasters } = props;
	const { userService } = useServices();

	const fetchUserStatus = useCallback(
		() => userService.getUserAuthenticationStatus(user.userId),
		[userService, user.userId]
	);

	const [userStatusQuery] = useFetchedData(fetchUserStatus);

	if (userStatusQuery.state === "success") {
		return (
			<ExpandedUserDetails
				user={user}
				budgetMasters={budgetMasters}
				userStatus={userStatusQuery.data.userStatus}
			/>
		);
	} else {
		return (
			<RelativeContainer>
				<LoadingIndicator />
			</RelativeContainer>
		);
	}
}
