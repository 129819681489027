/*  ss */
import { CurrencyInput } from "@components";
import { ReadonlyText } from "@components/vouchers/review/components/ReadonlyText";
import { EditableTrip } from "@models/travel/EditableTrip";
import { Currency } from "@util";
import React from "react";
import { Alert } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import useTripReviewContext from "../hooks/useTripReviewContext";

type Props = {
	id:
		| "overrides.mealsFlatRate"
		| "overrides.routeFlatRate"
		| "overrides.overnightStaysFlatRate";
};

export default function FlatRateOverride(props: Props) {
	const { id } = props;
	const { isTripEditable } = useTripReviewContext();

	const { watch, control } = useFormContext<EditableTrip>();
	const override = watch(id);

	return (
		<>
			<label htmlFor={id}>
				Pauschale mit diesem Wert überschreiben:
				<Controller
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					render={({ field: { ref, ...fieldRest } }) =>
						isTripEditable ? (
							<CurrencyInput id={id} {...fieldRest} allowNull />
						) : (
							<ReadonlyText
								value={fieldRest.value ? Currency.format(fieldRest.value) : "-"}
							/>
						)
					}
					control={control}
					name={id}
				/>
			</label>
			{override !== null && (
				<Alert variant="warning">
					Der berechnete Betrag wird durch den eingestellten Betrag von{" "}
					{Currency.format(override)} überschrieben.
				</Alert>
			)}
		</>
	);
}
