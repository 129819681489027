import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { CSVReader } from "react-papaparse";
import { Alert, ProgressBar, Spinner } from "react-bootstrap";
import { Button, ButtonsContainer, Icon, Text } from "@components";
import { DivisionRouteParams } from "@service/navigation/routes";
import styled from "styled-components";
import { useAsyncCommand, useServices } from "@hooks";
import { DefaultCsvError } from "@service/validation/errors";
import { ErrorBase } from "@util/errors";
import { CSVDataAndHeader, DownloadableCSV } from "./DownloadableCSV";
import { format } from "date-fns";
import { colors } from "@theme/theming";
import {
	CsvError,
	CsvImportType,
} from "@service/validation/validation.service";

const FILE_SIZE_LIMIT_MB = 25;

interface Props {
	onClickCloseModal: () => void;
	csvImportType: CsvImportType;
}

export default function UploadCSV({
	onClickCloseModal,
	csvImportType,
}: Props): ReactElement {
	const { validationService } = useServices();

	const abortController = useRef<AbortController | null>(null);
	const { divisionId } = useRouteMatch<DivisionRouteParams>().params;
	const [error, setError] = useState<string | string[] | null>(null);
	const [uploadedCsv, setUploadedCsv] = useState<File | null>(null);
	const [errorsCsv, setErrorsCsv] = useState<CSVDataAndHeader[] | null>(null);
	const [successMessage, setSuccessMessage] = useState<Record<
		string,
		number
	> | null>(null);

	const handleOnDropCsvImport = (_csv: any, file: File) => {
		handleOnRemoveAll();
		const fileExtension = file.name.substr(file.name.lastIndexOf("."));

		if (fileExtension !== ".csv") {
			return setError("Akzeptiert nur * .csv-Dateien");
		}

		// Size limit converted to Bytes
		if (file.size > FILE_SIZE_LIMIT_MB * 1024 * 1024) {
			setError(
				`Die ausgewählte Datei überschreitet die maximal zulässige Größe von ${FILE_SIZE_LIMIT_MB}MB. Bitte überprüfe, ob du die richtige Datei ausgewählt hast.`
			);
		}

		setUploadedCsv(file);
	};

	const handleImportError = (e: unknown, csv: File) => {
		const buildLineErrors = (errors: CsvError[]): string[] =>
			errors.map(
				(item: any) =>
					`Zeile: ${item.line} ` +
					`${item.field ? `Feld: ${item.field} ` : ""}` +
					`${item.message ? `Grund: ${item.message}` : `Typ: ${item.type}`}`
			);
		const buildErrorCsv = async (
			errors: CsvError[]
		): Promise<CSVDataAndHeader[]> => {
			const lines = (await csv.text()).split("\n");

			const errorsCsvTemp: CSVDataAndHeader[] = [
				{
					title: "Feld",
					accessor: "field",
					data: [],
				},
				{
					title: "Typ",
					accessor: "type",
					data: [],
				},
				{
					title: "Grund",
					accessor: "message",
					data: [],
				},
				{
					title: "##",
					accessor: "origin",
					data: [],
				},
				{
					title: `${lines[0].replace(/[\n\r]/g, "")}`,
					accessor: "line",
					data: [],
				},
			];

			errors.forEach((error) => {
				errorsCsvTemp[0].data.push(error.field ?? "");
				errorsCsvTemp[1].data.push(error.type);
				errorsCsvTemp[2].data.push(error.message ?? "");
				errorsCsvTemp[3].data.push("##");
				errorsCsvTemp[4].data.push(
					lines[Number(error.line) - 1].replace(/[\n\r]/g, "") ?? ""
				);
			});

			return errorsCsvTemp;
		};

		if (Array.isArray(e)) {
			setError(buildLineErrors(e));
			buildErrorCsv(e)
				.then(setErrorsCsv)
				.catch(() => undefined);
		} else {
			setError(
				e instanceof DefaultCsvError && e.message
					? e.message
					: e instanceof ErrorBase && e.message
					? e.message
					: "Ein Fehler ist aufgetreten."
			);
		}
	};

	const [triggerCsvValidationAndCreation, queryState, setProgress] =
		useAsyncCommand(
			async (
				divisionId: string,
				csvFile: File,
				csvImportType: CsvImportType,
				setProgress: (progress: number | undefined) => void
			) => {
				abortController.current = new AbortController();
				return validationService.importEntitiesFromCsv(
					divisionId,
					csvFile,
					csvImportType,
					abortController.current.signal,
					setProgress
				);
			}
		);

	const handleOnRemoveAll = () => {
		setUploadedCsv(null);
		setError(null);
		setErrorsCsv(null);
		setSuccessMessage(null);
		setProgress(undefined);
	};

	const handleOnSubmitCsvImport = async () => {
		if (!uploadedCsv) {
			setError("Keine Datei wurde gefunden.");
			return;
		}

		try {
			const { data: result } = await triggerCsvValidationAndCreation(
				divisionId,
				uploadedCsv,
				csvImportType,
				setProgress
			);

			if (result) {
				if ("errors" in result && result.errors.length) {
					handleImportError(result.errors, uploadedCsv);
				} else {
					setError(null);
				}

				if ("usersCreated" in result) {
					setSuccessMessage({
						"Neue User": result.usersCreated,
						"Aktualisierte User": result.usersUpdated,
						"User automatisch eingeladen": result.usersSalutated,
					});
					return;
				}

				if ("budgetsCreated" in result) {
					setSuccessMessage({
						"Neue Budgets": result.budgetsCreated,
						"Aktualisierte Budgets": result.budgetsUpdated,
					});
					return;
				}

				if ("contractsCreated" in result) {
					setSuccessMessage({
						"Neue Dauerbelege": result.contractsCreated,
						"Aktualisierte Dauerbelege": result.contractsUpdated,
					});
					return;
				}

				if ("couponsImported" in result) {
					setSuccessMessage({
						"Importierte Coupons": result.couponsImported,
					});
					return;
				}
			}
		} catch (e) {
			handleImportError(e, uploadedCsv);
		}
	};

	const showPartialSuccessAlert =
		// Only Sachbezug does not allow for partial success so we don't show the partial success message there
		Array.isArray(error) && csvImportType !== "sachbezugImport";

	const isButtonDisabled =
		!!error || uploadedCsv === null || queryState.state === "fetching";

	useEffect(() => {
		return () => {
			if (abortController.current) {
				abortController.current.abort();
			}
		};
	}, []);

	return (
		<UploadCSVContainer>
			<h5 css={"text-align: center"}>Ziehen Sie die hochzuladenden Dateien</h5>
			<CSVReader
				onDrop={handleOnDropCsvImport}
				accept=".csv"
				addRemoveButton={queryState.state !== "fetching"}
				noProgressBar
				onRemoveFile={handleOnRemoveAll}
				config={{
					header: true,
					skipEmptyLines: true,
				}}
			>
				<span css={"text-align: center"}>
					Legen Sie die CSV-Datei hier ab oder klicken Sie zum Hochladen.
				</span>

				<Text style={{ color: colors.gray.g400, fontSize: "0.9rem" }}>
					Bis zu {FILE_SIZE_LIMIT_MB}MB
				</Text>
			</CSVReader>
			{successMessage && (
				<Alert
					variant="success"
					style={{ textAlign: "center", marginTop: "1rem" }}
				>
					{Object.entries(successMessage).map(([key, value]) => (
						<div key={key}>
							{key}: {value}
						</div>
					))}
				</Alert>
			)}
			{error && (
				<>
					{showPartialSuccessAlert && (
						<Alert
							variant="success"
							style={{ textAlign: "center", marginTop: "1rem" }}
						>
							Alle Datensätze, die nicht in den folgenden Fehlern aufgeführt
							sind, wurden erfolgreich erstellt oder aktualisiert
						</Alert>
					)}
					<Alert
						variant="danger"
						style={{
							textAlign: "center",
							marginTop: "1rem",
							position: "relative",
							whiteSpace: "pre-line",
						}}
					>
						{Array.isArray(error) ? (
							<div>
								Die folgenden Fehler sind aufgetreten:
								{errorsCsv && (
									<ErrorsCSVDownloadButtons>
										<DownloadableCSV
											csvData={errorsCsv}
											fileName={`${
												uploadedCsv?.name.slice().replace(".csv", "-") ?? ""
											}${format(new Date(), "yyyyMMddHHmmss")}-errors`}
											testId="import-errors"
										>
											<Button variant="outline-primary">
												<Icon.Download
													style={{
														width: "1em",
														height: "1em",
														marginRight: "0.5ch",
													}}
												/>
												als CSV
											</Button>
										</DownloadableCSV>
									</ErrorsCSVDownloadButtons>
								)}
								<br />
								{error.map((err, idx) => (
									<React.Fragment key={idx}>
										{err}
										<br />
									</React.Fragment>
								))}
							</div>
						) : (
							error
						)}
					</Alert>
				</>
			)}
			{queryState.state === "fetching" && (
				<ProgressBarContainer>
					<ProgressBar
						style={{ height: "100%" }}
						variant="info"
						min={0}
						max={100}
						now={queryState.progress}
						label={
							<ProgressBarLabel>
								{queryState.progress !== undefined ? (
									`${queryState.progress}%`
								) : (
									<span>
										<Spinner
											style={{
												width: "1rem",
												height: "1rem",
											}}
											variant="info"
											animation="grow"
										/>{" "}
										Importiere Daten...
									</span>
								)}
							</ProgressBarLabel>
						}
					/>
				</ProgressBarContainer>
			)}
			<ButtonWrapper>
				<ButtonsContainer>
					{!successMessage && (
						<UploadButton
							type="button"
							onClick={handleOnSubmitCsvImport}
							disabled={isButtonDisabled}
						>
							Bestätigen
						</UploadButton>
					)}

					<Button type="button" onClick={onClickCloseModal}>
						{successMessage ? "Schließen" : "Abbrechen"}
					</Button>
				</ButtonsContainer>
			</ButtonWrapper>
		</UploadCSVContainer>
	);
}

const UploadCSVContainer = styled.div`
	// selected file wrapper
	input + div > div:first-child {
		width: 250px !important;
		padding: 0.5rem !important;

		// x button
		> :first-child {
			display: grid;
			top: 8px !important;
			right: 8px !important;
		}

		// file info
		:last-child {
			span {
				background-color: transparent !important;

				// file size
				&:first-child {
					font-size: 1.2rem;
					font-weight: 500;
				}
			}
		}
	}
`;

const ProgressBarContainer = styled.div`
	width: 100%;
	height: 26px;
	margin-top: 1rem;
	position: relative;

	.bg-info {
		transition: 0.3s ease all;
	}
`;

const ProgressBarLabel = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: black;
	font-weight: 500;
	font-size: 1rem;
`;

const UploadButton = styled(Button)`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const ButtonWrapper = styled.div`
	margin-top: 12px;
`;

const ErrorsCSVDownloadButtons = styled.span`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	display: flex;
	flex-direction: column;
`;
