import styled from "styled-components";

export const FormSection = styled.div`
	font-weight: bold;
`;

export const FormSectionContent = styled.div`
	margin-left: 16px;
	display: grid;
	grid-template-columns: auto 1fr;
	row-gap: 32px;
	column-gap: 32px;
	align-items: baseline;
`;

export const FormGrid = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: baseline;
`;
