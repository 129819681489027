import styled from "styled-components";
import Logo from "../images/logo.svg";

export default styled.img.attrs({
	src: Logo,
})`
	height: 31px;
	background-position: center;
	background-size: cover;
	border: 0;
`;
