import React from "react";
import { HintText, Label, Select } from "@components";
import { Division } from "@models";
import { format, setMonth } from "date-fns";
import _ from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import LocaleDE from "date-fns/locale/de";
import { reflect } from "@util";
import { numericSelectProps } from "./utils";
import { UpdatedDivision } from "@models/Division";
import styled from "styled-components";
import { Container } from "../StyledComponents";

const FIELD_NAME_SAVING_PERIOD = `${reflect<Division>().nameof(
	"excessBudgetSettings"
)}.${reflect<Division["excessBudgetSettings"]>().nameof(
	"savingPeriodStartMonth"
)}` as const;
const FIELD_NAME_EXPIRATION_MONTHS = `${reflect<Division>().nameof(
	"excessBudgetSettings"
)}.${reflect<Division["excessBudgetSettings"]>().nameof(
	"expirationTransitionTimeInMonths"
)}` as const;
const NULL_VALUE = "null";

export default function ExcessBudgetSettings() {
	const { control, watch } = useFormContext<UpdatedDivision>();

	const savingPeriodStartMonth = watch(
		FIELD_NAME_SAVING_PERIOD
	) as UpdatedDivision["excessBudgetSettings"]["savingPeriodStartMonth"];
	const expirationMonths = watch(
		FIELD_NAME_EXPIRATION_MONTHS
	) as UpdatedDivision["excessBudgetSettings"]["expirationTransitionTimeInMonths"];

	return (
		<Container>
			<h4>Überschuss-Budget</h4>
			<div>
				<Label htmlFor={FIELD_NAME_SAVING_PERIOD}>Startmonat Ansparphase</Label>
				<Controller
					control={control}
					name={FIELD_NAME_SAVING_PERIOD}
					render={({ field }) => (
						<Select
							id={FIELD_NAME_SAVING_PERIOD}
							{...field}
							{...numericSelectProps(field, NULL_VALUE)}
						>
							<option value={NULL_VALUE}>deaktiviert</option>
							{_.range(1, 13).map((monthNo) => (
								<option key={monthNo} value={monthNo}>
									{formatMonthName(monthNo)}
								</option>
							))}
						</Select>
					)}
				/>
				{!!savingPeriodStartMonth && (
					<StyledHint>
						Etwaige ungenutzte Beträge aus Budgets werden von{" "}
						{formatMonthName(savingPeriodStartMonth)} bis{" "}
						{formatMonthName((savingPeriodStartMonth + 11) % 12)} eines Jahres
						angesammelt.
						<br />
						<strong>Achtung!</strong> Dafür muss das Überschuss-Budget in den
						Budget-Einstellungen der Benutzer aktiviert sein.
					</StyledHint>
				)}
			</div>
			<div>
				<Label htmlFor={FIELD_NAME_EXPIRATION_MONTHS}>
					Länge der Übergangsphase, in der Budget aus voriger Ansparphase
					verbraucht werden kann, bis es verfällt
				</Label>
				<Controller
					control={control}
					name={FIELD_NAME_EXPIRATION_MONTHS}
					render={({ field }) => (
						<Select
							id={FIELD_NAME_EXPIRATION_MONTHS}
							{...field}
							{...numericSelectProps(field, NULL_VALUE)}
						>
							<option value={NULL_VALUE}>
								Sofortiger Verfall mit Ende der Ansparphase
							</option>
							{_.range(1, 6).map((monthNo) => (
								<option key={monthNo} value={monthNo}>
									{monthNo} Monate
								</option>
							))}
						</Select>
					)}
				/>
				{!!savingPeriodStartMonth && !!expirationMonths && (
					<StyledHint>
						Etwaige angesammelte Beträge aus der vorigen Ansparphase verfallen
						mit Wechsel auf den Monat{" "}
						{formatMonthName((savingPeriodStartMonth + expirationMonths) % 12)}.
					</StyledHint>
				)}

				{!!savingPeriodStartMonth && !expirationMonths && (
					<StyledHint>
						Angesammelte Beträge aus dem Überschussbudget verfallen sofort,
						sobald der erste Monat der Ansparphase erreicht wird.
					</StyledHint>
				)}
			</div>
		</Container>
	);
}

function formatMonthName(monthNo: number): React.ReactNode {
	return format(setMonth(new Date(), monthNo - 1), "MMMM", {
		locale: LocaleDE,
	});
}

const StyledHint = styled(HintText)`
	margin-block: 8px 0;
`;
