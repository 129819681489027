import Base64EncodedImage from "./Base64EncodedImage";
import { Properties } from "@util/types";

export default class VoucherImageFile {
	constructor(props: Omit<Properties<VoucherImageFile>, "fileExtension">) {
		this.voucherImageId = props.voucherImageId;
		this.imageData = props.imageData;
		this.mimeType = props.mimeType;
		this.loadedSuccessfully = props.loadedSuccessfully;
		this.isInfected = props.isInfected;

		if (this.mimeType.startsWith("application/pdf")) {
			this.fileExtension = "pdf";
		} else if (this.mimeType.startsWith("image/png")) {
			this.fileExtension = "png";
		} else {
			this.fileExtension = "jpg";
		}
	}
	readonly voucherImageId: string;
	readonly imageData: Base64EncodedImage;
	readonly mimeType: string;
	readonly fileExtension: "jpg" | "png" | "pdf";
	readonly loadedSuccessfully: boolean;
	readonly isInfected: boolean;

	public static fromSuccessfulResponse(
		voucherImageId: string,
		data: string,
		mimeType: string
	): VoucherImageFile {
		return new VoucherImageFile({
			voucherImageId: voucherImageId,
			imageData: data,
			mimeType,
			loadedSuccessfully: true,
			isInfected: false,
		});
	}

	public static fromError(props: { isInfected: boolean }): VoucherImageFile {
		return new VoucherImageFile({
			// kinda hacky
			voucherImageId: "",
			imageData: "",
			mimeType: "",
			loadedSuccessfully: false,
			isInfected: props.isInfected,
		});
	}
}
