import { useCallback } from "react";
import { UserWithBudgetMasters } from "@models";
import useServices from "./useServices";
import { PaginatedFetchedQueryResult, usePaginatedFetchedData } from "@hooks";
import { PaginatedQuery, QueryOptions } from "@service/types";

export default function useDivisionUsers(
	divisionId: string,
	queryOptions?: QueryOptions
): [PaginatedFetchedQueryResult<UserWithBudgetMasters>, () => Promise<void>] {
	const { userService } = useServices();
	const getUsersByDivisionQueryFn: PaginatedQuery<UserWithBudgetMasters> =
		useCallback(
			(options) =>
				userService.getUsersByDivision(divisionId, queryOptions ?? options),
			[userService, divisionId]
		);

	return usePaginatedFetchedData(getUsersByDivisionQueryFn);
}
