import {
	DropDownButton,
	DropDownWithIcon,
	Icon,
	QueryStateIndicators,
	Tooltip,
} from "@components";
import React, { useContext } from "react";
import { Alert } from "react-bootstrap";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { defer } from "@util/helpers";
import { BudgetMaster } from "@models";
import { alertStyle } from "@components/ErrorAlert";
import ProductsPageContext from "../ProductsPageContext";

type Props = {
	budgetMaster: BudgetMaster;
};

export default function BudgetMasterControlsDropdown(props: Props) {
	const pageContext = useContext(ProductsPageContext);
	const { productService } = useServices();
	const [deleteBudgetMasterFn, deleteQueryResult] = useAsyncCommand(
		() => productService.deleteBudgetMaster(props.budgetMaster.budgetMasterId),
		{ rethrowError: false }
	);

	const deleteProductDialog = useDialog();
	const onSelectDeleteBudgetMaster = () => {
		deleteProductDialog.showConfirmDialog({
			title: "Produkt löschen",
			text: "Produkt wirklich löschen? Wenn schon Belege hochgeladen wurden, wird empfohlen, stattdessen das Budget zeitlich zu begrenzen. Andernfalls wird die Verbindung der Belege zum Budget getrennt. Guthaben aus bestehenden Guthabenkonten gehen verloren. Das Budget kommt nicht mehr in Buchungslisten vor.",
			confirmText: "Löschen",
			onConfirm: async () => {
				const result = await deleteBudgetMasterFn();
				if (result.state === "success") {
					await defer(() => pageContext.triggerRefresh());
				}
			},
		});
	};

	return (
		<>
			<DropDownButton
				variant="outline-primary"
				id="budgetMasterControlsDropdown"
				title={
					<Tooltip
						id="budgetMasterControlsDropdownButton"
						tooltip={"Einstellungen"}
					>
						<Icon.Settings />
					</Tooltip>
				}
				size="sm"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<DropDownWithIcon
					onSelect={onSelectDeleteBudgetMaster}
					className="danger"
				>
					<Icon.Delete />
					<span>Löschen</span>
				</DropDownWithIcon>
			</DropDownButton>
			<QueryStateIndicators queryState={deleteQueryResult} />

			{deleteQueryResult.state === "success" && (
				<Alert style={alertStyle} variant="success">
					Produkt erfolgreich gelöscht
				</Alert>
			)}
		</>
	);
}
