import React, { useContext, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { RootProductId, Voucher } from "@models";
import { formatDate } from "@service/util";

import {
	FaUpload as UploadedIcon,
	FaCalendar as DateIcon,
	FaMoneyBillWave as AmountAfterReviewIcon,
	FaEquals as AmountIcon,
	FaFileInvoiceDollar as AmountOnVoucherIcon,
	FaTachometerAlt as KilometersIcon,
	FaClock as HoursIcon,
	FaCalendarCheck as ReviewIcon,
	FaCopy as CopyIcon,
	FaCheck as CheckIcon,
} from "react-icons/fa";
import { Currency, formatCategoryName } from "@util";
import {
	CardHeaderContainer,
	ProductIcon,
	StatusIcon,
	Tooltip,
	IconWrapper,
	CategoryCell,
	IconContainer,
	FlexCol,
	AnimatedCollapsed,
	FlexRow,
} from "@components";
import { useCategory } from "@hooks";
import ReviewContext from "./review/context/ReviewContext";
import { Badge } from "react-bootstrap";
import { differenceInSeconds } from "date-fns";
import { isCommuteVehicleVoucher } from "./review/utils";
import TripContext from "../../pages/trips/context/TripContext";
import { colors } from "@theme/theming";
import ContractVoucherMarker from "@components/vouchers/ContractVoucherMarker";

type Props = {
	voucher: Voucher;
	expanded?: boolean;
	style?: CSSProperties;
};

export default function VoucherCardHeader(props: Props) {
	const { voucher, expanded } = props;
	const [copied, setCopied] = useState(false);

	const category = useCategory(voucher.category);

	const isCommuteVehicle = isCommuteVehicleVoucher(voucher, category);
	const isShortTime = category.id === "shortTime";

	const reviewContext = useContext(ReviewContext);
	// NOTE: since updated and created currently don't have the same timestamp when a voucher is created we use a heuristic here
	// this should be fixed in the backend though

	const isProcessed =
		!!reviewContext &&
		differenceInSeconds(voucher.updated, voucher.created) > 30;

	const tripContext = useContext(TripContext);
	const embedded = !!tripContext;

	const copyVoucherId = (e: React.MouseEvent<HTMLSpanElement>) => {
		e.stopPropagation();
		navigator.clipboard
			.writeText(`${voucher.voucherId}-${Date.now()}`)
			.then(() => {
				setCopied(true);
				setTimeout(() => {
					setCopied(false);
				}, 1000);
			})
			.catch(() => console.log("Error copying"));
	};

	let expandedAmounts = (
		<div title="Betrag (angegeben)">
			<IconWrapper>
				<AmountOnVoucherIcon />
			</IconWrapper>
			{Currency.format(voucher.amountOnVoucher)}
		</div>
	);

	if (isCommuteVehicle && voucher.sourceValue) {
		expandedAmounts = (
			<div title="Entfernung (km)">
				<IconWrapper>
					<KilometersIcon />
				</IconWrapper>
				{(voucher.sourceValue / 1000).toFixed(1)} km
			</div>
		);
	}

	let amounts = (
		<>
			<div title="Geprüfter Betrag">
				<IconWrapper>
					<AmountAfterReviewIcon />
				</IconWrapper>
				{Currency.format(voucher.amountAfterReview)}
			</div>

			<AnimatedCollapsed expanded={expanded}>
				{expandedAmounts}
			</AnimatedCollapsed>
			{!embedded && (
				<div title="Betrag (im Budget)">
					<IconWrapper>
						<AmountIcon />
					</IconWrapper>
					{Currency.format(voucher.amount)}
				</div>
			)}
		</>
	);

	if (isShortTime && voucher.sourceValue !== null) {
		amounts = (
			<div title="Angegebene Minderstunden">
				<IconWrapper>
					<HoursIcon />
				</IconWrapper>
				{voucher.sourceValue.toFixed(1)} h
			</div>
		);
	}

	return (
		<CardHeaderContainer>
			<StyledFlexCol>
				<div title="Belegdatum">
					<IconWrapper>
						<DateIcon />
					</IconWrapper>
					{formatDate(voucher.dateOnVoucher)}
				</div>
				<div title="Hochgeladen am">
					<IconWrapper>
						<UploadedIcon />
					</IconWrapper>
					{formatDate(voucher.created)}
				</div>
				<AnimatedCollapsed expanded={expanded}>
					{!!voucher.reviewDate && (
						<div title="Geprüft am">
							<IconWrapper>
								<ReviewIcon />
							</IconWrapper>
							{formatDate(voucher.reviewDate)}
						</div>
					)}
				</AnimatedCollapsed>
			</StyledFlexCol>
			<StyledFlexCol>{amounts}</StyledFlexCol>
			{"userName" in voucher ? (
				<div>
					<div>{voucher.userName}</div>
					<div className="text-muted small">{voucher.customerName}</div>
					<div className="text-muted small">{voucher.divisionName}</div>
				</div>
			) : (
				<div></div>
			)}
			<CategoryCell>
				<IconContainer>
					<ProductIcon productId={category.root.id as RootProductId} />
				</IconContainer>
				<StyledFlexCol>
					<div>{formatCategoryName(category)}</div>
					{voucher.contractId && <ContractVoucherMarker voucher={voucher} />}
				</StyledFlexCol>
			</CategoryCell>
			<StyledFlexCol>{voucher.supplierName}</StyledFlexCol>
			<StyledFlexCol>
				<FlexRow>
					<div className="text-muted small">{voucher.voucherId}</div>
					<div>
						<CopyIconWrapper onClick={copyVoucherId} title="Kopieren">
							{copied ? <CopiedCheckIcon /> : <CopyIcon />}
						</CopyIconWrapper>
					</div>
				</FlexRow>
				<FlexRow>
					<StyledFlexCol style={{ flex: 1 }}>
						{isProcessed && (
							<Tooltip
								id="processed-badge"
								tooltip="Der Beleg wurde nach dem Hochladen bereits bearbeitet"
							>
								<Badge variant="secondary">bearbeitet</Badge>
							</Tooltip>
						)}
					</StyledFlexCol>
					{!embedded && (
						<div>
							<IconContainer>
								<StatusIcon voucher={voucher} />
							</IconContainer>
						</div>
					)}
				</FlexRow>
			</StyledFlexCol>
		</CardHeaderContainer>
	);
}

export const StyledFlexCol = styled(FlexCol)`
	margin: 0px 8px;
	justify-content: center;
`;

const CopyIconWrapper = styled.div`
	transition: color 0.3s, border-color 0.3s;
	border: solid 1px ${colors.gray.g300};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	color: ${colors.gray.g300};
	&:hover {
		color: ${colors.gray.g400};
		border-color: ${colors.gray.g400};
	}
`;

const CopiedCheckIcon = styled(CheckIcon)`
	color: ${colors.success.s300};
	animation: iconAnimation 0.3s ease-in-out;
	@keyframes iconAnimation {
		0% {
			transform: scale(0.5);
		}
		70% {
			transform: scale(1.1);
		}
		100% {
			transform: scale(1);
		}
	}
`;
