import React from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function AnimatedCollapsed({
	expanded,
	children,
}: {
	expanded?: boolean;
	children: React.ReactNode;
}) {
	return (
		<AnimatePresence initial={false}>
			{expanded && (
				<motion.div
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
						open: { opacity: 1, height: "auto" },
						collapsed: { opacity: 0, height: 0 },
					}}
					transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
}
