import { Icon, IconButton, Label } from "@components";
import { formatDate } from "@components/forms/datePickerUtils";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import CheckedLabel from "./CheckedLabel";
import { HeaderFunctionProps, PricingCardProps } from "./IPricingCardProps";

export default function PricingRecordHeader(
	props: PricingCardProps & HeaderFunctionProps
) {
	const { index, onDelete, onCopy, pricingSettings } = props;

	const pricingRecord = pricingSettings[index];

	const handleCopy = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onCopy(index);
	};

	const removePricingRecord = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onDelete(index);
	};

	return (
		<Row style={{ width: "100%" }}>
			<Label column sm={5}>
				{pricingRecord.availableFrom
					? formatDate(pricingRecord.availableFrom)
					: "unbefristet"}{" "}
				-{" "}
				{pricingRecord?.availableUntil
					? formatDate(pricingRecord.availableUntil)
					: "unbefristet"}
			</Label>
			<Col sm={5}>
				<CheckedLabel
					value={!!pricingRecord?.flatFeePerEmployee}
					text={"Festpreis pro Mitarbeiter"}
				/>
				<CheckedLabel
					value={!!pricingRecord?.feePerModuleAndEmployee}
					text={"Preis pro Mitarbeiter/Modul"}
				/>
				<CheckedLabel value={!!pricingRecord?.flatFee} text={"Festpreis"} />
			</Col>
			<Col
				sm={2}
				css={`
					text-align: end;
				`}
			>
				<CopyButton onClick={handleCopy} tooltip="Einstellungen kopieren">
					<Icon.Copy style={{ fontSize: "20px" }} />
				</CopyButton>
				<DeleteButton tooltip="Löschen" onClick={removePricingRecord}>
					<Icon.Delete style={{ fontSize: "20px" }} />
				</DeleteButton>
			</Col>
		</Row>
	);
}

const CopyButton = styled(IconButton)`
	background-color: transparent;
	padding: 5px;
	width: fit-content;
	justify-self: end;

	& > * {
		margin: 0;
	}
`;

const DeleteButton = styled(IconButton)`
	background-color: transparent;
	padding: 5px;
	width: fit-content;
	justify-self: end;

	& > * {
		margin: 0;
	}
`;
