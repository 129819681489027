import { Properties } from "@util/types";

export default class TravelExpense {
	constructor(props: Properties<TravelExpense>) {
		this.id = props.id;
		this.name = props.name;
		this.voucherId = props.voucherId;
		this.paymentSource = props.paymentSource;
	}

	readonly id: string;
	readonly name: string | null;
	readonly voucherId: string | null;
	readonly paymentSource: "employer" | "employee";

	get displayName() {
		return "Auslage";
	}

	static fromJson(data: any) {
		return new TravelExpense(data);
	}
}
