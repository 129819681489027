import React, { useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { getMonthOptions, getPreviousReportingMonth } from "../util";
import { Button, DropDownToggleButton, Icon } from "../../../components";
import DropDownMenu from "@components/DropDownMenu";

type Props = {
	onClickGenerateReport: (month: string) => void;
};

export default function ReportMonthDropdown(props: Props) {
	const { onClickGenerateReport } = props;
	const [selectedMonth, setSelectedMonth] = useState(
		getPreviousReportingMonth()
	);

	const monthOptions: string[] = getMonthOptions();

	const onSelectReportMonth = (key: string | null) => {
		if (key) {
			setSelectedMonth(key);
		}
	};

	return (
		<Dropdown as={ButtonGroup} onSelect={onSelectReportMonth}>
			<Button
				variant="outline-primary"
				onClick={() => onClickGenerateReport(selectedMonth)}
				data-testid="reportingMonthDropdownButton"
				style={{ borderRight: "none", marginRight: 0 }}
			>
				<Icon.Add /> {selectedMonth}
			</Button>
			<DropDownToggleButton
				split
				variant="outline-primary"
				id="dropdown-split-basic"
				data-testid="reportingMonthDropdownToggle"
			/>

			<DropDownMenu id="nav-dropdown">
				{monthOptions.reverse().map((month, idx) => (
					<Dropdown.Item key={idx} eventKey={month}>
						{month}
					</Dropdown.Item>
				))}
			</DropDownMenu>
		</Dropdown>
	);
}
