import React, { useState } from "react";
import { saveAs } from "file-saver";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { Alert, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
	ActionButton,
	Button,
	ButtonContainer,
	FormContainer,
	Icon,
	Label,
	QueryStateIndicators,
	Select,
} from "@components";
import {
	RootProductId,
	rootProductIds,
	getRootProductDescription,
} from "@models";
import { useAsyncCommand, useServices } from "@hooks";
import { DivisionRouteParams } from "@service/navigation/routes";
import { BalanceTransactionsPayload } from "@service/balanceTransaction/balanceTransaction.service";
import { getExampleFile } from "../../util";
import { ComponentProps } from "@util/types";
import { defer } from "@util/helpers";
import { canConfigureBalanceAccount } from "../../../products/components/util";

type FormInput = {
	product: string;
	month: string;
	format: string;
};

type Props = {
	onClickCloseModal: () => void;
};

export default function ChargeBalanceAccountsForm(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { divisionId } = params;
	const { register, handleSubmit, getValues } = useForm<FormInput>();
	const allProducts = rootProductIds.filter(canConfigureBalanceAccount);

	const [balanceTransactionFile, setBalanceTransactionFile] = useState<
		File | string
	>("");
	const [fileSelected, setFileSelected] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState("next-month");

	const style: ComponentProps<typeof Button> = fileSelected
		? { variant: "success" }
		: {};

	const { balanceTransactionService } = useServices();

	const [addBalanceTransactions, addQuery] = useAsyncCommand(
		(payload: BalanceTransactionsPayload) => {
			return balanceTransactionService.addBalanceTransactions(
				divisionId,
				payload
			);
		}
	);

	const handleFormSubmit = handleSubmit(async (formValues: FormInput) => {
		const reader = new FileReader();
		reader.onload = async () => {
			const csvLines = reader.result as string;
			const payload: BalanceTransactionsPayload = {
				csv: csvLines,
				fileFormat: formValues.format,
				product: formValues.product,
				month: formValues.month,
			};
			const result = await addBalanceTransactions(payload);
			if (result.state === "success") {
				await defer(() => props.onClickCloseModal());
			}
		};

		const blob = new Blob([balanceTransactionFile]);
		reader.readAsText(blob);
	});

	const onClickDownloadFile = async () => {
		const { product, month } = getValues();
		const response =
			await balanceTransactionService.getBalanceTransactionsTemplateFile(
				divisionId,
				product,
				month
			);

		const file = getExampleFile(response);
		saveAs(file);
	};

	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target && e.target.files) {
			setBalanceTransactionFile(e.target.files[0]);
			setFileSelected(true);
		}
	};

	return (
		<FormContainer className="form">
			<Form onSubmit={handleFormSubmit}>
				<FormGroup as={Row}>
					<Label column sm={4} htmlFor="product">
						Produkt
					</Label>
					<Col sm={8}>
						<Select
							id="product"
							{...register("product")}
							defaultValue={"spend" as RootProductId}
						>
							{allProducts.map((product: RootProductId) => (
								<option value={product} key={product}>
									{getRootProductDescription(product).name}
								</option>
							))}
						</Select>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<Label column sm={4} htmlFor="month">
						Monat
					</Label>
					<Col sm={8}>
						<Select
							id="month"
							{...register("month")}
							defaultValue={"next-month"}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setSelectedMonth(e.target.value)
							}
						>
							<option value="current-month">Aktueller Monat</option>
							<option value="next-month">Folgemonat</option>
						</Select>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<Label column sm={4} htmlFor="format">
						Format
					</Label>
					<Col sm={8}>
						<Select
							id="format"
							{...register("format")}
							defaultValue={"default"}
						>
							<option value="default">Standardformat</option>
							<option value="medifox">Medifox (DPG)</option>
						</Select>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<Col sm={4}></Col>
					<Col sm={8}>
						<Input
							type="file"
							accept="image/jpeg;capture=camera"
							onChange={handleFileSelection}
							ref={inputRef}
							data-testid="uploadFileInput"
						/>
						<Button {...style} onClick={() => inputRef.current?.click()}>
							{fileSelected ? (
								<>
									<Icon.Success /> Datei ausgewählt
								</>
							) : (
								<>
									<Icon.CSV /> Datei auswählen
								</>
							)}
						</Button>{" "}
						oder{" "}
						<Button variant="outline-primary" onClick={onClickDownloadFile}>
							<Icon.Download /> Vorlage-Datei herunterladen
						</Button>
					</Col>
				</FormGroup>

				{fileSelected && (
					<FormGroup as={Row}>
						<Alert variant="info">
							Bestehende Guthabenkonten werden mit den Beträgen in der Datei
							aufgeladen und für den{" "}
							{selectedMonth === "next-month"
								? "Folgemonat"
								: "Aktueller Monat"}{" "}
							gutgeschrieben
						</Alert>
					</FormGroup>
				)}

				<FormGroup as={Row}>
					<Col sm={4}></Col>
					<Col sm={8}>
						<ButtonContainer>
							<Button
								variant="outline-primary"
								onClick={props.onClickCloseModal}
							>
								Abbrechen
							</Button>
							<ActionButton
								type="submit"
								query={addQuery}
								successContent={"Gespeichert"}
								disabled={!fileSelected}
							>
								Konten aufladen
							</ActionButton>
						</ButtonContainer>
					</Col>
				</FormGroup>
			</Form>
			<QueryStateIndicators queryState={addQuery} />
		</FormContainer>
	);
}

const Input = styled.input`
	width: 100%;
	margin-bottom: 5px;
	display: none;
`;
