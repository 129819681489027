import { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useFetchedData, useServices } from "@hooks";
import { CustomerRouteParams } from "@service/navigation/routes";

export default function useCustomer(customerId?: string) {
	const { params } = useRouteMatch<CustomerRouteParams>();

	const services = useServices();

	const fetchCustomerFn = useCallback(
		() =>
			services.customerService.getCustomerById(customerId ?? params.customerId),
		[services.customerService, customerId, params.customerId]
	);

	return useFetchedData(fetchCustomerFn)[0];
}
