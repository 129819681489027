import { Properties } from "@util/types";
import ExpenseBeneficiary from "./ExpenseBeneficiary";
import TravelExpense from "./TravelExpense";

export default class GiftExpense extends TravelExpense {
	constructor(props: Properties<GiftExpense>) {
		super(props);
		this.beneficiaries = props.beneficiaries;
	}

	readonly beneficiaries: ReadonlyArray<ExpenseBeneficiary>;

	static readonly type = "gift";

	override get displayName() {
		return "Gastgeschenk";
	}

	static fromJson(data: any) {
		return new GiftExpense({
			...data,
			...TravelExpense.fromJson(data),
			beneficiaries: data.beneficiaries.map(ExpenseBeneficiary.fromJson),
		});
	}
}
