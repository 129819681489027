import { CheckboxToggle, FormGroup } from "@components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ConfigBudgetMaster } from "./types";

export default function BudgetMasterTravelSettings() {
	return (
		<FormGroup>
			<FlatRateSetting
				label="Verpflegungspauschale"
				name="settings.mealsFlatrate"
			/>
			<FlatRateSetting
				label="Übernachtungspauschale"
				name="settings.overnightStaysFlatrate"
			/>
			<FlatRateSetting
				label="Kilometerpauschale"
				name="settings.transportFlatrate"
			/>
		</FormGroup>
	);
}

type TravelSettingProps = {
	label: string;
	name:
		| "settings.mealsFlatrate"
		| "settings.overnightStaysFlatrate"
		| "settings.transportFlatrate";
	toggleTestId?: string;
};

function FlatRateSetting(props: TravelSettingProps) {
	const { register } = useFormContext<ConfigBudgetMaster>();
	const { toggleTestId, label, name } = props;

	return (
		<CheckboxToggle
			label={label}
			data-testid={toggleTestId}
			{...register(name)}
		/>
	);
}
